import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/bannerRegister.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// import { PermissionService } from "services/PermissionService";
// import { CRMService } from "services/CRMService";
// import { CongTyService } from "services/CongTyService";
// import { NhanVienService } from "services/NhanVienService";
import bgsuccess from "assets/img/auth/bg-success.jpeg";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { PermissionService } from "services/PermissionService";
import { notification } from "antd";
import { HeThongService } from "services/HeThongService";
import _ from "lodash";

function Register() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = useState(1);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const defaultData = {
    Server: "beehome",
    TenCT: "",
    MaCT: "",
    DienThoai: "",
    OTP: "",
    Password: "",
    RePassword: "",
  };
  const [data, setData] = useState(defaultData);
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");
  const handleClick = () => setShow(!show);
  const [nhomsp, setNhomsp] = useState([]);
  const [nhommodules, setNhommodules] = useState([]);
  function checkString(inputString) {
    // Kiểm tra tiếng Việt có dấu
    const vietnameseWithTone =
      /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ]/;
    // Kiểm tra ký tự đặc biệt
    const specialCharacter = /[^a-zA-Z0-9\s]/;
    // Kiểm tra khoảng trắng
    const whitespace = /\s/;
    return (
      vietnameseWithTone.test(inputString) ||
      specialCharacter.test(inputString) ||
      whitespace.test(inputString)
    );
  }
  function isValidVietnamesePhoneNumber(phoneNumber) {
    const vietnamesePhoneRegex = /^0[35789]\d{8}$/;
    return vietnamesePhoneRegex.test(phoneNumber);
  }
  const filterNSP = async () => {
    let _res = await PermissionService.NhomModules.getNhomModule();
    setNhommodules(_res.data ?? []);
    if(_res.data.length > 0){
      let _data = _.cloneDeep(data);
      _data.PackageID = _res.data[0]?.ID;
      setData(_data);
    }
  };
  useEffect(() => {
    // CongTyService.NhomModules.Get().then((res) => {
    //   setNhomsp(res?.data ?? []);
    // });
    filterNSP();
  }, []);
  const login = async () => {
    try {
      if (data?.DienThoai?.length === 0 || data?.TenCT?.length === 0) {
        setErr("Nhập đầy đủ thông tin");
        return;
      }
      let phone = isValidVietnamesePhoneNumber(data?.DienThoai);
      if (phone === false) {
        setErr("Vui lòng nhập đúng số điện thoại");
        return;
      } else {
        setErr("");
      }
      setLoading(true);
      let _otp = Math.floor(Math.random() * 9999);
      setOtp(_otp.toString());
      let _res = await HeThongService.Zalo.SendOTP({
        Phone: data?.DienThoai,
        OTP: _otp.toString(),
      });
      if (_res.status === 2000) {
        setStep(2);
      } else {
        setErr(_res.message);
      }
      setLoading(false);
    } catch {}
  };

  const confirmOTP = async () => {
    try {
      if (data?.OTP !== otp) {
        setErr("Vui lòng nhập đúng mã OTP gửi về điện thoại của bạn!");
        return;
      }
      setErr("");
      setStep(3);
    } catch {}
  };
  const handleDone = async () => {
    try {
      if (data?.MaCT?.length === 0 || data?.Password?.length === 0) {
        setErr("Vui lòng nhập đầy đủ thông tin!");
        return;
      }
      if (data?.MaCT?.length < 4) {
        setErr("Mã công ty phải lớn hơn 3 ký tự!");
        return;
      }
      let a = checkString(data?.MaCT);
      if (a === true) {
        setErr("Mã công ty chỉ được nhập chữ không dấu và chữ số!");
        return;
      }
      if (data.Password !== data?.RePassword) {
        setErr("Mật khẩu chưa trùng khớp. Vui lòng kiểm tra lại!");
        return;
      }
      let _payload = {
        ...data,
        TaiKhoan: data.DienThoai,
        MatKhau: data?.Password,
      };
      setLoading(true);
      let _res = await PermissionService.CongTy.addCT(_payload);
      if (_res.status === 2000) {
        setStep(4);
        openNotification("success", "topRight", `Thêm công ty thành công!`);
      } else {
        openNotification("error", "topRight", _res?.message);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };
  const applyChange = (p, v) => {
    let _data = Object.assign({}, data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const renderStep1 = () => {
    return (
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Đăng ký
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Nhập thông tin cần thiết để bắt đầu quản lý với chúng tôi!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tên công ty/ Shop<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="BEESKY"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.TenCT}
              onChange={(e) => applyChange("TenCT", e.target.value)}
            />
            {/* <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Mã gian hàng
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="BEESKY"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.MaCT}
              onChange={(e) => applyChange("MaCT", e.target.value)}
            /> */}
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Chọn gói<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data?.PackageID}
              onChange={(e) => applyChange("PackageID", e.target.value)}
            >
              {nhommodules?.map((item) => {
                return <option value={item.ID}>{item.Name}</option>;
              })}
            </Select>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Số điện thoại<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="number"
              placeholder="0986666666"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.DienThoai}
              onChange={(e) => applyChange("DienThoai", e.target.value)}
            />

            {err && <p style={{ color: "red" }}>{err}</p>}
            <Button
              isLoading={loading}
              onClick={() => login()}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Đăng ký
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Đã có tài khoản?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Đăng nhập
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  const renderStep2 = () => {
    return (
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            NHẬP MÃ OTP
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Vui lòng kiểm tra Zalo hoặc tin nhắn điện thoại!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Mã OTP<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="6666"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.OTP}
              onChange={(e) => applyChange("OTP", e.target.value)}
            />
            {err && <p style={{ color: "red" }}>{err}</p>}
            <Button
              isLoading={loading}
              onClick={() => confirmOTP()}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Xác nhận
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Đã có tài khoản?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Đăng nhập
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  const renderStep3 = () => {
    return (
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Thông tin đăng nhập
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Bước cuối cùng. Vui lòng nhập thông tin để đăng nhập!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Mã công ty
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Min 4. Chữ không dấu và chữ số!"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.MaCT}
              onChange={(e) => applyChange("MaCT", e.target.value)}
            />

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Mật khẩu<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="12345678"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={data.Password}
                onChange={(e) => applyChange("Password", e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Nhập lại mật khẩu<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="12345678"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={data.RePassword}
                onChange={(e) => applyChange("RePassword", e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            {err && <p style={{ color: "red" }}>{err}</p>}
            <Button
              isLoading={loading}
              onClick={() => handleDone()}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Hoàn thành
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Đã có tài khoản?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Đăng nhập
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  const rendertStep4 = () => {
    return (
      <Flex
        style={{
          backgroundImage: `url(${bgsuccess})`,
          height: "100vh",
          width: "40%",
        }}
        align="center"
        justify="center"
        direction="column"
      >
        <div className="box-search" style={{ height: 300, padding: 40 }}>
          <IoCheckmarkDoneCircle size={30} color="#52c41a" />
          <p className="label" style={{ fontSize: 20 }}>
            Bạn đả tạo thành công
          </p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Mã công ty</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>{data?.MaCT}</p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Tên đăng nhập</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>
            {data?.DienThoai}
          </p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Mật khẩu</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>
            {data.Password}
          </p>
        </div>

        <Button
          // isLoading={loading}
          // onClick={() => handleDone()}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="50%"
          h="35"
          mb="24px"
        >
          <NavLink to="/auth/sign-in" style={{ width: "100%" }}>
            Bắt đầu quản lý
          </NavLink>
        </Button>
      </Flex>
    );
  };
  return (
    <>
      {" "}
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        {step === 1
          ? renderStep1()
          : step === 2
          ? renderStep2()
          : step === 3
          ? renderStep3()
          : rendertStep4()}
      </DefaultAuth>
      {contextHolder}
    </>
  );
}

export default Register;
