// Chakra imports
import { Box, useDisclosure } from "@chakra-ui/react";
// Layout components
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { SignalrContextProvider } from "contexts/SignalrContext";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import { CongTyService } from "services/CongTyService";
export default function Dashboard(props) {
  const history = useHistory();
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("MaCTDK");
    localStorage.removeItem("url");
    history.push("/auth/sign-in");
  };
  useEffect(() => {
    (async () => {
      const _checkHSD = await CongTyService.checkHanSuDung({
        MaCT: localStorage.getItem("MaCTDKLogOut"),
      });
      if (_checkHSD?.status !== 2000) {
        logout();
      }
    })();
  }, []);
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].children) {
        let collapseActiveRoute = getActiveRoute(routes[i].children);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].label;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    // let _menu = JSON.parse(localStorage.getItem('menu'));
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.children?.length > 0) {
          return getRoutes(prop.children);
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      // if (prop.collapse) {
      // 	return getRoutes(prop.items);
      // }
      // if (prop.category) {
      // 	return getRoutes(prop.items);
      // } else {
      // 	return null;
      // }
    });
  };
  const { onOpen } = useDisclosure();
  if (!localStorage.getItem("token")) {
    history.push("/auth/sign-in");
  }
  return (
    <Box>
      <SignalrContextProvider>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            routes={routes.filter((v) => v?.layout === "/admin")}
            display="none"
            {...rest}
          />
          <Box
            // float="right"
            // minHeight="100vh"
            height={{ base: "100%", xl: "calc( 100% - 290px )" }}
            // overflow="auto"
            // position="relative"
            // maxHeight={{ base: "100%", xl: "calc( 100% - 290px )" }}
            // w={{ base: "100%", xl: "calc( 100% - 290px )" }}
            // maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            {/* <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"POKER"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal> */}

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ md: " 10px 20px" }}
                pe="20px"
                // minH="100vh"
                pt="0px"
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/" to="/admin/trang-chu" />
                </Switch>
              </Box>
            ) : null}
            {/* <Box>
							<Footer />
						</Box> */}
          </Box>
        </SidebarContext.Provider>
      </SignalrContextProvider>
    </Box>
  );
}
