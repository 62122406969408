import { Tabs } from "antd";
import React from "react";
import ViewDashboard2 from "views/admin/HeThong/Dashboard/ViewDashboard2";
import BaoCao from "../BaoCao";

const BieuDo = () => {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div style={{ backgroundColor: "white", margin: " -10px -20px" }}>
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <Tabs.TabPane
          tab={
            <span style={{ fontWeight: 600, marginLeft: "20px" }}>Dịch vụ</span>
          }
          key="1"
        >
          <>
            <ViewDashboard2 ThongKeDV={true} />
          </>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span style={{ fontWeight: 600 }}>Yêu cầu</span>}
          key="2"
        >
          <div style={{ minHeight: "100vh" }}>
            <ViewDashboard2 ThongKeYC={true} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span style={{ fontWeight: 600 }}>Doanh thu</span>}
          key="3"
        >
          <div style={{ padding: "0px 15px" }}>
            <BaoCao />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default BieuDo;
