import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { HopDongService } from "services/HopDongService";
const { TextArea } = Input;

const FormEdit = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HaoHut":
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
   let _res = await HopDongService.ChoThue.thanhLy({
        ..._data,
    
      });

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tài sản thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  return (
    <Drawer
      title="Thanh lý"
      width={1000}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 12]}>
        <Col span={12}>
          <InputCustom
            disabled={true}
            label="Số TL:"
            applyChange={applyChange}
            prop="KyHieu"
            value={data?.KyHieu}
            placeholder="Số thanh lý"
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày TL:"
            applyChange={applyChange}
            prop={"NgayTL"}
            value={data?.NgayTL}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          {" "}
          <DatePickerCustom
            label="Ngày ký VB kiểm kê:"
            applyChange={applyChange}
            prop={"NgayKyVBKK"}
            value={data?.NgayKyVBKK}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          {" "}
          <DatePickerCustom
            label="Ngày ký VB hoàn trả:"
            applyChange={applyChange}
            prop={"NgayKyVBHT"}
            value={data?.NgayKyVBHT}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            label="Tiền phạt vi phạm HĐ:"
            applyChange={applyChange}
            prop={"TienViPhamHD"}
            value={data?.TienViPhamHD}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            label="Tiền bồi thường thiệt hại:"
            applyChange={applyChange}
            prop={"TienBoiThuongThietHai"}
            value={data?.TienBoiThuongThietHai}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          {" "}
          <InputNumberCustom
            label="Phí dịch vụ và phí khác:"
            applyChange={applyChange}
            prop={"PhiDichVu"}
            value={data?.PhiDichVu}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          {" "}
          <InputNumberCustom
            label="Tiền thuê phát sinh thêm:"
            applyChange={applyChange}
            prop={"TienThuePhatSinh"}
            value={data?.TienThuePhatSinh}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            widthLabel={"50%"}
            label="Tiền thuê đã thanh toán trước:"
            applyChange={applyChange}
            prop={"TienThueDaTT"}
            value={data?.TienThueDaTT}
            placeholder={"0"}
          />
        </Col>
        <Col span={24}>
          <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
      </Row>

      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormEdit);
