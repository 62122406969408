import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayTP":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "NgayDK":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayHH":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "NgayTT":
        const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString5;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await ToaNhaService.addToaNha(_data);
    if (!_data?.MaTN) {
      setData((pre) => {
        return { ...pre, MaTN: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const tabs = [
    {
      title: "Thông tin",
      key: "0",
    },
    {
      title: "Cài đặt nâng cao",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  // const [modal, setModal] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [staffForm, setStaffForm] = useState([]);

  // const toggle = () => {
  //   setModal(!modal);
  // };
  // const handleAdd = () => {
  //   setStaffForm([]);
  //   setIsEdit(false);
  //   toggle();
  // };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Thông tin thẻ
        </p>

        <Row gutter={[20, 12]}>
          <Col span={6}>
            <SelectCustom
              label="Chọn thẻ:"
              applyChange={applyChange}
              prop="ChonThe"
              value={data?.ChonThe}
              placeholder="Chọn thẻ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Mã thẻ:"
              applyChange={applyChange}
              prop="MaThe"
              value={data?.MaThe}
              placeholder="Nhập di mã thẻ"
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Loại xe:"
              applyChange={applyChange}
              prop="LoaiXe"
              value={data?.LoaiXe}
              placeholder="Chọn loại xe"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="SL đăng ký:"
              applyChange={applyChange}
              prop={"SLDangKi"}
              value={data?.SLDangKi}
              placeholder={"0"}
            />
          </Col>

          <Col span={6}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                }}
              >
                Mặt bằng:
              </p>
              <DebounceSelect
                open={open}   
                value={data?.MaMB}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MaMB", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaMB"]}
              </p>
            )}
          </Col>
          <Col span={6}>
            <InputCustom
              label="CardNo:"
              applyChange={applyChange}
              prop="CardNo"
              value={data?.CardNo}
              placeholder="Nhập di cardNo"
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Biển số:"
              applyChange={applyChange}
              prop="BienSo"
              value={data?.BienSo}
              placeholder="Nhập di cardNo"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Tiền đặt cọc:"
              applyChange={applyChange}
              prop={"TienDatCoc"}
              value={data?.TienDatCoc}
              placeholder={"0"}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Chủ thẻ(C):"
              applyChange={applyChange}
              prop="ChuTheC"
              value={data?.ChuTheC}
              placeholder="Chọn thẻ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Chủ thẻ(N):"
              applyChange={applyChange}
              prop="ChuTheN"
              value={data?.ChuTheN}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Màu xe:"
              applyChange={applyChange}
              prop="MauXe"
              value={data?.MauXe}
              placeholder="Nhập màu xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Phí xe/tháng:"
              applyChange={applyChange}
              prop={"PhiXeThang"}
              value={data?.PhiXeThang}
              placeholder={"0"}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Quan hệ CH:"
              applyChange={applyChange}
              prop="QuanHeCH"
              value={data?.QuanHeCH}
              placeholder="Chọn"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Điện thoại:"
              applyChange={applyChange}
              prop={"DienThoai"}
              value={data?.DienThoai}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Nhãn hiệu:"
              applyChange={applyChange}
              prop="NhanHieu"
              value={data?.NhanHieu}
              placeholder="Nhập màu xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="% VAT:"
              applyChange={applyChange}
              prop={"VAT"}
              value={data?.VAT}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Nhóm thẻ:"
              applyChange={applyChange}
              prop="NhomThe"
              value={data?.NhomThe}
              placeholder="Chọn nhóm thẻ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Phòng:"
              applyChange={applyChange}
              prop="Phong"
              value={data?.Phong}
              placeholder="Nhập số phòng"
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Tên xe:"
              applyChange={applyChange}
              prop="TenXe"
              value={data?.TenXe}
              placeholder="Nhập tên xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Tiền VAT:"
              applyChange={applyChange}
              prop={"TienVAT"}
              value={data?.TienVAT}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Loại MB:"
              applyChange={applyChange}
              prop="LoaiMB"
              value={data?.LoaiMB}
              placeholder="Chọn loại mb"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày tính phí:"
              applyChange={applyChange}
              prop={"NgayTP"}
              value={data?.NgayTP}
              placeholder={"Chọn ngày tính phí"}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày đăng ký:"
              applyChange={applyChange}
              prop={"NgayDK"}
              value={data?.NgayDK}
              placeholder={"Chọn đăng ký"}
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Tổng phí xe:"
              applyChange={applyChange}
              prop={"TongPhiXe"}
              value={data?.TongPhiXe}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày hết hạn:"
              applyChange={applyChange}
              prop={"NgayHH"}
              value={data?.NgayHH}
              placeholder={"Chọn đăng ký"}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày thanh toán:"
              applyChange={applyChange}
              prop={"NgayTT"}
              value={data?.NgayTT}
              placeholder={"Chọn đăng ký"}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Trạng thái:"
              applyChange={applyChange}
              prop="TrangThai"
              value={data?.TrangThai}
              placeholder="Chọn"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Nhân viên:"
              applyChange={applyChange}
              prop="NhanVien"
              value={data?.NhanVien}
              placeholder="Chọn nhân viên"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>

          <Col span={24}>
            <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
            <TextArea
              value={data?.TenCongTy}
              onChange={(e) => applyChange("TenCongTy", e.target.value)}
            />
            {/* </Flex> */}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Thẻ xe"
      width={1280}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {/* <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? renderThongTinChung() : null}
            </TabPane>
          );
        })}
      </Tabs> */}

      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
