import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Drawer, notification, Table } from "antd";
import _ from "lodash";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const FormAddNV = (props, ref) => {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [maTN, setMaTN] = useState();
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setMaTN(item?.MaTN);
      let _res = await NhanVienService.getNhanVien({ InputSearch: "" });
      let _hstheoTN = await ToaNhaService.DuAn.getNVByTN({
        MaTN: item?.MaTN,
      });
      let selects = [];
      _hstheoTN?.data?.map((item) => {
        selects.push(item.MaNV);
      });
      setSelectedRowKeys(selects);
      setData(_res.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
  
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns = [
    {
      title: "Tên nhân viên",
      dataIndex: "MaSoNV",
    },
    // {
    //   title: "Ghi chú",
    //   dataIndex: "GhiChu",
    // },
  ];
  const onSelectChange = async (newSelectedRowKeys) => {
    setLoading(true);
    if (selectedRowKeys.length > newSelectedRowKeys.length) {
      let id = _.difference(selectedRowKeys, newSelectedRowKeys);
      if (id?.length > 0) {
        await ToaNhaService.DuAn.deleteNVByTN({
          MaTN: maTN,
          Ids: id,
        });
      }
    } else {
      let id = _.difference(newSelectedRowKeys, selectedRowKeys);
      if (id?.length > 0) {
        await ToaNhaService.DuAn.addNVByTN({
          MaTN: maTN,
          Ids: id,
        });
      }
    }
    setSelectedRowKeys(newSelectedRowKeys);
    setLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };
  return (
    <Drawer title="Thêm nhân viên" width={460} onClose={onClose} open={open}>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 170,
          // x: 2500
        }}
        rowSelection={rowSelection}
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        size="small"
        pagination={false}
      />

      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAddNV);
