import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  Menu,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";

import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DichVuService } from "services/DichVuService";
import { CategoryService } from "services/CategoryService";
import FormAddExcel from "./FormAddExcel";
// import Highlighter from 'react-highlight-words';
import {
  CaretRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  FileOutlined,
  ImportOutlined,
  KeyOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import FormAddCongNo from "./FormAddCongNo";
import FormModal from "./FormModal";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormPhieuHuy from "./FormPhieuHuy";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { RiExchangeDollarFill } from "react-icons/ri";
import { CiCreditCard2, CiSearch } from "react-icons/ci";
import { MdOutlineHomeWork, MdOutlineToday } from "react-icons/md";
import FormExportExcel from "./FormExportExcel";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DateTime } from "luxon";
import { FaEquals, FaGreaterThanEqual, FaLessThanEqual } from "react-icons/fa6";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [titleModal, setTitleModal] = useState();
  const [loaiXe, setLoaiXe] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceVAT, setTotalPriceVAT] = useState(0);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const startDate = DateTime.fromISO("2015-01-01").setZone("Asia/Ho_Chi_Minh");
  const endDate = currentDateTime.setZone("Asia/Ho_Chi_Minh").endOf("month");
  const defaultValue = {
    TuNgay: startDate.toISO(),
    DenNgay: endDate.toISO(),
    PageSize: 20,
    PageIndex: 1,
    InputSearch: "",
    DateType: "NgayDK",
    MaTN: null,
    MaKN: 0,
    TrangThai: ",,",
    LoaiXe: ",,",
    TongTien: {
      Type: "Empty",
      Value: 0,
      ValueTo: 0,
    },
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refCongNo = useRef(null);
  const refHistory = useRef(null);
  const refExport = useRef(null);
  const closeRef = useRef(null);
  const confirmRef = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    if (_tn.data) setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    let _res = await CategoryService.DichVu.Xe.TrangThai.get(_filter);
    if (_res.data) setTrangThai(_res.data);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (maTN) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: maTN,
    });
    setKhoinha(_tn.data);
    const _resLX = await CategoryService.DichVu.Xe.LoaiXe.get({
      MaTN: maTN,
    });
    if (_resLX.data) {
      setLoaiXe(_resLX.data);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    // const _resLX = await CategoryService.DichVu.Xe.LoaiXe.get({
    //   MaTN: _filter?.MaTN,
    // });
    // if (_resLX.data) {
    //   setLoaiXe(_resLX.data);
    // }
    let _res = await DichVuService.Xe.get(_filter);
    if (_res.data) {
      let totalTongTien = 0;
      let totalTienVAT = 0;

      _res.data.forEach((item) => {
        totalTongTien += item.TongTien;
        totalTienVAT += item.TienVAT;
      });
      setData(_res.data);
      setTotalPrice(totalTongTien);
      setTotalPriceVAT(totalTienVAT);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "Trạng thái",
      dataIndex: "MaTrangThai",
      render: (item) => {
        const tenTrangThai = trangThai.find(
          (tt) => tt.MaTrangThai === item
        )?.TenTrangThai;
        const color =
          tenTrangThai === "Khóa thẻ"
            ? "red"
            : tenTrangThai === "Hoạt động"
            ? "green"
            : tenTrangThai === "Đăng ký mới từ App"
            ? "blue"
            : "orange";

        return <Tag color={color}>{tenTrangThai}</Tag>;
      },
    },
    {
      title: "Mặt bằng",
      //  render: (text) => <EllipsisWithTooltip text={text} />,
      ellipsis: true,

      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.MaSoMB}
        </p>
      ),
    },
    {
      title: "Ngày ĐK",
      dataIndex: "NgayDK",

      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",

      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Biển số",
      dataIndex: "BienSo",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Loại xe",
      dataIndex: "MaLX",
      render: (item) => loaiXe.find((lx) => lx.MaLX === item)?.TenLX,
    },
    {
      title: "Tổng tiền",
      dataIndex: "TongTien",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Đơn giá",
      dataIndex: "PhiGuiXe",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "CardNo",
      dataIndex: "CardNo",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Mã thẻ",
      dataIndex: "SoThe",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },

    // {
    //   title: "Loại MB",
    //   dataIndex: "LoaiMB",
    //    render: (text) => <EllipsisWithTooltip text={text} />,
    // },
    {
      title: "Chủ hộ",
      dataIndex: "ChuHo",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Người SD xe",
      dataIndex: "ChuThe",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Quan hệ CH",
      dataIndex: "QuanHeCH",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },

    {
      title: "Nhãn hiệu",
      dataIndex: "NhanHieu",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },

    {
      title: "Số lượng đã ĐK",
      dataIndex: "SoLuong",
      width: 120,
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Ngày tính phí",
      dataIndex: "NgayTinhPhi",
      width: 110,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "NgayHetHan",
      width: 110,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Tiền đặt cọc",
      dataIndex: "TienDatCoc",
      render: (item) => (item ? Format_Currency(item) : ""),
    },

    {
      title: "% VAT",
      dataIndex: "PTVAT",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => (item ? Format_Currency(item) : ""),
    },

    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Màu xe",
      dataIndex: "MauXe",
      render: (text) => <EllipsisWithTooltip text={text} />,
      key: "MauXe",
    },
    {
      title: "Tên xe",
      dataIndex: "TenXe",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Ngày khóa",
      dataIndex: "NgayKhoa",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày hủy",
      dataIndex: "NgayHuy",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => (
        <div style={{ display: "flex" }}>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá thẻ xe"
            description="Bạn có chắc chắn muốn xoá dịch vụ thẻ xe?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const remove = async (value) => {
    let _res = await DichVuService.Xe.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setDataSelect(data.filter((item) => newSelectedRowKeys.includes(item.ID)));
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);

  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [caseModal, setCaseModal] = useState(4);
  const [dataFilterSelect, setDataFilterSelect] = useState();
  const showModal = (value, title) => {
    setIsModalOpen(true);
    setCaseModal(value);
    setTitleModal(title);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalPhieuHuy = () => {
    setDataFilterSelect(
      data.filter((item) => selectedRowKeys.includes(item.ID))
    );
    setIsModalOpen2(true);
  };
  const handleCancelPhieuHuy = () => {
    setIsModalOpen2(false);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Xe.get(_ft);
    if (_res.data) setDataExport(_res.data);

    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ xe" + fileExtension);
  };

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn mặt bằng, xin cảm ơn."
        );
        return;
      }
    }
    if (e.key === "3") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn thẻ xe, xin cảm ơn."
        );
        return;
      }
    }
    let titleCongNo = "Theo thẻ xe";
    if (e.key === "1") {
      titleCongNo = toanha.find(
        (item) => item.MaTN === filterCondition.MaTN
      )?.TenTN;
    } else if (e.key === "2") {
      titleCongNo = "Theo mặt bằng";
    }
    refCongNo.current.show(e.key, titleCongNo);
  };

  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    if (
      (e.key === "3" && selectedRowKeys.length > 1) ||
      (e.key === "7" && selectedRowKeys.length > 1)
    ) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chỉ chọn 1 mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        const setTitleModalTT = data.filter((item) =>
          selectedRowKeys.includes(item.ID)
        )[0]?.MaTrangThai;
        showModal(3, setTitleModalTT === 1 ? "Mở khóa thẻ xe" : "Khóa thẻ xe");
        break;
      case "4":
        showModalPhieuHuy();
        break;

      case "6":
        showModal(6, "Cập nhật đơn giá mặt bằng");
        break;
      case "7":
        showModal(7, "Cập nhật mã thẻ");
        break;
      case "8":
        showModal(8, "cập nhật nhày hết hạn");
        break;
      case "9":
        showModal(9, "Đổi mặt bằng");
        break;

      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo tòa nhà
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo mặt bằng
      </Menu.Item>
      <Menu.Item key="3">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo thẻ xe
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
      <Menu.Item key="3">
        <KeyOutlined style={{ paddingRight: "5px" }} />
        Khóa thẻ/mở thẻ
      </Menu.Item>
      <Menu.Item key="4">
        <FileOutlined style={{ paddingRight: "8px" }} />
        Tạo phiếu hủy
      </Menu.Item>
    </Menu>
  );
  const menuCapNhat = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="6">
        <Flex align="center">
          {" "}
          <RiExchangeDollarFill
            style={{ marginRight: "5px", fontSize: "16px" }}
          />{" "}
          Đơn giá
        </Flex>
      </Menu.Item>

      <Menu.Item key="7">
        {" "}
        <Flex align="center">
          {" "}
          <CiCreditCard2 style={{ marginRight: "5px", fontSize: "16px" }} /> Mã
          thẻ
        </Flex>
      </Menu.Item>

      <Menu.Item key="8">
        {" "}
        <Flex align="center">
          {" "}
          <MdOutlineToday style={{ marginRight: "5px", fontSize: "16px" }} />
          Ngày hết hạn
        </Flex>
      </Menu.Item>

      <Menu.Item key="9">
        {" "}
        <Flex align="center">
          {" "}
          <MdOutlineHomeWork style={{ marginRight: "5px", fontSize: "16px" }} />
          Mặt bằng
        </Flex>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
            TrangThai: trangThai,
            LoaiXe: loaiXe,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                onClick={async () => {
                  setFilteredInfo({});
                  setSortedInfo({});
                  // setSearchText("");
                  // setSearchedColumn("");
                  // setFilters([]);
                  filter(filterCondition);
                }}
              >
                Nạp lại
              </Button>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  <DollarOutlined />
                  Tạo công nợ
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menuCapNhat} trigger={["click"]}>
                <Button>
                  <SyncOutlined /> Cập nhật
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 2900,
          }}
          rowSelection={{
            ...rowSelection,
            columnWidth: 60,
          }}
          virtual={true}
          size="small"
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            setFilteredInfo(filters);
            setSortedInfo(sorter);
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>{" "}
                  <Table.Summary.Cell
                    index={0}
                    colSpan={17}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(totalPriceVAT)}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(totalPrice)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              showSizeChanger
              total={data[0]?.TotalRows}
              defaultPageSize={20}
            />
          )}
        </div>
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        loaiXe={loaiXe}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        maTN={filterCondition.MaTN}
        onAfterSubmit={onAfterSubmit}
      />
      <FormAddCongNo
        ref={refCongNo}
        dataSelect={dataSelect}
        selectedRowKeys={selectedRowKeys}
        maTN={filterCondition.MaTN}
        setSelectedRowKeys={setSelectedRowKeys}
        onAfterSubmit={onAfterSubmit}
        dataAllXe={data}
      />
      <FormModal
        filterCondition={filterCondition}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        caseModal={caseModal}
        titleModal={titleModal}
        setIsModalOpen={setIsModalOpen}
        selectedRowKeys={selectedRowKeys}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        dataAllXe={data}
      />
      <FormPhieuHuy
        handleCancel={handleCancelPhieuHuy}
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        dataAllXe={data}
        dataFilterSelect={dataFilterSelect}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
      {/* <FormExportExcel ref={refExport} /> */}
    </Row>
  );
}
