import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  DatePicker,
  Flex,
  Select,
  Spin,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { Format_Currency } from "layouts/ultis/common";
import Chart from "react-apexcharts";
import { BanHangService } from "services/BanHangService";
import "./scss/index.scss";
import VeOnOff from "./Home/VeOnOff";
import locale from "antd/es/date-picker/locale/vi_VN";
import VeBan from "./Home/VeBan";
import VeBanTheoNgay from "./Home/VeBanTheoNgay";
import VeBanTBTheoGio from "./Home/VeBanTBTheoGio";
import ViewDashboard from "../HeThong/Dashboard/ViewDashboard";
import ViewDashboard2 from "../HeThong/Dashboard/ViewDashboard2";
import MauChoThueDashboard from "../HeThong/Dashboard/MauChoThue/index2";
import { NhanVienService } from "services/NhanVienService";
import { DashboardService } from "services/dashboardService";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [maDashboard, setMaDashboard] = useState();
  const [dashboard, setDashboard] = useState([]);
  const [dashboardNV, setDashboardNV] = useState([]);
  useEffect(() => {
    filter();
  }, []);

  console.log(maDashboard);

  const filter = async () => {
    // const maDB = localStorage.getItem("NhanVien");
    // setMaDashboard(JSON.parse(maDB));
    let _resDB = await DashboardService.getDashboardAdd();
    setDashboard(_resDB?.data);
    let _res = await DashboardService?.TranChu.get();
    setDashboardNV(_res?.data);
    const maDashboard =
      _res?.data?.find((item) => item?.IsView === true)?.MaDashboard || "1";
    setMaDashboard(String(maDashboard));
  };

  const onAfterSubmit = async () => {
    let _res = await DashboardService?.TranChu.get();
    setDashboardNV(_res?.data);
    const maDashboard =
      _res?.data?.find((item) => item?.IsView === true)?.MaDashboard || "1";
    setMaDashboard(String(maDashboard));
  };
  const renderComponent = () => {
    switch (maDashboard) {
      case "1":
        return (
          <ViewDashboard2
            dashboard={dashboard}
            setMaDashboard={setMaDashboard}
            dashboardNV={dashboardNV}
            onAfterSubmit={onAfterSubmit}
            setDashboardNV={setDashboardNV}
          />
        );
      case "2":
        return (
          <MauChoThueDashboard
            dashboard={dashboard}
            setMaDashboard={setMaDashboard}
            dashboardNV={dashboardNV}
            onAfterSubmit={onAfterSubmit}
            setDashboardNV={setDashboardNV}
          />
        );

      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        );
    }
  };
  return (
    // <Box className="index-report">
    //   <Row gutter={[10]} style={{ marginBottom: 10 }}>
    //     <Col span={4}  >
    //       <VeBan />
    //     </Col>
    //     <Col span={8}>
    //       <VeBanTheoNgay />
    //     </Col>
    //     <Col span={8}>
    //       <div className="box">
    //         <Flex gap="middle" justify="space-between">
    //           <p style={{ fontWeight: "bold" }}>Doanh thu theo ngày</p>
    //           {/* <Select
    //             style={{
    //               width: "30%",
    //             }}
    //             // value={data?.MaQD}
    //             // placeholder="Chọn danh xưng"
    //             // onChange={(v) => applyChange("MaQD", v)}
    //             // options={quyDanh}
    //             fieldNames={{ label: "TenQD", value: "MaQD" }}
    //           /> */}
    //           <DatePicker
    //             allowClear={false}
    //             format="MM-YYYY"
    //             style={{ width: "120px" }}
    //             defaultValue={dayjs(filterCondition.Date1)}
    //             picker="month"
    //             locale={locale}
    //             // value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
    //             // placeholder="Ngày sinh"
    //             // locale={vi}
    //             onChange={(v) => applyChange("Date1", v)}
    //           />
    //         </Flex>
    //         <Chart
    //           options={options}
    //           series={series}
    //           type="bar"
    //           width="700px"
    //           className="line-chart"
    //           height={300}
    //         />
    //       </div>
    //     </Col>

    //     <Col span={4}>
    //       <VeOnOff />
    //     </Col>
    //   </Row>
    //   <Row gutter={[10]}>
    //     <Col span={8}>
    //       <div className="box">
    //         <Flex
    //           gap="middle"
    //           justify="space-between"
    //           style={{ marginRight: "15px" }}
    //         >
    //           <p style={{ fontWeight: "bold" }}>
    //             Top sản phẩm bán chạy theo doanh thu
    //           </p>
    //           <DatePicker
    //             allowClear={false}
    //             format="MM-YYYY"
    //             style={{ width: "120px" }}
    //             defaultValue={dayjs(filterCondition.Date2)}
    //             picker="month"
    //             locale={locale}
    //             onChange={(v) => applyChange("Date2", v)}
    //           />
    //         </Flex>

    //         <Chart
    //           options={options2}
    //           series={series2}
    //           type="bar"
    //           width="100%"
    //           height={300}
    //         />
    //       </div>
    //     </Col>
    //     <Col span={8}>
    //       <div className="box">
    //         <Flex
    //           gap="middle"
    //           justify="space-between"
    //           style={{ marginRight: "15px" }}
    //         >
    //           <p style={{ fontWeight: "bold" }}>
    //             Top sản phẩm bán chạy theo số lượng
    //           </p>
    //           <DatePicker
    //             allowClear={false}
    //             format="MM-YYYY"
    //             style={{ width: "120px" }}
    //             defaultValue={dayjs(filterCondition.Date3)}
    //             picker="month"
    //             locale={locale}
    //             onChange={(v) => applyChange("Date3", v)}
    //           />
    //         </Flex>
    //         <Chart
    //           options={options3}
    //           series={series3}
    //           type="bar"
    //           width="100%"
    //           height={300}
    //         />
    //       </div>
    //     </Col>
    //     <Col span={8}>
    //      <VeBanTBTheoGio />
    //     </Col>
    //   </Row>
    // </Box>
    <div style={{ backgroundColor: "white", margin: " -10px -20px" }}>
      {/* {maDashboard?.maDashboard === 1 ? (
        <ViewDashboard2 dashboard={dashboard} />
      ) : (
        <MauChoThueDashboard dashboard={dashboard} />
      )} */}

      {renderComponent()}
    </div>
  );
}
