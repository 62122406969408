import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  DownCircleOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit } from "react-icons/md";
import { CiTrash } from "react-icons/ci";
import FormAdd from "./FormAdd";
export default function DanhSachChuyenNhuong() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const currentDate = DateTime.now();
  const defaultValue = {
    Month: currentDate.month,
    Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
    PageIndex: 1,
    PageSize: 20,
    InputSearch: "",
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refEdit = useRef(null);
  const refAdd = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    filter(_filter);
  };

  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await DichVuService.Dien.get(_filter);
    if (_res.data) {
      setData(_res.data);
      setLoading(false);
    }
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const fakeData = [
    {
      SoCN: "CN001",
      NgayCN: "2024-12-20",
      SoHD: "HD001",
      LoaiChuyenNhuong: "Loại A",
      TongGiaTriHD: "1,000,000,000",
      ĐaThu: "800,000,000",
      ConLai: "200,000,000",
      TienThueCN: "50,000,000",
      PhiCN: "20,000,000",
      DaThuPhiCN: "15,000,000",
      ConLaiPhiCN: "5,000,000",
      KhachChuyenNhuong: "Nguyen Van A",
      KhachNhanCN: "Tran Van B",
      DungTenHD: "Nguyen Van A",
      DiDong: "0909123456",
      Email: "nguyenvana@example.com",
      NgayNhap: "2024-12-18",
      NguoiNhap: "Admin",
      NgaySua: "2024-12-19",
      NguoiSua: "Admin",
      SoVB: "VB001",
      NgayCongChung: "2024-12-15",
      NgayDeNghiCN: "2024-12-10",
      VanPhongCongChung: "VPCC A",
      DiaChiCongChung: "123 Đường ABC, Quận 1, TP.HCM",
      NoiDungCongChung: "Chuyển nhượng hợp đồng",
      VanBanXN: "XN001",
      ID: 1,
    },
  ];

  const columns = [
    {
      title: "Số CN",
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.SoCN}
        </p>
      ),
    },
    {
      title: "Ngày CN",
      dataIndex: "NgayCN",
    },
    {
      title: "Số HĐ",
      dataIndex: "SoHD",
    },
    {
      title: "Loại chuyển nhượng",
      dataIndex: "LoaiChuyenNhuong",
    },
    {
      title: "Tổng giá trị HĐ",
      dataIndex: "TongGiaTriHD",
    },
    {
      title: "Đã thu",
      dataIndex: "ĐaThu",
    },
    {
      title: "Còn lại",
      dataIndex: "ConLai",
    },
    {
      title: "Tiền thuê CN",
      dataIndex: "TienThueCN",
    },
    {
      title: "Phí chuyển nhượng",
      dataIndex: "PhiCN",
    },
    {
      title: "Đã thu phí CN",
      dataIndex: "DaThuPhiCN",
    },
    {
      title: "Còn lại phí CN",
      dataIndex: "ConLaiPhiCN",
    },
    {
      title: "Khách CN",
      dataIndex: "KhachChuyenNhuong",
    },
    {
      title: "Khách nhận CN",
      dataIndex: "KhachNhanCN",
    },
    {
      title: "Đứng tên hợp đồng",
      dataIndex: "DungTenHD",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày sữa",
      dataIndex: "NgaySua",
    },
    {
      title: "Người sữa",
      dataIndex: "NguoiSua",
    },
    {
      title: "Số văn bản",
      dataIndex: "SoVB",
    },

    {
      title: "Ngày công chứng",
      dataIndex: "NgayCongChung",
    },
    {
      title: "Ngày đề nghị CN",
      dataIndex: "NgayDeNghiCN",
    },
    {
      title: "Văn phòng công chứng",
      dataIndex: "VanPhongCongChung",
    },
    {
      title: "Địa chỉ công chứng",
      dataIndex: "DiaChiCongChung",
    },
    {
      title: "Nội dung công chứng",
      dataIndex: "NoiDungCongChung",
    },
    {
      title: "Văn bản XN",
      dataIndex: "VanBanXN",
    },
    {
      title: "",
      fixed: "right",
      width: 120,
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh sách"
              description="Bạn có chắc chắn muốn xoá danh sách?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      default:
        break;
    }
  };
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
    </Menu>
  );
  const menuDuyet = (
    <Menu>
      <Menu.Item key="1">
        {" "}
        {/* <ImportOutlined style={{ paddingRight: "8px" }} /> */}
        Duyệt
      </Menu.Item>
      <Menu.Item key="2">
        {/* <ExportOutlined style={{ paddingRight: "8px" }} /> */}
        Không duyệt
      </Menu.Item>
    </Menu>
  );
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refAdd.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menuDuyet} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <DownCircleOutlined style={{ marginRight: "0px" }} />
                  Duyệt
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={fakeData}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 3500,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              showSizeChanger
              total={data[0]?.TotalRows}
              defaultPageSize={20}
            />
          )}
        </div>
      </Col>

      {contextHolder}
      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        ToaNha={toanha}
      />
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
    </Row>
  );
}
