import * as React from "react";
import _ from "lodash";
import { Col, Image, Row, Table } from "antd";
import { DateTime } from "luxon";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { toVietnameseText } from "layouts/ultis/common";
const MauChiTiet = (props, ref) => {
  const { data, dataTable, dataCT } = props;

  const now = DateTime.now().setZone("UTC+7");
  const formattedDate = now.toFormat("'Ngày' dd 'tháng' MM 'năm' yyyy");
  console.log(data);
  console.log(dataTable);

  const tableData = dataTable.map((item, index) => {
    return {
      stt: index,
      noiDung: item?.DienGiai,
      kyThanhToan: Format_Date(item?.NgayThu),
      phaiThu: Format_Currency(item?.PhaiThu),
      thucThu: Format_Currency(item?.SoTien),
    };
  });

  const totals = dataTable.reduce(
    (acc, item) => {
      acc.soTienTotal += item.SoTien;
      acc.phaiThuTotal += item.PhaiThu;
      return acc;
    },
    { soTienTotal: 0, phaiThuTotal: 0 }
  );

  const styles = {
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
      fontWeight: "400",
    },
    cell: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
    },
    footerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      fontWeight: "600",
      textAlign: "center",
    },
  };

  const docSoTien = toVietnameseText(
    totals.soTienTotal ? totals.soTienTotal : data?.SoTienTongTT
  );
  return (
    <div ref={ref} style={{ padding: "20px 50px" }}>
      <Row gutter={[20, 5]}>
        <Col span={6}>
          <Image src={dataCT?.Logo} />
        </Col>
        <Col span={18}>
          <h5>{dataCT?.CongTyQuanLy}</h5>
          <p>Địa chỉ: {dataCT?.DiaChiCongTy}</p>
          <p>Điện thoại: {dataCT?.DienThoai}</p>
        </Col>
        <Col span={6}></Col>
        <Col span={12}>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <h1 style={{ fontWeight: "600", fontSize: "25px" }}>PHIẾU THU</h1>
            <p style={{ fontWeight: "500" }}>{formattedDate}</p>
          </div>
        </Col>
        <Col span={6}>
          <p style={{ fontWeight: "500" }}>
            Số phiếu: <span style={{ fontWeight: "300" }}>{data?.SoHD}</span>
          </p>
        </Col>
        <Col span={5}>
          <p>Họ tên người nộp:</p>
        </Col>
        <Col span={7}>{data?.NguoiNop}</Col>
        <Col span={12}>
          <p>
            Mã căn hộ: <span>{data?.MaSoMB}</span>
          </p>
        </Col>
        <Col span={5}>
          <p>Địa chỉ:</p>
        </Col>
        <Col span={19}>{data?.DiaChi}</Col>
        <Col span={5}>Lý do:</Col>
        <Col span={19}> {data?.DienGiai} </Col>
      </Row>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px 0px",
        }}
      >
        <thead>
          <tr>
            <th style={styles.headerCell}>
              <p>STT</p>
            </th>
            <th style={styles.headerCell}>
              <p>Nội dung</p>
            </th>
            <th style={styles.headerCell}>
              <p>Kỳ thanh toán</p>
            </th>
            <th style={styles.headerCell}>
              <p>Phải thu</p>
            </th>
            <th style={styles.headerCell}>
              <p>Thực thu</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={styles.cell}>{row.stt}</td>
              <td style={styles.cell}>{row.noiDung}</td>
              <td style={styles.cell}>{row.kyThanhToan}</td>
              <td style={styles.cell}>{row.phaiThu}</td>
              <td style={styles.cell}>{row.thucThu}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3" style={styles.footerCell}>
              TỔNG CỘNG SỐ TIỀN
            </td>
            <td style={styles.footerCell}>
              {totals.soTienTotal
                ? Format_Currency(totals.soTienTotal)
                : Format_Currency(data?.SoTienTongTT)}
            </td>
            <td style={styles.footerCell}>
              {totals.soTienTotal
                ? Format_Currency(totals.soTienTotal)
                : Format_Currency(data?.SoTienThanhToan)}
            </td>
          </tr>
        </tfoot>
      </table>
      <Row>
        <Col span={5}>
          <p>Số tiền bằng chữ:</p>
        </Col>
        <Col span={19}>
          <p>{docSoTien}</p>
        </Col>
        <Col span={5}>
          <p>Kèm theo:</p>
        </Col>
        <Col span={5}>
          <p></p>
        </Col>
        <Col span={5}>
          <p>Chứng từ gốc:</p>
        </Col>
        <Col span={19}>
          <p></p>
        </Col>
        <Col span={10}>
          <p>Đã nhận đủ số tiền(Viết bằng chữ):</p>
        </Col>
        <Col span={12}>
          <p>{docSoTien}</p>
        </Col>
        <Col span={24} style={{ paddingTop: "20px" }}></Col>
        <Col span={6}>
          <p style={{ fontWeight: "500" }}>Người nộp tiền</p>
        </Col>
        <Col span={6}>
          <p style={{ fontWeight: "500" }}>Người lập phiếu</p>
        </Col>
        <Col span={6}>
          <p style={{ fontWeight: "500" }}>Thũ quỹ</p>
        </Col>
        <Col span={6}>
          <p style={{ fontWeight: "500" }}>Trưởng BQLTN</p>
        </Col>
      </Row>
    </div>
  );
};
export default React.forwardRef(MauChiTiet);
