import React, { useState } from "react";
import { Modal, Button, Checkbox, Flex, Card, Avatar, Col, Row } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  MoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

const ModalAddDashboard = ({
  isModalVisible,
  handleOk,
  handleCancel,
  dashboard,
  dashboardNV,
  handleAddDashboard,
}) => {
  const filteredDashboard = dashboard?.filter(
    (item) => !dashboardNV?.some((nvItem) => nvItem.MaDashboard === item.ID)
  );
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Modal
      title="Tạo mới dashboard"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      width="700px"
    >
      <div>
        {filteredDashboard?.length > 0 ? (
          <Row gutter={[20, 20]}>
            {filteredDashboard?.map((item, index) => {
              return (
                <>
                  <Col span={12}>
                    <Card
                      style={{
                        width: 300,
                      }}
                      cover={
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                      }
                      actions={[
                        <Button
                          style={{
                            color: "#fff",
                            backgroundColor: isHovered ? "#1250AE" : "#42b814",
                            borderColor: isHovered ? "#1250AE" : "#42b814",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => handleAddDashboard(item)}
                        >
                          Sử dụng
                        </Button>,
                      ]}
                      //   actions={[<EditOutlined key="edit" />]}
                    >
                      <Meta title={item?.Name} />
                    </Card>
                  </Col>
                </>
              );
            })}
          </Row>
        ) : (
          <>
            <p style={{ textAlign: "center", padding: "15px" }}>
              Đã thêm tất cả mẫu
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalAddDashboard;
