import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
  Collapse,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { Format_Currency } from "layouts/ultis/common";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  //   const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    // onAfterSubmit();
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };

  const onSubmit = async (close) => {
    setLoading(true);
  };
  const dataSource = [
    {
      key: "1",
      stt: "1",
      dichVu: "Dịch vụ A",
      dotThanhToan: "Đợt 1",
      phaiThu: 1000000,
      thucThu: 900000,
      dienGiai: "Thanh toán lần 1 cho dịch vụ A",
    },
    {
      key: "2",
      stt: "2",
      dichVu: "Dịch vụ B",
      dotThanhToan: "Đợt 2",
      phaiThu: 2000000,
      thucThu: 1800000,
      dienGiai: "Thanh toán lần 2 cho dịch vụ B",
    },
  ];

  const totalRows = dataSource.length;
  const totalPhaiThu = dataSource.reduce(
    (sum, record) => sum + record.phaiThu,
    0
  );

  const [rowState, setRowState] = useState(
    dataSource.map((item) => ({ checked: true, thucThu: item.thucThu }))
  );

  const [totalThucThu, setTotalThucThu] = useState(
    dataSource.reduce((sum, record) => sum + record.thucThu, 0)
  );

  useEffect(() => {
    let total = rowState.reduce((sum, row) => sum + row.thucThu, 0);
    setTotalThucThu(total);
    data.ThucThu = total;
    data.TienThua = -total;
  }, [rowState]);

  const onChange = (index, checked) => {
    setRowState((prevState) =>
      prevState.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              checked,
              thucThu: checked ? dataSource[index].thucThu : 0,
            }
          : row
      )
    );
  };
  const columns = [
    { title: "Tên khách hàng" },
    { title: "Mặt bằng" },
    { title: "Dịch vụ" },
    { title: "Ngày thanh toán" },
    { title: "Tiền thanh toán" },
    { title: "Tiền sau CK" },
  ];

  const items = [
    {
      key: "1",
      label: "Nợ cũ",
      children: (
        <>
          {dataSource.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              <Col span={2}>
                <p>
                  <Checkbox
                    checked={rowState[index].checked}
                    onChange={(e) => onChange(index, e.target.checked)}
                  ></Checkbox>
                </p>
              </Col>
              <Col span={4}>
                <p>{item.dichVu}</p>
              </Col>
              <Col span={4}>
                <p>{item.dotThanhToan}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(item.phaiThu)}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(rowState[index].thucThu)}</p>
              </Col>
              <Col span={6}>
                <p>{item.dienGiai}</p>
              </Col>
            </Row>
          ))}
          <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={4}>
              <p>{totalRows}</p>
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <p>{Format_Currency(totalPhaiThu)}</p>
            </Col>
            <Col span={4}>
              <p>{Format_Currency(totalThucThu)}</p>
            </Col>
            <Col span={6}></Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Nợ mới",
      children: <p>This is panel header 2</p>,
    },
  ];
  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={12}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                }}
              >
                Loại MB:
              </p>
              <DebounceSelect
               open={open}
                value={data?.LoaiMB}
                placeholder="Tìm theo mã mặt bằng"
                // fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("LoaiMB", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
          </Col>
          <Col span={12}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder="Nhập tên khách hàng"
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Loại dịch vụ:"
              applyChange={applyChange}
              prop="LoaiDichVu"
              value={data?.LoaiDichVu}
              placeholder="Chọn loại dịch vụ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Thẻ xe:"
              applyChange={applyChange}
              prop="TheXe"
              value={data?.TheXe}
              placeholder="Chọn thẻ xe"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
              placeholder={"Chọn từ ngày"}
            />
          </Col>

          <Col span={6}>
            <DatePickerCustom
              label="đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
              placeholder={"Chọn đến ngày"}
            />
          </Col>
          <Col span={12}>
            <Checkbox>Tạo hóa đơn tất cả thẻ xe</Checkbox>
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Số tháng:"
              applyChange={applyChange}
              prop={"SoThang"}
              value={data?.SoThang}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="% chiết khấu:"
              applyChange={applyChange}
              prop={"ChieuKhau"}
              value={data?.ChieuKhau}
              placeholder="0"
            />
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            backgroundColor: "#E6F1FE",
            padding: "10px 0",
          }}
        >
          <Col span={2}></Col>
          <Col span={22}>
            <Row>
              {columns.map((item) => (
                <Col span={4} key={item.title}>
                  <p>{item.title}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Collapse
            style={{
              width: "100%",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Tên dịch vụ khác"
      width={1080}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
          <SaveOutlined />   Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
