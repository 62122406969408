import dayjs from "dayjs";
import { DateTime } from "luxon";
import OneSignal from "react-onesignal";

export const Format_Currency = (number) => {
  let num;
  const lengthArr = number?.toString().split(".").length;
  if (lengthArr > 1) {
    const parts = Number.parseFloat(number).toFixed(0).split(".");
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
  } else {
    num = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return num;
};
export const Format_Datetime = (date) => {
  return DateTime.fromISO(date, { zone: "utc" })
    .setZone("Asia/Ho_Chi_Minh")
    .toFormat("dd-MM-yyyy| HH:mm");
};
export const Format_Datetime2 = (date) => {
  return DateTime.fromISO(date)
    .setZone("Asia/Ho_Chi_Minh")
    .toFormat("yyyy-MM-dd | HH:mm");
};

// export const Format_Date = (date) => {
//   return DateTime.fromISO(date, { zone: "utc" })
//     .setZone("Asia/Ho_Chi_Minh")
//     .toFormat("dd-MM-yyyy");
// };
export const Format_Date = (date) => {
  return DateTime.fromISO(date)
    .setZone("Asia/Ho_Chi_Minh")
    .toFormat("dd-MM-yyyy");
};

export const Format_Time = (date) => {
  var m = new Date(date);
  var hour = m.getUTCHours() < 10 ? "0" + m.getUTCHours() : m.getUTCHours();
  var min =
    m.getUTCMinutes() < 10 ? "0" + m.getUTCMinutes() : m.getUTCMinutes();
  return hour + ":" + min;
};

export default async function runOneSignal() {
  await OneSignal.init({
    appId: "5be05308-a018-4ad9-bb4b-65c6581a9599",
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.Slidedown.promptPush();
}

const userColors = {};
const colorArr = [
  "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #a695e7 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #faa774 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #7bc862 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #6ec9cb 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #ee7aae 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #e17076 0%, #b9b6e5 100%)",

  "linear-gradient(-225deg, #f47e7b 0%, #c84b4a 100%)",
  "linear-gradient(-225deg, #fdcfcd 0%, #f55d5c 100%)",
  "linear-gradient(-225deg, #f6d5b3 0%, #e8871e 100%)",
  "linear-gradient(-225deg, #b9dbe6 0%, #448bb8 100%)",
  "linear-gradient(-225deg, #b0c7d7 0%, #2e6f8e 100%)",
  "linear-gradient(-225deg, #f4d0a4 0%, #d46a25 100%)",
  "linear-gradient(-225deg, #e8e3cd 0%, #a78f52 100%)",
  "linear-gradient(-225deg, #e1e2e2 0%, #7e8e99 100%)",
  "linear-gradient(-225deg, #ebebeb 0%, #4d4d4d 100%)",
];
export const GetRandomColor = (userId) => {
  // return colorArr[Math.floor(Math.random() * colorArr.length)];
  if (!userColors[userId]) {
    // Nếu chưa, tạo một màu mới và gán cho user đó
    userColors[userId] = colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  // Trả về màu của user
  return userColors[userId];
};
export function ConvertExcelDateToJSDate(excelDate) {
  const excelStartDate = new Date(1900, 0, 1); // 1/1/1900
  const jsDate = new Date(
    excelStartDate.getTime() + (excelDate - 1) * 24 * 60 * 60 * 1000
  );
  return dayjs(jsDate).format("DD-MM-YYYY");
}

export function toVietnameseText(number) {
  const defaultNumbers = " hai ba bốn năm sáu bảy tám chín";

  const chuHangDonVi = ("1 một" + defaultNumbers).split(" ");
  const chuHangChuc = ("lẻ mười" + defaultNumbers).split(" ");
  const chuHangTram = ("không một" + defaultNumbers).split(" ");
  const dvBlock = "1 nghìn triệu tỷ".split(" ");

  function convert_block_three(number) {
    if (number === "000") return "";
    var _a = number.toString();

    switch (_a.length) {
      case 1:
        return chuHangDonVi[_a];
      case 2:
        return convert_block_two(_a);
      case 3:
        var chuc_dv =
          _a.slice(1, 3) !== "00" ? convert_block_two(_a.slice(1, 3)) : "";
        var tram = chuHangTram[_a[0]] + " trăm";
        return tram + " " + chuc_dv;
      default:
        break;
    }
  }

  function convert_block_two(number) {
    var dv = chuHangDonVi[number[1]];
    var chuc = chuHangChuc[number[0]];
    var append = "";

    if (number[0] > 0 && number[1] === "5") dv = "lăm";
    if (number[0] > 1) {
      append = " mươi";
      if (number[1] === "1") dv = "mốt";
    }

    return chuc + append + " " + dv;
  }

  var str = parseInt(number).toString();
  var i = 0;
  var arr = [];
  var index = str.length;
  var result = [];
  var rsString = "";

  if (index === 0 || isNaN(str)) {
    return "";
  }

  while (index > 0) {
    arr.push(str.substring(index - 3, index));
    index -= 3;
  }

  for (i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== "" && arr[i] !== "000") {
      result.push(convert_block_three(arr[i]));
      if (dvBlock[i]) result.push(dvBlock[i]);
    }
  }

  rsString = result.join(" ");
  return rsString.replace(/[0-9]/g, "").replace(/\s+/g, " ").trim() + " đồng";
}

export const toEnglishText = (num) => {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const thousands = ["", "thousand", "million", "billion"];

  if (num === 0) return "zero VND";

  let result = "";
  let i = 0;

  while (num > 0) {
    let rem = num % 1000;
    if (rem !== 0) {
      result = `${helper(rem)} ${thousands[i]} ${result}`.trim();
    }
    num = Math.floor(num / 1000);
    i++;
  }

  return result + " VND";

  function helper(num) {
    if (num === 0) return "";
    if (num < 10) return ones[num];
    if (num < 20) return teens[num - 10];
    if (num < 100)
      return `${tens[Math.floor(num / 10)]} ${ones[num % 10]}`.trim();
    return `${ones[Math.floor(num / 100)]} hundred ${helper(num % 100)}`.trim();
  }
};

// export function toEnglishText(number) {
//   const defaultNumbers = " one two three four five six seven eight nine";

//   const unitDigits = ("zero one" + defaultNumbers).split(" ");
//   const tens = ("ten twenty" + defaultNumbers).split(" ");
//   const hundreds = ("zero one" + defaultNumbers).split(" ");
//   const blockWords = "thousand million billion".split(" ");

//   function convertBlockThree(number) {
//     if (number === "000") return "";
//     var _a = number.toString();

//     switch (_a.length) {
//       case 1:
//         return unitDigits[_a];
//       case 2:
//         return convertBlockTwo(_a);
//       case 3:
//         var tensOnes = _a.slice(1, 3) !== "00" ? convertBlockTwo(_a.slice(1, 3)) : "";
//         var hundred = hundreds[_a[0]] + " hundred";
//         return hundred + " " + tensOnes;
//       default:
//         break;
//     }
//   }

//   function convertBlockTwo(number) {
//     var ones = unitDigits[number[1]];
//     var tensDigit = tens[number[0]];
//     var append = "";

//     if (number[0] > 0 && number[1] === "5") ones = "five";
//     if (number[0] > 1) {
//       append = "ty";
//       if (number[1] === "1") ones = "one";
//     }

//     return tensDigit + append + " " + ones;
//   }

//   var str = parseInt(number).toString();
//   var i = 0;
//   var arr = [];
//   var index = str.length;
//   var result = [];
//   var rsString = "";

//   if (index === 0 || isNaN(str)) {
//     return "";
//   }

//   while (index > 0) {
//     arr.push(str.substring(index - 3, index));
//     index -= 3;
//   }

//   for (i = arr.length - 1; i >= 0; i--) {
//     if (arr[i] !== "" && arr[i] !== "000") {
//       result.push(convertBlockThree(arr[i]));
//       if (blockWords[i]) result.push(blockWords[i]);
//     }
//   }

//   rsString = result.join(" ");
//   return rsString.trim() + " dollars";
// }
