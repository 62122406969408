import React, { useEffect, useState } from "react";
import { Button, DatePicker, InputNumber, Popover, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { FaEquals, FaGreaterThanEqual, FaLessThanEqual } from "react-icons/fa6";
import { SwapOutlined } from "@ant-design/icons";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    loadcategoriesByMaTN,
    defaultValue,
    setFilterCondition,
  } = props;
  const [activeType, setActiveType] = useState(true);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "DateType":
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "NgayTT":
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "LoaiXe":
      case "TrangThai":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TongTien":
        console.log(_filter.TongTien.Type);

        if (v.Type === "Từ->đến") {
          _filter.TongTien = {
            Type: "TuDen",
            Value: v.ValueTu,
            ValueTo: v.ValueDen,
          };
        } else if (v.Type === "*") {
          if (
            _filter.TongTien.Value !== v.Value &&
            _filter.TongTien.Type === "Empty"
          ) {
            return;
          } else {
            _filter.TongTien = {
              Type: "Empty",
              Value: 0,
            };
          }
        } else {
          _filter.TongTien = {
            Type: filterOptions3[v.Type].label,
            Value: v.Value,
          };
        }
        break;
      case "MaKN":
        if (v === undefined) {
          _filter.MaKN = 0;
          break;
        }
        _filter[p] = v;
        break;
      // case "MaNX":
      //   if (v === undefined) {
      //     _filter.MaNX = 0;
      //     break;
      //   }
      //   _filter[p] = v;
      //   break;

      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const [filterTT3, setFilterTT3] = useState("*");
  const [visible3, setVisible3] = useState(false);
  const [tuDen3, setTuDen3] = useState(false);
  const [valueLTT3, setValueLTT3] = useState();
  const [valueTu3, setValueTu3] = useState();
  const [valueDen3, setValueDen3] = useState();

  const filterOptions3 = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "Từ->đến": { label: "TuDen", icon: <SwapOutlined /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };
  const handleClick3 = (type) => {
    if (type === "Từ->đến") {
      setTuDen3(true);
    } else {
      setTuDen3(false);
    }
    setFilterTT3(type);
    setVisible3(false);
  };

  const content3 = (
    <div>
      {Object.entries(filterOptions3).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick3(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) {
          if (filterTT3 === "Từ->đến") {
            applyChangeFilter("TongTien", {
              Type: filterTT3,
              ValueTu: valueTu3,
              ValueDen: valueDen3,
            });
          } else {
            applyChangeFilter("TongTien", {
              Type: filterTT3,
              Value: valueLTT3,
            });
          }
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [valueLTT3, filterTT3, valueTu3, valueDen3]);

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };

  const defaultDate = {
    Name: "Hôm nay",
    TuNgay: filterCondition.TuNgay,
    DenNgay: filterCondition.DenNgay,
  };
  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setTuDen3(false);
          setFilterTT3("*");
          setVisible3(false);
        }}
      />
      <div className="box-search">
        {/* <p className="label">Loại ngày</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition?.DateType}
          placeholder="Chọn loại ngày"
          onChange={(v) => applyChangeFilter("DateType", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={[
            { TenLN: "Ngày đăng ký", MaLN: "NgayDK" },
            { TenLN: "Ngày thanh toán", MaLN: "NgayTT" },
          ]}
          fieldNames={{ label: "TenLN", value: "MaLN" }}
        />
      </div>

      <FilterDate
        onChangeDate={onChangeDate}
        setActiveProp={activeType}
        defaultDateXe={defaultDate}
        onOf={onOf} 
      />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            // mode="multiple"
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.TrangThai?.split(",")
            .filter(Boolean)
            .map(Number)}
          placeholder="Chọn trạng thái"
          onChange={(v) => applyChangeFilter("TrangThai", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.TrangThai}
          fieldNames={{ label: "TenTrangThai", value: "MaTrangThai" }}
        />
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.LoaiXe.split(",").filter(Boolean).map(Number)}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Lọc theo điều kiện</p>
        {tuDen3 ? (
          <>
            <div
              style={{
                // display: "flex",
                gap: 5,
                alignItems: "center",
                padding: "0 5px",
                marginBottom: "8px",
                paddingTop: "5px",
              }}
            >
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <Popover
                  content={content3}
                  title=""
                  trigger="click"
                  visible={visible3}
                  onClick={() => setVisible3(!visible3)}
                >
                  <Button style={{ marginRight: "15px" }}>Từ</Button>
                </Popover>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueTu3}
                  onChange={(value) => setValueTu3(value)}
                  placeholder="Từ tổng tiền"
                  min={0}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => setVisible3(!visible3)}
                >
                  Đến
                </Button>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueDen3}
                  onChange={(value) => setValueDen3(value)}
                  placeholder="Đến tổng tiền"
                  min={0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                marginBottom: "8px",
                paddingTop: "5px",
              }}
            >
              <Popover
                content={content3}
                title=""
                trigger="click"
                visible={visible3}
                onClick={() => setVisible3(!visible3)}
              >
                <Button>{filterOptions3[filterTT3]?.icon}</Button>
              </Popover>
              <InputNumber
                style={{ width: "100%" }}
                value={valueLTT3}
                onChange={(value) => setValueLTT3(value)}
                placeholder="Lọc tổng tiền"
                min={0}
              />
            </div>
          </>
        )}
      </div>
      {/* <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          mode="multiple"
          
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("TrangThai", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.TrangThai}
          fieldNames={{ label: "TenTrangThai", value: "MaTrangThai" }}
        />
         <Select
          mode="multiple"
       
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div> */}
      {/* <div className="box-search">
        <p className="label">Loại xe</p>
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          // value={filterCondition.MaTT}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
