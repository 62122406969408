import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Table,
  DatePicker,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { CiTrash } from "react-icons/ci";
import Column from "antd/es/table/Column";
import { DataTable } from "primereact/datatable";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [chiTietThuChi, setChiTietThuChi] = useState([]);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HaoHut":
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tài sản thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };

  const handleTabChange = (key) => {
    console.log("Tab active:", key);
  };

  const renderChiTietThuChi = () => {
    const addChiTietThuChi = () => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      _chiTietThuChi.push({
        ID: null,
        SanPham: "",
        SoPhieu: "",
        SoTienChi: "",
        LoaiChi: "",
        NgayChi: "",
        DienGiai: "",
        GhiChu: "",
        PTGiamTinhLai: "",
        SoTienChiGiamLai: "",
        SoGiaoDich: "",
        SoDatMua: "",
        SoHDMB: "",
        SoHDVay: "",
        NguoiNop: "",
      });
      setChiTietThuChi(_chiTietThuChi);
    };

    const onChangeChiTietThuChi = (field, value, index) => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      let item = _chiTietThuChi.slice(index, index + 1);
      if (item?.length > 0) {
        _chiTietThuChi.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setChiTietThuChi(_chiTietThuChi);
    };

    const deleteChiTietThuChi = (item, index) => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      _chiTietThuChi.splice(index, 1);
      setChiTietThuChi(_chiTietThuChi);
      // if (item.ID) {
      //   ToaNhaService.DuAn.deleteChiTietThuChi({ ID: item.ID });
      // }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={chiTietThuChi}
          tableStyle={{ minWidth: "150rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Sản phẩm</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.SanPham}
                onChange={(value) =>
                  onChangeChiTietThuChi("SanPham", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số phiếu</p>}
            body={(item, row) => (
              <Input
                value={item.SoPhieu}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoPhieu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Số tiền chi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                value={item.SoTienChi}
                placeholder=""
                onChange={(value) =>
                  onChangeChiTietThuChi("SoTienChi", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Loại chi</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.LoaiChi}
                onChange={(value) =>
                  onChangeChiTietThuChi("LoaiChi", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Ngày chi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <DatePicker
                value={item?.NgayChi ? dayjs(item?.NgayChi) : null}
                onChange={(date) =>
                  onChangeChiTietThuChi(
                    "NgayChi",
                    date ? date.format("YYYY-MM-DD") : null,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Diễn giải</p>}
            body={(item, row) => (
              <Input
                value={item.DienGiai}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "DienGiai",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />

          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Ghi chú</p>}
            body={(item, row) => (
              <Input
                value={item.GhiChu}
                onChange={(e) =>
                  onChangeChiTietThuChi("GhiChu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>PT giảm tính lãi</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.PTGiamTinhLai}
                onChange={(value) =>
                  onChangeChiTietThuChi("PTGiamTinhLai", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Số tiền chi giảm lãi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                value={item.SoTienChiGiamLai}
                placeholder=""
                onChange={(value) =>
                  onChangeChiTietThuChi("SoTienChiGiamLai", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số giao dịch</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.SoGiaoDich}
                onChange={(value) =>
                  onChangeChiTietThuChi("SoGiaoDich", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số đặt mua</p>}
            body={(item, row) => (
              <Input
                value={item.SoDatMua}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "SoDatMua",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số HĐMB</p>}
            body={(item, row) => (
              <Input
                value={item.SoHDMB}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoHDMB", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số HĐ vay</p>}
            body={(item, row) => (
              <Input
                value={item.SoHDVay}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoHDVay", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Người nộp</p>}
            body={(item, row) => (
              <Input
                value={item.NguoiNop}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "NguoiNop",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addChiTietThuChi}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteChiTietThuChi(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };
  return (
    <Drawer
      title="Thêm mới phiếu chi"
      width={1300}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 12]}>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Số phiếu chi:"
            applyChange={applyChange}
            prop="DCCongChung"
            value={data?.DCCongChung}
          />
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          {" "}
          <DatePickerCustom
            label="Ngày chi:"
            applyChange={applyChange}
            prop={"NgayChi"}
            value={data?.NgayChi}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputNumberCustom
            label="Tổng chi:"
            applyChange={applyChange}
            prop={"TongChi"}
            value={data?.TongChi}
            placeholder={"0"}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Khách hàng:"
            applyChange={applyChange}
            prop="KhachHang"
            value={data?.KhachHang}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Di động:"
            applyChange={applyChange}
            prop="DiDong"
            value={data?.DiDong}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Tài khoản có:"
            applyChange={applyChange}
            prop="TaiKhoanCo"
            value={data?.TaiKhoanCo}
            placeholder="Chọn tài khoản có"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Số CCCD:"
            applyChange={applyChange}
            prop="SoCCCD"
            value={data?.SoCCCD}
          />
        </Col>
        <Col span={8}>
          {" "}
          <DatePickerCustom
            label="Ngày cấp:"
            applyChange={applyChange}
            prop={"NgayCap"}
            value={data?.NgayCap}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Tài khoản nợ:"
            applyChange={applyChange}
            prop="TaiKhoanNo"
            value={data?.TaiKhoanNo}
            placeholder="Chọn tài khoản nợ"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Nơi cấp:"
            applyChange={applyChange}
            prop="NoiCap"
            value={data?.NoiCap}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Email:"
            applyChange={applyChange}
            prop="Email"
            value={data?.Email}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Địa chỉ:"
            applyChange={applyChange}
            prop="DiaChi"
            value={data?.DiaChi}
          />
        </Col>
        <Col span={24}>
          <p style={{ fontWeight: "600", width: "13%" }}>Ghi chú:</p>
          <TextArea
            value={data?.GhiChu}
            onChange={(e) => applyChange("GhiChu", e.target.value)}
          />
        </Col>
        <Col span={24}>
          {" "}
          <InputCustom
            label="Kèm theo:"
            applyChange={applyChange}
            prop="KemTheo"
            value={data?.KemTheo}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Dự án:"
            applyChange={applyChange}
            prop="DuAn"
            value={data?.DuAn}
            placeholder="Chọn dự án"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Hình thức:"
            applyChange={applyChange}
            prop="HinhThuc"
            value={data?.HinhThuc}
            placeholder="Chọn hình thức thanh toán"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Ngân hàng:"
            applyChange={applyChange}
            prop="NganHang"
            value={data?.NganHang}
            placeholder="Chọn ngân hàng"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" onChange={handleTabChange} type="line">
        <TabPane tab="Chi tiết thu chi" key="1">
          {renderChiTietThuChi()}
        </TabPane>
      </Tabs>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
