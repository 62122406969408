import axiosApi from "./api_helper";
export const NhanVienService = {
  getNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/get", payload)
      .then((res) => res.data);
  },
  getNhanVienByMaTN: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/get-bymatn", payload)
      .then((res) => res.data);
  },
  getDetail: async () => {
    return await axiosApi()
      .post("api/nhanvien/detail")
      .then((res) => res.data);
  },
  addNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/add", payload)
      .then((res) => res.data);
  },
  deleteNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/delete", payload)
      .then((res) => res.data);
  },

  khoiPhucPass: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/khoiphuc-pass", payload)
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/nhanvien/get-nhom-quyen")
      .then((res) => res.data);
  },
  getAction: async (payload) => {
    return await axiosApi()
      .post("api/get-action", payload)
      .then((res) => res.data);
  },
  getDashboard: async (payload) => {
    return await axiosApi()
      .post("api/hethong/get-dashboard", payload)
      .then((res) => res.data);
  },
  Categories: {
    getChucVu: async () => {
      return await axiosApi()
        .post("api/nhan-vien/category/get-chucvu")
        .then((res) => res.data);
    },
    addChucVu: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/category/add-chucvu", payload)
        .then((res) => res.data);
    },
    deleteChucVu: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/category/delete-chucvu", payload)
        .then((res) => res.data);
    },
    getPhongBan: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/category/get-phongban", payload)
        .then((res) => res.data);
    },
    addPhongBan: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/category/add-phongban", payload)
        .then((res) => res.data);
    },
    deletePhongBan: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/category/delete-phongban", payload)
        .then((res) => res.data);
    },
  },
  ///categories
  // chức vụ
  getChucVu: async () => {
    return await axiosApi()
      .post("api/categories/get-chucvu")
      .then((res) => res.data);
  },
  addChucVu: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-chucvu", payload)
      .then((res) => res.data);
  },
  deleteChucVu: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-chucvu", payload)
      .then((res) => res.data);
  },
  // phòng ban
  getPhongBan: async () => {
    return await axiosApi()
      .post("api/categories/get-phongban")
      .then((res) => res.data);
  },
  addPhongBan: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-phongban", payload)
      .then((res) => res.data);
  },
  deletePhongBan: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-phongban", payload)
      .then((res) => res.data);
  },
  // nhóm kinh doanh
  getNhomKinhDoanh: async () => {
    return await axiosApi()
      .post("api/categories/get-nhomkinhdoanh")
      .then((res) => res.data);
  },
  addNhomKD: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-nhomkinhdoanh", payload)
      .then((res) => res.data);
  },
  deleteNKD: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-nhomkinhdoanh", payload)
      .then((res) => res.data);
  },
  changePass: async (payload) => {
    return await axiosApi()
      .post("api/ChangePassword", payload)
      .then((res) => res.data);
  },
  resetPass: async (payload) => {
    return await axiosApi()
      .post("api/ResetPassword", payload)
      .then((res) => res.data);
  },

  getNVPB: async (payload) => {
    return await axiosApi()
      .post("api/cagegory/duan/get-nvphongban", payload)
      .then((res) => res.data);
  },
  addNVPB: async (payload) => {
    return await axiosApi()
      .post("api/cagegory/duan/add-nvphongban", payload)
      .then((res) => res.data);
  },
  deleteNVPB: async (payload) => {
    return await axiosApi()
      .post("api/cagegory/duan/delete-nvphongban", payload)
      .then((res) => res.data);
  },
};
