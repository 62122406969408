import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  notification,
} from "antd";
import { CiTrash } from "react-icons/ci";
import { MdDashboard, MdEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import _ from "lodash";
import "./scss/index.scss";
import FormAdd from "./FormAdd";
import Toolbar from "components/Toolbar";
import { PermissionService } from "services/PermissionService";
import FormAddDashboard from "./FormAddDashboard";
export default function PhanQuyen() {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refDetail = useRef(null);
  const refPhanQuyenDashboard = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter();
  }, []);
  const filter = async () => {
    setLoading(true);
    let _res = await PermissionService.getNhomQuyen();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter();
  };
  const columns = [
    {
      title: "Tên nhóm quyền",
      dataIndex: "GroupName",
    },
    {
      title: "",
      fixed: "right",
      width: "190px",
      render: (item) => {
        return (
          <div>
            {" "}
            <Tooltip placement="topRight" title="Phân quyền">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<IoSettingsOutline />}
                onClick={() => refDetail.current.show(item.GroupID)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Phân quyền dashboard">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdDashboard />}
                onClick={() => refPhanQuyenDashboard.current.show(item.GroupID)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => {
                  setDetail(item);
                  showModal();
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá nhóm quyền"
              description="Bạn có chắc chắn muốn xoá nhóm quyền?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.GroupID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await PermissionService.deleteNhomQuyen({
      GroupID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.GroupID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhóm quyền thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    console.log(detail, "test add");

    let _res = await PermissionService.addNhomQuyen(detail);
    if (_res.status === 2000) {
      onAfterSubmit();
      setDetail({});
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [detail, setDetail] = useState({});
  const onChangeGroup = (v) => {
    let _detail = _.cloneDeep(detail);
    _detail.GroupName = v;
    _detail.DienGiai = v;
    setDetail(_detail);
  };
  return (
    <Box>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => showModal()}>
            Thêm
          </Button>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      {contextHolder}
      <Modal
        title="Nhóm quyền"
        open={isModalOpen}
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ"
        onCancel={handleCancel}
      >
        {/* <p>Tên nhóm quyền</p> */}
        <Input
          placeholder="Nhập tên nhóm quyền"
          onChange={(v) => onChangeGroup(v.target.value)}
          value={detail.GroupName}
        />
      </Modal>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddDashboard
        ref={refPhanQuyenDashboard}
        onAfterSubmit={onAfterSubmit}
      />
    </Box>
  );
}
