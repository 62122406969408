import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    loadcategoriesByMaTN,
    defaultValue,
    setFilterCondition,
  } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = null;
        _filter[p] = v;
        break;
      case "KhoiNha":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        _filter.MaKN = v;
        break;

      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {}, []);

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      <FilterDate onChangeDate={onChangeDate} onOf={onOf} />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            mode="multiple"
            value={filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("KhoiNha", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
      </div>
    </div>
  );
};
export default FilterModal;
