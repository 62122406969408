import {
  Button,
  Drawer,
  Flex,
  Input,
  notification,
  Select,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import Column from "antd/es/table/Column";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { Format_Datetime2 } from "layouts/ultis/common";
import { DataTable } from "primereact/datatable";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CiSaveDown1, CiTrash } from "react-icons/ci";
import { DichVuService } from "services/DichVuService";
import { HopDongService } from "services/HopDongService";
import _ from "lodash";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import FormDetail from "views/admin/SoQuy/PhieuChi/FormDetail";
import FormDetailPT from "views/admin/SoQuy/PhieuThu/FormDetail";
import { FaCheckCircle, FaRegSave } from "react-icons/fa";
import { UploadService } from "services/UploadService";
import { MdOutlinePreview } from "react-icons/md";
const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  const [payLoad, setPayload] = useState();
  const [taiLieu, setTaiLieu] = useState([]);
  const [visible, setVisible] = useState(false);

  const refPC = useRef(null);
  const refPT = useRef(null);
  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
      setActiveKey("1");
    },
  }));

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns1 = [
    {
      title: "Dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "% VAT",
      dataIndex: "TyLeVAT",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tổng tiền + VAT",
      dataIndex: "TongTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Kỳ TT",
      dataIndex: "KyThanhToan",
    },
    // {
    //   title: "Ngày TT",
    //   dataIndex: "NgayTT",
    //   render: (item) => (item ? Format_Date(item) : null),
    // },
    // {
    //   title: "Ngày tính phí",
    //   dataIndex: "NgayTinhPhi",
    //   render: (item) => (item ? Format_Date(item) : null),
    // },
    {
      title: "Ngày kết thúc",
      dataIndex: "NgayKT",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const fetchDataForTab = async (key, value) => {
    setActiveKey(key);
    let _res;
    switch (key) {
      case "1":
        _res = await HopDongService.PhuLuc.DVTheoPL({
          ID: value,
        });
        if (_res.data) setData(_res.data);

        break;

      default:
        setData([]);
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Thông tin chi tiết phụ lục"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="1. Dịch vụ" key="1">
            <Table
              pagination={false}
              columns={columns1}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
        <FormDetail ref={refPC} onAfterSubmit={onAfterSubmit} />
        <FormDetailPT ref={refPT} onAfterSubmit={onAfterSubmit} />
      </Drawer>
      {contextHolder}
    </>
  );
};

export default forwardRef(FormQLHistory);
