import axios from "axios";
import axiosApi from "./api_helper";
export const CongNoService = {
  GetCongNoTongHop: async (payload) => {
    return await axiosApi()
      .post("api/cong-no/get-tonghop", payload)
      .then((res) => res.data);
  },
  GetCongNoTongHopChiTiet: async (payload) => {
    return await axiosApi()
      .post("api/cong-no/get-tonghop-detail", payload)
      .then((res) => res.data);
  },

  GetHoaDonChiTiet: async (payload) => {
    return await axiosApi()
      .post("api/soquy/get-hoadon-chitiet", payload)
      .then((res) => res.data);
  },
  GetHoaDonChiTietXoa: async (payload) => {
    return await axiosApi()
      .post("api/soquy/get-hoadon-chitiet-xoa", payload)
      .then((res) => res.data);
  },
  GetThanhToanApp: async (payload) => {
    return await axiosApi()
      .post("api/soquy/get-thanhtoan-app", payload)
      .then((res) => res.data);
  },
  DuyetThanhToan: async (payload) => {
    return axios
      .post("https://api-bms.beesky.vn/api/confirm-debt", payload, {
        auth: {
          username: "admin",
          password: "lal3nlkh1bcd2",
        },
      })
      .then((res) => res.data);
    // return await axiosApi(header)
    //   .post("api/confirm-debt", payload)
    //   .then((res) => res.data);
  },
  HoaDonChiTiet: {
    huyDuyetCN: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/duyet", payload)
        .then((res) => res.data);
    },
    deleteCN: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/delete", payload)
        .then((res) => res.data);
    },
    SuaCN: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/edit-hoadon", payload)
        .then((res) => res.data);
    },
    importExcel: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/import", payload)
        .then((res) => res.data);
    },
    addHoaDon: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/add-hoadonthutruoc", payload)
        .then((res) => res.data);
    },
  },
  TongHop: {
    getTienThua: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/get-info-pay", payload)
        .then((res) => res.data);
    },
    thanhToan: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/pay", payload)
        .then((res) => res.data);
    },
    thaoTacThongBao: async (payload) => {
      return await axiosApi()
        .post("api/hethong/bieumau/thaotac-mauthongbao", payload)
        .then((res) => res.data);
    },
    getNgayHDTT: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/hoadonthutruoc/get-ngay", payload)
        .then((res) => res.data);
    },
    getCanHoThuThua: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/get-canhothuthua", payload)
        .then((res) => res.data);
    },
  },
  TheoKhachHang: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/get-tonghop-bykh", payload)
        .then((res) => res.data);
    },
    detail: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/get-tonghop-detail-bymakh", payload)
        .then((res) => res.data);
    },
  },
  HoaDonPhaiTra: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cong-no/get-hoadonphaitra", payload)
        .then((res) => res.data);
    },
    getDV: async (payload) => {
      return await axiosApi()
        .post("api/phieu-chi/get-dichvu", payload)
        .then((res) => res.data);
    },
    addPhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieu-chi/add", payload)
        .then((res) => res.data);
    },
  },
};
