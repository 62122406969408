import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import Chart from "react-apexcharts";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DeleteOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import FormAdd from "./FormAdd";
import { HeThongService } from "services/HeThongService";
import { ToaNhaService } from "services/ToaNhaService";
import { ThongKeService } from "services/ThongKeService";
import dayjs from "dayjs";
import { Format_Currency } from "layouts/ultis/common";
export default function BaoCao() {
  const [data, setData] = useState([]);
  const [tong12Thang, setTong12Thang] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);

  const [loaiBM, setLoaiBM] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );

  const currentYear = dayjs().year();

  const defaultValue = {
    MaTN: null,
    Year: currentYear,
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  useEffect(() => {
    loadCategories(filterCondition);
  }, []);

  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    let _res = await HeThongService.BieuMau.getLBM();
    if (_res.data) setLoaiBM(_res.data);

    setFilterCondition(_filter);
    filter(_filter);
  };
  const filter = async (_filter) => {
    setLoading(true);

    let doanhThu = [];
    let chiPhi = [];

    // Lấy dữ liệu Doanh Thu
    let _resDT = await ThongKeService.BaoCao.getDoanhThu(_filter);
    if (_resDT?.data) {
      doanhThu = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        const total = _resDT.data
          .filter(
            (item) => item.Thang === month && item.SoTienThanhToan != null
          )
          .reduce((sum, item) => sum + item.SoTienThanhToan, 0);
        return { Thang: month, TongTienThu: total };
      });
    }

    // Lấy dữ liệu Chi Phí
    let _resCP = await ThongKeService.BaoCao.getChiPhi(_filter);
    if (_resCP?.data) {
      chiPhi = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        const total = _resCP.data
          .filter(
            (item) => item.Thang === month && item.SoTienThanhToan != null
          )
          .reduce((sum, item) => sum + item.SoTienThanhToan, 0);
        return { Thang: month, TongTienChi: total };
      });
    }

    // Gộp dữ liệu thành 1 mảng
    const combinedResult = Array.from({ length: 12 }, (_, i) => {
      const month = i + 1;
      const doanhThuThang =
        doanhThu.find((item) => item.Thang === month)?.TongTienThu || 0;
      const chiPhiThang =
        chiPhi.find((item) => item.Thang === month)?.TongTienChi || 0;
      return {
        Thang: month,
        TongTienThu: doanhThuThang,
        TongTienChi: chiPhiThang,
        LoiNhuan: doanhThuThang - chiPhiThang,
      };
    });
    const totals = combinedResult.reduce(
      (acc, item) => {
        acc.totalDoanhThu += item.TongTienThu;
        acc.totalChiPhi += item.TongTienChi;
        acc.totalLoiNhuan += item.LoiNhuan;
        return acc;
      },
      { totalDoanhThu: 0, totalChiPhi: 0, totalLoiNhuan: 0 }
    );
    setTong12Thang(totals);

    // Cập nhật state
    setData(combinedResult); // Nếu cần sử dụng mảng hợp nhất này thay cho setChiPhi hoặc setDoanhThu
    setLoading(false);
  };

  const handleXemMau = (link) => {
    if (link) {
      const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
      window.open(fullLink, "_blank"); // Opens the link in a new tab
    }
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await HeThongService.BieuMau.delete({
      MaBM: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaBM !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };

  // const chartOptionsTKTLX = {
  //   chart: {
  //     type: "bar", // Đặt loại biểu đồ là cột
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false, // Đặt biểu đồ cột dọc
  //       columnWidth: "50%", // Đặt chiều rộng cột
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false, // Ẩn nhãn trên các cột
  //   },
  //   xaxis: {
  //     categories: [
  //       "Tháng 1",
  //       "Tháng 2",
  //       "Tháng 3",
  //       "Tháng 4",
  //       "Tháng 5",
  //       "Tháng 6",
  //       "Tháng 7",
  //       "Tháng 8",
  //       "Tháng 9",
  //       "Tháng 10",
  //       "Tháng 11",
  //       "Tháng 12",
  //     ],
  //     title: {
  //       text: "", // Tiêu đề trục X
  //     },
  //     style: {
  //       fontSize: "0px", // Đặt kích thước phông chữ là 0 để ẩn tiêu đề
  //     },
  //   },
  //   yaxis: {
  //     title: {
  //       text: "", // Tiêu đề trục Y
  //     },
  //   },
  //   colors: ["#FF6347", "#4682B4"],
  // };

  // const chartSeriesTKTLX = [
  //   {
  //     name: "Doanh thu",
  //     data: [
  //       1200000, 1500000, 1800000, 2000000, 2200000, 2500000, 2700000, 3000000,
  //       3200000, 3400000, 3600000, 4000000,
  //     ],
  //   },
  //   {
  //     name: "Chi phí",
  //     data: [
  //       800000, 900000, 1000000, 1200000, 1400000, 1500000, 1600000, 1800000,
  //       1900000, 2000000, 2100000, 2200000,
  //     ],
  //   },
  //   {
  //     name: "Lợi nhuận",

  //     data: [
  //       400000, 600000, 800000, 800000, 800000, 1000000, 1100000, 1200000,
  //       1300000, 1400000, 1500000, 1800000,
  //     ],
  //   },
  // ];

 

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: [0, 3],
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
      title: {
        text: "VND",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
  };

  const chartSeriesTKTLX = [
    {
      name: "Doanh thu",
      type: "bar", // Đặt loại là cột
      data: data?.map((item) => item?.TongTienThu),
    },
    {
      name: "Chi phí",
      type: "bar", // Đặt loại là cột
      data: data?.map((item) => item?.TongTienChi),
    },
    {
      name: "Lợi nhuận",
      type: "line", // Đặt loại là đường
      data: data?.map((item) => item?.LoiNhuan),
    },
  ];

  return (
    <div style={{ backgroundColor: "white", margin: " -10px -20px" }}>
      <Row style={{ padding: "10px 20px" }} gutter={[10, 10]}>
        {/* <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
          }}
        />
      </Col> */}
        <Col span={24}>
          <FilterModal
            defaultValue={defaultValue}
            setFilterCondition={setFilterCondition}
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
            options={{
              ToaNha: toanha,
            }}
          />
          <Row
            style={{
              border: "1px solid #ddd",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <Col
              span={2}
              style={{
                border: "1px solid #ddd",
                textAlign: "center",
                // backgroundColor: "white",
                backgroundColor: "#E6F1FE",
                // padding: "8px",
              }}
            >
              <p
                style={{
                  borderBottom: "1px solid #ddd",
                  fontWeight: "500",
                  padding: "8px",
                }}
              >
                Tháng
              </p>
              <br />
              <p style={{ borderBottom: "1px solid #ddd", fontWeight: "500" }}>
                Doanh Thu
              </p>
              <br />
              <p style={{ borderBottom: "1px solid #ddd", fontWeight: "500" }}>
                Chi phí
              </p>
              <br />
              <p style={{ fontWeight: "500" }}>Lợi nhuận</p>
              <br />
            </Col>
            <Col span={20}>
              <Row
                style={{ border: "1px solid #ddd", backgroundColor: "white" }}
              >
                {data?.map((item, index) => (
                  <Col
                    span={2}
                    key={index}
                    style={{
                      border: "1px solid #ddd",
                      textAlign: "center",

                      // padding: "8px",
                    }}
                  >
                    <p
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "7.8px",
                        fontWeight: "500",
                        backgroundColor: "#E6F1FE",
                      }}
                    >
                      Tháng {item?.Thang}
                    </p>
                    <br />
                    <p
                      style={{
                        borderBottom: "1px solid #ddd",
                        paddingBottom: "8px",
                        marginTop: "-8.5px",
                      }}
                    >
                      {" "}
                      {Format_Currency(item?.TongTienThu)}
                    </p>
                    <br />
                    <p
                      style={{
                        borderBottom: "1px solid #ddd",
                        paddingBottom: "8px",
                        marginTop: "-8.5px",
                      }}
                    >
                      {Format_Currency(item?.TongTienChi)}
                    </p>
                    <br />
                    <p>{Format_Currency(item?.LoiNhuan)}</p>
                    <br />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              span={2}
              style={{
                border: "1px solid #ddd",
                textAlign: "center",
                // backgroundColor: "white",

                // padding: "8px",
              }}
            >
              <p
                style={{
                  backgroundColor: "#E6F1FE",
                  borderBottom: "1px solid #ddd",
                  fontWeight: "500",
                  padding: "8px",
                }}
              >
                Tổng
              </p>
              <br />
              <p style={{ borderBottom: "1px solid #ddd", fontWeight: "500" }}>
                {Format_Currency(tong12Thang?.totalDoanhThu)}
              </p>
              <br />
              <p style={{ borderBottom: "1px solid #ddd", fontWeight: "500" }}>
                {Format_Currency(tong12Thang?.totalChiPhi)}
              </p>
              <br />
              <p style={{ fontWeight: "500" }}>
                {" "}
                {Format_Currency(tong12Thang?.totalLoiNhuan)}
              </p>
              <br />
            </Col>
          </Row>
        </Col>
        {contextHolder}
        <FormAdd
          ref={refDetail}
          onAfterSubmit={onAfterSubmit}
          maTN={filterCondition.MaTN}
          loaiBM={loaiBM}
        />
      </Row>

      <Chart
        options={chartOptions}
        series={chartSeriesTKTLX}
        type="bar"
        height={350} // Chiều cao biểu đồ
      />
    </div>
  );
}
