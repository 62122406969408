import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { CategoryService } from "services/CategoryService";
import { DateTime } from "luxon";
import { Format_Date } from "layouts/ultis/common";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, toanha } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    TuNgay: new Date(),
    DenNgay: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiMB, setLoaiMb] = useState([]);

  const defaultErrors = {
 
  };

  const [errors, setErrors] = useState(defaultErrors);
  const filter = async (_filter) => {
    setLoading(true);
    let _resMB;

    _resMB = await CategoryService.DuAn.MatBang.get(_filter);
    if (_resMB.data) {
      setLoaiMb(_resMB.data);
    }
    setLoading(false);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      filter(filterCondition);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };

  const onSubmit = async (close) => {
    setLoading(true);

    // let valid = validate();
    // if (valid.length > 0) {
    //   setLoading(false);
    //   return;
    // }

    let _data = _.cloneDeep(data);
    const _res = await CategoryService.DichVu.HopDongThiCong.TinhTrang.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} trạng thái!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "15px" }} gutter={[20, 12]}>
          <Col span={24}>
            <InputCustom
              label="Tên tình trạng:"
              applyChange={applyChange}
              prop={"TenTT"}
              value={data?.TenTT}
              placeholder="0"
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={
        isupdate ? "Sửa tình trạng hợp đồng" : "Cập nhật tình trạng hợp đồng"
      }
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
