import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import _ from "lodash";
import "dayjs/locale/vi";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const oneMonthAgo = dateInHoChiMinh.minus({ months: 1 });
  const defaulData = {};

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MatBang: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData({
        ...data,
        MatBang: {
          value: data?.MaMB,
          label: data?.MaSoMB,
        },
      });
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };

  const sumTongTien = (data) => {
    data.TongTien = data?.SoTien + (data?.VAT * data?.SoTien) / 100;
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MatBang":
        const KH = JSON.parse(v?.title);
        _data.ChiSoMoi = null;
        _data.MaTN = maTN;
        _data.MaKH = KH?.MaKH;
        _data.KhachHang = KH?.TenKH;
        _data.MaMB = KH?.MaMB;
        _data.MaLMB = KH?.MaLMB;
        _data[p] = v;

        break;
      case "VAT":
        _data[p] = v;
        sumTongTien(_data);
        break;
      case "ChiSoMoi":
        _data.TieuThu = v - _data?.ChiSoCu ?? 0;
        _data.SoTien = (v - _data?.ChiSoCu ?? 0) * 1000;
        _data[p] = v;
        sumTongTien(_data);
        break;
      case "ChiSoCu":
        _data.TieuThu = _data?.ChiSoMoi ?? 0 - v;
        _data.SoTien = (_data?.ChiSoMoi ?? 0 - v) * 1000;
        _data[p] = v;
        sumTongTien(_data);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Nuoc.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} dịch vụ nước thành công!`
      );
      setIsUpdate(true);
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: maTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={10}>
            <Row gutter={[20, 12]}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Thông tin chung
              </p>

              <Col span={24}>
                <Flex style={{ borderBottom: "1px solid #ff9087" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30% ",
                    }}
                  >
                    Mặt bằng:<span color="red">*</span>
                  </p>
                  <DebounceSelect
                    open={open}
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MatBang"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputCustom
                  label="Khách hàng:"
                  applyChange={applyChange}
                  prop="KhachHang"
                  value={data?.KhachHang}
                  placeholder=""
                  disabled={true}
                />
              </Col>

              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày nhập:"
                  applyChange={applyChange}
                  prop={"NgayNhap"}
                  value={data?.NgayNhap}
                  placeholder={"Chọn ngày nhập"}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayNhap"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                  Chỉ số gas
                </p>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Chỉ số cũ:"
                  applyChange={applyChange}
                  prop={"ChiSoCu"}
                  value={data?.ChiSoCu}
                  placeholder="0"
                />
              </Col>{" "}
              <Col span={12}>
                <InputNumberCustom
                  label="Chỉ số mới:"
                  applyChange={applyChange}
                  prop={"ChiSoMoi"}
                  value={data?.ChiSoMoi}
                  placeholder="0"
                />
              </Col>{" "}
              <Col span={12}>
                <InputNumberCustom
                  disabled={true}
                  label="Tiêu thụ:"
                  applyChange={applyChange}
                  prop={"TieuThu"}
                  value={data?.TieuThu}
                  placeholder="0"
                />
              </Col>{" "}
              <Col span={12}>
                <InputNumberCustom
                  // disabled={true}
                  label="Số tiền:"
                  applyChange={applyChange}
                  prop={"SoTien"}
                  value={data?.SoTien}
                  placeholder="0"
                />
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="VAT:"
                  applyChange={applyChange}
                  prop={"VAT"}
                  value={data?.VAT}
                  placeholder="0"
                />
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  disabled={true}
                  label="Tổng tiền:"
                  applyChange={applyChange}
                  prop={"TongTien"}
                  value={data?.TongTien}
                  placeholder="0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Thêm: gas"
      width={1060}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
