import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
  Tag,
  Dropdown,
} from "antd";
import _ from "lodash";
import { CiSearch } from "react-icons/ci";
import { YeuCauService } from "services/YeuCauService";
import { ToaNhaService } from "services/ToaNhaService";
import "./index.scss";
import { ChatsService } from "services/ChatsService";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { BsGrid1X2 } from "react-icons/bs";
import { io } from "socket.io-client";
import { Format_Time } from "layouts/ultis/common";
import BoxRight from "./BoxRight";
import MessageItem from "./MessageItem";
import MessageInput from "./MessageInput";
import MessageTop from "./MessageTop";
import MessageBox from "./MessageBox";
import { useLocation } from "react-router-dom";
import { UploadService } from "services/UploadService";
import { URL_UPLOAD } from "services/UploadService";
import { OtherService } from "services/OtherService";
import BoxLeft from "./BoxLeft";
import { NhanVienService } from "services/NhanVienService";

const { TextArea } = Input;
function calculateMessageTime(messageTime) {
  // Chuyển đổi thời gian tin nhắn sang đối tượng Date
  const messageDate = new Date(messageTime);
  // Lấy thời gian hiện tại
  const currentDate = new Date();
  // Tính khoảng thời gian giữa hai thời điểm (tính bằng mili giây)
  const timeDifference = currentDate - messageDate;
  // Chuyển đổi khoảng thời gian sang phút, giờ và ngày
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Nếu số ngày trôi qua lớn hơn 7, hiển thị dưới dạng dd/mm/yy
  if (days > 7) {
    const dd = messageDate.getDate();
    const mm = messageDate.getMonth() + 1; // Tháng bắt đầu từ 0
    const yy = messageDate.getFullYear().toString().slice(-2); // Lấy 2 chữ số cuối của năm
    return `${dd}/${mm}/${yy}`;
  } else {
    // Trả về kết quả dưới dạng chuỗi mô tả thời gian
    if (days > 0) {
      return `${days} ngày`;
    } else if (hours > 0) {
      return `${hours} giờ`;
    } else if (minutes > 0) {
      return `${minutes} phút`;
    } else {
      return "Vừa gửi";
    }
  }
}
const userColors = {};
const colorArr = [
  "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #a695e7 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #faa774 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #7bc862 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, #6ec9cb 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, ##ee7aae 0%, #b9b6e5 100%)",
  "linear-gradient(-225deg, ##e17076 0%, #b9b6e5 100%)",
];
const getRandomColor = (userId) => {
  // return colorArr[Math.floor(Math.random() * colorArr.length)];
  if (!userColors[userId]) {
    // Nếu chưa, tạo một màu mới và gán cho user đó
    userColors[userId] = colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  // Trả về màu của user
  return userColors[userId];
};
export default function Index() {
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingms, setLoadingms] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const refRight = useRef(null);
  const refInbox = useRef(null);
  const [messageBox, setMessageBox] = useState(null);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: 0,
    InputSearch: "",
  });
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [typing, setTyping] = useState(false);
  const audio = new Audio(require("../../assets/audio/noti-message.wav"));
  const audiotyping = new Audio(require("../../assets/audio/typing.mp3"));
  const location = useLocation();

  useEffect(() => {
    //  console.log(params);
    // if (params.length > 0){

    // console.log(id);

    // }
    (async () => {
      await loadCategories();
    })();
    const newSocket = io("https://api-beesky.onrender.com");
    // const newSocket = io("http://localhost:3001");
    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, []);
  useLayoutEffect(() => {
    scrollToBottom();
  });
  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 2000;
    }
  };
  const loadCategories = async () => {
    // let _tn = await ToaNhaService.getToaNhaByNV();
    // setToanha(_tn.data);
    let matn = location?.pathname?.slice(7);
    let _filter = _.cloneDeep(filterCondition);
    // _filter.MaTN = _tn.data[0]?.MaTN;
    _filter.MaTN = Number(matn);
    let _resNV = await NhanVienService?.getNhanVienByMaTN({
      MaTN: Number(matn),
    });
    if (_resNV?.data) setNhanVien(_resNV?.data);
    setFilterCondition(_filter);
    // await load(_filter);
    let _res = await ChatsService.getInbox(_filter);
    setData(_res.data);
    let search = location?.search;
    if (search.length > 0) {
      let id = Number(search.slice(4));
      setLoadingms(true);
      refInbox.current = { id: id };
      let _resmessages = await ChatsService.getMessages(id);
      setMessages(_resmessages?.data);
      let _resmb = await ChatsService.getMemberGroup(id);
      setMembers(_resmb?.data);
      setLoadingms(false);
      let _ibs = _.cloneDeep(_res.data);

      let _ib = _ibs.find((v) => v.id === id);
      if (_ib) _ib.isread = true;
      setData(_ibs);
      ChatsService.readMessage({
        inbox_id: id,
        user_id: Number(JSON.parse(localStorage.getItem("NhanVien")).maNV),
        read: true,
      });
      refRight.current?.show();
      // await onClickDetail({ id: id });
    }
  };
  const load = async (_filter) => {
    setLoading(true);
    let _res = await ChatsService.getInbox(_filter);
    setData(_res.data);
    setLoading(false);
  };
  useEffect(() => {
    if (socket === null) return;
    let manv = Number(JSON.parse(localStorage.getItem("NhanVien"))?.maNV);
    socket.emit("addNewUser", { userId: manv, is_admin: true });
    socket.on("getOnlineUsers", (res) => {
      setOnlineUsers(res);
    });
    socket.on("getMessage", (res) => {
      audio.play();
      if (refInbox?.current?.id === res?.data?.inbox_id) {
        let _data = _.cloneDeep(data);
        let _ib = _data.find((v) => v.id === res?.data?.inbox_id);
        if (_ib) {
          _ib.date_send = res?.data?.create_at;
          _ib.last_message = res?.data?.message;
          _ib.last_sent_user_id = res?.data?.user_id;
          setData(_data);
        }
        setMessages((pre) => {
          return [...pre, res.data];
        });
      } else {
        let _data = _.cloneDeep(data);
        let _ib = _data.find((v) => v.id === res?.data?.inbox_id);
        if (_ib) {
          _ib.date_send = res?.data?.create_at;
          _ib.isread = false;
          _ib.last_message = res?.data?.message;
          _ib.last_sent_user_id = res?.data?.user_id;
          setData(_data);
        }
      }
    });
    // socket.on("getInbox", (res) => {});
    socket.on("typing", (res) => {
      let _data = _.cloneDeep(data);
      if (refInbox?.current?.id === res.inbox_id) {
        setTyping(res.data);
        if (res.data === true) {
          audiotyping.play();
        }
      }
    });
    return () => {
      socket.off("getOnlineUsers");
      socket.off("getMessage");
      // socket.off("getInbox");
      socket.off("typing");
    };
  }, [socket, data]);

  const applyChange = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter[p] = v;
    setFilterCondition(_filter);
    load(_filter);
  };
  const onClickDetail = async (item) => {
    setLoadingms(true);
    refInbox.current = item;
    let _res = await ChatsService.getMessages(item.id);
    setMessages(_res?.data);
    let _resmb = await ChatsService.getMemberGroup(item.id);
    // filterMemberGroup(_resmb?.data);
    setMembers(_resmb?.data);
    setLoadingms(false);
    let _ibs = _.cloneDeep(data);
    let _ib = _ibs.find((v) => v.id === item.id);
    if(_ib){
      if (_ib) _ib.isread = true;
      setData(_ibs);
    }
   
    ChatsService.readMessage({
      inbox_id: item.id,
      user_id: Number(JSON.parse(localStorage.getItem("NhanVien")).maNV),
      read: true,
    });
    refRight.current?.show();
  };
  // function truncate(str, n) {
  //   return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  // }
  const addMessage = async (textSend) => {
    setLoadingSend(true);
    let nhanvien = JSON.parse(localStorage.getItem("NhanVien"));
    let _res = await ChatsService.sendMessage({
      inbox_id: refInbox?.current?.id,
      message: textSend,
      user_id: nhanvien?.maNV,
      user_name: nhanvien?.tenNV,
      ma_tn: filterCondition?.MaTN,
      is_image: false,
    });
    if (_res.status === 2000) {
      //gửi tin realtime cho user khác (socket)
      let _users = members.filter((v) => v.user_id !== nhanvien?.maNV);
      if (socket) {
        _users?.map((item) => {
          socket.emit("sendMessage", {
            data: {
              id: _res.data,
              user_id: nhanvien?.maNV,
              message: textSend,
              create_at: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toJSON(),
              inbox_id: refInbox?.current?.id,
              is_image: false,
              user_name: nhanvien?.tenNV,
            },
            userId: item.user_id,
          });
          socket.emit("typing", { data: false, userId: item.user_id });
        });
      }
      //end

      //gửi thông báo nếu user không đang chat
      //  let _userOnline = _.map(onlineUsers, "userId");
      OtherService.sendThongBao({
        TieuDe: nhanvien?.tenNV,
        NoiDung: textSend,
        FormID: 123,
        LinkID: refInbox?.current?.id,
        MatBang: _.map(_users, "user_id"), //item?.MatBang?.split(",")?.map(Number),
      });
      _users?.map((item) => {
        ChatsService.readMessage({
          inbox_id: refInbox?.current?.id,
          user_id: item.user_id,
          read: false,
        });
      });
      //end

      //cập nhật inboxs
      let _data = _.cloneDeep(data);
      let _ib = _data.find((v) => v.id === refInbox.current?.id);
      if(_ib){
        _ib.date_send = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toJSON();
        _ib.isread = true;
        _ib.last_message = textSend;
        _ib.last_sent_user_id = nhanvien?.maNV;
        setData(_data);
      }
      
      //end


      // thêm 1 dòng vào tin nhắn
      let _mes = _.cloneDeep(messages);
      _mes.push({
        id: _res.data,
        user_id: nhanvien?.maNV,
        message: textSend,
        create_at: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toJSON(),
        is_image: false,
        user_name: nhanvien?.tenNV,
      });
      setMessages(_mes);
      //end
    }
    setLoadingSend(false);
  };
  const addMessageFile = async (files) => {
    setLoadingSend(true);
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("Image", files[i]);
    }
    formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
    let _resupload = await UploadService.upload(formData);
    if (_resupload.status !== 2000) {
      setLoadingSend(false);
      return;
    }
    let nhanvien = JSON.parse(localStorage.getItem("NhanVien"));
    let _res = await ChatsService.sendMessage({
      inbox_id: refInbox?.current?.id,
      message: JSON.stringify(_resupload?.data?.map((v) => URL_UPLOAD + v)),
      user_id: nhanvien?.maNV,
      user_name: nhanvien?.tenNV,
      ma_tn: filterCondition?.MaTN,
      is_image: true,
    });
    if (_res.status === 2000) {
      let _users = members.filter((v) => v.user_id !== nhanvien?.maNV);
      if (socket) {
        _users?.map((item) => {
          socket.emit("sendMessage", {
            data: {
              id: _res.data,
              user_id: nhanvien?.maNV,
              message: JSON.stringify(
                _resupload?.data?.map((v) => URL_UPLOAD + v)
              ),
              create_at: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toJSON(),
              inbox_id: refInbox?.current?.id,
              is_image: true,
              user_name: nhanvien?.tenNV,
            },
            userId: item.user_id,
          });
          socket.emit("typing", { data: false, userId: item.user_id });
        });
      }
      // cập nhật inbox
      let _data = _.cloneDeep(data);
      let _ib = _data.find((v) => v.id === refInbox.current?.id);
      if(_ib){
        _ib.date_send = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toJSON();
        _ib.isread = true;
        _ib.is_image = true;
        _ib.last_sent_user_id = nhanvien?.maNV;
        setData(_data);
      }

      //end
      let _mes = _.cloneDeep(messages);
      _mes.push({
        id: _res.data,
        is_image: true,
        user_id: nhanvien?.maNV, //token.User?.MaNV,
        message: JSON.stringify(_resupload?.data?.map((v) => URL_UPLOAD + v)),
        create_at: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toJSON(),
        user_name: nhanvien?.tenNV,
      });
      setMessages(_mes);
    }
    setLoadingSend(false);
  };
  return (
    <Row className="chats">
      <Col span={6} className="left-chats">
        {/* <Flex
          style={{
            padding: "5px 10px",
            boxShadow: "0 2px 2px rgb(114,114,114,0.169)",
          }}
          align="center"
          gap={10}
        >
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Flex
              align="center"
              justify="center"
              style={{
                background:
                  "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
                width: 35,
                height: 35,
                borderRadius: 50,
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: 9,
              }}
            >
              <p>User</p>
            </Flex>
          </Dropdown>
          <div className="search-input">
            <CiSearch size={20} />
            <input
              className="input"
              placeholder="Tìm kiếm"
              onChange={(v) => applyChange("InputSearch", v.target.value)}
            />
          </div>
        </Flex>
        <div className="inboxs">
          {loading && <Skeleton active />}
          {_.orderBy(data, ["date_send"], ["desc"]).map((item) => {
            return (
              <Flex
                className={
                  item?.id === refInbox?.current?.id
                    ? "ripple-container-active"
                    : "ripple-container"
                }
                align="center"
                gap={10}
                key={item.id}
                onClick={() => onClickDetail(item)}
              >
                <div
                  className="avatar"
                  style={{
                    background: getRandomColor(item.id),
                  }}
                >
                  {item?.name_chat?.charAt(0)}
                </div>
                <div style={{ width: "85%" }}>
                  <Flex justify="space-between" style={{ width: "100%" }}>
                    <p style={{ fontWeight: item?.isread ? "400" : "bold" }}>
                      {item.name_chat}
                    </p>
                    <p>{calculateMessageTime(item.date_send)}</p>
                  </Flex>
                  <Flex justify="space-between">
                    <p
                      style={{
                        color:
                          item?.id === refInbox?.current?.id
                            ? "white"
                            : "rgb(142,142,146)",
                      }}
                    >
                      {item.last_sent_user_id ===
                        Number(
                          JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                        ) && <span>Bạn: </span>}
                      {truncate(item.last_message, 30)}
                    </p>
                    {!item?.isread && (
                      <Tag color="green" style={{ height: "23px" }}>
                        +1
                      </Tag>
                    )}
                  </Flex>
                </div>
              </Flex>
            );
          })}
        </div> */}
        <BoxLeft
          filterCondition={filterCondition}
          refInbox={refInbox}
          onClickDetail={onClickDetail}
          applyChange={applyChange}
          loading={loading}
          data={data}
          nhanVien={nhanVien}
          setLoading={setLoading}
        />
      </Col>
      {refInbox?.current?.id && (
        <Col span={isShowDetail ? 12 : 18} className="content-chats">
          <MessageTop
            refInbox={refInbox}
            members={members}
            setIsShowDetail={setIsShowDetail}
            isShowDetail={isShowDetail}
          />
          <MessageBox
            loadingms={loadingms}
            messages={messages}
            setMessageBox={setMessageBox}
          />
          <MessageInput
            typing={typing}
            socket={socket}
            addMessage={(v) => addMessage(v)}
            loadingSend={loadingSend}
            members={members}
            addMessageFile={(v) => addMessageFile(v)}
          />
        </Col>
      )}
      {isShowDetail && (
        <BoxRight
          ref={refRight}
          members={members}
          images={messages?.filter((v) => v.is_image)}
        />
      )}
    </Row>
  );
}
