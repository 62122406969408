import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Input,
  Pagination,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import {
  MdEdit,
  MdOutlineContentPasteSearch,
  MdOutlinePendingActions,
} from "react-icons/md";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import FormView from "./FormView";
import { Format_Datetime } from "layouts/ultis/common";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CongNoService } from "services/CongNoService";
import { Format_Currency } from "layouts/ultis/common";
import { CuDanService } from "services/CuDanService";
import {
  CreditCardOutlined,
  DollarOutlined,
  ExportOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DateTime } from "luxon";
import FormModal from "./FormThongBao";
import { HeThongService } from "services/HeThongService";
import FormDetailConNo from "./FormDetailConNo";

export default function Index() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refDetailCN = useRef(null);
  const refDetailView = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultValue = {
    DenNgay: dateInHoChiMinh.endOf("month").toISO(),
    PageSize: 1000,
    PageIndex: 1,
    Input: "",
    MaTN: 0,
    MaKN: null,
    MaTL: ",,",
    MaLDV: ",,",
    TongTien: {
      Type: "Empty",
      Value: 0,
      ValueTo: 0,
    },
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const [nhanvien, setNhanvien] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [bieuMau, setBieuMau] = useState([]);
  const [canHoThuThua, setCanHoThuThua] = useState(false);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "Mã khách hàng",
      width: 120,
      render: (item) => (
        <p
          style={{ fontWeight: "bold", color: "cadetblue", cursor: "pointer" }}
          onClick={() => refDetailView.current.show(item)}
        >
          {item.KyHieu}
        </p>
      ),
    },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoai",
      width: 120,
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Tổng tiền nợ",
      dataIndex: "TongTien",
      render: (item) =>
        item ? (
          <p
            style={{ cursor: "pointer" }}
            // onClick={() => refDetailCN.current.show(item)}
          >
            {" "}
           { Format_Currency(item)}
          </p>
        ) : (
          0
        ),
    },
    {
      title: "",
      fixed: "right",
      width: 80,
      render: (item) => (
        <Flex gap={5} justify="center">
          <Tooltip placement="topRight" title="Thanh toán">
            <Button
              type="primary"
              ghost
              icon={<CreditCardOutlined />}
              onClick={() => refDetail.current.show(item)}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    loadCategories();
  }, []);

  const load = async (_filter, newCanHoThuThua) => {
    let _res;
    setLoading(true);
    try {
      if (newCanHoThuThua) {
        _res = await CongNoService.TongHop.getCanHoThuThua(_filter);
      } else {
        _res = await CongNoService.TheoKhachHang.get(_filter);
        _filter.MaKN = null;
        _filter.MaTL = ",,";
        _filter.MaLDV = ",,";
        _filter.TongTien = {
          Type: "Empty",
          Value: 0,
          ValueTo: 0,
        };
      }
      const fetchedData = _res?.data || [];
      await setData2(fetchedData);

      handleFilterCongNo(
        _filter?.MaKN,
        _filter?.MaTL,
        _filter?.MaLDV,
        _filter?.TongTien,
        fetchedData
      );
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterCongNo = (khoiNha, tangLau, dichVu, tongTien, data) => {
    try {
      const tangLauFilter = Array.isArray(tangLau)
        ? tangLau
        : (tangLau || "")
            .split(",")
            .filter((item) => item.trim() !== "")
            .map(Number);

      const filteredData = (data || data2 || []).filter((item) => {
        const matchKhoiNha =
          khoiNha !== null && khoiNha !== undefined
            ? item?.MaKN === khoiNha
            : true;

        const matchTangLau =
          tangLauFilter.length > 0 ? tangLauFilter.includes(item?.MaTL) : true;

        let matchTongTien = true;
        if (tongTien) {
          const { Type, Value, ValueTo } = tongTien;
          switch (Type) {
            case "TuDen":
              if (Value !== undefined && ValueTo !== undefined) {
                matchTongTien =
                  item?.TongTien >= Value && item?.TongTien <= ValueTo;
              }
              break;
            case "Bang":
              if (Value !== undefined) {
                matchTongTien = item?.TongTien === Value;
              }
              break;
            case "LonHonBang":
              if (Value !== undefined) {
                matchTongTien = item?.TongTien >= Value;
              }
              break;
            case "BeHonBang":
              if (Value !== undefined) {
                matchTongTien = item?.TongTien <= Value;
              }
              break;
            default:
              matchTongTien = true;
          }
        }
        return matchKhoiNha && matchTangLau && matchTongTien;
      });

      setData(filteredData);
    } catch (error) {
      console.error("Error filtering data:", error);
      setData([]);
    }
  };

  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    await loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    load(_filter, canHoThuThua);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _pb = await NhanVienService.Categories.getPhongBan({
      MaTN: matn,
    });
    setPhongban(_pb.data);
    let _nv = await NhanVienService.getNhanVienByMaTN({ MaTN: matn });
    setNhanvien(_nv.data);
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
    loadTangLau("", matn);
    let _resBM = await HeThongService.BieuMau.get({ MaTN: matn });
    setBieuMau(_resBM.data);
  };
  const loadTangLau = async (makn, matn) => {
    let _res = await ToaNhaService.Categories.getTangByKhoiNhas({
      KhoiNha: "," + makn + ",",
      MaTN: matn,
    });
    setTanglau(_res.data);
  };

  const loadKhoiNha = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onAfterSubmit = () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = 1;
    load(_ft, canHoThuThua);
  };
  const onChangeFilter = (_filter, newCanHoThuThua) => {
    let _ft = _.cloneDeep(_filter);
    _ft.PageIndex = 1;
    // load(_ft, newCanHoThuThua);
    setFilterCondition(_filter);
  };
  const onChangeFilterByTN = (_filter, newCanHoThuThua) => {
    let _ft = _.cloneDeep(_filter);
    _ft.PageIndex = 1;
    load(_ft, newCanHoThuThua);
    setFilterCondition(_filter);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    // load(_ft, canHoThuThua);
  };

  const onSelectChange = async (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);

    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách công nợ tổng hợp" + fileExtension);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.Input = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    if (!filterCondition?.Input) {
      setData(data2);
      return;
    }
    const newData = data2.filter(
      (item) =>
        item?.MaSoMB?.toLowerCase().includes(
          filterCondition?.Input?.toLowerCase()
        ) ||
        item?.Email?.toLowerCase().includes(
          filterCondition?.Input?.toLowerCase()
        ) ||
        item?.DienThoai?.toLowerCase().includes(
          filterCondition?.Input?.toLowerCase()
        ) ||
        item?.TenKH?.toLowerCase().includes(
          filterCondition?.Input?.toLowerCase()
        )
    );

    setData(newData);
  }, [filterCondition.Input]);

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const handleThongBao = () => {
    if (selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    setIsModalVisible(true);
  };
  return (
    <Row gutter={[10, 10]} className="congno-tonghop">
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          onChangeFilterByTN={onChangeFilterByTN}
          options={{
            TangLau: tanglau,
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          loadKhoiNha={loadKhoiNha}
          loadTangLau={loadTangLau}
          canHoThuThua={canHoThuThua}
          setCanHoThuThua={setCanHoThuThua}
          handleFilterCongNo={handleFilterCongNo}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                onClick={() => handleThongBao()}
                icon={
                  <MdOutlineContentPasteSearch
                    style={{ fontSize: "13px", margin: "0 -2px" }}
                  />
                }
              >
                {" "}
                Thông báo
              </Button>
              <Button onClick={() => exportToCSV()}>
                {" "}
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
          bordered
          pagination={false}
          size="small"
          virtual={true}
          rowSelection={{
            ...rowSelection,
            columnWidth: 60,
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={2}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} fixed="right">
                    <p style={{ fontWeight: "bold" }}>
                      {!canHoThuThua
                        ? Format_Currency(_.sum(_.map(pageData, "TongTien")))
                        : Format_Currency(
                            _.sum(_.map(pageData, "TienThuThua"))
                          )}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        {/* <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data?.length > 0 && (
            <Pagination
              onChange={onChangePage}
              total={data[0]?.TotalRows}
              showSizeChanger
              pageSizeOptions={["100", "500", "1000", "5000"]}
              defaultPageSize={"1000"}
            />
          )}
        </div> */}
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{
          ToaNha: toanha,
        }}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{
          NhanVien: nhanvien,

          ToaNha: toanha,
        }}
        filterCondition={filterCondition}
      />
      <FormDetailConNo ref={refDetailCN} />
      <FormView
        ref={refDetailView}
        options={{
          ToaNha: toanha,
          NhanVien: nhanvien,
          PhongBan: phongban,
        }}
      />
      <FormModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        bieuMau={bieuMau}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        selectedRowKeys={selectedRowKeys}
        dataAll={data}
      />
      {contextHolder}
    </Row>
  );
}
