import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { HomeService } from "services/HomeService";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { OtherService } from "services/OtherService";
import { Col, Image, Row } from "antd";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const MaCTDK = localStorage.getItem("MaCTDKLogOut");
  const defaultData = {
    TenCTDKVT: MaCTDK ? MaCTDK : "",
    Email: "",
    Password: "",
  };
  const [data, setData] = useState(defaultData);
  const [err, setErr] = useState("");
  const handleClick = () => setShow(!show);
  const login = async () => {
    try {
      let _checkHSD = await CongTyService.checkHanSuDung({
        MaCT: data.TenCTDKVT,
      });
      if (_checkHSD?.status !== 2000) {
        setErr(_checkHSD.message);
        return;
      }
      let _res = await HomeService.Auth.login(data);
      if (_res.status === 200) {
        localStorage.setItem("token", _res?.acessToken);
        localStorage.setItem("NhanVien", JSON.stringify(_res?.data));
        localStorage.setItem("MaCTDK", data.TenCTDKVT);
        localStorage.setItem("MaCTDKLogOut", data.TenCTDKVT);
        // let _resmenu = await HomeService.Auth.getMenu();
        // localStorage.setItem("menu", JSON.stringify(_resmenu.data));
        let _resmenuct = await PermissionService.CongTy.getMenu();
        localStorage.setItem("menu_congty", JSON.stringify(_resmenuct.data));
        let _resmenu = await PermissionService.getMenu();
        localStorage.setItem("menu", JSON.stringify(_resmenu.data));

        let matn = window.location?.hash?.slice(20);
        if (matn) {
          history.push("/chats/" + matn);
        } else {
          history.push("/");
        }
      } else {
        setErr(_res.message);
      }
    } catch {}
  };
  const applyChange = (p, v) => {
    let _data = Object.assign({}, data);
    switch (p) {
      // case "private":
      //   _data[p] = v;
      //   localStorage.setItem("private", v);
      //   break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Row gutter={[20, 12]}>
      <Col sm={2} md={3} lg={4}></Col>
      <Col sm={9} md={8} lg={7}>
        {" "}
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Đăng nhập
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Nhập mã công ty, email và mật khẩu để đăng nhập!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Mã công ty<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="BEESKY"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={data.TenCTDKVT}
                onChange={(e) => applyChange("TenCTDKVT", e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Tài khoản<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={data.Email}
                onChange={(e) => applyChange("Email", e.target.value)}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Mật khẩu<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  value={data.Password}
                  onChange={(e) => applyChange("Password", e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {/* <Checkbox
              defaultChecked={localStorage.getItem("private") === "true"}
              // checked={localStorage.getItem("private") === "true"}
              style={{ marginBottom: 10 }}
              onChange={(e) => applyChange("private", e.target.checked)}
            >
              Nội bộ
            </Checkbox> */}
              {err && <p style={{ color: "red" }}>{err}</p>}
              {/* <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
              <Button
                onClick={() => login()}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                Đăng nhập
              </Button>
            </FormControl>
            <Flex>
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Chưa có tài khoản?
                <NavLink to="/auth/sign-up">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Tạo tài khoản mới
                  </Text>
                </NavLink>
              </Text>
            </Flex>
            {/* <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
          </Flex>
        </Flex>
      </Col>
      <Col sm={0} md={1} lg={2}></Col>
      <Col sm={13} md={12} lg={11}>
        <Image
          style={{ borderBottomLeftRadius: "30%" }}
          preview={false}
          width={"100wh"}
          height={"99vh"}
          src="https://images.pexels.com/photos/273209/pexels-photo-273209.jpeg?cs=srgb&dl=pexels-pixabay-273209.jpg&fm=jpg"
        />
      </Col>
    </Row>
    // </DefaultAuth>
  );
}

export default SignIn;
