import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Datetime2 } from "layouts/ultis/common";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DichVuService } from "services/DichVuService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
    },
  }));

  const columns = [
    {
      title: "Ngày xử lý",
      dataIndex: "NgayXL",
      key: "NgayXL",

      render: (item) => (item ? Format_Datetime2(item) : ""),
    },
    {
      title: "Trạng thái",
      dataIndex: "TinhTrang",
      key: "TinhTrang",
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
      key: "NoiDung",
    },

    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
    },
  ];

  const columnsTab2 = [
    {
      title: "ký hiệu",
      dataIndex: "KyHieu",
      key: "KyHieu",
    },
    {
      title: "Tên tài liệu",
      dataIndex: "TenTaiLieu",
      key: "TenTaiLieu",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
    {
      title: "Nhân viên",
      dataIndex: "NhanVien",
      key: "NhanVien",
    },
    {
      title: "Ngày CN",
      dataIndex: "NgayCN",
      key: "NgayCN",
      render: (item) => (item ? Format_Datetime2(item) : ""),
    },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await DichVuService.HopDongThiCong.NhatKyXuLy({
          MaHDTC: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử hợp đồng thi công"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Nhật ký xử lý" key="1">
            <Table
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="Tài liệu" key="2">
            <Table
              pagination={false}
              columns={columnsTab2}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
