import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  notification,
  Table,
  Divider,
  Checkbox,
  Flex,
  Collapse,
  InputNumber,
  Modal,
  Spin,
} from "antd";
import "./index.scss";
import _, { cloneDeep } from "lodash";
import { YeuCauService } from "services/YeuCauService";
import { Format_Datetime } from "layouts/ultis/common";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import { LoadingOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Format_Currency } from "layouts/ultis/common";
import FormHoaDon from "./FormHoaDon";
import { DateTime } from "luxon";
import { CongNoService } from "services/CongNoService";
import { MatBangService } from "services/MatBangService";
import FormLapHoaDon from "./FormLapHoaDon";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { ToaNhaService } from "services/ToaNhaService";
import Mau1A5 from "views/admin/SoQuy/PhieuThu/FormPrint/Mau1A5";
import { useReactToPrint } from "react-to-print";
import MauChiTiet from "views/admin/SoQuy/PhieuThu/FormPrint/MauChiTiet";
import { Format_Date } from "layouts/ultis/common";
import dayjs from "dayjs";

const { TextArea } = Input;
const FormDetail = (props, ref) => {
  const refDetail = useRef(null);
  const componentRef = useRef();
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");

  const defaultData = {
    ChuyenKhoan: false,
    NgayThu: dateInHoChiMinh.toISO(),
    KhachTra: 0,
  };
  const [data, setData] = useState(defaultData);
  const [nhatky, setNhatky] = useState([]);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [listSoPhieu, setListSoPhieu] = useState([]);
  const [pT, SetPT] = useState([]);
  const [nganHang, setNganHang] = useState([]);
  const [iDMauPrint, setIDMauPrint] = useState();
  const [soHD, setSoHD] = useState("");
  const [visible, setVisible] = useState(false);
  const [allPSTT, setAllPSTT] = useState(true);
  const [allNC, setAllNC] = useState(true);
  const [bienTichLyTT, setBienTichLyTT] = useState(0);
  const [NoCu, setNoCu] = useState([]);
  const [PhatSinh, setPhatSinh] = useState([]);

  const [NoCu2, setNoCu2] = useState([]);
  const [PhatSinh2, setPhatSinh2] = useState([]);

  console.log(bienTichLyTT);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    handlePrint();
  };

  const handleCancel = () => {
    setVisible(false);
    setData({});
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // const NoCu = dataSource.filter((item) => item.IsDebt === true);

  const totalRows = NoCu?.length;
  const totalRows2 = PhatSinh?.length;
  const totalPhaiThu = NoCu?.reduce((sum, record) => sum + record.PhaiThu, 0);
  const totalPhaiThu2 = PhatSinh?.reduce(
    (sum, record) => sum + record.PhaiThu,
    0
  );
  const TongThucThu = NoCu?.reduce((sum, record) => sum + record.SoTien, 0);
  const TongThucThu2 = PhatSinh?.reduce(
    (sum, record) => sum + record.SoTien,
    0
  );
  const [rowState, setRowState] = useState(
    dataSource?.map((item) => ({ checked: true, ThucThu: item.ThucThu }))
  );

  const [totalThucThu, setTotalThucThu] = useState(
    dataSource?.reduce((sum, record) => sum + record.ThucThu, 0)
  );

  useEffect(() => {
    let total = rowState.reduce((sum, row) => sum + row.ThucThu, 0);
    setTotalThucThu(total);
    data.ThucThu = total;
    data.TienThua = -total;
  }, [rowState]);

  const onChangeRowSate = (index, checked) => {
    setRowState((prevState) =>
      prevState.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              checked,
              thucThu: checked ? dataSource[index].thucThu : 0,
            }
          : row
      )
    );
  };
  const filterByMaMB = async (maMB) => {
    let _res = await CongNoService.TongHop.getTienThua({
      MaMB: maMB,
      MaTN: filterCondition.MaTN,
    });
    let _resNH = await ToaNhaService.DuAn.getTKNHByTN({
      MaTN: filterCondition.MaTN,
    });
    setNganHang(_resNH?.data ?? []);

    setListSoPhieu(_res?.data ?? []);
    const _resPT = await ToaNhaService.DuAn.getPhieuThu({});
    if (_resPT.data) {
      const mauPT = options.ToaNha.find(
        (item) => item.MaTN === filterCondition.MaTN
      )?.MaMauPhieuThu;

      let mauPTParse = mauPT ? mauPT : [];

      SetPT(_resPT.data?.filter((item) => mauPTParse.includes(item.ID)));
      const newArrMau = _resPT.data?.filter((item) =>
        mauPTParse.includes(item.ID)
      );
      setIDMauPrint(
        newArrMau.find((item) => item.IsMacDinh === true)?.ID ||
          newArrMau[0]?.ID
      );
      const lowestSoTT = _resNH?.data.reduce((min, item) => {
        return item?.SoTT < min?.SoTT ? item : min;
      }, _resNH?.data[0]);
      setData((item) => ({
        ...item,
        KhachTra: 0,
        MaTK: lowestSoTT?.ID,
        ThuaThangTruoc: _res.data?.tienThuaThangTruoc,
        SoPhieu: _res.data?.soPhieuCK,
        MauPhieuThu:
          newArrMau.find((item) => item.IsMacDinh === true)?.ID ||
          newArrMau[0]?.ID,
      }));
    }
  };
  const filterCongNo = async (item) => {
    const jsDate = new Date(filterCondition.DenNgay);
    const dateTime = DateTime.fromJSDate(jsDate);
    const payLoad = {
      MaMB: item.MaMB,
      month: dateTime.month,
      year: dateTime.year,
    };
    let _res = await CongNoService.GetCongNoTongHopChiTiet(payLoad);

    if (_res?.data) {
      const filteredData = _res.data;

      const NoCu = filteredData.filter((item) => item.IsDebt === true);
      const PhatSinh = filteredData.filter((item) => item.IsDebt !== true);

      const TongThucThuNC = NoCu.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      const TongThucThuPS = PhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      setDataSource(filteredData);
      setNoCu(NoCu);
      setPhatSinh(PhatSinh);
      setNoCu2(NoCu);
      setPhatSinh2(PhatSinh);
      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
        TienThua: 0 - (TongThucThuNC + TongThucThuPS),
      }));
    }
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setLoading(true)
      await filterByMaMB(item?.MaMB);
      setData({
        ...item,
        MaHinhThucThuTien: 2,
        YeuCauID: item.ID,
        MaTT: item.MaTT,
        ThucThu: totalThucThu,
        // TienThua: -totalThucThu,
        // PhaiThu: TongThucThu + TongThucThu2,
        ChuyenKhoan: false,
        NguoiNop: item?.TenKH,
        MatBang: {
          value: item?.MaMB,
          label: item?.MaSoMB,
        },
        IsThuThua: false,
        NgayThu: dateInHoChiMinh.toISO(),
      });
      if (filterCondition.DenNgay) {
        await filterCongNo(item);
      } else {
        // console.error("filterCondition.DenNgay is null or undefined");
      }
      setLoading(false);
    },
  }));
  useEffect(() => {
    if (data?.KhachTra != null && data?.PhaiThu != null) {
      setData((prev) => ({
        ...prev,
        TienThua: data.KhachTra - data.PhaiThu,
      }));
    }
  }, [data?.PhaiThu]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onClose = () => {
    setOpen(false);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayThu":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "MaHinhThucThuTien":
        if (_data?.IsThuThua) {
          _data.SoPhieu = listSoPhieu?.soPhieuTT;
        } else if (v === 2) {
          _data.SoPhieu = listSoPhieu?.soPhieuCK;
        } else {
          _data.SoPhieu = listSoPhieu?.soPhieu;
        }

        _data[p] = v;
        break;

      case "CheckNC":
        const updatedNoCu = NoCu.map((item) => {
          if (item.ID === v.ID) {
            const soTien =
              item?.SoTien !== 0 && item?.SoTien <= item?.PhaiThu
                ? item?.SoTien
                : item?.PhaiThu;
            if (
              bienTichLyTT + 1 > _data?.ThuaThangTruoc &&
              _data.IsThuThua === true &&
              item?.IsCheck === false
            ) {
              openNotification(
                "error",
                "topRight",
                "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
              );
              return { ...item, IsCheck: false, SoTien: 0 };
            } else {
              const newIsCheck = !item.IsCheck;

              if (newIsCheck) {
                const increment = Math.min(
                  soTien,
                  _data?.ThuaThangTruoc - bienTichLyTT
                );
                setBienTichLyTT((pr) => pr + increment);

                return {
                  ...item,
                  IsCheck: newIsCheck,
                  SoTien: _data.IsThuThua ? increment : soTien,
                };
              } else {
                setBienTichLyTT((pr) => pr - item?.SoTien);

                return {
                  ...item,
                  IsCheck: newIsCheck,
                  SoTien: 0,
                };
              }
            }
          }
          return item;
        });

        setNoCu(updatedNoCu);
        const allChecked = updatedNoCu.every((item) => item.IsCheck);
        setAllNC(allChecked);
        const TongThucThuNC2 = updatedNoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS2 = PhatSinh.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        _data.PhaiThu = TongThucThuNC2 + TongThucThuPS2;
        break;
      case "CheckPSTT":
        const updatedNPS = PhatSinh.map((item) => {
          if (item.ID === v.ID) {
            const soTien =
              item?.SoTien !== 0 && item?.SoTien <= item?.PhaiThu
                ? item?.SoTien
                : item?.PhaiThu;

            // Điều kiện kiểm tra lỗi vượt quá ThuaThangTruoc
            if (
              bienTichLyTT + 1 > _data?.ThuaThangTruoc &&
              _data.IsThuThua === true &&
              item?.IsCheck === false
            ) {
              openNotification(
                "error",
                "topRight",
                "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
              );
              return { ...item, IsCheck: false, SoTien: 0 };
            } else {
              if (item.IsCheck === true) {
                setBienTichLyTT((pr) => pr - item?.SoTien);
              } else {
                const newTien = Math.min(
                  soTien,
                  _data?.ThuaThangTruoc - bienTichLyTT
                );
                setBienTichLyTT((pr) => pr + newTien);
              }

              return {
                ...item,
                IsCheck: !item.IsCheck,
                SoTien:
                  item.IsCheck === true
                    ? 0
                    : _data.IsThuThua
                    ? Math.min(soTien, _data?.ThuaThangTruoc - bienTichLyTT)
                    : soTien,
              };
            }
          }

          return item;
        });

        setPhatSinh(updatedNPS);
        const allCheckedNC = updatedNPS.every((item) => item.IsCheck);
        setAllPSTT(allCheckedNC);
        const TongThucThuNC = NoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS = updatedNPS.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        _data.PhaiThu = TongThucThuNC + TongThucThuPS;
        break;

      case "IsThuThua":
        setBienTichLyTT(0);
        const TongThucThuNC3 = NoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS3 = PhatSinh.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        if (
          v === false &&
          _data?.ThuaThangTruoc <= TongThucThuNC3 + TongThucThuPS3
        ) {
          const updatedNPS = PhatSinh.map((item) => {
            return { ...item, IsCheck: false, SoTien: 0 };
          });
          const updatedNC = NoCu.map((item) => {
            return { ...item, IsCheck: false, SoTien: 0 };
          });
          setAllNC(false);
          setAllPSTT(false);
          _data.PhaiThu = 0;
          setPhatSinh(updatedNPS);
          setNoCu(updatedNC);
          openNotification(
            "error",
            "topRight",
            "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
          );
        } else {
          const updatedNPS = PhatSinh.map((item) => {
            return { ...item, IsCheck: true, SoTien: item?.PhaiThu };
          });
          const updatedNC = NoCu.map((item) => {
            return { ...item, IsCheck: true, SoTien: item?.PhaiThu };
          });
          const totalAmount = [...updatedNPS, ...updatedNC].reduce(
            (accumulator, item) => {
              return accumulator + (item.SoTien || 0);
            },
            0
          );
          setAllNC(true);
          setAllPSTT(true);
          _data.PhaiThu = totalAmount;
          setPhatSinh(updatedNPS);
          setNoCu(updatedNC);
        }
        if (v) {
          if (_data?.MaHinhThucThuTien === 2) {
            _data.SoPhieu = listSoPhieu?.soPhieuCK;
          } else {
            _data.SoPhieu = listSoPhieu?.soPhieu;
          }
        } else {
          _data.SoPhieu = listSoPhieu?.soPhieuTT;
        }
        _data[p] = !v;

        break;
      case "MauPhieuThu":
        setIDMauPrint(v);
        _data[p] = v;
        break;
      case "KhachTra":
        _data.TienThua = v - _data?.PhaiThu;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (_data?.PhaiThu <= 0) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn dịch vụ để thanh toán!"
      );
      setLoading(false);
      return;
    }
    if (_data?.IsThuThua && ((_data.ThuaThangTruoc ?? 0) < _data.PhaiThu)) {
      openNotification(
        "error",
        "topRight",
        "Tiền thừa tháng trước không đủ, vui lòng kiểm tra lại!"
      );
      setLoading(false);
      return;
    }

    const arrChiTiet = [
      ...NoCu.filter((item) => item.IsCheck === true),
      ...PhatSinh.filter((item) => item.IsCheck === true),
    ];

    // let valid = validate();
    // if (valid?.length > 0) {
    //   console.log(errors);
    //   setLoading(false);
    //   return;
    // }
    const payload = {
      ..._data,
      MaTN: filterCondition.MaTN,
      ChiTiet: arrChiTiet,
    };
    let _res = await CongNoService.TongHop.thanhToan(payload);
    setLoading(false);
    if (_res.status === 2000) {
      setSoHD(_res.data);

      showModal();
      openNotification("success", "topRight", `Thanh toán thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const handleSoTienChange = (item, newValue) => {
    if (isNaN(newValue) || newValue < 0) return;
    const newTienTL =
      item?.IsCheck === true ? bienTichLyTT - item?.SoTien : bienTichLyTT;

    if (newTienTL > data?.ThuaThangTruoc && data?.ThuaThangTruoc) {
      openNotification(
        "error",
        "topRight",
        "Tổng tiền vượt quá giới hạn! Vui lòng nhập lại."
      );
      return;
    }

    setBienTichLyTT(newTienTL);
    const updatedPhatSinh = PhatSinh.map((record) =>
      record.ID === item.ID
        ? {
            ...record,
            SoTien: newValue > item?.PhaiThu ? item?.PhaiThu : newValue,
            // IsCheck: false,
          }
        : record
    );
    setAllPSTT(false);
    setPhatSinh(updatedPhatSinh);

    const TongThucThuNC = NoCu.reduce((sum, record) => sum + record.SoTien, 0);
    const TongThucThuPS = updatedPhatSinh.reduce(
      (sum, record) => sum + record.SoTien,
      0
    );
    setData((prevData) => ({
      ...prevData,
      PhaiThu: TongThucThuNC + TongThucThuPS,
    }));
  };
  const handleSoTienChangeNC = (item, newValue) => {
    if (isNaN(newValue) || newValue < 0) return;

    if (
      bienTichLyTT + newValue > data?.ThuaThangTruoc &&
      data?.ThuaThangTruoc
    ) {
      openNotification(
        "error",
        "topRight",
        "Tổng tiền vượt quá giới hạn! Vui lòng nhập lại."
      );
      return;
    }

    const newTienTL =
      item?.IsCheck === true ? bienTichLyTT - item?.SoTien : bienTichLyTT;
    setAllNC(false);
    setBienTichLyTT(newTienTL);

    const updatedNoCu = NoCu.map((record) =>
      record.ID === item.ID
        ? {
            ...record,
            SoTien: newValue > item?.PhaiThu ? item?.PhaiThu : newValue,
            // IsCheck: false,
          }
        : record
    );
    setNoCu(updatedNoCu);
    const TongThucThuNC = updatedNoCu.reduce(
      (sum, record) => sum + record.SoTien,
      0
    );
    const TongThucThuPS = PhatSinh.reduce(
      (sum, record) => sum + record.SoTien,
      0
    );

    setData((prevData) => ({
      ...prevData,
      PhaiThu: TongThucThuNC + TongThucThuPS,
    }));
  };

  const handlePSTT = () => {
    const beforePSTT = PhatSinh2.reduce(
      (sum, record) => sum + record.SoTien,
      0
    );
    const TongThucThuNC = NoCu2.reduce((sum, record) => sum + record.SoTien, 0);
    let sumTotalCheck = beforePSTT;
    if (allNC) {
      sumTotalCheck += TongThucThuNC;
    }
    if (data?.IsThuThua && data?.ThuaThangTruoc >= sumTotalCheck) {
      const updatedPhatSinh = PhatSinh.map((item) => ({
        ...item,
        IsCheck: !allPSTT,
        SoTien: allPSTT ? 0 : item?.PhaiThu,
      }));
      setPhatSinh(updatedPhatSinh);
      setAllPSTT(!allPSTT);

      const TongThucThuPS = updatedPhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
      }));
    }
    if (!data?.IsThuThua) {
      const updatedPhatSinh = PhatSinh.map((item) => ({
        ...item,
        IsCheck: !allPSTT,
        SoTien: allPSTT ? 0 : item?.PhaiThu,
      }));
      setPhatSinh(updatedPhatSinh);
      setAllPSTT(!allPSTT);

      const TongThucThuPS = updatedPhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
      }));
    }
  };
  const handleNC = () => {
    const beforeNC = NoCu2.reduce((sum, record) => sum + record.SoTien, 0);
    const TongThucThuPS = PhatSinh2.reduce(
      (sum, record) => sum + record.SoTien,
      0
    );
    let sumTotalCheck = beforeNC;
    if (allPSTT) {
      sumTotalCheck += TongThucThuPS;
    }
    if (data?.IsThuThua && data?.ThuaThangTruoc >= sumTotalCheck) {
      const updatedNC = NoCu.map((item) => ({
        ...item,
        IsCheck: !allNC,
        SoTien: allNC ? 0 : item?.PhaiThu,
      }));
      setNoCu(updatedNC);
      setAllNC(!allNC);
      const TongThucThuNC = updatedNC.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      const TongThucThuPS = PhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
      }));
    }
    if (!data?.IsThuThua) {
      const updatedNC = NoCu.map((item) => ({
        ...item,
        IsCheck: !allNC,
        SoTien: allNC ? 0 : item?.PhaiThu,
      }));
      setNoCu(updatedNC);
      setAllNC(!allNC);
      const TongThucThuNC = updatedNC.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      const TongThucThuPS = PhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
      }));
    }
  };
  const items = [
    PhatSinh.length > 0 && {
      key: "1",
      label: (
        <Flex>
          {" "}
          <p style={{ paddingRight: "210px", fontWeight: "500" }}>
            Phát sinh trong tháng
          </p>
          <p style={{ paddingRight: "73px", fontWeight: "bold" }}>
            {Format_Currency(totalPhaiThu2)}
          </p>
          <p style={{ fontWeight: "bold" }}>{Format_Currency(TongThucThu2)}</p>
        </Flex>
      ),
      extra: (
        // <Checkbox onClick={() => handlePSTT()} checked={allPSTT}>
        //   Chọn tất cả
        // </Checkbox>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ display: "inline-block" }}
        >
          <Checkbox
            style={{ paddingLeft: "15px" }}
            onClick={handlePSTT}
            checked={allPSTT}
          >
            Chọn tất cả
          </Checkbox>
        </div>
      ),
      children: (
        <div
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {PhatSinh.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "4.5px",
              }}
            >
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={2}>
                <p>
                  <Checkbox
                    onChange={(e) => applyChange("CheckPSTT", e.target.value)}
                    value={item}
                    checked={item?.IsCheck}
                  ></Checkbox>
                </p>
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                <p>{item.Name}</p>
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                {item?.NgayThu ? dayjs(item.NgayThu).format("MM-YYYY") : ""}
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                <p>{Format_Currency(item?.PhaiThu)}</p>
              </Col>
              <Col
                style={{ width: "100%", borderRight: "1px solid #d9d9d9" }}
                span={3}
              >
                <InputNumber
                  // max={
                  //   data?.IsThuThua
                  //     ? item?.PhaiThu <= data?.ThuaThangTruoc - bienTichLyTT
                  //       ? item?.PhaiThu
                  //       : data?.ThuaThangTruoc - bienTichLyTT
                  //     : item?.PhaiThu
                  // }
                  style={{
                    width: "70%",
                    marginTop: "-20px",
                    marginLeft: "25px",
                  }}
                  // value={Math.round(item?.SoTien)}
                  value={item?.SoTien}
                  onChange={(value) => handleSoTienChange(item, value)}
                  // onBlur={(e) => {
                  //   const newValue = Number(e.target.value);
                  //   handleSoTienChange(item, newValue);
                  // }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Col>
              <Col style={{ textAlign: "left", paddingLeft: "10px" }} span={10}>
                <p>{item.DienGiai}</p>
              </Col>
            </Row>
          ))}
          {/* <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={3}>
              <p>{totalRows2}</p>
            </Col>
            <Col span={3}></Col>
            <Col span={3}>
              <p>{Format_Currency(totalPhaiThu2)}</p>
            </Col>
            <Col span={3}>
              <p>{Format_Currency(TongThucThu2)}</p>
            </Col>
            <Col span={10}></Col>
          </Row> */}
        </div>
      ),
    },
    NoCu.length > 0 && {
      key: "2",

      label: (
        <Flex>
          {" "}
          <p style={{ paddingRight: "307px", fontWeight: "500" }}>Nợ cũ</p>
          <p style={{ paddingRight: "73px", fontWeight: "bold" }}>
            {Format_Currency(totalPhaiThu)}
          </p>
          <p style={{ fontWeight: "bold" }}>{Format_Currency(TongThucThu)}</p>
        </Flex>
      ),
      extra: (
        // <Checkbox style={{paddingLeft:' 15px'}} onClick={() => handleNC()} checked={allNC}>
        //   Chọn tất cả
        // </Checkbox>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ display: "inline-block" }}
        >
          <Checkbox
            style={{ paddingLeft: "15px" }}
            onClick={handleNC}
            checked={allNC}
          >
            Chọn tất cả
          </Checkbox>
        </div>
      ),
      children: (
        <div
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {NoCu.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "10px",
              }}
            >
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={2}>
                <p>
                  <Checkbox
                    onChange={(e) => applyChange("CheckNC", e.target.value)}
                    value={item}
                    checked={item?.IsCheck}
                  ></Checkbox>
                </p>
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                <p>{item.Name}</p>
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                <p>
                  {item?.NgayThu ? dayjs(item.NgayThu).format("MM-YYYY") : ""}
                </p>
              </Col>
              <Col style={{ borderRight: "1px solid #d9d9d9" }} span={3}>
                <p>{Format_Currency(item?.PhaiThu)}</p>
              </Col>
              <Col
                style={{ width: "100%", borderRight: "1px solid #d9d9d9" }}
                span={3}
              >
                <InputNumber
                  style={{
                    width: "70%",
                    marginTop: "-20px",
                    marginLeft: "25px",
                    border: "none",
                  }}
                  // value={Math.round(item?.SoTien)}
                  // // onChange={(value) => handleSoTienChangeNC(item, value)}
                  // onBlur={(e) => {
                  //   const newValue = Number(e.target.value);
                  //   handleSoTienChangeNC(item, newValue);
                  // }}
                  value={item?.SoTien}
                  onChange={(value) => handleSoTienChangeNC(item, value)}
                  // max={
                  //   data?.IsThuThua
                  //     ? item?.PhaiThu <= data?.ThuaThangTruoc - bienTichLyTT
                  //       ? item?.PhaiThu
                  //       : data?.ThuaThangTruoc - bienTichLyTT
                  //     : item?.PhaiThu
                  // }
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Col>
              <Col style={{ textAlign: "left", paddingLeft: "10px" }} span={10}>
                <p>{item.DienGiai}</p>
              </Col>
            </Row>
          ))}
          {/* <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={3}>
              <p>{totalRows}</p>
            </Col>
            <Col span={3}></Col>
            <Col span={3}>
              <p>{Format_Currency(totalPhaiThu)}</p>
            </Col>
            <Col span={3}>
              <p>{Format_Currency(TongThucThu)}</p>
            </Col>
            <Col span={10}></Col>
          </Row> */}
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: "Dịch vụ",
      dataIndex: "dichVu",
      key: "dichVu",
    },
    {
      title: "Đợt thanh toán",
      dataIndex: "dotThanhToan",
      key: "dotThanhToan",
    },
    {
      title: "Phải thu",
      dataIndex: "phaiThu",
      key: "phaiThu",
      // render: (text) => text,
    },
    {
      title: "Thực thu",
      dataIndex: "thucThu",
      key: "thucThu",
    },
    {
      title: "Diễn giải",
      dataIndex: "dienGiai",
      key: "dienGiai",
    },
  ];
  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ paddingTop: "0px" }} gutter={[20, 8]}>
          <Col span={data?.MaHinhThucThuTien === 2 ? 6 : 8}>
            <DatePickerCustom
              label="Ngày thu:"
              applyChange={applyChange}
              prop="NgayThu"
              value={data?.NgayThu}
              placeholder="Chọn ngày thu"
            />
          </Col>
          <Col span={data?.MaHinhThucThuTien === 2 ? 6 : 8}>
            <InputCustom
              disabled={true}
              label="Số phiếu:"
              applyChange={applyChange}
              prop="SoPhieu"
              value={data?.SoPhieu}
              placeholder=""
            />
          </Col>
          <Col span={data?.MaHinhThucThuTien === 2 ? 6 : 8}>
            {" "}
            <SelectCustom
              label="Hình thức TT:"
              applyChange={applyChange}
              prop="MaHinhThucThuTien"
              value={data?.MaHinhThucThuTien}
              placeholder="Chọn hình thức thanh toán"
              options={[
                { TenHTTT: "Tiền mặt", key: 1 },
                { TenHTTT: "Chuyển khoản ngân hàng", key: 2 },
                { TenHTTT: "Bù trừ PQL", key: 8 },
                { TenHTTT: "Bù trừ tiền cọc", key: 10 },
                { TenHTTT: "Thanh toán máy POS", key: 3 },
              ]}
              fieldNames={{ label: "TenHTTT", value: "key" }}
            />
          </Col>
          {data?.MaHinhThucThuTien === 2 && (
            <Col span={6}>
              {" "}
              <SelectCustom
                label="Tài khoản:"
                applyChange={applyChange}
                prop="MaTK"
                value={data?.MaTK}
                placeholder="Chọn tài khoản"
                options={nganHang}
                fieldNames={{ label: "SoTaiKhoan", value: "ID" }}
              />
            </Col>
          )}

          <Col span={6}>
            <InputNumberCustom
              label="Khách trả:"
              applyChange={applyChange}
              prop={"KhachTra"}
              value={data?.KhachTra}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              widthLabel={"58%"}
              disabled={true}
              label="Thừa tháng trước:"
              applyChange={applyChange}
              prop={"ThuaThangTruoc"}
              value={data?.ThuaThangTruoc}
              placeholder="1"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Số dư:"
              applyChange={applyChange}
              prop={"SoDu"}
              value={data?.SoDu}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              disabled={true}
              label="Thực thu:"
              applyChange={applyChange}
              prop={"PhaiThu"}
              value={Math.round(data?.PhaiThu)}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              disabled={true}
              label="Tiền thừa:"
              applyChange={applyChange}
              prop={"TienThua"}
              value={data?.TienThua}
              placeholder="0"
            />
          </Col>

          {isVisible ? (
            <>
              {/* <Col span={12}>
                <InputCustom
                  label="Số phiếu:"
                  applyChange={applyChange}
                  prop="SoPhieu"
                  value={data?.SoPhieu}
                  placeholder="Nhập số phiếu"
                />
              </Col> */}

              <Col span={6}>
                <SelectCustom
                  label="Mẫu PT:"
                  applyChange={applyChange}
                  prop="MauPhieuThu"
                  value={data?.MauPhieuThu}
                  placeholder="Chọn mẫu phiếu thu"
                  options={pT}
                  fieldNames={{ label: "TenMauPT", value: "ID" }}
                />
              </Col>
              <Col span={6}>
                <InputCustom
                  widthLabel={"45%"}
                  label="Người nộp:"
                  applyChange={applyChange}
                  prop="NguoiNop"
                  value={data?.NguoiNop}
                  placeholder="Nhập tên người nộp"
                />
              </Col>
              <Col span={6}>
                <InputCustom
                  label="Địa chỉ:"
                  applyChange={applyChange}
                  prop="DiaChi"
                  value={data?.DiaChi}
                  placeholder="Nhập địa chỉ"
                />
              </Col>

              <Col span={6}>
                <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "50% ",
                    }}
                  >
                    Mặt bằng:
                  </p>
                  <DebounceSelect
                    open={open}
                    disabled={true}
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
              </Col>
              <Col span={10}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.ChuyenKhoan}
              onChange={(e) => applyChange("ChuyenKhoan", e.target.value)}
            >
              Chuyển khoản
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.BuTruPhiQL}
              onChange={(e) => applyChange("BuTruPhiQL", e.target.value)}
            >
              Bù trừ phí QL
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.BuTruTienCoc}
              onChange={(e) => applyChange("BuTruTienCoc", e.target.value)}
            >
              Bù trừ tiền cọc
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.ThanhToanMayPOS}
              onChange={(e) => applyChange("ThanhToanMayPOS", e.target.value)}
            >
              Thanh toán máy POS
            </Checkbox>
          </Col> */}
          <Col span={8}>
            <Flex justify="space-between" align="center">
              <Checkbox
                style={{
                  fontWeight: "500",
                }}
                value={data?.IsThuThua}
                checked={data?.IsThuThua}
                onChange={(e) => applyChange("IsThuThua", e.target.value)}
              >
                Thanh toán với số tiền thừa
              </Checkbox>

              <Button onClick={toggleVisibility}>
                {isVisible ? "Thu gọn" : "Mở rộng"}
              </Button>
            </Flex>
          </Col>
        </Row>

        <Row
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontWeight: "600",
            backgroundColor: "#E6F1FE",
            padding: "8px 0",
          }}
        >
          <Col span={2}></Col>
          {columns.map((item) => (
            <Col span={3} key={item.title}>
              <p>{item.title}</p>
            </Col>
          ))}
        </Row>
        <Row>
          {loading ? (
            <Col span={24}>
              <Spin indicator={<LoadingOutlined spin />} size="small">
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "200px",
                    backgroundColor: "#f0f0f0",
                  }}
                />
              </Spin>
            </Col>
          ) : (
          <Collapse
            style={{
              width: "100%",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
            className="custom-collapse"
            items={items.filter(Boolean)}
            // defaultActiveKey={[activeCollapse]}
            defaultActiveKey={["1", "2"]}
            onChange={onChangeRowSate}
          />)}
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thanh toán"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            style={{ marginRight: "15px" }}
            icon={
              <FaFileInvoiceDollar
                style={{
                  marginBottom: "-2px",
                  marginLeft: "-3px",
                  color: "orange",
                }}
              />
            }
            onClick={() => refDetail.current.show(data)}
            name="Lập hóa đơn"
          >
            Lập hóa đơn TT trước
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu phiếu thu
          </Button>
        </>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
      <FormLapHoaDon
        ref={refDetail}
        filterCongNo={filterCongNo}
        filterCondition={filterCondition}
      />
      {/* <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {{
            1: <Mau1A5 />,
            2: <Mau1A5 songNgu={true} />,
            4: <Mau1A5 double={true} />,
            5: <Mau1A5 songNgu={true} double={true} />,
            6: <MauChiTiet />,
          }[iDMauPrint] || <MauChiTiet />}
        </div>
      </div> */}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {(() => {
            switch (iDMauPrint) {
              case 1:
                return (
                  <Mau1A5
                    songNgu={false}
                    double={false}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 2:
                return (
                  <Mau1A5
                    songNgu={true}
                    double={false}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 3:
                return (
                  <Mau1A5
                    songNgu={false}
                    double={true}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 4:
                return (
                  <Mau1A5
                    songNgu={true}
                    double={true}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 5:
              case 6:
              default:
                return (
                  <MauChiTiet
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
            }
          })()}
        </div>
      </div>
      <Modal
        title=""
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Đồng ý"
        cancelText="Không"
        width={"300px"}
        style={{
          top: "20%",
        }}
        footer={[
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              key="back"
              onClick={handleCancel}
              style={{ marginRight: 8 }}
            >
              Không
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              Đồng ý
            </Button>
          </div>,
        ]}
      >
        <p style={{ textAlign: "", fontWeight: "600", marginBottom: "15px" }}>
          Bạn có muốn in phiếu thu?
        </p>
      </Modal>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
