import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Table,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { CiTrash } from "react-icons/ci";
import Column from "antd/es/table/Column";
import { DataTable } from "primereact/datatable";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [thongTinKhac, setThongTinKhac] = useState([]);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HaoHut":
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tài sản thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };

  const columns7 = [
    {
      title: "Họ tên",
      dataIndex: "HoTen",
    },
    {
      title: "Số CCCD",
      dataIndex: "SoCCCD",
    },

    {
      title: "Ngày cấp",
      dataIndex: "NgayCap",
    },
    {
      title: "Nơi cấp",
      dataIndex: "NoiCap",
    },

    {
      title: "Hộ khẩu TT",
      dataIndex: "HoKhauTT",
    },
    {
      title: "Địa chỉ LL",
      dataIndex: "DiaChiLL",
    },

    {
      title: "Điện thoại NR",
      dataIndex: "DienThoaiNR",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
  ];
  const handleTabChange = (key) => {
    console.log("Tab active:", key);
  };

  const renderThongTinChung = () => {
    return (
      <Row gutter={[20, 12]}>
        <Col span={16}>
          <Row gutter={[20, 12]}>
            <Col span={4}>
              <SelectCustom
                applyChange={applyChange}
                prop="XungHo"
                value={data?.XungHo}
                placeholder="Chọn tùy chọn xưng hô"
                options={[]}
                fieldNames={{ label: "CardGroupName", value: "CardGroupID" }}
                widthLabel={"0"}
                widthSelect={"100%"}
              />
            </Col>
            <Col span={12}>
              <InputCustom
                label="Họ và tên:"
                applyChange={applyChange}
                prop="HoTen"
                value={data?.HoTen}
              />
            </Col>
            <Col span={8}>
              <DatePickerCustom
                label="Ngày sinh:"
                applyChange={applyChange}
                prop={"NgaySinh"}
                value={data?.NgaySinh}
              />
            </Col>
            <Col span={8}>
              <InputCustom
                label="Số CCCD:"
                applyChange={applyChange}
                prop="SoCCCD"
                value={data?.SoCCCD}
              />
            </Col>
            <Col span={8}>
              <DatePickerCustom
                label="Ngày cấp:"
                applyChange={applyChange}
                prop={"NgayCap"}
                value={data?.NgayCap}
              />
            </Col>
            <Col span={8}>
              <InputCustom
                label="Nơi cấp:"
                applyChange={applyChange}
                prop="NoiCap"
                value={data?.NoiCap}
              />
            </Col>
            <Col span={8}>
              <InputCustom
                label="Di động:"
                applyChange={applyChange}
                prop="DiDong"
                value={data?.DiDong}
              />
            </Col>
            <Col span={16}>
              <InputCustom
                label="Email:"
                applyChange={applyChange}
                prop="Email"
                value={data?.Email}
              />
            </Col>
            <Col span={24}>
              <InputCustom
                label="Địa chỉ liên hệ:"
                applyChange={applyChange}
                prop="DiaChiLL"
                value={data?.DiaChiLL}
              />
            </Col>
            <Col span={24}>
              <InputCustom
                label="Địa chỉ thường trú:"
                applyChange={applyChange}
                prop="DiaChiTT"
                value={data?.DiaChiTT}
              />
            </Col>
            <Col span={24}>
              <p style={{ fontWeight: "600", width: "13%" }}>Ghi chú:</p>
              <TextArea
                value={data?.GhiChu}
                onChange={(e) => applyChange("GhiChu", e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <InputCustom
                required={true}
                label="Số CN:"
                applyChange={applyChange}
                prop="SoCN"
                value={data?.SoCN}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày ký:"
                applyChange={applyChange}
                prop={"NgayKy"}
                value={data?.NgayKy}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày đề nghị CN:"
                applyChange={applyChange}
                prop={"NgayDeNghiCN"}
                value={data?.NgayDeNghiCN}
              />
            </Col>
            <Col span={24}>
              <SelectCustom
                required={true}
                label="Loại CN:"
                applyChange={applyChange}
                prop="LoaiCN"
                value={data?.LoaiCN}
                placeholder="Chọn loại cập nhật"
                options={[]}
                fieldNames={{ label: "TenLX", value: "MaLX" }}
              />
            </Col>
            <Col span={24}>
              <SelectCustom
                required={true}
                label="Hợp đồng cần CN:"
                applyChange={applyChange}
                prop="HopDongCanCN"
                value={data?.HopDongCanCN}
                placeholder="Chọn loại hợp đồng cần cập nhật"
                options={[]}
                fieldNames={{ label: "TenLX", value: "MaLX" }}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="Khách hàng:"
                applyChange={applyChange}
                prop="KhachHang"
                value={data?.KhachHang}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputNumberCustom
                label="Giá trị HĐ:"
                applyChange={applyChange}
                prop={"GiaTriHD"}
                value={data?.GiaTriHD}
                placeholder={"0"}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputNumberCustom
                label="Số tiền đã TT:"
                applyChange={applyChange}
                prop={"TienDatCoc"}
                value={data?.TienDatCoc}
                placeholder={"0"}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputNumberCustom
                label="Còn nợ:"
                applyChange={applyChange}
                prop={"ConNo"}
                value={data?.ConNo}
                placeholder={"0"}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputNumberCustom
                label="Tiền thuê CN:"
                applyChange={applyChange}
                prop={"TienThueCN"}
                value={data?.TienThueCN}
                placeholder={"0"}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputNumberCustom
                label="Phí CN:"
                applyChange={applyChange}
                prop={"PhiCN"}
                value={data?.PhiCN}
                placeholder={"0"}
              />
            </Col>
            <Col span={24}>
              <SelectCustom
                required={true}
                label="Nhân viên:"
                applyChange={applyChange}
                prop="NhanVien"
                value={data?.NhanVien}
                placeholder="Chọn loại hợp đồng cần cập nhật"
                options={[]}
                fieldNames={{ label: "TenLX", value: "MaLX" }}
              />
            </Col>
            <Col span={24}>
              <SelectCustom
                required={true}
                label="Loại tiền:"
                applyChange={applyChange}
                prop="LoaiTien"
                value={data?.LoaiTien}
                placeholder="Chọn loại hợp đồng cần cập nhật"
                options={[]}
                fieldNames={{ label: "TenLX", value: "MaLX" }}
              />
            </Col>
            <Col span={24}>
              <SelectCustom
                required={true}
                label="Thanh toán hóa đơn:"
                applyChange={applyChange}
                prop="TTHoaDon"
                value={data?.TTHoaDon}
                placeholder="Chọn loại hợp đồng cần cập nhật"
                options={[]}
                fieldNames={{ label: "TenLX", value: "MaLX" }}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="Số công chứng:"
                applyChange={applyChange}
                prop="SoCongChung"
                value={data?.SoCongChung}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày công chứng:"
                applyChange={applyChange}
                prop={"NgayCongChung"}
                value={data?.NgayCongChung}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="VP công chứng:"
                applyChange={applyChange}
                prop="VPCongChung"
                value={data?.VPCongChung}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="ĐC công chứng:"
                applyChange={applyChange}
                prop="DCCongChung"
                value={data?.DCCongChung}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const renderNguoiCungDungTen = () => {
    return (
      <>
        <Table
          pagination={false}
          columns={columns7}
          dataSource={[]}
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
        />
      </>
    );
  };

  const renderThongTinKhac = () => {
    const addHotline = () => {
      let _thongTinKhac = _.cloneDeep(thongTinKhac);
      _thongTinKhac.push({
        ID: null,
        ThuocTinh: "",
        GiaTri: "",
      });
      setThongTinKhac(_thongTinKhac);
    };

    const onChangeThongTinKhac = (field, value, index) => {
      let _thongTinKhac = _.cloneDeep(thongTinKhac);
      let item = _thongTinKhac.slice(index, index + 1);
      if (item?.length > 0) {
        _thongTinKhac.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setThongTinKhac(_thongTinKhac);
    };

    const deleteThongTinKhac = (item, index) => {
      let _thongTinKhac = _.cloneDeep(thongTinKhac);
      _thongTinKhac.splice(index, 1);
      setThongTinKhac(_thongTinKhac);
      // if (item.ID) {
      //   ToaNhaService.DuAn.deleteThongTinKhac({ ID: item.ID });
      // }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={thongTinKhac}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Thuộc tính</p>}
            body={(item, row) => (
              <Input
                value={item.ThuocTinh}
                onChange={(e) =>
                  onChangeThongTinKhac(
                    "ThuocTinh",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Giá Trị</p>}
            body={(item, row) => (
              <Input
                value={item.GiaTri}
                onChange={(e) =>
                  onChangeThongTinKhac("GiaTri", e.target.value, row.rowIndex)
                }
              />
            )}
          />

          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addHotline}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteThongTinKhac(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };

  return (
    <Drawer
      title="Chuyển nhượng"
      width={1300}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Tabs defaultActiveKey="1" onChange={handleTabChange} type="line">
        <TabPane tab="Thông tin chung" key="1">
          {renderThongTinChung()}
        </TabPane>
        <TabPane tab="Người cùng đứng tên" key="2">
          {renderNguoiCungDungTen()}
        </TabPane>
        <TabPane tab="Thông tin khác" key="3">
          {renderThongTinKhac()}
        </TabPane>
      </Tabs>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
