import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from "react";
import { Button, Space, Drawer, notification, Row, Col } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import {
  addEdge,
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { Sidebar } from "./Sidebar";
import "./xy-theme.css";
import FormDetailNode from "./FormDetailNode";

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: {
      label: "Tòa nhà",
    },
    position: {
      x: 998.2162073333808,
      y: -218.65786024594567,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "2",
    data: {
      label: "Dự án",
    },
    position: {
      x: 210.45607297167538,
      y: -9.692750840134451,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "2a",
    data: {
      label: "Dự án",
    },
    position: {
      x: 78.32933269323091,
      y: 62.741238598175684,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "2b",
    data: {
      label: "Mặt bằng",
    },
    position: {
      x: 254.75276044167072,
      y: 62.741238598175684,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "3",
    data: {
      label: "Công nợ",
    },
    position: {
      x: 554.9399903984638,
      y: -21.099375900144064,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "3a",
    data: {
      label: "Công nợ tổng hợp",
    },
    position: {
      x: 424.52232357177155,
      y: 119.7743638982237,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "3b",
    data: {
      label: "Hóa đơn chi tiết",
    },
    position: {
      x: 431.36821891502626,
      y: 57.03792606817089,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "3c",
    data: {
      label: "Hóa đơn chi tiết xóa",
    },
    position: {
      x: 643.8081338482297,
      y: 57.03792606817089,
    },
    measured: {
      width: 202,
      height: 292,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "3d",
    data: {
      label: "Thanh toán app",
    },
    position: {
      x: 636.120979356697,
      y: 122.32153556922617,
    },
    measured: {
      width: 202,
      height: 256,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4",
    data: {
      label: "Dịch vụ",
    },
    position: {
      x: 1459.5961317555928,
      y: -35.35765722515604,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4a",
    data: {
      label: "Nước",
    },
    position: {
      x: 1355.4968795007203,
      y: 186.31301008161304,
    },
    measured: {
      width: 202,
      height: 220,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4b",
    data: {
      label: "Điện",
    },
    position: {
      x: 1542.9476716274605,
      y: 179.6591454632741,
    },
    measured: {
      width: 202,
      height: 220,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4c",
    data: {
      label: "Xe",
    },
    position: {
      x: 1353.0292846855498,
      y: 126.42822851656263,
    },
    measured: {
      width: 202,
      height: 220,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4d",
    data: {
      label: "Phí quản lý",
    },
    position: {
      x: 1540.48007681229,
      y: 120.72491598655782,
    },
    measured: {
      width: 202,
      height: 256,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "4e",
    data: {
      label: "Hợp đồng thi công",
    },
    position: {
      x: 1351.5122419587135,
      y: 54.186269803168486,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: true,
    dragging: false,
  },
  {
    id: "4f",
    data: {
      label: "Nhân viên thi công",
    },
    position: {
      x: 1537.0619299087855,
      y: 54.186269803168486,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "5",
    data: {
      label: "Khách hàng",
    },
    position: {
      x: 149.62073931829093,
      y: 203.61497839654342,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "5a",
    data: {
      label: "Yêu cầu",
    },
    position: {
      x: 63.87902064330291,
      y: 295.0600096015362,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "5b",
    data: {
      label: "Thông báo",
    },
    position: {
      x: 67.87325972155543,
      y: 362.5492078732597,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "5c",
    data: {
      label: "Cư dân",
    },
    position: {
      x: 234.41190590494477,
      y: 291.2578012481997,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "5d",
    data: {
      label: "Tiện ích",
    },
    position: {
      x: 241.2578012481997,
      y: 363.49975996159384,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6",
    data: {
      label: "Sổ quỹ",
    },
    position: {
      x: 1464.6957298449042,
      y: 262.87364596660655,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6a",
    data: {
      label: "Phiếu thu",
    },
    position: {
      x: 1555.5288631901515,
      y: 342.9096974021029,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6b",
    data: {
      label: "Phiếu thu chi tiết",
    },
    position: {
      x: 1378.7544166319208,
      y: 482.006106457448,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6c",
    data: {
      label: "Phiếu chi",
    },
    position: {
      x: 1560.1758883289847,
      y: 407.70945845549744,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6d",
    data: {
      label: "Phiếu hủy thẻ xe",
    },
    position: {
      x: 1367.8830834241442,
      y: 344.18076306797235,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "6e",
    data: {
      label: "Phiếu thu xóa",
    },
    position: {
      x: 1367.6182833292069,
      y: 422.74979359687177,
    },
    measured: {
      width: 202,
      height: 256,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "7",
    data: {
      label: "Thống kê",
    },
    position: {
      x: 532.3603562290374,
      y: 214.72071245807484,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "7a",
    data: {
      label: "Báo cáo",
    },
    position: {
      x: 438.7345222342799,
      y: 287.3208454588699,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "7b",
    data: {
      label: "Biểu đồ",
    },
    position: {
      x: 631.8768533435907,
      y: 293.34231685692316,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "8",
    data: {
      label: "Hệ thống",
    },
    position: {
      x: 981.8508455481616,
      y: 225.80917865003593,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "8a",
    data: {
      label: "Lịch sử OTP",
    },
    position: {
      x: 857.9397374532184,
      y: 314.72934639286,
    },
    measured: {
      width: 202,
      height: 256,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "8b",
    data: {
      label: "Phân quyền công ty",
    },
    position: {
      x: 1096.5541987875174,
      y: 318.06198791026475,
    },
    measured: {
      width: 202,
      height: 274,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "9",
    data: {
      label: "Danh mục",
    },
    position: {
      x: 973.5491152828042,
      y: -38.12849774829153,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "9a",
    data: {
      label: "Dự án",
    },
    position: {
      x: 891.2812423519946,
      y: 50.473511126484,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "9b",
    data: {
      label: "Dịch vụ",
    },
    position: {
      x: 890.856577919871,
      y: 132.21157188527198,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "9c",
    data: {
      label: "Khách hàng",
    },
    position: {
      x: 1087.9664760418407,
      y: 48.73070148067772,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10",
    data: {
      label: "Phân quyền",
    },
    position: {
      x: 1830.5326236272542,
      y: -32.76375156855479,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10a",
    data: {
      label: "Nhân viên",
    },
    position: {
      x: 1925.3315603094422,
      y: 247.20875199464496,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10b",
    data: {
      label: "Tài khoản MSB",
    },
    position: {
      x: 1744.2863010305873,
      y: 182.25462092619995,
    },
    measured: {
      width: 202,
      height: 256,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10c",
    data: {
      label: "Dashboard",
    },
    position: {
      x: 1920.485625440752,
      y: 176.55130839619517,
    },
    measured: {
      width: 202,
      height: 220,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10d",
    data: {
      label: "Biểu mẫu",
    },
    position: {
      x: 1735.3231361279397,
      y: 116.82325155568213,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10e",
    data: {
      label: "Lịch sử notification",
    },
    position: {
      x: 1917.8620883789852,
      y: 116.03177890137226,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10f",
    data: {
      label: "Cấu hình",
    },
    position: {
      x: 1730.9536522329388,
      y: 52.503083513847315,
    },
    measured: {
      width: 202,
      height: 238,
    },
    selected: false,
    dragging: false,
  },
  {
    id: "10g",
    data: {
      label: "Automation",
    },
    position: {
      x: 1915.5527880946765,
      y: 60.10750022052035,
    },
    measured: {
      width: 202,
      height: 220,
    },
    selected: false,
    dragging: false,
  },
];

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
  },
  {
    id: "e1-4",
    source: "1",
    target: "4",
  },
  {
    id: "e1-5",
    source: "1",
    target: "5",
  },
  {
    id: "e1-6",
    source: "1",
    target: "6",
  },
  {
    id: "e1-7",
    source: "1",
    target: "7",
  },
  {
    id: "e1-8",
    source: "1",
    target: "8",
  },
  {
    id: "e1-9",
    source: "1",
    target: "9",
  },
  {
    id: "e1-10",
    source: "1",
    target: "10",
  },
  {
    id: "e2-2a",
    source: "2",
    target: "2a",
  },
  {
    id: "e2-2b",
    source: "2",
    target: "2b",
  },
  {
    id: "e3-3a",
    source: "3",
    target: "3a",
  },
  {
    id: "e3-3b",
    source: "3",
    target: "3b",
  },
  {
    id: "e3-3c",
    source: "3",
    target: "3c",
  },
  {
    id: "e3-3d",
    source: "3",
    target: "3d",
  },
  {
    id: "e4-4a",
    source: "4",
    target: "4a",
  },
  {
    id: "e4-4b",
    source: "4",
    target: "4b",
  },
  {
    id: "e4-4c",
    source: "4",
    target: "4c",
  },
  {
    id: "e4-4d",
    source: "4",
    target: "4d",
  },
  {
    id: "e4-4e",
    source: "4",
    target: "4e",
  },
  {
    id: "e4-4f",
    source: "4",
    target: "4f",
  },
  {
    id: "e5-5a",
    source: "5",
    target: "5a",
  },
  {
    id: "e5-5b",
    source: "5",
    target: "5b",
  },
  {
    id: "e5-5c",
    source: "5",
    target: "5c",
  },
  {
    id: "e5-5d",
    source: "5",
    target: "5d",
  },
  {
    id: "e6-6a",
    source: "6",
    target: "6a",
  },
  {
    id: "e6-6b",
    source: "6",
    target: "6b",
  },
  {
    id: "e6-6c",
    source: "6",
    target: "6c",
  },
  {
    id: "e6-6d",
    source: "6",
    target: "6d",
  },
  {
    id: "e6-6e",
    source: "6",
    target: "6e",
  },
  {
    id: "e7-7a",
    source: "7",
    target: "7a",
  },
  {
    id: "e7-7b",
    source: "7",
    target: "7b",
  },
  {
    id: "e8-8a",
    source: "8",
    target: "8a",
  },
  {
    id: "e8-8b",
    source: "8",
    target: "8b",
  },
  {
    id: "e9-9a",
    source: "9",
    target: "9a",
  },
  {
    id: "e9-9b",
    source: "9",
    target: "9b",
  },
  {
    id: "e9-9c",
    source: "9",
    target: "9c",
  },
  {
    id: "e10-10a",
    source: "10",
    target: "10a",
  },
  {
    id: "e10-10b",
    source: "10",
    target: "10b",
  },
  {
    id: "e10-10c",
    source: "10",
    target: "10c",
  },
  {
    id: "e10-10d",
    source: "10",
    target: "10d",
  },
  {
    id: "e10-10e",
    source: "10",
    target: "10e",
  },
  {
    id: "e10-10f",
    source: "10",
    target: "10f",
  },
  {
    id: "e10-10g",
    source: "10",
    target: "10g",
  },
];

const FormEdit = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [isupdate, setIsUpdate] = useState(false);
  const [errors, setErrors] = useState();
  const refDetailNode = useRef();
  const loadCategories = async (data) => {};
  const projectFL = useReactFlow();

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories(data);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKhoThe":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      if (item === "TenCongTy" || item === "DiDong") {
        if (data?.MaKH?.length === 0 || data?.MaKH === undefined) {
          _errors[item] = data[item] ? null : "Không để trống";
        }
        //
      } else {
        _errors[item] = data[item] ? null : "Không để trống";
      }
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      return;
    }

    let _res;
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thẻ thành công!`
      );
      if (close) onClose();
      setIsUpdate(true);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [colorMode, setColorMode] = useState("light");
  // console.log(nodes);
  // console.log(edges);
  // console.log(setEdges);
  // console.log(onEdgesChange, "test node");

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = document
        .querySelector(".react-flow")
        .getBoundingClientRect();

      const nodeData = event.dataTransfer.getData("application/reactflow");
      if (!nodeData) return;

      const { nodeType, label } = JSON.parse(nodeData);

      // Tính toán vị trí chuột
      const rawPosition = {
        x: event.clientX - reactFlowBounds.left - 20,
        y: event.clientY - reactFlowBounds.top + 40,
      };

      const position = projectFL?.screenToFlowPosition(rawPosition);

      // const newNode = {
      //   id: `${nodes.length + 1}`,
      //   type: nodeType,
      //   position, // Vị trí đã được chuyển đổi
      //   data: { label },
      //   style: {
      //     backgroundColor:
      //       nodeType === "output"
      //         ? "rgb(67, 141, 87)"
      //         : nodeType === "input"
      //         ? "rgb(63, 138, 226)"
      //         : "rgb(235, 195, 71)",
      //     color: "#fff",
      //     border:
      //       nodeType === "output"
      //         ? "rgb(67, 141, 87)"
      //         : nodeType === "input"
      //         ? "rgb(63, 138, 226)"
      //         : "rgb(235, 195, 71)",
      //     borderRadius:
      //       nodeType === "output" ? "20%" : nodeType === "input" ? "50%" : "",
      //   },
      // };
      const newNode = {
        id: `${nodes.length + 1}`,
        type: nodeType,
        position, // Vị trí đã được chuyển đổi
        data: { label },
        style: {
          backgroundColor:
            nodeType === "output"
              ? "rgb(67, 141, 87)"
              : nodeType === "input"
              ? "rgb(63, 138, 226)"
              : "rgb(235, 195, 71)",
          color: "#fff",
          border:
            nodeType === "output"
              ? "2px solid rgb(67, 141, 87)"
              : nodeType === "input"
              ? "2px solid rgb(63, 138, 226)"
              : "2px solid rgb(235, 195, 71)",
          borderRadius:
            nodeType === "output" // Hình vuông tròn
              ? "20%"
              : nodeType === "input" // Hình tròn
              ? "50%"
              : "0",
          width: "100px",
          height: "100px",
          clipPath:
            nodeType === "default"
              ? "polygon(50% 0%, 0% 100%, 100% 100%)" // Hình tam giác
              : "none", // Các hình khác giữ nguyên
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [nodes, setNodes]
  );

  const handleNodeClick = (event, node) => {
    console.log("Node clicked:", node);
    console.log("Node details:", node.id);
    refDetailNode.current.show(node);
  };
  const onChangeColor = (evt) => {
    setColorMode(evt.target.value);
  };
  console.log(nodes);
  console.log(edges);

  return (
    <Drawer
      title="SỬA AUTOMATION"
      width={"100%"}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 12]}>
        <Col span={24}>
          <div style={{ height: "80vh", border: "1px solid black" }}>
            <ReactFlow
              className="react-flow"
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              onNodeClick={handleNodeClick}
              colorMode={colorMode}
            >
              <MiniMap />
              <Controls />
              <Background />
              <Panel position="top-right">
                <select onChange={onChangeColor} data-testid="colormode-select">
                  <option value="light">light</option>
                  <option value="dark">dark</option>
                </select>
              </Panel>
            </ReactFlow>
          </div>
        </Col>
        {/* <Col
          style={{ borderLeft: "1px solid #d9d9d9", minHeight: "80vh" }}
          span={6}
        >
          <Sidebar />
        </Col> */}
      </Row>
      {contextHolder}
      <FormDetailNode ref={refDetailNode} />
    </Drawer>
  );
};
export default forwardRef(FormEdit);
