import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  CheckCircleOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit } from "react-icons/md";
import FormEdit from "./FormEdit";
import { HopDongService } from "services/HopDongService";
import { Format_Currency } from "layouts/ultis/common";
export default function ThanhLy() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  // const startOfYear = now.startOf("year");
  // const endOfYear = now.endOf("year");
  const defaultValue = {
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaTN: null,
    InputSearch: "",
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refEdit = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    filter(_filter);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await HopDongService.ThanhLy.get(_filter);
    if (_res.data) {
      setData(_res.data);
      setLoading(false);
    }
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const handleDuyetHDTL = async (id) => {
    setLoading(true);
    let _res = await HopDongService.ThanhLy.duyetTrangThai({
      ID: id,
      State: "ACCEPT",
    });
    if (_res.data) {
      setLoading(false);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `Duyệt hợp đồng thanh lý thành công!`
      );
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const columns = [
    {
      title: "Số thanh lý",
      width: 180,
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.KyHieu}
        </p>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "State",
      render: (item) =>
        item ? (
          <Tag color={item === "NEW" ? "blue" : "green"}>
            {item === "NEW" ? "Chờ duyệt" : "Đã duyệt"}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Ngày thanh lý",
      dataIndex: "NgayTL",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Số hợp đồng",
      dataIndex: "SoHD",
    },
    {
      title: "Ngày ký HĐ",
      dataIndex: "NgayKyHD",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày ký VB kiểm kê",
      dataIndex: "NgayKyVBKK",
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "Ngày ký VB hoàn trả",
      dataIndex: "NgayKyVBHT",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Tiền phạt vi phạm HĐ",
      dataIndex: "TienViPhamHD",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Tiền bồi thường",
      dataIndex: "TienBoiThuongThietHai",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Phí DV và phí khác",
      dataIndex: "PhiDichVu",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Tiền thuê phát sinh thêm",
      dataIndex: "TienThuePhatSinh",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Tiền đã TT trước",
      dataIndex: "TienThueDaTT",
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      width: 180,
    },
    {
      title: "Số CCCD",
      dataIndex: "CMND",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayTao",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Người cập nhật",
      dataIndex: "NguoiSua",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgaySua",
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "",
      fixed: "right",
      width: 120,
      render: (item) => {
        return (
          <Flex gap={10}>
            {item?.State === "NEW" && (
              <Popconfirm
                title="Duyệt hợp đồng thanh lý"
                description="Bạn có chắc chắn muốn duyệt hợp đồng thanh lý?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleDuyetHDTL(item.ID)}
              >
                <Tooltip placement="topRight" title="Duyệt">
                  <Button
                    style={{
                      marginLeft: 10,
                      color: "green",
                      border: "1px solid green",
                    }}
                    // type="primary"
                    ghost
                    icon={<CheckCircleOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            )}

            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refEdit.current.update(item)}
              />
            </Tooltip>
            {/* 
            <Popconfirm
              title="Xoá toà nhà"
              description="Bạn có chắc chắn muốn xoá toà nhà?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaTN)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm> */}
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      default:
        break;
    }
  };
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
    </Menu>
  );
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2500,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              showSizeChanger
              total={data[0]?.TotalRows}
              defaultPageSize={20}
            />
          )}
        </div>
      </Col>

      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
      <FormEdit
        ref={refEdit}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        ToaNha={toanha}
      />
    </Row>
  );
}
