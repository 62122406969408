import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Row, Col, Drawer, notification, Tag, Flex, Image, Tabs } from "antd";
import _ from "lodash";
import { YeuCauService } from "services/YeuCauService";
import { Format_Currency } from "layouts/ultis/common";
const FormView = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const loadChamSoc = async (mayc) => {
    let _res = await YeuCauService.getImages({ ID: mayc });
    setImages(_res.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      loadChamSoc(item.ID);
      setData(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await YeuCauService.addPhanHoi(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setData({ YeuCauID: _data.YeuCauID });
      // loadChamSoc(_data.YeuCauID);
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (key) => {};

  const items = [
    {
      key: "1",
      label: "Thông tin chi tiết",
      children: (
        <Row gutter={[24, 5]}>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mặt bằng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaSoMB}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Khách hàng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TenKH}
            </p>
          </Col>

          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền thu thừa </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaYC}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền điện </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TienDien}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền nước </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TienNuoc}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền xe, PQL </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaYC}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền xe, nước, điện </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaYC}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền tiện ích </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaYC}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền xe </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TienXe}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiền PQL </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TienPQL}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Dịch vụ khác </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TienDVK}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tổng tiền nợ </p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {Format_Currency(data?.TongTien)}
            </p>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Lịch sử gửi email",
      children: "Content of Tab Pane 2",
    },
  ];

  return (
    <Drawer
      title="Chi tiết"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Flex>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          {/* <Tag
            color={
              options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.Color
            }
          >
            {options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.TenTT}
          </Tag> */}
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button> */}
        </Flex>
      }
    >
      {contextHolder}
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Drawer>
  );
};
export default forwardRef(FormView);
