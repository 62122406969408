import axiosApi from "./api_helper";

export const HopDongService = {
  ChoThue: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get", payload)
        .then((res) => res.data);
    },
    getHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/filter", payload)
        .then((res) => res.data);
    },
    getSoPhieu: async (payload) => {
      return await axiosApi()
        .post("api/hethong/get-sophieu", payload)
        .then((res) => res.data);
    },
    getUpdateByID: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-byid", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/add", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/update", payload)
        .then((res) => res.data);
    },
    dichVu: async (payload) => {
      return await axiosApi()
        .post("api/category/dichvu/get-tenloaidv", payload)
        .then((res) => res.data);
    },
    getMatBang: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-matbang", payload)
        .then((res) => res.data);
    },
    getMatBangChuaThue: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-matbang-empty", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/delete", payload)
        .then((res) => res.data);
    },
    deleteMatBang: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/delete-matbang", payload)
        .then((res) => res.data);
    },
    deleteDichVu: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/delete-dichvu", payload)
        .then((res) => res.data);
    },
    duyetHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/change-state", payload)
        .then((res) => res.data);
    },
    addCongNo: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/tao-cong-no-bytn", payload)
        .then((res) => res.data);
    },
    addCongNoByHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/tao-cong-no", payload)
        .then((res) => res.data);
    },
    thanhLy: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/thanh-ly", payload)
        .then((res) => res.data);
    },
    getMBTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-matbang-bymahd", payload)
        .then((res) => res.data);
    },
    getDVTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-dichvu-bymahd", payload)
        .then((res) => res.data);
    },
    getHDTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-hoadon-bymahd", payload)
        .then((res) => res.data);
    },
    getPTTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-phieuthu-bymahd", payload)
        .then((res) => res.data);
    },
    getPCTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-phieuchi-bymahd", payload)
        .then((res) => res.data);
    },
    getTLTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-tailieu-bymahd", payload)
        .then((res) => res.data);
    },
    getPLTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-phuluc-bymahd", payload)
        .then((res) => res.data);
    },
    addTLTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/upload-tailieu", payload)
        .then((res) => res.data);
    },
    deleteTLTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/delete-tailieu", payload)
        .then((res) => res.data);
    },
  },
  DanhSachDichVu: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-dichvu", payload)
        .then((res) => res.data);
    },
    getLSNgayTT: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/dich-vu/get-lichsu-taocn", payload)
        .then((res) => res.data);
    },
    getLSPhuLuc: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/dich-vu/get-phuluc-bymbdv", payload)
        .then((res) => res.data);
    },
  },
  ThanhLy: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-thanh-ly", payload)
        .then((res) => res.data);
    },
    duyetTrangThai: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/thanh-ly/change-state", payload)
        .then((res) => res.data);
    },
  },
  PhuLuc: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-phuluc", payload)
        .then((res) => res.data);
    },
    getByID: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-phuluc-byid", payload)
        .then((res) => res.data);
    },
    getLPL: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-loaiphuluc", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/add-phuluc", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/update-phuluc", payload)
        .then((res) => res.data);
    },
    duyet: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/duyet-phuluc", payload)
        .then((res) => res.data);
    },
    DVTheoPL: async (payload) => {
      return await axiosApi()
        .post("api/cho-thue/get-dichvu-bymapl", payload)
        .then((res) => res.data);
    },
  },
};
