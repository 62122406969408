import React, { useEffect, useContext, useState } from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import ReportLayout from "layouts/reports";
import ChatsLayout from "layouts/chats/index";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { SignalrContextProvider } from "contexts/SignalrContext";
import { ChatContextProvider } from "./contexts/ChatContext";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./app.scss";
import OneSignal from "react-onesignal";
import runOneSignal from "layouts/ultis/common";
import { ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { CongTyService } from "services/CongTyService";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { notification } from "antd";


const App = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const history = useHistory();
  useEffect(() => {
    // var id = "web" + Math.random().toString(16).slice(2);
    // if (!localStorage.getItem("deviceID")) {
    //   localStorage.setItem("deviceID", id);
    // }
    // runOneSignal();
  });
  
  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        // Check expiration status
        

        // Initialize OneSignal
        await OneSignal.init({
          appId: "537584ea-554d-400f-bd59-30ce74e242e1",
        });

        // Get push subscription ID
        const userId = await OneSignal.getUserId();
        console.log("User ID:", userId);

        // Listen for push subscription changes (if needed)
        OneSignal.User.PushSubscription.addEventListener(
          "change",
          (subscription) => {
            console.log("Subscription changed:", subscription?.current?.id);
          }
        );
      } catch (error) {
        console.error("Error in initialization or fetching data:", error);
      }
    };

    initializeOneSignal();
    // OneSignal.push(() => {
    //   OneSignal.getUserId()
    //     .then((userId) => {
    //       console.log("User ID:", userId);
    //     })
    //     .catch((error) => {
    //       console.error("Error getting user ID:", error);
    //     });
    // });

    OneSignal.init({ appId: "537584ea-554d-400f-bd59-30ce74e242e1" }).then(
      () => {
        let a = OneSignal.User.PushSubscription.id;
        console.log(a);
      }
    );

    //  OneSignal.User.PushSubscription.addEventListener(
    //    "change",
    //    (subscription) => {
    //      console.log(subscription?.current?.id);
    //    }
    //  );
  }, []);
  const onIds = (device) => {
    console.log("Device info: ", device);
    console.log("player id: ", device.userId);
    this.setState({
      pid: device.userId,
    });
    console.log(this.state.pid);
  };
  return (
    <ChakraProvider theme={theme}>
      {/* <SignalrContextProvider> */}
      <React.StrictMode>
        <ReactFlowProvider>
          <ThemeEditorProvider>
            <HashRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/report`} component={ReportLayout} />
                <Route path={`/chats/:id`} component={ChatsLayout} />
                <Redirect from="/" to="/admin/trang-chu" />
              </Switch>
            </HashRouter>
          </ThemeEditorProvider>
        </ReactFlowProvider>
      </React.StrictMode>
      {/* </SignalrContextProvider> */}
      {contextHolder}
    </ChakraProvider>
  );
};
export default App;
