import React, {
  useEffect,
} from "react";
import { DatePicker } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(()=>{
  },[])
  return (
    <div>
      <ActiveRoute />
      <div className="box-search">
        <p className="label">Từ ngày</p>
        <DatePicker
          format="DD-MM-YYYY"
          style={{ width: "100%" }}
          defaultValue={dayjs(filterCondition.TuNgay)}
          placeholder="Từ ngày"
          onChange={(v) => applyChangeFilter("TuNgay", v)}
          // locale={locale}
        />
        <p className="label" style={{ marginTop: 10 }}>
          Đến ngày
        </p>
        <DatePicker
          format="DD-MM-YYYY"
          style={{ width: "100%" }}
          defaultValue={dayjs(filterCondition.DenNgay)}
          placeholder="Đến ngày "
          // locale={locale}
          onChange={(v) => applyChangeFilter("DenNgay", v)}
        />
      </div>
      {/* <div className="box-search">
        <p className="label">Hình thức thanh toán</p>
        <Select
          mode="multiple"
          placeholder="Chọn hình thức"
          onChange={(v) => applyChangeFilter("HinhThuc", v)}
          style={{
            width: "100%",
          }}
          options={hinhThucTT}
          fieldNames={{ label: "TenHinhThuc", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Loại thu</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại thu"
          onChange={(v) => applyChangeFilter("LoaiThu", v)}
          style={{
            width: "100%",
          }}
          options={loaiPT}
          fieldNames={{ label: "TenLPT", value: "ID" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
