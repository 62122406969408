import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  DatePicker,
  Tooltip,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DataTable } from "primereact/datatable";
import Column from "antd/es/table/Column";
import { CiTrash } from "react-icons/ci";
import { HopDongService } from "services/HopDongService";
import { KhachHangService } from "services/KhachHangService";
import FormAddKhachHang from "views/admin/KhachHang/KhachHang/FormAddKhachHang";
const { TextArea } = Input;
const { Option } = Select;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [khachHang, setKhachHang] = useState([]);
  const [khachHangValue, setKhachHangValue] = useState([]);
  const [phuLuc, setPhuLuc] = useState([]);
  const [getDichVu, setGetDichVu] = useState([]);
  const [matBang, setMatBang] = useState([]);
  const [matBangDV, setMatBangDV] = useState([]);
  const defaultErrors = { MaLPL: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [errorsMB, setErrorsMB] = useState(false);
  const [errorsDV, setErrorsDV] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [disabledDonGia, setDisabledDonGia] = useState(true);
  const [disabledGiaHan, setDisabledGiaHan] = useState(true);
  const [thongTinChiTiet, setThongTinChiTiet] = useState([]);
  // const [dVCu, setDVCu] = useState([]);
  const [dichVu, setDichVu] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const refAdd = useRef(null);
  const handleSelectChange = (value) => {
    setSelectedValue(value);
    applyChange("KhachHang", value);
  };

  const filterKhachHang = async (data) => {
    let _data = _.cloneDeep(data);
    let _res = await HopDongService.PhuLuc.getByID({
      ID: data?.ID,
    });
    setDisabledSubmit(data.State !== "NEW");
    if (data?.MaLPL === 1) {
      setDisabledGiaHan(true);
    } else {
      setDisabledGiaHan(false);
    }
    // setDVCu(_res?.dichvu);
    // setDisabledSubmit(_res?.data?.State !== "NEW");
    let _resKH = await KhachHangService.KhachHang.get({
      InputSearch: "",
      CheckMail: false,
      MaTN: filterCondition?.MaTN,
    });
    let _resDV = await HopDongService.ChoThue.dichVu({});
    let _resMB = await HopDongService.ChoThue.getMatBang({
      MaTN: filterCondition?.MaTN,
    });
    let _resPL = await HopDongService.PhuLuc.getLPL({});
    setPhuLuc(_resPL?.data ?? []);
    setKhachHang(_resKH?.data ?? []);
    setMatBang(_resMB?.data ?? []);
    setMatBangDV(_resMB?.data ?? []);
    setGetDichVu(_resDV?.data?.filter((item) => item?.isThueMB === true) ?? []);
    setThongTinChiTiet(_res?.matbang);
    setDichVu(_res?.dichvu);

    const InfoUser = _resKH?.data.find((item) => item?.MaKH === data?.MaKH);
    _data.CMND = InfoUser?.CMND;
    _data.NgayCap = InfoUser?.NgayCap;
    _data.NoiCap = InfoUser?.NoiCap;
    _data.DienThoaiKH = InfoUser?.DienThoaiKH;
    _data.NgaySinh = InfoUser?.NgaySinh;
    _data.EmailKH = InfoUser?.EmailKH;
    _data.DCTT = InfoUser?.DCTT;
    _data.DCLL = InfoUser?.DCLL;
    _data.KhachHang = InfoUser?.TenKH;
    _data.NgayKTCu = dayjs(_res?.dichvu[0]?.NgayKT)
      .subtract(data?.ThoiHan, "month")
      .toDate();
    _data.MaHD = data?.ID;
    setSelectedValue(InfoUser?.TenKH);
    setData((pr) => ({ ...pr, ..._res?.data, ..._data }));
  };

  const filterData = async (data) => {
    let _data = _.cloneDeep(data);
    // let _res = await HopDongService.PhuLuc.getByID({
    //   ID: data?.ID,
    // });
    let _resSoPhieu = await HopDongService.ChoThue.getSoPhieu({
      MaTN: filterCondition?.MaTN,
      Type: "PL",
    });
    let _res = await HopDongService.ChoThue.getUpdateByID({
      ID: data?.ID,
    });

    let _resKH = await KhachHangService.KhachHang.get({
      InputSearch: "",
      CheckMail: false,
      MaTN: filterCondition?.MaTN,
    });
    let _resDV = await HopDongService.ChoThue.dichVu({});
    let _resMB = await HopDongService.ChoThue.getMatBang({
      MaTN: filterCondition?.MaTN,
    });
    let _resPL = await HopDongService.PhuLuc.getLPL({});
    setPhuLuc(_resPL?.data ?? []);
    setKhachHang(_resKH?.data ?? []);
    setMatBang(_resMB?.data ?? []);
    setMatBangDV(_resMB?.data ?? []);
    setGetDichVu(_resDV?.data?.filter((item) => item?.isThueMB === true) ?? []);
    setThongTinChiTiet(_res?.matbang);
    setDichVu(_res?.dichvu);

    const InfoUser = _resKH?.data.find((item) => item?.MaKH === data?.MaKH);
    _data.CMND = InfoUser?.CMND;
    _data.NgayCap = InfoUser?.NgayCap;
    _data.NoiCap = InfoUser?.NoiCap;
    _data.DienThoaiKH = InfoUser?.DienThoaiKH;
    _data.NgaySinh = InfoUser?.NgaySinh;
    _data.EmailKH = InfoUser?.EmailKH;
    _data.DCTT = InfoUser?.DCTT;
    _data.DCLL = InfoUser?.DCLL;
    _data.KhachHang = InfoUser?.TenKH;
    _data.SoPL = _resSoPhieu?.data;
    _data.MaHD = data?.ID;
    _data.NgayPL = new Date();
    _data.NgayKTCu = _res?.data?.NgayKT;
    setSelectedValue(InfoUser?.TenKH);
    setData((pr) => ({ ...pr, ..._res?.data, ..._data, ThoiHan: 0 }));
  };
  const filterKhachHangNew = async () => {
    let _res = await KhachHangService.KhachHang.get({
      InputSearch: "",
      CheckMail: false,
      MaTN: filterCondition?.MaTN,
    });
    setKhachHang(_res?.data ?? []);
  };

  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      filterKhachHang(data);
      // setThongTinChiTiet([
      //   {
      //     ID: null,
      //     MaMB: 0,
      //     DienGiai: "",
      //   },
      // ]);
      setDisabledDonGia(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      filterData(data);
      setDisabledSubmit(false);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setLoading(false);
    setKhachHangValue([]);
    setThongTinChiTiet([]);
    setDichVu([]);
    setSelectedValue(null);
    setErrorsMB(false);
    setErrorsDV(false);
    setErrors(defaultErrors);
    setDisabledGiaHan(true);
    setDisabledDonGia(true);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    let _dichVu = _.cloneDeep(dichVu);
    switch (p) {
      case "KhachHang":
        _data.MaKH = v;
        const InfoUser = khachHang.find((item) => item?.MaKH === v);
        _data.CMND = InfoUser?.CMND;
        _data.NgayCap = InfoUser?.NgayCap;
        _data.NoiCap = InfoUser?.NoiCap;
        _data.DienThoaiKH = InfoUser?.DienThoaiKH;
        _data.NgaySinh = InfoUser?.NgaySinh;
        _data.EmailKH = InfoUser?.EmailKH;
        _data.DCTT = InfoUser?.DCTT;
        _data.DCLL = InfoUser?.DCLL;

        _data[p] = v;
        break;

      case "NgayPL":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        _dichVu?.forEach((item) => {
          item.NgayKT = isoString;
        });
        setDichVu(_dichVu);
        break;
      case "MaLPL":
        if (v === 1) {
          setDisabledGiaHan(true);
          setDisabledDonGia(false);
          _data.ThoiHan = 0;
          const ngayKT2 = dayjs(_data?.NgayKTCu).add(0, "month").toDate();
          _data.NgayKT = ngayKT2;

          _dichVu?.forEach((item) => {
            // item.NgayTinhPhi = _data?.NgayHL;
            item.NgayKT = ngayKT2;
          });
          setDichVu(_dichVu);
        } else if (v === 2) {
          setDisabledGiaHan(false);
          setDisabledDonGia(true);
        }

        _data[p] = v;
        break;
      case "ThoiHan":
        const ngayKT2 = dayjs(_data?.NgayKTCu).add(v, "month").toDate();
        console.log(ngayKT2);
        _dichVu?.forEach((item) => {
          item.NgayKT = ngayKT2;
        });
        setDichVu(_dichVu);

        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }

    if (
      thongTinChiTiet.length <= 0 ||
      thongTinChiTiet.some((item) => !item.MaMB)
    ) {
      setLoading(false);
      setErrorsMB(true);

      return;
    }

    if (
      dichVu.length <= 0 ||
      dichVu.some((item) => !item.MaMB || !item.MaDV || !item.NgayKT)
    ) {
      setLoading(false);
      setErrorsDV(true);
      return;
    }

    const payLoad = {
      ..._data,
      MatBang: thongTinChiTiet,
      DichVu: dichVu,
    };
    // const payLoad2 = {
    //   ..._data,
    //   MatBang: thongTinChiTiet,
    //   DichVu: dichVu,
    //   DichVuCu: dVCu,
    // };
    let _res;
    if (!isupdate) {
      _res = await HopDongService.PhuLuc.update(payLoad);
    } else {
      _res = await HopDongService.PhuLuc.add(payLoad);
    }

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${!isupdate ? "Sửa" : "Thêm"} phụ lục thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };

  const handleTabChange = (key) => {
    // console.log("Tab active:", key);
  };

  const renderThongTinChiTiet = () => {
    const addThongTinChiTiet = () => {
      let _thongTinchiTiet = _.cloneDeep(thongTinChiTiet);
      _thongTinchiTiet.push({
        ID: null,
        MaMB: 0,
        DienGiai: "",
      });
      setThongTinChiTiet(_thongTinchiTiet);
    };

    const onChangeThongTinChiTiet = (field, value, index) => {
      let _thongTinchiTiet = _.cloneDeep(thongTinChiTiet);
      let item = _thongTinchiTiet.slice(index, index + 1);

      if (item?.length > 0) {
        _thongTinchiTiet.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }

      if (field === "MaMB") {
        let _dichVu = _.cloneDeep(dichVu);
        _dichVu.push({
          ID: null,
          MaMB: value,
          MaDV: getDichVu[0]?.ID,
          TinhTongTien: false,
          DienTich: 0,
          DonGia: 0,
          TyLeVAT: 0,
          TienVAT: 0,
          TongTien: 0,
          ThanhTien: 0,
          ThoiHan: 1,
          // NgayTT: dateInHoChiMinh.toISO(),
          // NgayTinhPhi: data?.NgayHL,
          NgayKT: data?.NgayKT,
          DienGiai: "",
        });

        // setDichVu(_dichVu);

        const newMB = matBang?.filter((item) =>
          _thongTinchiTiet?.map((it) => it?.MaMB).includes(item.MaMB)
        );
        setDichVu(
          _dichVu?.filter((item) =>
            newMB?.map((it) => it?.MaMB).includes(item.MaMB)
          )
        );
      }

      setThongTinChiTiet(_thongTinchiTiet);
    };

    const deleteThongTinChiTiet = (item, index) => {
      let _thongTinchiTiet = _.cloneDeep(thongTinChiTiet);
      _thongTinchiTiet.splice(index, 1);
      setThongTinChiTiet(_thongTinchiTiet);
      if (item.ID) {
        HopDongService.ChoThue.deleteMatBang({ ID: item.ID });
      }
    };

    return (
      <>
        {errorsMB && (
          <p style={{ color: "red", textAlign: "center" }}>
            Vui lòng nhập đầy đủ các trường
          </p>
        )}
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={thongTinChiTiet}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Mặt bằng</p>}
            body={(item, row) => (
              <Select
                disabled={isupdate}
                style={{ width: "100%" }}
                value={item.MaMB}
                onChange={(value) =>
                  onChangeThongTinChiTiet("MaMB", value, row.rowIndex)
                }
                options={matBang?.filter(
                  (matBangItem) =>
                    !thongTinChiTiet?.some(
                      (it) =>
                        it?.MaMB === matBangItem.MaMB && it?.MaMB !== item.MaMB
                    )
                )}
                fieldNames={{ label: "MaSoMB", value: "MaMB" }}
              />
            )}
          />

          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Diễn giải</p>}
            body={(item, row) => (
              <Input
                disabled={isupdate}
                value={item.DienGiai}
                onChange={(e) =>
                  onChangeThongTinChiTiet(
                    "DienGiai",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addThongTinChiTiet}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteThongTinChiTiet(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };

  const renderDichVu = () => {
    const addDichVu = () => {
      let _dichVu = _.cloneDeep(dichVu);
      _dichVu.push({
        ID: null,
        MaMB: 0,
        MaDV: 0,
        TinhTongTien: false,
        DienTich: 0,
        DonGia: 0,
        TyLeVAT: 0,
        TienVAT: 0,
        TongTien: 0,
        ThanhTien: 0,
        ThoiHan: 1,
        NgayKT: "",
        DienGiai: "",
      });
      setDichVu(_dichVu);
    };

    const onChangeDichVu = (field, value, index) => {
      let _dichVu = _.cloneDeep(dichVu);

      if (field === "MaMB") {
        _dichVu[index].NgayTinhPhi = data?.NgayHL;
        _dichVu[index].NgayKT = data?.NgayKT;
      }

      let item = _dichVu.slice(index, index + 1);
      if (item?.length > 0) {
        _dichVu.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }

      const formatNumber = (num) => parseFloat((num ?? 0).toFixed(2));

      if (field === "TyLeVAT") {
        const donGia = _dichVu[index].DonGia ?? 0;
        const dienTich = _dichVu[index].DienTich ?? 0;

        _dichVu[index].TienVAT = formatNumber(
          (donGia * dienTich * value) / 100
        );
        _dichVu[index].TongTien = formatNumber(
          donGia * dienTich + _dichVu[index].TienVAT
        );
      }

      if (field === "DonGia") {
        const dienTich = _dichVu[index].DienTich ?? 0;
        const tyLeVAT = _dichVu[index]?.TyLeVAT ?? 0;

        _dichVu[index].TienVAT = formatNumber(
          (value * dienTich * tyLeVAT) / 100
        );
        _dichVu[index].ThanhTien = formatNumber(value * dienTich);
        _dichVu[index].TongTien = formatNumber(
          value * dienTich + _dichVu[index].TienVAT
        );
      }

      if (field === "DienTich") {
        const donGia = _dichVu[index].DonGia ?? 0;
        const tyLeVAT = _dichVu[index]?.TyLeVAT ?? 0;
        _dichVu[index].TienVAT = formatNumber((donGia * value * tyLeVAT) / 100);
        _dichVu[index].ThanhTien = formatNumber(donGia * value);
        _dichVu[index].TongTien = formatNumber(
          donGia * value + _dichVu[index].TienVAT
        );
      }
      setDichVu(_dichVu);
    };
    const deleteDichVu = (item, index) => {
      let _dichVu = _.cloneDeep(dichVu);
      _dichVu.splice(index, 1);
      setDichVu(_dichVu);
      if (item.ID) {
        HopDongService.ChoThue.deleteDichVu({ ID: item.ID });
      }
    };
    return (
      <>
        {errorsDV && (
          <p style={{ color: "red", textAlign: "center" }}>
            Vui lòng nhập đầy đủ các trường
          </p>
        )}
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={dichVu}
          tableStyle={{ minWidth: "110rem" }}
        >
          <Column
            fixed="left"
            header={
              <Button
                loading={loading}
                onClick={addDichVu}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                // style={{ marginRight: "50px" }}
                onClick={() => deleteDichVu(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "center",
              width: "50px",
              border: "1px solid #d9d9d9",
            }}
          />
          <Column
            style={{ width: "120px", border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Mặt bằng</p>}
            body={(item, row) => (
              <Select
                disabled={isupdate}
                style={{ width: "120px" }}
                value={item.MaMB}
                onChange={(value) =>
                  onChangeDichVu("MaMB", value, row.rowIndex)
                }
                options={matBang?.filter((item) =>
                  thongTinChiTiet?.map((it) => it?.MaMB).includes(item.MaMB)
                )}
                fieldNames={{ label: "MaSoMB", value: "MaMB" }}
              />
            )}
          />
          <Column
            style={{ width: "120px", border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Dịch vụ</p>}
            body={(item, row) => (
              <Select
                disabled={isupdate}
                style={{ width: "120px" }}
                value={item.MaDV}
                onChange={(value) =>
                  onChangeDichVu("MaDV", value, row.rowIndex)
                }
                options={getDichVu}
                fieldNames={{ label: "Name", value: "ID" }}
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Diện tích</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={disabledDonGia && isupdate}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                value={item.DienTich}
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("DienTich", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Đơn giá</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={disabledDonGia && isupdate}
                value={item.DonGia}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("DonGia", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>% VAT</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={disabledDonGia && isupdate}
                max={100}
                value={item.TyLeVAT}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("TyLeVAT", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Tiền VAT</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={isupdate}
                value={item.TienVAT}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("TienVAT", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Tổng tiền chưa VAT</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={isupdate}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                value={item.ThanhTien}
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("ThanhTien", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Tổng tiền VAT/Tháng</p>}
            style={{ width: "70px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                disabled={isupdate}
                value={item.TongTien}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("TongTien", value, row.rowIndex)
                }
              />
            )}
          />

          <Column
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Kỳ TT</p>}
            body={(item, row) => (
              <InputNumber
                disabled={isupdate}
                value={item.ThoiHan}
                placeholder=""
                onChange={(value) =>
                  onChangeDichVu("ThoiHan", value, row.rowIndex)
                }
              />
            )}
          />

          <Column
            header={<p style={{ fontSize: "13px" }}>Ngày kết thúc</p>}
            style={{ width: "130px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <DatePicker
                disabled={disabledGiaHan && isupdate}
                format="DD-MM-YYYY"
                value={item?.NgayKT ? dayjs(item?.NgayKT) : null}
                onChange={(date) =>
                  onChangeDichVu(
                    "NgayKT",
                    date ? date.format("YYYY-MM-DD") : null,
                    row.rowIndex
                  )
                }
              />
            )}
          />

          <Column
            style={{ width: "150px", border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Diễn giải</p>}
            body={(item, row) => (
              <Input
                disabled={isupdate}
                value={item.DienGiai}
                onChange={(e) =>
                  onChangeDichVu("DienGiai", e.target.value, row.rowIndex)
                }
              />
            )}
          />
        </DataTable>
      </>
    );
  };
  const handleSearch = (value) => {
    if (!value) {
    } else {
      const filteredOptions = khachHang.filter(
        (option) =>
          option.TenKH?.toLowerCase().includes(value.toLowerCase()) ||
          option?.CMND?.includes(value) ||
          option?.DienThoaiKH?.includes(value)
      );

      setKhachHangValue(filteredOptions);
    }
  };
  return (
    <Drawer
      title={
        "Thêm: phụ lục - " +
        ToaNha?.find((item) => item?.MaTN === filterCondition.MaTN)?.TenTN
      }
      width={"100%"}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            // disabled={disabledSubmit && !isupdate}
            disabled={disabledSubmit}
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 12]}>
        <Col span={10}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "500" }}>Thông tin chung</p>
            </Col>
            <Col span={24}>
              <InputCustom
                label="Số phụ lục:"
                applyChange={applyChange}
                prop="SoPL"
                value={data?.SoPL}
                placeholder="Số phụ lục"
              />
            </Col>

            <Col span={12}>
              <DatePickerCustom
                open2={open}
                label="Ngày phụ lục:"
                applyChange={applyChange}
                prop={"NgayPL"}
                value={data?.NgayPL}
                placeholder={""}
              />
            </Col>
            <Col span={12}>
              <SelectCustom
                label="Loại PL:"
                applyChange={applyChange}
                prop="MaLPL"
                value={data?.MaLPL}
                placeholder="Chọn loại phụ lục"
                options={phuLuc}
                fieldNames={{ label: "Name", value: "ID" }}
                errors={errors}
              />
            </Col>
            {!disabledGiaHan && (
              <Col span={12}>
                <InputNumberCustom
                  suffix={"Tháng"}
                  label="Thời hạn:"
                  applyChange={applyChange}
                  prop={"ThoiHan"}
                  value={data?.ThoiHan}
                  placeholder={"0"}
                  min={1}
                />
              </Col>
            )}

            <Col span={24}>
              <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "500" }}>Thông tin khách hàng</p>
            </Col>

            <Col span={20}>
              <Flex>
                <lable
                  htmlFor={"TimKiemKhachHang"}
                  style={{
                    fontWeight: "600",
                    width: "30% ",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  Họ và tên:<span color="red">*</span>
                </lable>
                <Select
                  id={"TimKiemKhachHang"}
                  showSearch
                  placeholder="Tìm kiếm khách hàng"
                  onSearch={handleSearch}
                  onChange={handleSelectChange}
                  value={selectedValue}
                  style={{ width: "100%" }}
                  allowClear
                  filterOption={false}
                >
                  {khachHangValue.map((option) => (
                    <Option key={option.MaKH} value={option.MaKH}>
                      {option.TenKH} - {option.CMND}
                    </Option>
                  ))}
                </Select>
              </Flex>
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["KhachHang"]}
                </p>
              )}
            </Col>
            <Col span={4}>
              <div onClick={() => refAdd.current.show(filterCondition?.MaTN)}>
                <Tooltip title={"thêm khách hàng"}>
                  <PlusOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={8}>
              <InputCustom
                disabled={true}
                label="Số CCCD:"
                applyChange={applyChange}
                prop={"CMND"}
                value={data?.CMND}
              />
            </Col>
            <Col span={8}>
              <DatePickerCustom
                open2={open}
                disabled={true}
                label="Ngày cấp:"
                applyChange={applyChange}
                prop={"NgayCap"}
                value={data?.NgayCap}
              />
            </Col>
            <Col span={8}>
              <InputCustom
                disabled={true}
                label="Nơi cấp:"
                applyChange={applyChange}
                prop="NoiCap"
                value={data?.NoiCap}
              />
            </Col>
            <Col span={8}>
              {" "}
              <InputCustom
                disabled={true}
                label="Di động:"
                applyChange={applyChange}
                prop="DienThoaiKH"
                value={data?.DienThoaiKH}
              />
            </Col>
            <Col span={8}>
              <DatePickerCustom
                open2={open}
                disabled={true}
                label="Ngày sinh:"
                applyChange={applyChange}
                prop={"NgaySinh"}
                value={data?.NgaySinh}
              />
            </Col>
            <Col span={8}>
              <InputCustom
                disabled={true}
                label="Email:"
                applyChange={applyChange}
                prop="EmailKH"
                value={data?.EmailKH}
              />
            </Col>
            <Col span={24}>
              <InputCustom
                disabled={true}
                label="Địa chỉ TT:"
                applyChange={applyChange}
                prop="DCTT"
                value={data?.DCTT}
              />
            </Col>
            <Col span={24}>
              <InputCustom
                disabled={true}
                label="Địa chỉ liên hệ:"
                applyChange={applyChange}
                prop="DCLL"
                value={data?.DCLL}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" onChange={handleTabChange} type="line">
        <TabPane tab="Thông tin chi tiết" key="1">
          {renderThongTinChiTiet()}
        </TabPane>
        <TabPane tab="Dịch vụ" key="2">
          {renderDichVu()}
        </TabPane>
      </Tabs>
      {contextHolder}

      <FormAddKhachHang
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{ ToaNha: ToaNha }}
        filterCondition={filterCondition}
        filterKhachHangNew={filterKhachHangNew}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
