import axiosApi from "./api_helper";

export const HeThongService = {
  Zalo:{
    SendOTP: async (payload) => {
      return await axiosApi()
        .post("api/crm/send-zalo-otp", payload)
        .then((res) => res.data);
    },
  },
  BieuMau: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/hethong/bieumau/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/hethong/bieumau/add", payload)
        .then((res) => res.data);
    },
    editMau: async (payload) => {
      return await axiosApi()
        .post("api/hethong/bieumau/edit-mau", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/hethong/bieumau/delete", payload)
        .then((res) => res.data);
    },
    getLBM: async (payload) => {
      return await axiosApi()
        .post("api/hethong/loaibm/get", payload)
        .then((res) => res.data);
    },
    
  },
  ThongBao: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/hethong/noti/get", payload)
        .then((res) => res.data);
    },
    getHistoryNotification: async (payload) => {
      return await axiosApi()
        .post("api/hethong/noti/get-filter", payload)
        .then((res) => res.data);
    },
  },
  CauHinh: {
    mauMail: {
      get: async (payload) => {
        return await axiosApi()
          .post("api/hethong/maumail/get", payload)
          .then((res) => res.data);
      },
      getLM: async (payload) => {
        return await axiosApi()
          .post("api/hethong/maumail/get-loaimau", payload)
          .then((res) => res.data);
      },
      add: async (payload) => {
        return await axiosApi()
          .post("api/hethong/maumail/add", payload)
          .then((res) => res.data);
      },
      delete: async (payload) => {
        return await axiosApi()
          .post("api/hethong/maumail/delete", payload)
          .then((res) => res.data);
      },
    },
    taiKhoanMail: {
      get: async (payload) => {
        return await axiosApi()
          .post("api/hethong/taikhoanmail/get", payload)
          .then((res) => res.data);
      },

      add: async (payload) => {
        return await axiosApi()
          .post("api/hethong/taikhoanmail/add", payload)
          .then((res) => res.data);
      },
      delete: async (payload) => {
        return await axiosApi()
          .post("api/hethong/taikhoanmail/delete", payload)
          .then((res) => res.data);
      },
    },
  },
};
