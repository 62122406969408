import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  InputNumber,
  Popover,
  Select,
} from "antd";
import _ from "lodash";
import ActiveRoute from "components/ActiveRoute";
import { YeuCauService } from "services/YeuCauService";
import FilterDate from "components/FilterDate";
import dayjs from "dayjs";
import { ToaNhaService } from "services/ToaNhaService";
import DatePickerCustomFilter from "components/Forms/DatePickerCustomFilter";
import { DateTime } from "luxon";
import { FaEquals, FaGreaterThanEqual, FaLessThanEqual } from "react-icons/fa6";
import { SwapOutlined } from "@ant-design/icons";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    defaultValue,
    setFilterCondition,
    loadKhoiNha,
    loadTangLau,
    loadcategoriesByMaTN,
    canHoThuThua,
    setCanHoThuThua,
    onChangeFilterByTN,
    handleFilterCongNo,
  } = props;
  const [loaiyeucau, setLoaiyeucau] = useState([]);
  const [filterTT, setFilterTT] = useState("*");
  const [visible, setVisible] = useState(false);
  const [tuDen, setTuDen] = useState(false);
  const [valueLTT, setValueLTT] = useState();
  const [valueTu, setValueTu] = useState();
  const [valueDen, setValueDen] = useState();

  const [filterTT2, setFilterTT2] = useState("*");
  const [visible2, setVisible2] = useState(false);
  const [tuDen2, setTuDen2] = useState(false);
  const [valueLTT2, setValueLTT2] = useState();
  const [valueTu2, setValueTu2] = useState();
  const [valueDen2, setValueDen2] = useState();

  const applyChangeFilter = async (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        _filter[p] = v;
        _filter.MaBP = null;
        _filter.MaLYC = null;
        _filter.MaKN = null;
        localStorage.setItem("MaTN", v);
        onChangeFilterByTN(_filter, canHoThuThua);
        loadcategoriesByMaTN(v);
        break;
      case "MaKN":
        _filter[p] = v;
        _filter.MaTL = ",,";
        loadTangLau(v ? v : "", _filter?.MaTN);
        handleFilterCongNo(v, _filter?.MaTL, _filter?.MaLDV, _filter?.TongTien);
        break;
      case "MaTL":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        handleFilterCongNo(
          _filter?.MaKN,
          v?.length > 0 ? "," + v.join(",") + "," : ",,",
          _filter?.MaLDV,
          _filter?.TongTien
        );
        break;
      case "MaLDV":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        onChangeFilterByTN(_filter, canHoThuThua);

        break;
      case "MaBP":
        _filter.MaLYC = null;
        _filter[p] = v;
        break;
      case "DenNgay":
        const dateInVietnam = DateTime.fromJSDate(v.$d, {
          zone: "utc",
        }).setZone("Asia/Ho_Chi_Minh");
        _filter[p] = dateInVietnam.toISO();
        onChangeFilterByTN(_filter, canHoThuThua);
        break;
      case "TongTien":
        if (v.Type === "Từ->đến") {
          _filter.TongTien = {
            Type: "TuDen",
            Value: v.ValueTu,
            ValueTo: v.ValueDen,
          };
        } else if (v.Type === ">=") {
          _filter.TongTien = {
            Type: "LonHonBang",
            Value: v.Value,
          };
        } else if (v.Type === "<=") {
          _filter.TongTien = {
            Type: "BeHonBang",
            Value: v.Value,
          };
        } else if (v.Type === "*") {
          _filter.TongTien = {
            Type: "Empty",
            Value: 0,
          };
        } else {
          _filter.TongTien = {
            Type: "Bang",
            Value: v.Value,
          };
        }

        handleFilterCongNo(
          _filter?.MaKN,
          _filter?.MaTL,
          _filter?.MaLDV,
          _filter?.TongTien
        );
        break;
      default:
        _filter[p] = v;
        break;
    }
    setFilterCondition(_filter);
  };

  const filterOptions = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "Từ->đến": { label: "TuDen", icon: <SwapOutlined /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };

  const handleClick = (type) => {
    if (type === "Từ->đến") {
      setTuDen(true);
    } else {
      setTuDen(false);
    }
    setFilterTT(type);
    setVisible(false);
  };

  useEffect(() => {
    if (filterCondition.MaTN) {
      if (filterTT === "Từ->đến") {
        applyChangeFilter("TongTien", {
          Type: filterTT,
          ValueTu: valueTu,
          ValueDen: valueDen,
        });
      } else {
        applyChangeFilter("TongTien", { Type: filterTT, Value: valueLTT });
      }
    }
  }, [valueLTT, filterTT, valueTu, valueDen]);

  const content = (
    <div>
      {Object.entries(filterOptions).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );

  const [onOf, setOnOf] = useState(false);

  return (
    <>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilterByTN(
            {
              ...defaultValue,
              MaTN: filterCondition.MaTN,
            },
            canHoThuThua
          );

          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      <div className="box-search">
        <DatePickerCustomFilter
          label="Đến ngày"
          applyChange={applyChangeFilter}
          prop={"DenNgay"}
          value={filterCondition.DenNgay}
          placeholder={"Chọn ngày nhập"}
        />
      </div>
      <div className="box-search">
        <Select
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            value={filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        {filterCondition?.MaKN && (
          <Select
            value={filterCondition.MaTL.split(",").filter(Boolean).map(Number)}
            mode="multiple"
            placeholder="Chọn tầng lầu"
            onChange={(v) => applyChangeFilter("MaTL", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options.TangLau}
            fieldNames={{ label: "TenTL", value: "MaTL" }}
          />
        )}
        {!canHoThuThua && (
          <Select
            mode="multiple"
            value={filterCondition.MaLDV.split(",").filter(Boolean).map(Number)}
            placeholder="Chọn loại dịch vụ"
            onChange={(v) => applyChangeFilter("MaLDV", v)}
            style={{
              width: "100%",
            }}
            options={[
              { ID: 1, Name: "Điện" },
              { ID: 2, Name: "Nước" },
              { ID: 6, Name: "Giữ xe" },
              { ID: 12, Name: "Phí quản lý" },
              { ID: 3, Name: "Dịch vụ khác" },
              { ID: 5, Name: "Hợp đồng thuê" },
              { ID: 33, Name: "Tiền nội thất" },
            ]}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        )}
      </div>
      <div className="box-search">
        <p className="label">Lọc theo điều kiện</p>
        {!canHoThuThua && (
          <>
            {" "}
            {tuDen ? (
              <>
                <div
                  style={{
                    gap: 5,
                    alignItems: "center",
                    padding: "0 5px",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ display: "flex", marginBottom: "5px" }}>
                    <Popover
                      content={content}
                      title=""
                      trigger="click"
                      visible={visible}
                      onClick={() => setVisible(!visible)}
                    >
                      <Button style={{ marginRight: "15px" }}>Từ</Button>
                    </Popover>
                    <InputNumber
                      style={{ width: "100%" }}
                      value={valueTu}
                      onChange={(value) => setValueTu(value)}
                      placeholder="Từ tổng tiền nợ"
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={() => setVisible(!visible)}
                    >
                      Đến
                    </Button>
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      style={{ width: "100%" }}
                      value={valueDen}
                      onChange={(value) => setValueDen(value)}
                      placeholder="Đến số tiền nợ"
                      min={0}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Popover
                    content={content}
                    title=""
                    trigger="click"
                    visible={visible}
                    onClick={() => setVisible(!visible)}
                  >
                    <Button>{filterOptions[filterTT]?.icon}</Button>
                  </Popover>
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    style={{ width: "90%" }}
                    value={valueLTT}
                    onChange={(value) => setValueLTT(value)}
                    placeholder="Lọc tổng tiền nợ"
                    min={0}
                  />
                </div>
              </>
            )}
          </>
        )}
        <Checkbox
          checked={canHoThuThua}
          value={canHoThuThua}
          onClick={async () => {
            const newCanHoThuThua = !canHoThuThua;
            setCanHoThuThua(newCanHoThuThua);
            await setCanHoThuThua(newCanHoThuThua);
            onChangeFilterByTN(filterCondition, newCanHoThuThua);
          }}
        >
          Căn hộ thu thừa
        </Checkbox>
      </div>
    </>
  );
};
export default FilterModal;
