import * as React from "react";
import _ from "lodash";
import { Col, Flex, Image, Row, Table } from "antd";
import { Format_Currency } from "layouts/ultis/common";
import "./index.scss";
const MauDichVu = (props, ref) => {
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: 30,
    },
    {
      title: "Tháng",
      dataIndex: "soChungTu",
      key: "soChungTu",
      align: "center",
      width: 60,
    },
    {
      title: "Diễn giải nội dung",
      dataIndex: "dienGiai",
      key: "dienGiai",
      align: "center",
      render: (item) => <p style={{ textAlign: "left" }}>{item}</p>,
      width: 250,
    },
    {
      title: "Đơn vị",
      dataIndex: "donVi",
      key: "donVi",
      align: "center",
      width: 50,
    },
    {
      title: "Số đầu",
      dataIndex: "soDau",
      key: "soDau",
      align: "center",
      width: 60,
    },
    {
      title: "Số cuối",
      dataIndex: "soCuoi",
      key: "soCuoi",
      align: "center",
      width: 60,
    },
    {
      title: "Số lượng",
      dataIndex: "slDung",
      key: "slDung",
      align: "center",
      width: 60,
    },
    {
      title: "Đơn giá",
      dataIndex: "donGia",
      key: "donGia",
      align: "center",
      render: (value) => `${value.toLocaleString()}`,
      width: 80,
    },
    {
      title: "Tổng tiền",
      dataIndex: "tongTien",
      key: "tongTien",
      align: "center",
      render: (value) => (
        <p style={{ textAlign: "right" }}>{`${value.toLocaleString()}`}</p>
      ),

      width: 80,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      align: "center",
      width: 120,
    },
  ];

  const data = [
    {
      key: "1",
      stt: 1,
      soChungTu: "123456",
      dienGiai: "Mua hàng A",
      donVi: "Cái",
      soDau: 10,
      soCuoi: 5,
      slDung: 5,
      donGia: 20000,
      tongTien: 100000,
      ghiChu: "Đã nhận hàng",
    },
    {
      key: "2",
      stt: 2,
      soChungTu: "123457",
      dienGiai: "Mua hàng B",
      donVi: "Thùng",
      soDau: 50,
      soCuoi: 40,
      slDung: 10,
      donGia: 50000000,
      tongTien: 50000000,
      ghiChu: "Chưa giao đủ",
    },
  ];
  return (
    <div ref={ref} className="container-formprint">
      <Row gutter={[20, 5]}>
        <Col span={24}>
          <Flex align="center" justify="space-between">
            <Image src="https://beesky.vn/img/logo1.png" preview={false} />
            <div style={{ textAlign: "center" }}>
              <p className="label-bold">CÔNG HÒA XÃ HỘI CHỦ NGHĨ VIỆT NAM</p>
              <p className="label">ĐỘC LẬP - TỰ DO - HẠNH PHÚC</p>
            </div>
          </Flex>
        </Col>{" "}
        <Col span={24}>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            GIẤY ĐỀ NGHỊ THANH TOÁN TIỀN MẶT/CHUYỂN KHOẢN
          </h1>
          <p
            className="label"
            style={{
              padding: "15px 0",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            - Căn cứ vào Bộ Luật dân sự đã được Quốc hội nước Cộng hòa Xã hội
            Chủ nghĩa Việt Nam thông qua ngày 24 tháng 11 năm 2015.
          </p>
        </Col>
        <Col span={24}>
          <p className="label-bold">
            I. Nơi đề nghị : CÔNG TY TNHH BẤT ĐỘNG SẢN UPLAND
          </p>
          <Flex align="center">
            <p className="label" style={{ paddingRight: "20px" }}>
              - Đại diện ( bà ) :{" "}
              <span className="label-bold">Phạm Thị Hồng</span>{" "}
            </p>
            <p className="label">
              Chức vụ : <span className="label-bold">Giám đốc</span>{" "}
            </p>
          </Flex>
          <p className="label">
            - Địa chỉ : Số 134, phố Trung Kính, phường Yên Hòa, quận Cầu Giấy,
            TP Hà Nội
          </p>
          <Flex align="center">
            <p className="label" style={{ paddingRight: "20px" }}>
              - Số điện thoại : 0911.498.228 ( Phòng kế toán)
            </p>
            <p className="label">- Email: upland.vn@gmail.com</p>
          </Flex>
        </Col>
        <Col span={24}>
          <p className="label-bold">
            II. Nợi nhận : Công ty TNHH công nghệ và thương mại Beesky Việt Nam
            - Phòng 602 - Số 65 Ngõ 54 Lê Quang Đạo
          </p>
          <Flex align="center">
            <p className="label" style={{ paddingRight: "20px" }}>
              - Đại diện : Nguyễn Quang Phúc
            </p>
            <p className="label">Chức vụ : GIÁM ĐỐC</p>
          </Flex>
          <Flex align="center">
            <p className="label" style={{ paddingRight: "20px" }}>
              - Số điện thoại : 0936281128
            </p>
            <p className="label"> Email :</p>
          </Flex>
        </Col>
        <Col span={24}>
          <p className="label-bold">III.Nội dung đề nghị thanh toán :</p>
          <Table
            style={{ padding: "15px 0", fontSize: "2px" }}
            columns={columns}
            dataSource={data}
            bordered
            pagination={false}
            cellFontSize={1}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={8}>
                        <p style={{ textAlign: "center" }}>
                          TỔNG TIỀN CẦN THANH TOÁN
                        </p>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ textAlign: "right" }}>
                          {Format_Currency(_.sumBy(pageData, "tongTien"))}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={8}>
                        <p className="label" style={{ textAlign: "center" }}>
                          TỔNG ĐÃ THANH TOÁN
                        </p>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ textAlign: "right" }}>
                          {Format_Currency(300000)}
                        </p>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={8}>
                        <p
                          className="label-bold"
                          style={{ textAlign: "center" }}
                        >
                          TỔNG TIỀN CÒN LẠI PHẢI THANH TOÁN
                        </p>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p
                          className="label-bold"
                          style={{ textAlign: "right" }}
                        >
                          {Format_Currency(
                            _.sumBy(pageData, "tongTien") - 300000
                          )}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                </>
              );
            }}
          />
        </Col>
        <Col span={24}>
          <p className="label-bold" style={{ fontStyle: "italic" }}>
            Ghi chú :
          </p>
          <p className="label-bold" style={{ fontStyle: "italic" }}>
            1. Thời hạn thanh toán bắt đầu từ ngày nhận được thông báo này đến
            17h ngày 06/10/2023 dương lịch
          </p>
          <p className="label-bold" style={{ fontStyle: "italic" }}>
            2. Hình thức thanh toán:
          </p>
          <p className="label">
            *** Chuyển khoản qua số tài khoản:{" "}
            <span className="label-bold">63.66.67.68.69</span>
          </p>
          <p
            style={{
              paddingLeft: "15px",
            }}
            className="label"
          >
            Chủ tài khoản:{" "}
            <span className="label-bold">
              Nguyễn Duy Hải tại ngân hàng VPBANK - chi nhánh Tràng An
            </span>
          </p>
          <p className="label-bold" style={{ fontStyle: "italic" }}>
            3. Quy định về việc thanh toán:
          </p>
          <p
            className="label"
            style={{
              paddingLeft: "15px",
            }}
          >
            * Đối với khoản phí dịch vụ nếu quý vị nộp tiền sau ngày 05 của
            tháng, vui lòng nộp phí thanh toán chậm 20.000đ/ngày, và không chậm
            quá 3 ngày
          </p>
          <p
            className="label"
            style={{
              paddingLeft: "15px",
            }}
          >
            * Đối với khoản tiền nhà hạn thanh toán sẽ là ngày cuối cùng của kỳ
            tiền nhà trước, nếu nộp sau thời hạn trên quý vị vui lòng nộp phí
            chậm nộp là 50.000VNĐ/1 ngày và không chậm quá 03 ngày
          </p>
          <p
            className="label"
            style={{
              paddingLeft: "15px",
            }}
          >
            * Sau 3 ngày kể từ thời hạn thanh toán cuối cùng của khoản tiền nhà
            và khoản phí dịch vụ mà quý vị không thanh toán hoặc không gửi các
            biên bản liên quan đến việc thanh toán cho kế toán thì ban quản lý
            tòa nhà có quyền ngừng cung cấp dịch vụ mà không cần báo trước
          </p>
          <p
            className="label"
            style={{ paddingTop: "5px", paddingLeft: "15px" }}
          >
            Mong quý vị thông cảm và hợp tác.
          </p>
          <p className="label">
            Mọi thắc mắc, phản hồi vui lòng GỌI ĐIỆN TỚI HOTLINE:{" "}
            <span className="label-bold">
              Mrs Nga kế toán : 0911.498.228 - Hotline CSKH 0246.663.2662{" "}
            </span>
          </p>
          <p className="label-bold">
            Rất mong nhận được sự hợp tác của Quý khách hàng!
          </p>
        </Col>
        <Col span={2}></Col>
        <Col span={6}>
          <Flex justify="space-between" align="center">
            <div>
              {" "}
              <p className="label-bold">Người nhận</p>
              <p className="label" style={{ fontStyle: "italic" }}>
                (Ký, họ tên)
              </p>
            </div>

            <div>
              {" "}
              <p className="label-bold">Người lập</p>
              <p className="label" style={{ fontStyle: "italic" }}>
                (Ký, họ tên)
              </p>
            </div>
          </Flex>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <div style={{ textAlign: "center" }}>
            <p className="label-bold" style={{ fontStyle: "italic" }}>
              Hà Nội, ngày 27 tháng 09 năm 2023
            </p>
            <p className="label-bold">Người đề nghị</p>
            <p className="label-bold">Kế toán</p>
            <p className="label" style={{ fontStyle: "italic" }}>
              (Ký, họ tên)
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default React.forwardRef(MauDichVu);
