import axiosApi from "./api_helper";

export const DashboardService = {
  getDashboardAdd: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/get-dashboard-byper", payload)
      .then((res) => res.data);
  },
  getPhanQuyen: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/get-phanquyen-dashboard", payload)
      .then((res) => res.data);
  },
  addPhanQuyen: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/add-phanquyen-dashboard", payload)
      .then((res) => res.data);
  },
  deletePhanQuyen: async (payload) => {
    return await axiosApi()
      .post("api/nhan-vien/delete-phanquyen-dashboard", payload)
      .then((res) => res.data);
  },
  getYC: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/yeucau-bymonth", payload)
      .then((res) => res.data);
  },
  getThongTinDashboard: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/get-total-bymonth", payload)
      .then((res) => res.data);
  },

  getPLYC: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/category/get-loaiyeucau-bymatn", payload)
      .then((res) => res.data);
  },
  getTheXe: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/thexe-byyear", payload)
      .then((res) => res.data);
  },
  getNuoc: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/nuoc-byyear", payload)
      .then((res) => res.data);
  },
  getDien: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/dien-byyear", payload)
      .then((res) => res.data);
  },
  getDienTichMB: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/get-dientichmb", payload)
      .then((res) => res.data);
  },
  TranChu: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/get-dashboard", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/delete-dashboard", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/add-dashboard", payload)
        .then((res) => res.data);
    },
    isView: async (payload) => {
      return await axiosApi()
        .post("api/nhan-vien/showhide-dashboard", payload)
        .then((res) => res.data);
    },
  },
};
