import axiosApi from "./api_helper";

export const CategoryService = {
  DichVu: {
    Xe: {
      LoaiXe: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/get-loai", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/add-loai", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/delete-loai", payload)
            .then((res) => res.data);
        },
      },
      DinhMuc: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/get-dinhmuc", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/add-dinhmuc", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/delete-dinhmuc", payload)
            .then((res) => res.data);
        },
      },
      TrangThai: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/get-trangthai", payload)
            .then((res) => res.data);
        },
      },
      KetNoiBaiXe: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/get-cauhinhbaixe", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/add-cauhinhbaixe", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/xe/delete-cauhinhbaixe", payload)
            .then((res) => res.data);
        },
      },
    },
    Dien: {
      DinhMucDien: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/get-dinhmuc", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/add-dinhmuc", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/delete-dinhmuc", payload)
            .then((res) => res.data);
        },
      },
      CongTo: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/get-congto", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/add-congto", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/delete-congto", payload)
            .then((res) => res.data);
        },
      },
    },
    Nuoc: {
      DinhMuc: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/get-dinhmuc", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/add-dinhmuc", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/dien/delete-dinhmuc", payload)
            .then((res) => res.data);
        },
      },
      DongHo: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/get-dongho", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/add-dongho", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/delete-dongho", payload)
            .then((res) => res.data);
        },
        import: async (payload) => {
          return await axiosApi()
            .post("api/category/nuoc/import-dongho", payload)
            .then((res) => res.data);
        },
      },
    },
    Khac: {
      get: async (payload) => {
        return await axiosApi()
          .post("api/category/khac/get-name", payload)
          .then((res) => res.data);
      },
      add: async (payload) => {
        return await axiosApi()
          .post("api/category/khac/add-name", payload)
          .then((res) => res.data);
      },
      delete: async (payload) => {
        return await axiosApi()
          .post("api/category/khac/delete-name", payload)
          .then((res) => res.data);
      },
    },
    HopDongThiCong: {
      TinhTrang: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/hdthicong/get-tinhtrang", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/hdthicong/add-tinhtrang", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/hdthicong/delete-tinhtrang", payload)
            .then((res) => res.data);
        },
      },
    },
  },
  DuAn: {
    MatBang: {
      get: async (payload) => {
        return await axiosApi()
          .post("api/category/duan/get-loaimb", payload)
          .then((res) => res.data);
      },
      add: async (payload) => {
        return await axiosApi()
          .post("api/category/duan/add-loaimb", payload)
          .then((res) => res.data);
      },
      delete: async (payload) => {
        return await axiosApi()
          .post("api/category/duan/delete-loaimb", payload)
          .then((res) => res.data);
      },
      TrangThai: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/duan/get-trangthaimb", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/duan/add-trangthaimb", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/duan/delete-trangthaimb", payload)
            .then((res) => res.data);
        },
      },
    },
  },
  Khac: {
    get: async () => {
      return await axiosApi()
        .post("api/category/dichvu/get-tenloaidv")
        .then((res) => res.data);
    },
  },
  KhachHang: {
    CuDan: {
      TrangThai: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/cu-dan/get-trangthai", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/cu-dan/add-trangthai", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/delete-ten", payload)
            .then((res) => res.data);
        },
      },
      QuanHe: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/cu-dan/get-quanhe", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/cu-dan/add-quanhe", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/cu-dan/delete-quanhe", payload)
            .then((res) => res.data);
        },
      },
    },
    TienIch: {
      TenTienIch: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/get-ten", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/add-ten", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/delete-ten", payload)
            .then((res) => res.data);
        },
      },
      khuBan: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/get-ban", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/add-ban", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/delete-ban", payload)
            .then((res) => res.data);
        },
      },
      KhungGio: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/get-khunggio", payload)
            .then((res) => res.data);
        },
        add: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/add-khunggio", payload)
            .then((res) => res.data);
        },
        delete: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/delete-khunggio", payload)
            .then((res) => res.data);
        },
      },
      TrangThai: {
        get: async (payload) => {
          return await axiosApi()
            .post("api/category/tien-ich/get-trangthai", payload)
            .then((res) => res.data);
        },
      },
    },
  },
};
