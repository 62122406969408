import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
} from "antd";
import _, { cloneDeep } from "lodash";
import "dayjs/locale/vi";
import { SaveOutlined } from "@ant-design/icons";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import { PermissionService } from "services/PermissionService";

const CheckboxGroup = Checkbox.Group;
const FormAddFeature = (props, ref) => {
  const { loadForm } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formID, setFormID] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  const filterFeature = async (formID) => {
    setLoading(true);
    let _res = await PermissionService.getFeature();
    if (_res.data) setData(_res.data);
    let _res2 = await PermissionService.getFeatureByForm({
      FormID: formID,
    });
    if (_res2.data) setSelectedFeatures(_.map(_res2.data, "FeatureID"));
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (id) => {
      setOpen(true);
      setIsUpdate(false);
      setFormID(id);
      filterFeature(id);
    },
    update: async (item) => {
      setOpen(true);
      setIsUpdate(true);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSelectedFeatures([]);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await PermissionService.addFeature({
      FormID: formID,
      FeaturesIds: selectedFeatures,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tính năng thành công!`
      );
      setIsUpdate(true);
      // onAfterSubmit();
      loadForm();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (checkedValue, itemID) => {
    setSelectedFeatures((prev) => {
      const newSelected = checkedValue
        ? [...prev, itemID]
        : prev.filter((id) => id !== itemID);
      console.log("Selected Features:", newSelected);
      return newSelected;
    });
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ paddingTop: "10px" }} gutter={[20, 12]}>
          {data.map((item) => (
            <Col span={24} key={item.ID}>
              <Checkbox
                onChange={(e) => onChange(e.target.checked, item.ID)}
                checked={selectedFeatures.includes(item.ID)}
              >
                {item.Name}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thêm tính năng"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAddFeature);
