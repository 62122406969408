import React, { useState } from "react";
import { Col, DatePicker, Row, Select } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import ActiveRoute from "components/ActiveRoute";

const FilterModal = ({
  defaultValue,
  setFilterCondition,
  filterCondition,
  onChangeFilter,
  options,
}) => {
  // Function to handle filter updates
  const [onOf, setOnOf] = useState(false);
  const applyChangeFilter = (key, value) => {
    const updatedFilter = { ...filterCondition, [key]: value };
    onChangeFilter(updatedFilter);
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={6}>
        {/* <div style={{ display: "flex" }}>
          <p style={{ width: "80px" }} className="label">
            Dự án
          </p>
          <Select
            value={filterCondition.MaTN}
            placeholder="Chọn dự án"
            onChange={(value) => applyChangeFilter("MaTN", value)}
            style={{
              width: "100%",
            }}
            options={options?.ToaNha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
        </div> */}
        <div style={{ display: "flex" }}>
          <p
            className="label"
            style={{
              marginTop: 4,
              paddingRight: "10px",
              fontWeight: "600",
            }}
          >
            Dự án
          </p>
          <Select
            // mode="multiple"
            // defaultValue={localStorage.getItem("MaTN")}
            value={filterCondition?.MaTN}
            placeholder="Chọn dự án"
            onChange={(v) => applyChangeFilter("MaTN", v)}
            style={{ width: "70%" }}
            options={options?.ToaNha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
        </div>
      </Col>
      <Col span={4}>
        <div style={{ display: "flex" }}>
          <p
            style={{
              marginTop: 4,
              paddingRight: "10px",
              fontWeight: "600",
            }}
            className="label"
          >
            Năm
          </p>
          <DatePicker
            picker="year"
            value={
              filterCondition.Year
                ? dayjs(filterCondition.Year.toString(), "YYYY")
                : null
            }
            onChange={(date) =>
              applyChangeFilter("Year", date ? date.year() : null)
            }
            placeholder="Chọn năm"
            style={{ width: "100%" }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FilterModal;
