import axiosApi from "./api_helper";
export const ThongKeService = {
  BaoCao: {
    getDoanhThu: async (payload) => {
      return await axiosApi()
        .post("api/admin/report/doanhthu-byyear", payload)
        .then((res) => res.data);
    },
    getChiPhi: async (payload) => {
      return await axiosApi()
        .post("api/admin/report/chiphi-byyear", payload)
        .then((res) => res.data);
    },
  },
};
