import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Row,
  Segmented,
  Table,
  notification,
} from "antd";
import FormAdd from "./FormAdd";
import { MdSkipNext } from "react-icons/md";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import FilterKN from "./FilterKN";
import { YeuCauService } from "services/YeuCauService";
import FormAddNuoc from "./FormAddNuoc/DinhMucNuoc";
import FormAddNuoc2 from "./FormAddNuoc/DongHoNuoc";
import FormAddDien from "./FormAddDien/DinhMucDien";
import FormAddDien2 from "./FormAddDien/LoaiCongTo";
import FormAddXe from "./FormAddXe/LoaiXe";
import FormAddXe2 from "./FormAddXe/DinhMuc";
import FormAddXe3 from "./FormAddXe/TrangThai";
import FormAddXe4 from "./FormAddXe/KetNoiBaiXe";
import FormAddXe5 from "./FormAddXe/DanhSachKhoThe";
import FormAddKhac from "./FormAddKhac/Khac";
import FormAddGas from "./FormAddGas/DinhMucGas";
import FormAddThangMay from "./FormAddThangMay/DinhMucPhiThangMay";
import FormAddHĐThiCong from "./FormAddHĐThiCong/TinhTrangHD";
import { CategoryService } from "services/CategoryService";
import getColumns from "./ColumnsDichVu";
import Toolbar from "components/Toolbar";
import FormAddExcel from "./FormAddNuoc/FormAddExcel";
import { DownOutlined, ImportOutlined } from "@ant-design/icons";
import { BsMotherboard } from "react-icons/bs";

export default function Index() {
  const [secondaryOption, setSecondaryOption] = useState(1);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [animate, setAnimate] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [toanha, setToanha] = useState([]);
  const [loaiMB, setLoaiMB] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [responseColum, setResponseColum] = useState(false);
  const [xColumns, setXColumns] = useState(2000);

  useEffect(() => {
    filter(activeTab, secondaryOption, filterCondition);
  }, []);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const nextWeekDate = new Date();

  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
    TuNgay: currentDate.toISOString(),
    DenNgay: nextWeekDate.toISOString(),
    InputSearch: "",
  });
  const optionsXe = [
    { value: 1, label: "Loại xe" },
    { value: 2, label: "Đinh mức" },
    { value: 3, label: "Trạng thái" },
    { value: 4, label: "Kết nối bãi xe" },
    { value: 5, label: "Danh sách kho thẻ" },
  ];
  const optionsNuoc = [
    { value: 1, label: "Định mức nước" },
    { value: 2, label: "Đồng hồ nước" },
  ];
  const optionsDien = [
    { value: 1, label: "Định mức điện" },
    { value: 2, label: "Công tơ điện" },
  ];
  const optionsGas = [{ value: 1, label: "Định mức gas" }];
  const optionsKhac = [{ value: 1, label: "Tên dịch vụ khác" }];
  const optionsThangMay = [{ value: 1, label: "Định mức phí thang máy" }];
  const optionsHĐThiCong = [{ value: 1, label: "Tình trạng" }];
  const refDinhMucNuoc = useRef(null);
  const refDongHoNuoc = useRef(null);
  const refDinhMucDien = useRef(null);
  const refLoaiCongTo = useRef(null);
  const refLoaiXe = useRef(null);
  const refDinhMucXe = useRef(null);
  const refTrangThaiXe = useRef(null);
  const refKetNoiBaiXe = useRef(null);
  const refDanhSachKhoThe = useRef(null);
  const refKhac = useRef(null);
  const refDinhMucGas = useRef(null);
  const refDinhMucThangMay = useRef(null);
  const refTinhTrangThiCong = useRef(null);
  const refAddExcel = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const filter = async (activeTab, secondaryOption, _filter) => {
    setLoading(true);
    let _res;
    let _tn;
    if (toanha.length === 0) {
      _tn = await ToaNhaService.getToaNhaByNV();
      setToanha(_tn.data);
    }

    switch (activeTab) {
      case 1:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(1800);
            filterCondition.MaTN = _filter.MaTN || _tn?.data[0]?.MaTN;
            _res = await CategoryService.DichVu.Nuoc.DinhMuc.get(
              _filter.MaTN === null
                ? { ..._filter, MaTN: _tn?.data[0].MaTN }
                : _filter
            );
            if (_res.data) {
              setData(_res.data);
            }
            break;

          case 2:
            setXColumns(2000);
            _res = await CategoryService.DichVu.Nuoc.DongHo.get(
              _filter.MaTN === null
                ? { ..._filter, MaTN: _tn?.data[0].MaTN }
                : _filter
            );
            if (_res.data) {
              setData(_res.data);
            }
            break;
          default:
            break;
        }
        break;
      case 2:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(1400);
            filterCondition.MaTN = _filter.MaTN || _tn?.data[0]?.MaTN;
            _res = await CategoryService.DichVu.Dien.DinhMucDien.get(
              _filter.MaTN === null
                ? (_filter.MaTN = _tn?.data[0]?.MaTN)
                : _filter
            );
            if (_res.data) {
              setData(_res.data);
            }
            break;
          case 2:
            setXColumns(1600);
            _res = await CategoryService.DichVu.Dien.CongTo.get(_filter);
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      case 3:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setResponseColum(false);
            setXColumns(800);
            filterCondition.MaTN = _filter.MaTN || _tn?.data[0]?.MaTN;
            _res = await CategoryService.DichVu.Xe.LoaiXe.get(
              _filter.MaTN === null
                ? (_filter.MaTN = _tn?.data[0]?.MaTN)
                : _filter
            );
            if (_res.data) {
              setData(_res.data);
            }
            break;
          case 2:
            setResponseColum(false);
            setXColumns(2000);
            _res = await CategoryService.DichVu.Xe.DinhMuc.get(_filter);
            if (_res.data) setData(_res.data);
            break;
          case 3:
            setResponseColum(true);
            setXColumns(800);
            _res = await CategoryService.DichVu.Xe.TrangThai.get(_filter);
            if (_res.data) setData(_res.data);
            break;
          case 4:
            setResponseColum(false);
            setXColumns(1000);
            _res = await CategoryService.DichVu.Xe.KetNoiBaiXe.get(_filter);
            if (_res.data) setData(_res.data);
            break;
          case 5:
            setResponseColum(false);
            setXColumns(1000);
            _res = await CategoryService.DichVu.Xe.KetNoiBaiXe.get(_filter);
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      case 4:
        setResponseColum(true);
        switch (secondaryOption) {
          case 1:
            setXColumns(1000);
            _res = await CategoryService.DichVu.Khac.get({
              MaTN: null,
            });
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      case 5:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(1000);
            _res = await CategoryService.DichVu.Khac.get({
              MaTN: null,
            });
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      case 6:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(1000);
            _res = await CategoryService.DichVu.HopDongThiCong.TinhTrang.get();
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;

      case 7:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(1000);
            _res = await CategoryService.DichVu.HopDongThiCong.TinhTrang.get();
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter(activeTab, secondaryOption, filterCondition);
  };
  const remove = async (value) => {
    const handleResponse = (response, dataKey) => {
      if (response.status === 2000) {
        let _data = _.cloneDeep(data);
        let filteredData = _data.filter((v) => v[dataKey] !== Number(value));
        setData(filteredData);
        openNotification("success", "topRight", "Xoá danh mục thành công");
      } else {
        openNotification("error", "topRight", response?.message);
      }
    };

    let _res = null;
    try {
      switch (activeTab) {
        case 1:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.DichVu.Nuoc.DinhMuc.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 2:
              _res = await CategoryService.DichVu.Nuoc.DongHo.delete({
                ID: Number(value),
              });
              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.DichVu.Dien.DinhMucDien.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 2:
              _res = await CategoryService.DichVu.Dien.CongTo.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.DichVu.Xe.LoaiXe.delete({
                ID: Number(value),
              });

              handleResponse(_res, "MaLX");
              break;

            case 2:
              _res = await CategoryService.DichVu.Xe.DinhMuc.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 3:
              break;
            case 4:
              _res = await CategoryService.DichVu.Xe.KetNoiBaiXe.delete({
                ID: Number(value),
              });
              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 4:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.DichVu.Khac.delete({
                ID: Number(value),
              });
              handleResponse(_res, "MaLDV");
              break;
            default:
              break;
          }
          break;
        case 5:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.DichVu.Khac.delete({
                ID: Number(value),
              });
              handleResponse(_res, "MaLDV");
              break;
            default:
              break;
          }
          break;
        case 6:
          switch (secondaryOption) {
            case 1:
              _res =
                await CategoryService.DichVu.HopDongThiCong.TinhTrang.delete({
                  ID: Number(value),
                });
              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 7:
          switch (secondaryOption) {
            case 1:
              _res =
                await CategoryService.DichVu.HopDongThiCong.TinhTrang.delete({
                  ID: Number(value),
                });
              handleResponse(_res, "MaTT");
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } catch (error) {
      openNotification("error", "topRight", "An error occurred");
    }
  };
  const onChange = (newActiveKey) => {
    setSecondaryOption(newActiveKey);
    filter(activeTab, newActiveKey, filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(activeTab, secondaryOption, _filter);
    setFilterCondition(_filter);
  };
  const handleClick = (v) => {
    setSecondaryOption(1);
    setTimeout(() => setAnimate(false), 100);
    setTimeout(() => setActiveTab(v), 200);
    setTimeout(() => setAnimate(true), 400);
    setTimeout(() => filter(v, 1, filterCondition), 300);
  };

  const getColumnsByTab = getColumns(
    activeTab,
    secondaryOption,
    {
      refDinhMucNuoc,
      refDinhMucDien,
      refLoaiXe,
      refDinhMucXe,
      refTrangThaiXe,
      refKetNoiBaiXe,
      refKhac,
      refLoaiCongTo,
      refDongHoNuoc,
      refDanhSachKhoThe,
      loaiMB,
      refDinhMucGas,
      refDinhMucThangMay,
      refTinhTrangThiCong,
    },
    remove,
    data
  );
  const getOptionsByTab = (tab) => {
    switch (tab) {
      case 1:
        return optionsNuoc;
      case 2:
        return optionsDien;
      case 3:
        return optionsXe;
      case 4:
        return optionsKhac;
      case 5:
        return optionsGas;
      case 6:
        return optionsThangMay;
      case 7:
        return optionsHĐThiCong;
      default:
        return [];
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN)
          filter(activeTab, secondaryOption, filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    // setDataSelect(data.filter((item) => newSelectedRowKeys.includes(item.ID)));
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleMenuClick = async (e) => {
    if (e.key === "1" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn đồng hồ, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        const filterData = data.filter((obj) =>
          selectedRowKeys.includes(obj.ID)
        );
        let _data = _.cloneDeep(data);
        let hasDeleted = false;

        for (const item of filterData) {
          try {
            const _res = await CategoryService.DichVu.Nuoc.DongHo.delete({
              ID: Number(item.ID),
            });

            if (_res.status === 2000) {
              _data = _data.filter((v) => v.ID !== Number(item.ID));
              hasDeleted = true;
            } else {
              openNotification("error", "topRight", _res?.message);
            }
          } catch (error) {
            openNotification(
              "error",
              "topRight",
              "Có lỗi xảy ra trong quá trình xóa"
            );
          }
        }

        if (hasDeleted) {
          setData(_data);
          setSelectedRowKeys([]);
          openNotification("success", "topRight", "Xoá danh mục thành công");
        }
        break;

      default:
        break;
    }
  };
  const thaoTacKhac = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Xóa nhiều
      </Menu.Item>
    </Menu>
  );

  return (
    <Box>
      <Flex align="center" gap={10} style={{ marginBottom: 20 }}>
        <Segmented
          defaultValue={1}
          style={{ padding: "3px", zIndex: 3 }}
          onChange={(value) => handleClick(value)}
          options={[
            { value: 1, label: "Nước" },
            { value: 2, label: "Điện" },
            { value: 3, label: "Xe" },
            { value: 4, label: "Khác" },
            // { value: 5, label: "Gas" },
            // { value: 6, label: "Thẻ thang máy" },
            { value: 7, label: "Hợp đồng thi công" },
          ]}
          size="large"
        />

        <Flex
          align="center"
          className={`segment-right ${animate ? "animate-right" : ""}`}
        >
          <MdSkipNext size={20} color="rgb(0, 112, 244)" />
          <Segmented
            defaultValue={1}
            value={secondaryOption}
            style={{ padding: "3px" }}
            onChange={(value) => onChange(value)}
            options={getOptionsByTab(activeTab)}
            size="middle"
          />
        </Flex>
      </Flex>
      <Row gutter={[10, 10]}>
        <Col span={responseColum ? 0 : 4}>
          <FilterKN
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
            toanha={toanha}
            secondaryOption={secondaryOption}
            activeTab={activeTab}
          />
        </Col>
        <Col span={responseColum ? 24 : 20}>
          {secondaryOption === 2 && activeTab === 1 && (
            <div style={{ marginTop: "-58px" }}>
              <Toolbar
                right={() => (
                  <div style={{ display: "flex", marginBottom: "18px" }}>
                    <Input
                      style={{
                        width: "300px",
                        padding: "4px 11px",
                        borderRadius: 10,
                        marginRight: "10px",
                      }}
                      placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                      onChange={(e) => onSearch(e.target.value)}
                    />
                    <Button
                      onClick={() => refAddExcel.current.show()}
                      // style={{ marginBottom: "18px" }}
                    >
                      <ImportOutlined
                        style={{ fontSize: "13px", margin: "0 -2px" }}
                      />{" "}
                      Import
                    </Button>
                    <Dropdown overlay={thaoTacKhac} trigger={["click"]}>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <BsMotherboard style={{ marginRight: "5px" }} /> Thao
                        tác khác
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                )}
              />
            </div>
          )}
          <Table
            loading={loading}
            dataSource={data}
            columns={getColumnsByTab}
            scroll={{
              y: window.innerHeight - 350,
              x: xColumns,
            }}
            rowSelection={{
              ...rowSelection,
              columnWidth: 60,
            }}
            size="small"
            bordered
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "50", "100", "1000", "10000"],
              showSizeChanger: true,
            }}
          />
        </Col>
      </Row>

      <FormAddNuoc
        ref={refDinhMucNuoc}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        toanha={toanha}
      />
      <FormAddNuoc2
        ref={refDongHoNuoc}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddDien
        ref={refDinhMucDien}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddDien2
        ref={refLoaiCongTo}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddXe
        ref={refLoaiXe}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddXe2
        ref={refDinhMucXe}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddXe3 ref={refTrangThaiXe} onAfterSubmit={onAfterSubmit} />
      <FormAddXe4
        ref={refKetNoiBaiXe}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddXe5
        ref={refDanhSachKhoThe}
        onAfterSubmit={onAfterSubmit}
        toanha={toanha}
        filterCondition={filterCondition}
      />
      <FormAddKhac ref={refKhac} onAfterSubmit={onAfterSubmit} />
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormAddGas
        ref={refDinhMucGas}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        toanha={toanha}
      />
      <FormAddThangMay
        ref={refDinhMucThangMay}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        toanha={toanha}
      />
      <FormAddHĐThiCong
        ref={refTinhTrangThiCong}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      {contextHolder}
    </Box>
  );
}
