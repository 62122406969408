import axiosApi from "./api_helper";
export const ToaNhaService = {
  addToaNha: async (payload) => {
    return await axiosApi()
      .post("api/toa-nha/add", payload)
      .then((res) => res.data);
  },
  deleteToaNha: async (payload) => {
    return await axiosApi()
      .post("api/toa-nha/delete", payload)
      .then((res) => res.data);
  },
  getToaNha: async () => {
    return await axiosApi()
      .get("api/toa-nha/filter")
      .then((res) => res.data);
  },
  getToaNhaByNV: async () => {
    return await axiosApi()
      .get("api/toa-nha/filter-bynv")
      .then((res) => res.data);
  },
  getCanHoChothue: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-chothue", payload)
      .then((res) => res.data);
  },
  getDoiTuong: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-doituong", payload)
      .then((res) => res.data);
  },
  Categories: {
    getKhoiNha: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/get-khoinha", payload)
        .then((res) => res.data);
    },
    addKhoiNha: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/add-khoinha", payload)
        .then((res) => res.data);
    },
    deleteKhoiNha: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/delete-khoinha", payload)
        .then((res) => res.data);
    },
    getLMB: async (payload) => {
      return await axiosApi()
        .post("api/category/duan/get-loaimb", payload)
        .then((res) => res.data);
    },
    addLMB: async (payload) => {
      return await axiosApi()
        .post("api/category/duan/add-loaimb", payload)
        .then((res) => res.data);
    },
    deleteLMB: async (payload) => {
      return await axiosApi()
        .post("api/category/duan/delete-loaimb", payload)
        .then((res) => res.data);
    },
    getTang: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/get-tanglau", payload)
        .then((res) => res.data);
    },
    getTangByKhoiNhas: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/get-tanglaus", payload)
        .then((res) => res.data);
    },
    addTang: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/add-tanglau", payload)
        .then((res) => res.data);
    },
    AddNhieuTang: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/add-nhieutanglau", payload)
        .then((res) => res.data);
    },

    deleteTang: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/delete-tanglau", payload)
        .then((res) => res.data);
    },
  },
  DuAn: {
    getNVByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/get-nhanvientoanha", payload)
        .then((res) => res.data);
    },
    addNVByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/add-nhanvien", payload)
        .then((res) => res.data);
    },
    deleteNVByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/delete-nhanvien", payload)
        .then((res) => res.data);
    },
    getKhachHang: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/get-bymatn", payload)
        .then((res) => res.data);
    },
    getHTTTByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/get-hinhthuctt", payload)
        .then((res) => res.data);
    },
    getHotlineByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/get-hotline", payload)
        .then((res) => res.data);
    },
    getTKNHByTN: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/get-tknh", payload)
        .then((res) => res.data);
    },

    getNganHang: async (payload) => {
      return await axiosApi()
        .get("api/toa-nha/category/get-banks", payload)
        .then((res) => res.data);
    },
    getPhieuThu: async (payload) => {
      return await axiosApi()
        .get("api/toa-nha/get-mauphieuthu", payload)
        .then((res) => res.data);
    },

    getHTTT: async (payload) => {
      return await axiosApi()
        .get("api/toa-nha/category/get-hinhthuctt", payload)
        .then((res) => res.data);
    },
    deleteHotline: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/delete-hotline", payload)
        .then((res) => res.data);
    },
    deleteHTTT: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/delete-hinhthuctt", payload)
        .then((res) => res.data);
    },
    deleteTKNH: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/delete-tknh", payload)
        .then((res) => res.data);
    },
  },
};
