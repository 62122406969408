import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  Flex,
  ColorPicker,
} from "antd";
import { CustomerService } from "services/CustomerService";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import TextArea from "antd/es/input/TextArea";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";
import { NhanVienService } from "services/NhanVienService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const defaultData = { ChoThue: false };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);

  const defaultErrors = {
    // : null,
  };
  const defaultErrors2 = {
    TenTL: null,
  };
  const defaultErrors3 = {
    TenLMB: null,
  };
  const defaultErrors4 = {
    TenTT: null,
  };
  const defaultErrors5 = {
    TenKN: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [errors2, setErrors2] = useState(defaultErrors2);
  const [errors3, setErrors3] = useState(defaultErrors3);
  const [errors4, setErrors4] = useState(defaultErrors4);
  const [errors5, setErrors5] = useState(defaultErrors5);

  const loadcategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
  };
  const loadKhoiNha = async (maTN) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: maTN,
    });
    setKhoinha(_tn.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (tab, filterCondition) => {
      setOpen(true);
      loadcategories();
      setActiveTab(tab);
      loadKhoiNha(filterCondition.MaTN);
      setData((prevData) => ({
        ...prevData,
        ChoThue: false,
        MaTN: filterCondition.MaTN,
        MaKN: tab === "0" ? null : filterCondition.MaKN,
      }));
    },
    update: async (data, tab) => {
      setOpen(true);
      loadcategories();
      loadKhoiNha(data.MaTN);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setLoading(false);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaTN":
        loadKhoiNha(v);
        _data.MaKN = null;
        _data[p] = v;
        break;
      case "ChoThue":
        _data[p] = !v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (propsErrors) => {
    let _errors = _.cloneDeep(propsErrors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    switch (activeTab) {
      case "0":
        let valid = validate(errors5);
        if (valid?.length > 0) {
          setLoading(false);
          return;
        }
        break;
      case "1":
        let valid2 = validate(errors2);
        if (valid2?.length > 0) {
          setLoading(false);
          return;
        }
        break;
      case "2":
        let valid3 = validate(errors3);
        if (valid3?.length > 0) {
          setLoading(false);
          return;
        }
        break;
      case "3":
        let valid4 = validate(errors4);
        if (valid4?.length > 0) {
          setLoading(false);
          return;
        }
        setData(defaultData);
        break;
      default:
        break;
    }

    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await ToaNhaService.Categories.addKhoiNha(_data);
        break;
      case "1":
        _res = await ToaNhaService.Categories.addTang(_data);
        break;
      case "2":
        _res = await ToaNhaService.Categories.addLMB(_data);
        break;
      case "3":
        _res = await CategoryService.DuAn.MatBang.TrangThai.add(_data);
        setData(defaultData);
        break;
      case "4":
        _res = await NhanVienService.Categories.addChucVu(_data);
        break;
      case "5":
        _res = await NhanVienService.Categories.addPhongBan(_data);
        break;
      default:
        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Danh mục"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        {activeTab === "0" ? (
          <Col span={24} style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "600" }}>Tên toà nhà</p>
            <Input
              value={data?.TenKN}
              placeholder="Nhập tên danh mục"
              onChange={(e) => applyChange("TenKN", e.target.value)}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["TenKN"]}
              </p>
            )}

            <p style={{ fontWeight: "600" }}>Dự án</p>
            <Select
              style={{
                width: "100%",
              }}
              // disabled={true}
              value={data?.MaTN}
              placeholder="Chọn dự án"
              onChange={(v) => applyChange("MaTN", v)}
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
          </Col>
        ) : activeTab === "1" ? (
          <>
            <Col span={24} style={{ marginTop: "10px" }}>
              <p style={{ fontWeight: "600" }}>Tên tầng</p>
              <Input
                value={data?.TenTL}
                placeholder="Nhập tên tầng"
                onChange={(e) => applyChange("TenTL", e.target.value)}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["TenTL"]}
                </p>
              )}
              <p style={{ marginTop: 20, fontWeight: "600" }}>Dự án</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaTN}
                placeholder="Chọn dự án"
                onChange={(v) => applyChange("MaTN", v)}
                options={toanha}
                fieldNames={{ label: "TenTN", value: "MaTN" }}
              />
              <p style={{ marginTop: 20, fontWeight: "600" }}>Khối nhà</p>
              <Select
                value={data.MaKN}
                placeholder="Chọn khối nhà"
                onChange={(v) => applyChange("MaKN", v)}
                style={{
                  width: "100%",
                }}
                options={khoinha}
                fieldNames={{ label: "TenKN", value: "MaKN" }}
              />
            </Col>
          </>
        ) : activeTab === "2" ? (
          <>
            <Col span={24} style={{ marginTop: "10px" }}>
              <p style={{ fontWeight: "600" }}>Tên loại mặt bằng</p>
              <Input
                value={data?.TenLMB}
                placeholder="Nhập tên loại mặt bằng"
                onChange={(e) => applyChange("TenLMB", e.target.value)}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["TenLMB"]}
                </p>
              )}
            </Col>
          </>
        ) : activeTab === "3" ? (
          <>
            <Col span={24} style={{ marginTop: "10px" }}>
              <p style={{ fontWeight: "600" }}>Tên trạng thái</p>
              <Input
                value={data?.TenTT}
                placeholder="Nhập tên trạng thái"
                onChange={(e) => applyChange("TenTT", e.target.value)}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["TenTT"]}
                </p>
              )}
            </Col>
            <Col span={24}>
              {/* <Flex> */}
              <p
                style={{
                  fontWeight: "600",
                  // width: "20%",
                  // borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải
              </p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
              {/* </Flex> */}
            </Col>
            <Col span={12}>
              <Flex>
                <p style={{ fontWeight: "600" }}>Màu nền:</p>
                <ColorPicker
                  style={{ marginLeft: "10px", marginTop: "-12px" }}
                  value={data?.ColorWeb}
                  onChangeComplete={(color) => {
                    applyChange("ColorWeb", color.toHexString());
                  }}
                />
              </Flex>
            </Col>
            <Col span={12}>
              <Checkbox
                value={data?.ChoThue}
                checked={data?.ChoThue}
                onChange={(e) => applyChange("ChoThue", e.target.value)}
              >
                Cho thuê
              </Checkbox>
            </Col>
          </>
        ) : activeTab === "4" ? (
          <Col span={24}>
            <p style={{ fontWeight: "600", marginTop: "15px" }}>Tên chức vụ</p>
            <Input
              value={data?.TenCV}
              placeholder="Nhập tên chức vụ"
              onChange={(e) => applyChange("TenCV", e.target.value)}
            />
          </Col>
        ) : (
          <>
            <Col span={24}>
              <p style={{ fontWeight: "600", marginTop: "15px" }}>Dự án</p>
              <Select
                placeholder="Chọn dự án"
                onChange={(v) => applyChange("MaTN", v)}
                style={{
                  width: "100%",
                }}
                value={data?.MaTN}
                options={toanha}
                fieldNames={{ label: "TenTN", value: "MaTN" }}
              />
            </Col>
            <Col span={24}>
              <p style={{ fontWeight: "600" }}>Tên phòng ban</p>
              <Input
                value={data?.TenPB}
                placeholder=""
                onChange={(e) => applyChange("TenPB", e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Checkbox
                checked={data?.isHienThiCuDan}
                value={data?.isHienThiCuDan}
                onChange={(e) => applyChange("isHienThiCuDan", e.target.value)}
              >
                Hiển thị app cư dân
              </Checkbox>
            </Col>
          </>
        )}
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
