import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { KhachHangService } from "services/KhachHangService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition, filterKhachHangNew } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    IsCaNhan: true,
    Nam: false,
    Nu: false,
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData({
        ...data,
        NgayCap: data?.NgayCap,
        NgaySinh: data?.NgaySinh,
        Nam: data?.GioiTinh === true ? true : false,
        Nu: data?.GioiTinh === false ? true : false,
      });
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "Nam":
        _data[p] = !v;
        _data.Nu = false;
        _data.GioiTinh = true;
        break;
      case "Nu":
        _data[p] = !v;
        _data.Nam = false;
        _data.GioiTinh = false;
        break;
      case "IsCaNhan":
        _data[p] = !v;
        break;
      case "NgayCap":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "NgaySinh":
        const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString5;
        break;

      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await KhachHangService.KhachHang.add(_data);
    // if (!_data?.MaTN) {
    //   setData((pre) => {
    //     return { ...pre, MaTN: _res.data };
    //   });
    // }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} khách hàng thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
      if (filterKhachHangNew) filterKhachHangNew();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [staffForm, setStaffForm] = useState([]);

  const toggle = () => {
    setModal(!modal);
  };
  const handleAdd = () => {
    setStaffForm([]);
    setIsEdit(false);
    toggle();
  };
  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          {/* <Col span={24}>
            <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
              Thông tin chung
            </p>
          </Col>
          <Col span={12}>
            <InputCustom
              label="Mã số:"
              applyChange={applyChange}
              prop="KyHieu"
              value={data?.KyHieu}
              placeholder="Nhập mã số"
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["KyHieu"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <InputCustom
              label="Mã phụ:"
              applyChange={applyChange}
              prop="MaPhu"
              value={data?.MaPhu}
              placeholder="Nhập mã số"
            />
          </Col> */}
          <Col span={24}>
            <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
              Cá nhân
            </p>
          </Col>
          <Col span={12}>
            <InputCustom
              label="Ký hiệu:"
              applyChange={applyChange}
              prop="KyHieu"
              value={data?.KyHieu}
              placeholder="Nhập mã số"
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["KyHieu"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <InputCustom
              label="Họ tên KH:"
              applyChange={applyChange}
              prop="TenKH"
              value={data?.TenKH}
              placeholder="Nhập tên khách hàng"
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["TenKH"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <InputCustom
              label="Mã phụ:"
              applyChange={applyChange}
              prop="MaPhu"
              value={data?.MaPhu}
              placeholder="Nhập mã số"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Mã số thuế:"
              applyChange={applyChange}
              prop="MaSoThue"
              value={data?.MaSoThue}
              placeholder="Nhập mã số thuế"
            />
          </Col>{" "}
          <Col span={12}>
            <InputCustom
              label="Quốc tịch:"
              applyChange={applyChange}
              prop="QuocTich"
              value={data?.QuocTich}
              placeholder="Nhập quốc tịch"
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Ngày sinh:"
              applyChange={applyChange}
              prop={"NgaySinh"}
              value={data?.NgaySinh}
              placeholder={"Chọn ngày sinh"}
            />
          </Col>
          <Col span={4}>
            <Flex
              style={{ marginTop: "5px" }}
              justify="flex-end"
              align="center"
            >
              <Checkbox
                checked={data?.Nam}
                value={data?.Nam}
                onChange={(e) => applyChange("Nam", e.target.value)}
              >
                Nam
              </Checkbox>
              <Checkbox
                checked={data?.Nu}
                value={data?.Nu}
                onChange={(e) => applyChange("Nu", e.target.value)}
              >
                Nữ
              </Checkbox>
            </Flex>
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={options.ToaNha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="CCCD/Passport:"
              applyChange={applyChange}
              prop={"CMND"}
              value={data?.CMND}
              placeholder={"0"}
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Ngày cấp:"
              applyChange={applyChange}
              prop={"NgayCap"}
              value={data?.NgayCap}
              placeholder={"Chọn ngày cấp"}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Nơi cấp:"
              applyChange={applyChange}
              prop="NoiCap"
              value={data?.NoiCap}
              placeholder="Nhập nơi cấp"
            />
          </Col>
          {/* <Col span={12}>
            <InputCustom
              label="TK ngân hàng:"
              applyChange={applyChange}
              prop={"TaiKhoanNganHang"}
              value={data?.TaiKhoanNganHang}
              placeholder={"0"}
            />
          </Col> */}
          <Col span={12}>
            <InputCustom
              label="Điện thoại:"
              applyChange={applyChange}
              prop={"DienThoaiKH"}
              value={data?.DienThoaiKH}
              placeholder={"0"}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Email:"
              applyChange={applyChange}
              prop="EmailKH"
              value={data?.EmailKH}
              placeholder="Nhập email"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Điện thoại2:"
              applyChange={applyChange}
              prop={"DienThoai2"}
              value={data?.DienThoai2}
              placeholder={"0"}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Email2:"
              applyChange={applyChange}
              prop="Email2"
              value={data?.Email2}
              placeholder="Nhập email"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="D/c thường trú:"
              applyChange={applyChange}
              prop="DCTT"
              value={data?.DCTT}
              placeholder="Nhập địa chỉ thường trú"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="D/c liên lạc:"
              applyChange={applyChange}
              prop="DCLL"
              value={data?.DCLL}
              placeholder="Nhập địa chỉ thường trú"
            />
          </Col>
          <Col span={24}>
            <p style={{ fontWeight: "600", width: "13%" }}>Ghi chú:</p>
            <TextArea
              value={data?.GhiChu}
              onChange={(e) => applyChange("GhiChu", e.target.value)}
            />
          </Col>
          <Col span={24}>
            {" "}
            <Checkbox
              value={data?.IsCaNhan}
              checked={!data?.IsCaNhan}
              onChange={(e) => applyChange("IsCaNhan", e.target.value)}
            >
              Doanh ngiệp
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Khách hàng"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      />
      {contextHolder}
      {/* <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? renderThongTinChung() : null}
            </TabPane>
          );
        })}
      </Tabs> */}

      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
