import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MdPreview } from "react-icons/md";
import ViewDashboard from "./ViewDashboard";
import ViewDashboard3 from "./ViewDashboard3";
import { NhanVienService } from "services/NhanVienService";
import MauChoThue from "./MauChoThue";

const Dashboard = () => {
  const refViewDashboard = useRef(null);
  const refMauChoThue = useRef(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        let _resDB = await NhanVienService.getDashboard();
        setData(_resDB?.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  const columns = [
    {
      title: "Têm Dashboard",
      dataIndex: "Name",
      key: "Name",
    },

    {
      title: "",
      fixed: "right",
      render: (item) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip placement="topRight" title="Xem">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdPreview />}
                onClick={() =>
                  item.ID === 1
                    ? refViewDashboard.current.show(item)
                    : refMauChoThue.current.show(item)
                }
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table dataSource={data} columns={columns} pagination={false} />
      <ViewDashboard ref={refViewDashboard} />
      <MauChoThue ref={refMauChoThue} />
    </div>
  );
};
export default Dashboard;
