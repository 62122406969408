import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Table,
} from "antd";
import "dayjs/locale/vi";
import _ from "lodash";
import { YeuCauService } from "services/YeuCauService";
import dayjs from "dayjs";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";

const { TextArea } = Input;
const FormDetailConNo = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const defaultData = {};
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    _data.MaMB = _data.MatBang?.value;
    setLoading(true);
    let _res = await YeuCauService.addYeuCau(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const columns = [
    {
      title: "Mã mặt bằng",
      dataIndex: "MaSoMB",
      fixed: "left",
    },
    {
      title: "Tháng TT",
      dataIndex: "NgayTT",
      render: (item) => (item ? dayjs(item).format("MM-YYYY") : ""),
    },
    {
      title: "Tên dịch vụ",
      dataIndex: "TenDichVu",
      width: 130,
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Phải thu",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã thu",
      dataIndex: "DaThu",

      render: (item) => Format_Currency(item),
    },
    {
      title: "Còn nợ",
      dataIndex: "ConLai",

      render: (item) => Format_Currency(item),
    },
    // {
    //   title: "Loại mặt bằng",
    //   dataIndex: "LoaiMB",
    //
    //
    // },
    {
      title: "Khối nhà",
      dataIndex: "MaKN",

      //   render: (item) => khoinha.find((v) => v.MaKN === item)?.TenKN,
    },
    // {
    //   title: "tầng lầu",
    //   dataIndex: "TangLau",
    //
    // },
    // {
    //   title: "Diện tích",
    //   dataIndex: "DienTich",
    // },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
      width: 150,
    },
    // {
    //   title: "Địa chỉ",
    //   dataIndex: "DiaChi",
    //
    // },
    // {
    //   title: "Điện thoại",
    //   dataIndex: "DienThoaiKH",
    // },
    // {
    //   title: "Email/Fax",
    //   dataIndex: "Email/Fax",
    //
    // },
    // {
    //   title: "Người sửa",
    //   dataIndex: "NguoiSua",
    //
    //
    // },
    // {
    //   title: "Ngày sửa",
    //   dataIndex: "NgaySua",
    //
    //
    // }, ư
    {
      title: "Ngày sửa",
      dataIndex: "NgaySua",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },
    {
      title: "Lý do sửa",
      dataIndex: "GhiChuSua",
    },

    {
      title: "Nhân viên sửa",
      dataIndex: "MaNVS",
      //   render: (item) => nhanVien?.find((nv) => nv.MaNV === item)?.HoTenNV,
    },
    {
      title: "Duyệt hóa đơn",
      dataIndex: "IsDuyet",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayNhap",
      width: 150,
      render: (item) => (item ? dayjs(item).format("HH:mm DD-MM-YYYY") : ""),
    },
    {
      title: "Nhân viên tạo",
      dataIndex: "NguoiTao",
    },
    {
      title: "Số tháng",
      dataIndex: "SoThang",
    },

    // {
    //   title: "Tình trạng",
    //   dataIndex: "TrangThai",

    //   fixed: "right",
    //   render: (item) => (
    //     <Tag color={item === "Đã thanh toán" ? "green" : "red"}>{item}</Tag>
    //   ),
    // },
  ];
  return (
    <Drawer
      title="Thông tin chi tiết"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button> */}
        </Space>
      }
    >
      <Table
        style={{ paddingTop: "15px" }}
        loading={loading}
        dataSource={[
          {
            TotalRows: 8,
            IsCheck: false,
            STT: 1,
            MaNV: "338",
            ID: 2491145,
            MaKH: "72941",
            NgayNhap: "2024-12-31T18:42:38.633",
            NgayTT: "2025-01-10T18:42:19.623",
            key: 2491145,
            SoTien: 443556,
            DaThu: 0,
            ConLai: 443556,
            IsDuyet: "Đã duyệt",
            MaNVS: null,
            NgaySua: null,
            GhiChuSua: null,
            SoThang: null,
            MaLDV: 5,
            MaCongTo: null,
            MaDongHo: null,
            MaTheXe: null,
            DienGiaiCN: null,
            MaMB: "88995",
            MaSoMB: "B1-1001",
            MaLMB: null,
            MaTL: "5174",
            DienTich: 0,
            MaKN: "420",
            TenKH: "Lưu Thị Quỳnh Mai",
            DienThoaiKH: "0976543310",
            NguoiTao: "admin",
            TenDichVu: "Hợp đồng thuê",
          },
        ]}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          x: 1900,
        }}
        // rowSelection={{ ...rowSelection, columnWidth: 60 }}
        bordered
        pagination={false}
        size="small"
        summary={(pageData) => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>
                  <p style={{ fontWeight: "bold" }}>Tổng: {pageData?.length}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(443556)}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <p style={{ fontWeight: "bold" }}>{Format_Currency(0)}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(443556)}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      {contextHolderMS}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormDetailConNo);
