import axiosApi from "./api_helper";
export const PermissionService = {
  getMenu: async () => {
    return await axiosApi()
      .post("api/get-menu")
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      // .post("api/nhanvien/get-nhom-quyen")
      .post("api/permission/get-nhomquyen")
      .then((res) => res.data);
  },
  addNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-nhomquyen", payload)
      .then((res) => res.data);
  },
  deleteNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/delete-nhomquyen", payload)
      .then((res) => res.data);
  },
  getMenuLevel1: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-module", payload)
      .then((res) => res.data);
  },
  getForm: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-form", payload)
      .then((res) => res.data);
  },
  addAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-accessdata", payload)
      .then((res) => res.data);
  },
  getFeature: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-feature", payload)
      .then((res) => res.data);
  },
  getFeatureByForm: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-feature-byform", payload)
      .then((res) => res.data);
  },
  addFeature: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-features", payload)
      .then((res) => res.data);
  },
  updateAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/update-accessdata", payload)
      .then((res) => res.data);
  },
  addAction: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-action", payload)
      .then((res) => res.data);
  },
  deleteAction: async (payload) => {
    return await axiosApi()
      .post("api/permission/delete-action", payload)
      .then((res) => res.data);
  },
  CongTy: {
    getCT: async (payload) => {
      return await axiosApi()
        .post("api/congty-dangky/get", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/congty-dangky/permission/get-form", payload)
        .then((res) => res.data);
    },
    addCT: async (payload) => {
      return await axiosApi()
        .post("api/congty-dangky/add", payload)
        .then((res) => res.data);
    },
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/congty-dangky/permission/add-form", payload)
        .then((res) => res.data);
    },
    getMenu: async () => {
      return await axiosApi()
        .post("api/congty-dangky/get-menu")
        .then((res) => res.data);
    },
    getServer: async () => {
      return await axiosApi()
        .post("api/core/get-server")
        .then((res) => res.data);
    },
  },
  NhomModules: {
    getNhomModule: async (payload) => {
      return await axiosApi()
        .post("api/packages/get", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/packages/get-form", payload)
        .then((res) => res.data);
    },
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/packages/add-form", payload)
        .then((res) => res.data);
    },
    addNhomModule: async (payload) => {
      return await axiosApi()
        .post("api/packages/add", payload)
        .then((res) => res.data);
    },
  },
};
