import * as React from "react";
import _ from "lodash";
import { Col, Flex, Image, Row, Table } from "antd";
import { DateTime } from "luxon";
import { Format_Currency } from "layouts/ultis/common";
import { toVietnameseText } from "layouts/ultis/common";
import { toEnglishText } from "layouts/ultis/common";
const Mau1A5 = (props, ref) => {
  const { songNgu, double, dataTable, data, dataCT } = props;

  const now = DateTime.now().setZone("UTC+7");
  const formattedDate = now.toFormat("'Ngày' dd 'tháng' MM 'năm' yyyy");
  const totals = dataTable.reduce(
    (acc, item) => {
      acc.soTienTotal += item.SoTien;

      return acc;
    },
    { soTienTotal: 0 }
  );

  const amountInWordsEnglish = toEnglishText(
    totals.soTienTotal ? totals.soTienTotal : data?.SoTienTongTT
  );
  const amountInWords = toVietnameseText(
    totals.soTienTotal ? totals.soTienTotal : data?.SoTienTongTT
  );
  return (
    <>
      {double ? (
        <div ref={ref}>
          {" "}
          <div style={{ padding: "20px 50px" }}>
            <Row gutter={[20, 15]}>
              <Col span={4}>
                {/* <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM7ZcJMeXifv8jOuuOrwu7Zg-mjBkDZ9I4KA&s" /> */}
                <Image src={dataCT?.Logo} />
              </Col>
              <Col span={14}>
                <h5>{dataCT?.CongTyQuanLy}</h5>
                <p>Địa chỉ: {dataCT?.DiaChiCongTy}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "600", textAlign: "center" }}>
                  Mẫu số 01-TT
                </p>
                <p style={{ textAlign: "center" }}>
                  (Ban hành thông tư
                  <br />
                  200/1014/TT-BTC)
                </p>
              </Col>
              <Col span={7}></Col>
              <Col span={10}>
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                  <h1 style={{ fontWeight: "600", fontSize: "25px" }}>
                    PHIẾU THU TIỀN MẶT
                    <br />
                    {songNgu && "RECEIPT"}
                  </h1>
                  <p style={{ fontWeight: "500" }}>{formattedDate}</p>
                </div>
              </Col>
              <Col span={7}>
                <Row>
                  <Col span={24}>
                    {" "}
                    <Flex align="center">
                      <p style={{ fontWeight: "400" }}>
                        Quyền số {songNgu && "/Book No"}:
                      </p>{" "}
                      <p style={{ fontWeight: "400" }}></p>{" "}
                    </Flex>
                  </Col>

                  <Col span={24}>
                    <Flex align="center">
                      <p style={{ fontWeight: "400" }}>
                        Số {songNgu && "/Series"}:
                      </p>{" "}
                      <p style={{ fontWeight: "400" }}>{data?.SoHD}</p>{" "}
                    </Flex>
                  </Col>

                  <Col span={24}>
                    <Flex align="center">
                      {" "}
                      <p style={{ fontWeight: "400" }}>
                        Nợ {songNgu && "/Debit"}:
                      </p>{" "}
                      <p style={{ fontWeight: "400" }}></p>
                    </Flex>
                  </Col>
                  <Col span={24}>
                    {" "}
                    <Flex align="center">
                      <p style={{ fontWeight: "400" }}>
                        Có {songNgu && "/Credit"}:
                      </p>{" "}
                      <p style={{ fontWeight: "400" }}></p>{" "}
                    </Flex>
                  </Col>
                </Row>
              </Col>
              {/* 
        <Col span={3}>
       
       
         
        </Col>

        <Col span={4}>
      
          
        
        
        </Col> */}

              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Họ tên người nộp tiền {songNgu && "(Payer)"}:
                </p>
              </Col>
              <Col span={18}>{data?.NguoiNop}</Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Địa chỉ {songNgu && "(Address)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>{data?.DiaChi}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Lý do thu {songNgu && "(Paid for)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>{data?.DienGiai}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Số tiền {songNgu && "(Amount)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>
                  {" "}
                  {totals.soTienTotal
                    ? Format_Currency(totals.soTienTotal)
                    : Format_Currency(data?.SoTienTongTT)}{" "}
                  VNĐ
                </p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Viết bằng chữ {songNgu && "(In word)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>
                  {amountInWords}
                  {songNgu && <span>/{amountInWordsEnglish}</span>}
                </p>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: "500" }}>
                  Kèm theo {songNgu && "(Attached with)"}:
                </p>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: "500" }}>
                  Chứng từ gốc {songNgu && "(Original Document)"}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ paddingTop: "30px" }}></Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>TỔNG GIÁM ĐỐC</p>
                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    general director
                  </p>
                )}

                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên, Đóng dấu)
                </p>
                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>KẾ TOÁN TRƯỞNG</p>
                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    chief accountant
                  </p>
                )}

                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>
                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>NGƯỜI NỘP TIỀN</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Payer
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>NGƯỜI LẬP PHIẾU</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Cashier
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={4}>
                <p style={{ paddingBottom: "5px" }}>THŨ QUỸ</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Treasurer
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div style={{ padding: "20px 50px" }}>
            <Row gutter={[20, 15]}>
              <Col span={4}>
                {/* <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM7ZcJMeXifv8jOuuOrwu7Zg-mjBkDZ9I4KA&s" /> */}
                <Image src={dataCT?.Logo} />
              </Col>
              <Col span={14}>
                <h5>{dataCT?.CongTyQuanLy}</h5>
                <p>Địa chỉ: {dataCT?.DiaChiCongTy}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "600", textAlign: "center" }}>
                  Mẫu số 01-TT
                </p>
                <p>
                  (Ban hành thông tư
                  <br />
                  200/1014/TT-BTC)
                </p>
              </Col>
              <Col span={7}></Col>
              <Col span={10}>
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                  <h1 style={{ fontWeight: "600", fontSize: "25px" }}>
                    PHIẾU THU TIỀN MẶT
                    <br />
                    {songNgu && "RECEIPT"}
                  </h1>
                  <p style={{ fontWeight: "500" }}>{formattedDate}</p>
                </div>
              </Col>
              <Col span={7}>
                <Row>
                  <Col span={10}>
                    {" "}
                    <p style={{ fontWeight: "400" }}>
                      Quyền số {songNgu && "/Book No"}:
                    </p>{" "}
                  </Col>
                  <Col span={14}>
                    {" "}
                    <p style={{ fontWeight: "400" }}></p>{" "}
                  </Col>

                  <Col span={8}>
                    <p style={{ fontWeight: "400" }}>
                      Số {songNgu && "/Series"}:
                    </p>{" "}
                  </Col>
                  <Col span={16}>
                    <p style={{ fontWeight: "400" }}>{data?.SoHD}</p>{" "}
                  </Col>
                  <Col span={8}>
                    {" "}
                    <p style={{ fontWeight: "400" }}>
                      Nợ {songNgu && "/Debit"}:
                    </p>{" "}
                  </Col>
                  <Col span={16}>
                    {" "}
                    <p style={{ fontWeight: "400" }}></p>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <p style={{ fontWeight: "400" }}>
                      Có {songNgu && "/Credit"}:
                    </p>{" "}
                  </Col>

                  <Col span={16}>
                    {" "}
                    <p style={{ fontWeight: "400" }}></p>{" "}
                  </Col>
                </Row>
              </Col>
              {/* 
        <Col span={3}>
       
       
         
        </Col>

        <Col span={4}>
      
          
        
        
        </Col> */}

              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Họ tên người nộp tiền {songNgu && "(Payer)"}:
                </p>
              </Col>
              <Col span={18}>{data?.NguoiNop}</Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Địa chỉ {songNgu && "(Address)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>{data?.DiaChi}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Lý do thu {songNgu && "(Paid for)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>{data?.DienGiai}</p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Số tiền {songNgu && "(Amount)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>
                  {totals.soTienTotal
                    ? Format_Currency(totals.soTienTotal)
                    : Format_Currency(data?.SoTienTongTT)}
                  VNĐ
                </p>
              </Col>
              <Col span={6}>
                <p style={{ fontWeight: "500" }}>
                  Viết bằng chữ {songNgu && "(In word)"}:
                </p>
              </Col>
              <Col span={18}>
                <p>
                  {amountInWords}
                  {songNgu && <span>/{amountInWordsEnglish}</span>}
                </p>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: "500" }}>
                  Kèm theo {songNgu && "(Attached with)"}:
                </p>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: "500" }}>
                  Chứng từ gốc {songNgu && "(Original Document)"}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ paddingTop: "30px" }}></Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>TỔNG GIÁM ĐỐC</p>
                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    general director
                  </p>
                )}

                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên, Đóng dấu)
                </p>
                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>KẾ TOÁN TRƯỞNG</p>
                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    chief accountant
                  </p>
                )}

                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>
                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>NGƯỜI NỘP TIỀN</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Payer
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={5}>
                <p style={{ paddingBottom: "5px" }}>NGƯỜI LẬP PHIẾU</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Cashier
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
              <Col style={{ textAlign: "center" }} span={4}>
                <p style={{ paddingBottom: "5px" }}>THŨ QUỸ</p>

                {songNgu && (
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      paddingBottom: "5px",
                    }}
                  >
                    Treasurer
                  </p>
                )}
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Ký, Họ tên)
                </p>

                {songNgu && (
                  <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                    (Signature)
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div ref={ref} style={{ padding: "20px 50px" }}>
          <Row gutter={[20, 15]}>
            <Col span={4}>
              {/* <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM7ZcJMeXifv8jOuuOrwu7Zg-mjBkDZ9I4KA&s" /> */}
              <Image src={dataCT?.Logo} />
            </Col>
            <Col span={14}>
              <h5>{dataCT?.CongTyQuanLy}</h5>
              <p>Địa chỉ: {dataCT?.DiaChiCongTy}</p>
            </Col>
            <Col span={6}>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                Mẫu số 01-TT
              </p>
              <p>
                (Ban hành thông tư
                <br />
                200/1014/TT-BTC)
              </p>
            </Col>
            <Col span={7}></Col>
            <Col span={10}>
              <div style={{ textAlign: "center", marginBottom: "25px" }}>
                <h1 style={{ fontWeight: "600", fontSize: "25px" }}>
                  PHIẾU THU TIỀN MẶT
                  <br />
                  {songNgu && "RECEIPT"}
                </h1>
                <p style={{ fontWeight: "500" }}>{formattedDate}</p>
              </div>
            </Col>
            <Col span={7}>
              <Row>
                <Col span={10}>
                  {" "}
                  <p style={{ fontWeight: "400" }}>
                    Quyền số {songNgu && "/Book No"}:
                  </p>{" "}
                </Col>
                <Col span={14}>
                  {" "}
                  <p style={{ fontWeight: "400" }}></p>{" "}
                </Col>

                <Col span={8}>
                  <p style={{ fontWeight: "400" }}>
                    Số {songNgu && "/Series"}:
                  </p>{" "}
                </Col>
                <Col span={16}>
                  <p style={{ fontWeight: "400" }}>{data?.SoHD}</p>{" "}
                </Col>
                <Col span={8}>
                  {" "}
                  <p style={{ fontWeight: "400" }}>
                    Nợ {songNgu && "/Debit"}:
                  </p>{" "}
                </Col>
                <Col span={16}>
                  {" "}
                  <p style={{ fontWeight: "400" }}></p>
                </Col>
                <Col span={8}>
                  {" "}
                  <p style={{ fontWeight: "400" }}>
                    Có {songNgu && "/Credit"}:
                  </p>{" "}
                </Col>

                <Col span={16}>
                  {" "}
                  <p style={{ fontWeight: "400" }}></p>{" "}
                </Col>
              </Row>
            </Col>
            {/* 
    <Col span={3}>
   
   
     
    </Col>

    <Col span={4}>
  
      
    
    
    </Col> */}

            <Col span={6}>
              <p style={{ fontWeight: "500" }}>
                Họ tên người nộp tiền {songNgu && "(Payer)"}:
              </p>
            </Col>
            <Col span={18}>{data?.NguoiNop}</Col>
            <Col span={6}>
              <p style={{ fontWeight: "500" }}>
                Địa chỉ {songNgu && "(Address)"}:
              </p>
            </Col>
            <Col span={18}>
              <p>{data?.DiaChi}</p>
            </Col>
            <Col span={6}>
              <p style={{ fontWeight: "500" }}>
                Lý do thu {songNgu && "(Paid for)"}:
              </p>
            </Col>
            <Col span={18}>
              <p>{data?.DienGiai}</p>
            </Col>
            <Col span={6}>
              <p style={{ fontWeight: "500" }}>
                Số tiền {songNgu && "(Amount)"}:
              </p>
            </Col>
            <Col span={18}>
              <p>
                {" "}
                {totals.soTienTotal
                  ? Format_Currency(totals.soTienTotal)
                  : Format_Currency(data?.SoTienTongTT)}{" "}
                VNĐ
              </p>
            </Col>
            <Col span={6}>
              <p style={{ fontWeight: "500" }}>
                Viết bằng chữ {songNgu && "(In word)"}:
              </p>
            </Col>
            <Col span={18}>
              <p>
                {amountInWords}
                {songNgu && <span>/{amountInWordsEnglish}</span>}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ fontWeight: "500" }}>
                Kèm theo {songNgu && "(Attached with)"}:
              </p>
            </Col>
            <Col span={12}>
              <p style={{ fontWeight: "500" }}>
                Chứng từ gốc {songNgu && "(Original Document)"}
              </p>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingTop: "30px" }}></Col>
            <Col style={{ textAlign: "center" }} span={5}>
              <p style={{ paddingBottom: "5px" }}>TỔNG GIÁM ĐỐC</p>
              {songNgu && (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  general director
                </p>
              )}

              <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                (Ký, Họ tên, Đóng dấu)
              </p>
              {songNgu && (
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Signature)
                </p>
              )}
            </Col>
            <Col style={{ textAlign: "center" }} span={5}>
              <p style={{ paddingBottom: "5px" }}>KẾ TOÁN TRƯỞNG</p>
              {songNgu && (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  chief accountant
                </p>
              )}

              <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                (Ký, Họ tên)
              </p>
              {songNgu && (
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Signature)
                </p>
              )}
            </Col>
            <Col style={{ textAlign: "center" }} span={5}>
              <p style={{ paddingBottom: "5px" }}>NGƯỜI NỘP TIỀN</p>

              {songNgu && (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  Payer
                </p>
              )}
              <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                (Ký, Họ tên)
              </p>

              {songNgu && (
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Signature)
                </p>
              )}
            </Col>
            <Col style={{ textAlign: "center" }} span={5}>
              <p style={{ paddingBottom: "5px" }}>NGƯỜI LẬP PHIẾU</p>

              {songNgu && (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  Cashier
                </p>
              )}
              <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                (Ký, Họ tên)
              </p>

              {songNgu && (
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Signature)
                </p>
              )}
            </Col>
            <Col style={{ textAlign: "center" }} span={4}>
              <p style={{ paddingBottom: "5px" }}>THŨ QUỸ</p>

              {songNgu && (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  Treasurer
                </p>
              )}
              <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                (Ký, Họ tên)
              </p>

              {songNgu && (
                <p style={{ fontSize: "12px", paddingBottom: "5px" }}>
                  (Signature)
                </p>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default React.forwardRef(Mau1A5);
