import {
  Button,
  Checkbox,
  Drawer,
  Input,
  notification,
  Table,
  Tabs,
} from "antd";
import _ from "lodash";
import Column from "antd/es/table/Column";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Datetime2 } from "layouts/ultis/common";
import { DataTable } from "primereact/datatable";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { CiTrash } from "react-icons/ci";
import { DichVuService } from "services/DichVuService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
    },
  }));

  const columns = [
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },

    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
      key: "NoiDung",
    },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await DichVuService.Xe.getLichSuTX({
          MaTX: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };
  const [hotline, setHotline] = useState([]);
  const renderHotline = () => {
    const addHotline = () => {
      let _hotline = _.cloneDeep(hotline);
      _hotline.push({
        ID: null,
        tenHotline: "",
        dienThoai: "",
        ghiChu: "",
        isViewApp: true,
      });
      setHotline(_hotline);
    };

    const onChangeHotline = (field, value, index) => {
      let _hotline = _.cloneDeep(hotline);
      let item = _hotline.slice(index, index + 1);
      if (item?.length > 0) {
        _hotline.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setHotline(_hotline);
    };

    const deleteHotline = (item, index) => {
      let _hotline = _.cloneDeep(hotline);
      _hotline.splice(index, 1);
      setHotline(_hotline);
      // if (item.ID) {
      //   ToaNhaService.DuAn.deleteHotline({ ID: item.ID });
      // }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={hotline}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Tên Hotline</p>}
            body={(item, row) => (
              <Input
                value={item.tenHotline}
                onChange={(e) =>
                  onChangeHotline("tenHotline", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Di động</p>}
            body={(item, row) => (
              <Input
                value={item.dienThoai}
                onChange={(e) =>
                  onChangeHotline("dienThoai", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Ghi chú</p>}
            body={(item, row) => (
              <Input
                value={item.ghiChu}
                onChange={(e) =>
                  onChangeHotline("ghiChu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Hiển thị app cư dân</p>}
            body={(item, row) => (
              <Checkbox
                checked={item.isViewApp}
                onChange={() =>
                  onChangeHotline("isViewApp", !item.isViewApp, row.rowIndex)
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addHotline}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteHotline(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử tài sản kiểm tra"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Công việc liên quan" key="1">
            <Table
              pagination={false}
              columns={[{ title: "Tên công việc", dataIndex: "TenCongViec" }]}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="Kiến nghị" key="2">
            <Table
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
