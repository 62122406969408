import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { DateTime } from "luxon";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    defaultValue,
    setFilterCondition,
  } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
      case "LoaiDV":
      case "KhoiNha":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {}, []);

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month");
  const defaultDate = {
    Name: "Tháng này",
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
  };
  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      <FilterDate
        onChangeDate={onChangeDate}
        defaultDateProp={defaultDate}
        onOf={onOf}
      />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            mode="multiple"
            // value={filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("KhoiNha", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
          mode="multiple"
          value={filterCondition.LoaiDV.split(",").filter(Boolean).map(Number)}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("LoaiDV", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: 1, Name: "Điện" },
            { ID: 2, Name: "Nước" },
            { ID: 6, Name: "Giữ xe" },
            { ID: 12, Name: "Phí quản lý" },
            { ID: 3, Name: "Dịch vụ khác" },
            { ID: 5, Name: "Hợp đồng thuê" },
            { ID: 33, Name: "Tiền nội thất" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
        <Select
          mode="multiple"
          value={filterCondition.HinhThuc.split(",")
            .filter(Boolean)
            .map(Number)}
          placeholder="Hình thức thu tiền"
          onChange={(v) => applyChangeFilter("HinhThuc", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.HinhThucThanhToan}
          fieldNames={{ label: "TenHinhThuc", value: "ID" }}
        />

        <Select
          // mode="multiple"
          value={filterCondition.MaNV}
          placeholder="Nhân viên thu tiền"
          onChange={(v) => applyChangeFilter("MaNV", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.NhanVien}
          fieldNames={{ label: "HoTenNV", value: "MaNV" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
