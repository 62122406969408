import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit, MdEditDocument } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { CongNoService } from "services/CongNoService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { BsMotherboard } from "react-icons/bs";
import FormModal from "./FormModal";
import FormAddExcel from "./FormAddExcel";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { Format_Datetime } from "layouts/ultis/common";
import { NhanVienService } from "services/NhanVienService";
import { CategoryService } from "services/CategoryService";
import FormLapHoaDon from "../TongHop/FormLapHoaDon";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [DataSelect, setDataSelect] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const [loaiXe, setLoaiXe] = useState([]);

  const [phaiThu, setPhaiThu] = useState(0);
  const [daThu, setDaThu] = useState(0);
  const [conNo, setConNo] = useState(0);

  const currentDate = DateTime.now();

  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month").startOf("day");
  const defaultValue = {
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
    MaTN: null,
    InputSearch: "",
    PageIndex: 1,
    PageSize: 10,
    KhoiNha: ",,",
    DichVu: ",,",
    LoaiXe: ",,",
    PhaiThu: {
      Type: "Empty", //LonHonBang,BeHonBang,Bang,TuDen
      Value: 0,
      ValueTo: 0,
    },
    DaThu: {
      Type: "Empty", //LonHonBang,BeHonBang,Bang,TuDen
      Value: 0,
      ValueTo: 0,
    },
    ConNo: {
      Type: "Empty", //LonHonBang,BeHonBang,Bang,TuDen
      Value: 0,
      ValueTo: 0,
    },
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refFormModal = useRef(null);
  const refAddExcel = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });

    setNhanVien(_nv.data);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (maTN) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: maTN,
    });
    setKhoinha(_tn.data);
    const _resLX = await CategoryService.DichVu.Xe.LoaiXe.get({
      MaTN: maTN,
    });
    if (_resLX.data) {
      setLoaiXe(_resLX.data);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await CongNoService.GetHoaDonChiTiet(_filter);
    if (_res.data) {
      let DaThu = 0;
      let PhaiThu = 0;
      let ConNo = 0;

      _res.data.forEach((item) => {
        PhaiThu += item.SoTien;
        DaThu += item.DaThu;
        ConNo += item.ConLai;
      });
      setData(_res.data);

      setPhaiThu(PhaiThu);
      setDaThu(DaThu);
      setConNo(ConNo);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "Mã mặt bằng",
      dataIndex: "MaSoMB",
      fixed: "left",
    },
    {
      title: "Tháng TT",
      dataIndex: "NgayTT",
      render: (item) => (item ? dayjs(item).format("MM-YYYY") : ""),
    },
    {
      title: "Đến ngày",
      dataIndex: "NgayKT",
      render: (item) => (item ? dayjs(item).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Tên dịch vụ",
      dataIndex: "TenDichVu",
      width: 130,
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Phải thu gồm VAT",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã thu",
      dataIndex: "DaThu",

      render: (item) => Format_Currency(item),
    },
    {
      title: "Còn nợ",
      dataIndex: "ConLai",

      render: (item) => Format_Currency(item),
    },
    // {
    //   title: "Loại mặt bằng",
    //   dataIndex: "LoaiMB",
    //
    //
    // },
    {
      title: "Khối nhà",
      dataIndex: "MaKN",

      render: (item) => khoinha.find((v) => v.MaKN === item)?.TenKN,
    },
    // {
    //   title: "tầng lầu",
    //   dataIndex: "TangLau",
    //
    // },
    // {
    //   title: "Diện tích",
    //   dataIndex: "DienTich",
    // },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
      width: 150,
    },
    // {
    //   title: "Địa chỉ",
    //   dataIndex: "DiaChi",
    //
    // },
    // {
    //   title: "Điện thoại",
    //   dataIndex: "DienThoaiKH",
    // },
    // {
    //   title: "Email/Fax",
    //   dataIndex: "Email/Fax",
    //
    // },
    // {
    //   title: "Người sửa",
    //   dataIndex: "NguoiSua",
    //
    //
    // },
    // {
    //   title: "Ngày sửa",
    //   dataIndex: "NgaySua",
    //
    //
    // }, ư
    {
      title: "Ngày sửa",
      dataIndex: "NgaySua",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },
    {
      title: "Lý do sửa",
      dataIndex: "GhiChuSua",
    },

    {
      title: "Nhân viên sửa",
      dataIndex: "MaNVS",
      render: (item) => nhanVien?.find((nv) => nv.MaNV === item)?.HoTenNV,
    },
    {
      title: "Duyệt hóa đơn",
      dataIndex: "IsDuyet",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayNhap",
      width: 150,
      render: (item) => (item ? dayjs(item).format("HH:mm DD-MM-YYYY") : ""),
    },
    {
      title: "Nhân viên tạo",
      dataIndex: "NguoiTao",
    },
    {
      title: "Số tháng",
      dataIndex: "SoThang",
    },

    // {
    //   title: "Tình trạng",
    //   dataIndex: "TrangThai",

    //   fixed: "right",
    //   render: (item) => (
    //     <Tag color={item === "Đã thanh toán" ? "green" : "red"}>{item}</Tag>
    //   ),
    // },
    {
      title: "",
      fixed: "right",
      width: 110,
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refFormModal.current.update(item, "1")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa phải thu">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEditDocument />}
                onClick={() => refFormModal.current.update(item, "2")}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setDataSelect(data.filter((item) => newSelectedRowKeys.includes(item.ID)));
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    _ft.PageIndex = 1;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onChangePage = async (page, size) => {
    console.log(page, size);

    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // const exportToCSV = () => {
  //   const fieldsToExport = columns.map((col) => col.dataIndex);
  //   const filteredData = data.map((item) => {
  //     let filteredItem = {};
  //     fieldsToExport.forEach((field) => {
  //       filteredItem[field] = item[field];
  //     });
  //     return filteredItem;
  //   });
  //   const ws = XLSX.utils.json_to_sheet(filteredData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataEX = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(dataEX, "Danh sách hóa đơn chi tiết" + fileExtension);
  // };
  const exportToCSV = async () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    setLoading(true);
    let _res = await CongNoService.GetHoaDonChiTiet({
      ...filterCondition,
      PageIndex: 1,
      PageSize: 100000,
    });
    if (_res.data) setLoading(false);
    const filteredData = _res.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        // Check if the field is a date field based on `dataIndex`
        if (field === "NgayTT" && item[field]) {
          filteredItem[field] = dayjs(item[field]).format("MM-YYYY");
        } else if (field === "NgaySua" && item[field]) {
          filteredItem[field] = dayjs(item[field]).format("DD-MM-YYYY");
        } else if (field === "NgayNhap" && item[field]) {
          filteredItem[field] = dayjs(item[field]).format("HH:mm DD-MM-YYYY");
        } else {
          // Handle non-date fields directly
          filteredItem[field] = item[field];
        }
      });

      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách hóa đơn chi tiết" + fileExtension);
  };
  const handleMenuXuLyDuyet = async (e) => {
    if (selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    let _res;
    switch (e.key) {
      case "1":
        setLoading(true);
        _res = await CongNoService.HoaDonChiTiet.huyDuyetCN({
          IsDuyet: true,
          List: selectedRowKeys,
        });
        break;
      case "2":
        setLoading(true);
        _res = await CongNoService.HoaDonChiTiet.huyDuyetCN({
          IsDuyet: false,
          List: selectedRowKeys,
        });
        break;
      default:
        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${e.key === "1" ? "Duyệt thành công!" : "Không duyệt thành công!"}`
      );
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const handleMenuThaoTacKhac = async (e) => {
    if (selectedRowKeys.length < 1 && e.key === "2") {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }

    switch (e.key) {
      case "1":
        exportToCSV();
        break;
      case "3":
        refAddExcel.current.show();
        break;
      case "2":
        setLoading(true);
        const payLoadXoa = DataSelect.map((item) => ({
          ID: item.ID,
          MaSoMB: item.MaSoMB,
          TenDichVu: item.TenDichVu,
          SoTien: item.SoTien,
        }));
        let _res = await CongNoService.HoaDonChiTiet.deleteCN({
          List: payLoadXoa,
        });

        setLoading(false);
        if (_res.status === 2000) {
          openNotification("success", "topRight", `Xóa công nợ thành công!`);
          onAfterSubmit();
        } else {
          openNotification("error", "topRight", _res?.message);
        }
        break;
      default:
        break;
    }
  };

  const menuXuLyDuyet = (
    <Menu onClick={handleMenuXuLyDuyet}>
      <Menu.Item key="1">
        {" "}
        <Flex align="center">
          {" "}
          <CheckOutlined
            style={{ marginRight: "5px", fontSize: "12px", color: "green" }}
          />
          Duyệt
        </Flex>
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <Flex align="center">
          {" "}
          <CloseOutlined
            style={{ marginRight: "5px", fontSize: "12px", color: "red" }}
          />
          Không duyệt
        </Flex>
      </Menu.Item>
    </Menu>
  );
  const menuThaoTacKhac = (
    <Menu onClick={handleMenuThaoTacKhac}>
      <Menu.Item key="3">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="1">
        <ExportOutlined style={{ paddingRight: "8px", color: "" }} />
        Export
      </Menu.Item>

      <Menu.Item key="2">
        {" "}
        <Flex align="center">
          {" "}
          <CiTrash
            style={{
              marginRight: "8px",
              marginLeft: "-1px",
              fontSize: "16px",
              color: "red",
            }}
          />{" "}
          Xóa công nợ
        </Flex>
      </Menu.Item>
    </Menu>
  );
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
            LoaiXe: loaiXe,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show(data)}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menuXuLyDuyet} trigger={["click"]}>
                <Button>
                  <CheckCircleOutlined style={{ color: "green" }} />
                  Xử lý
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menuThaoTacKhac} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2000,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={3}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(phaiThu)}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(daThu)}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(conNo)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "10px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              total={data[0]?.TotalRows}
              defaultCurrent={filterCondition.PageIndex}
            />
          )}
        </div>
      </Col>
      {/* <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      /> */}
      <FormLapHoaDon
        ref={refDetail}
        filterCongNo={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <FormModal
        ref={refFormModal}
        onAfterSubmit={onAfterSubmit}
        selectedRowKeys={selectedRowKeys}
      />
      <FormAddExcel
        ref={refAddExcel}
        maTN={filterCondition.MaTN}
        onAfterSubmit={onAfterSubmit}
      />
      {contextHolder}
    </Row>
  );
}
