import axiosApi from "./api_helper";
export const CongTyService = {
  checkHanSuDung: async (payload) => {
    return await axiosApi()
      .post("api/core/check-hansudung", payload)
      .then((res) => res.data);
  },
  getCongTy: async () => {
    return await axiosApi()
      .post("api/poker/get-company")
      .then((res) => res.data);
  },
  addCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-company", payload)
      .then((res) => res.data);
  },
  updateCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-company", payload)
      .then((res) => res.data);
  },
  deleteCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-company", payload)
      .then((res) => res.data);
  },
};
