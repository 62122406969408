import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Select, Drawer, Divider, Checkbox, Row, Col } from "antd";
import _ from "lodash";
import { PermissionService } from "services/PermissionService";
import { Accordion, AccordionTab } from "primereact/accordion";

const FormAddPer = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [congtydk, setCongtydk] = useState({});
  const [menulv1, setMenulv1] = useState([]);
  const loadCategories = async (congty) => {
    let _res = await PermissionService.CongTy.getForm({
      MaCTDK: congty.MaCTDK,
      Server: congty.Server,
    });
    setData(_res?.data ?? []);
    let _res2 = await PermissionService.getMenuLevel1({
      Server: congty.Server,
    });
    setMenulv1(_res2?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setCongtydk(item);
      loadCategories(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    onAfterSubmit();
  };
  const onChangePer = async (item) => {
    if (item.Per) {
      let payload = {
        MaCTDK: congtydk.MaCTDK,
        Server: congtydk.Server,
        FormID: item.ID,
        HavePer: null,
      };
      let _res = await PermissionService.CongTy.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.ID === item.ID);
        _dt.Per = null;
        setData(_data);
      }
    } else {
      let payload = {
        MaCTDK: congtydk.MaCTDK,
        Server: congtydk.Server,
        FormID: item.ID,
        HavePer: true,
      };
      let _res = await PermissionService.CongTy.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.ID === item.ID);
        _dt.Per = item.ID;
        setData(_data);
      }
    }
  };
  return (
    <Drawer
      title="PHÂN QUYỀN CÔNG TY "
      width={620}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Accordion multiple>
        {menulv1?.map((lv1) => {
          return (
            <AccordionTab key={lv1.ModuleID} header={lv1.ModuleName}>
              {data
                ?.filter((v) => v.ModuleID === lv1.ModuleID)
                ?.map((item) => {
                  return (
                    <div key={item.FormID}>
                      <Row gutter={[20]}>
                        <Col span={24}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Checkbox
                              onChange={() => onChangePer(item)}
                              checked={item.Per}
                            />
                            <p style={{ fontWeight: "500", fontSize: "16px" }}>
                              {item.Name}
                            </p>
                          </div>
                        </Col>
                        {/* <Col span={6}>
                          <Select
                            // mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            placeholder="Quyền xem dữ liệu"
                            onChange={(v) => onSelectDuLieu(v, item)}
                            fieldNames={{ label: "Name", value: "ID" }}
                            value={item.sdbid}
                            maxTagCount="responsive"
                            options={[
                              { ID: 1, Name: "Xem tất cả" },
                              { ID: 2, Name: "Theo chi nhánh" },
                              { ID: 3, Name: "Theo nhân viên" },
                            ]}
                          />
                        </Col>
                        <Col span={10}>
                          <Select
                            mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            placeholder="Chọn action"
                            onChange={(v) => handleChange(v, item)}
                            fieldNames={{ label: "name", value: "id" }}
                            value={_.map(
                              item.actions.filter((v) => v.isCheck),
                              "id"
                            )}
                            maxTagCount="responsive"
                            options={item.actions}
                          />
                        </Col> */}
                      </Row>

                      <Divider dashed style={{ margin: "8px 0px" }} />
                    </div>
                  );
                })}
            </AccordionTab>
          );
        })}
      </Accordion>
    </Drawer>
  );
};
export default forwardRef(FormAddPer);
