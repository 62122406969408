import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
  Input,
  Pagination,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
} from "react-icons/md";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import "../scss/index.scss";
import { OtherService } from "services/OtherService";
import { Editor } from "primereact/editor";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FormAddExcel from "./FormAddExcel";
import { DateTime } from "luxon";
import { KhachHangService } from "services/KhachHangService";
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { AiOutlineMenuFold } from "react-icons/ai";

export default function Index() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const refAddExcel = useRef(null);
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultValue = {
    TuNgay: dateInHoChiMinh.toISO(),
    DenNgay: dateInHoChiMinh.toISO(),
    MaTN: 1,
    PageIndex: 1,
    PageSize: 10,
    InputSearch: "",
    KhoiNha: ",,",
  }
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const [mucdo, setMucdo] = useState([]);
  const [hinhthuc, setHinhthuc] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [trangthai, setTrangthai] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );

  useEffect(() => {
    loadCategories();
    // load(filterCondition);
  }, []);

  const load = async (_filter) => {
    setLoading(true);
    let _res = await KhachHangService.CuDan.get(_filter);
    // let _tt = await YeuCauService.Categories.getTrangThai({
    //   MaTN: _filter.MaTN,
    // });
    // let status = [
    //   { MaTT: 0, TenTT: "Tất cả", Color: "#cccccc" },
    //   ..._tt.data?.filter((v) => v.isViewApp),
    // ];
    // status.map((item) => {
    //   if (item.MaTT === 0) {
    //     item.Total = _res.data?.length;
    //   } else {
    //     item.Total = _res?.data?.filter((v) => v.MaTT === item.MaTT)?.length;
    //   }
    // });
    // setTrangthai(status);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _ht = await YeuCauService.Categories.getHinhThuc();
    setHinhthuc(_ht.data);
    let _md = await YeuCauService.Categories.getMucDo();
    setMucdo(_md.data);
    let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
    setNhanvien(_nv.data);
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    load(_filter);
  };

  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  const columns = [
    {
      title: "Ngày ĐK",
      dataIndex: "NgayDK",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Mã thẻ CD",
      dataIndex: "MaTheCuDan",
      // ellipsis: true,
    },
    {
      title: "Mã thẻ RV",
      dataIndex: "MaTheRaVao",
      // ellipsis: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTrangThai",
      // ellipsis: true,
    },
    {
      title: "Họ tên",
      dataIndex: "HoTenNK",
      // ellipsis: true,
    },
    {
      title: "Quan hệ",
      dataIndex: "QuanHeID",
      // ellipsis: true,
    },
    {
      title: "Giới tính",
      dataIndex: "GioiTinh",
      // ellipsis: true,
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Số CCCD",
      dataIndex: "CMND",
      // ellipsis: true,
    },
    {
      title: "Quốc tịch",
      dataIndex: "QuocTich",
      // ellipsis: true,
    },
    {
      title: "Dân tộc",
      dataIndex: "DanToc",
      // ellipsis: true,
    },
    {
      title: "Tôn giáo",
      dataIndex: "TonGiao",
      // ellipsis: true,
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      // ellipsis: true,
    },
    {
      title: "Chủ hộ",
      dataIndex: "TenKH",
      // ellipsis: true,
    },
    {
      title: "Hộ khẩu TT",
      dataIndex: "DCTT",
      // ellipsis: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoai",
      // ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "Email",
      // ellipsis: true,
    },
    // {
    //   title: "ĐKTT",
    //   dataIndex: "IsCheck",
    // ellipsis: true,
    //   render: (item) => <Checkbox checked={item}></Checkbox>,
    // },
    {
      title: "Ngày còn ĐKTT",
      dataIndex: "NgayConDKTT",
      width: 120,
      // ellipsis: true,
    },
    {
      title: "Ngày HH ĐKTT",
      dataIndex: "NgayHetHanDKTT",
      // ellipsis: true,
      width: 120,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      // ellipsis: true,
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      // ellipsis: true,
    },
    {
      title: "Ngày chuyển đến",
      dataIndex: "NgayChuyenDen",
      // ellipsis: true,
      width: 130,
    },
    {
      title: "Ngày chuyển đi",
      dataIndex: "NgayChuyenDi",
      // ellipsis: true,
      width: 120,
    },
    {
      title: "Ngày còn lại",
      dataIndex: "NgayConLai",
      // ellipsis: true,
    },
    {
      title: "Ngày ĐKTT cũ",
      dataIndex: "NgayDKTTCu",
      width: 120,
      // ellipsis: true,
    },
    {
      title: "Ngày ĐKTT mới",
      dataIndex: "NgayDKTTMoi",
      // ellipsis: true,
      width: 120,
    },
    {
      title: "Đăng ký tạm trú",
      dataIndex: "DaDKTT",
      // ellipsis: true,
      width: 120,
      render: (item) => <Checkbox checked={item}></Checkbox>,
    },
    {
      title: "Đăng ký ĐM",
      dataIndex: "DangKyDinhMuc",

      // ellipsis: true,
      render: (item) => <Checkbox checked={item}></Checkbox>,
    },
    {
      title: "Nhận SMS",
      dataIndex: "NhanSMS",
      // ellipsis: true,
      render: (item) => <Checkbox checked={item}></Checkbox>,
    },
    {
      title: "Nhận email",
      dataIndex: "NhanEmail",
      // ellipsis: true,
      render: (item) => <Checkbox checked={item}></Checkbox>,
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={10} justify="center">
            {/* <Tooltip placement="topRight" title="Gửi thông báo">
              <Button
                type="link"
                icon={<MdNotificationsActive size={18} />}
                onClick={() => sendThongBao(item)}
              />
            </Tooltip> */}
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="link"
                icon={<MdEdit size={18} color="" />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá yêu cầu"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Button type="text" danger icon={<CiTrash size={18} />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const remove = async (item) => {
    let _res = await KhachHangService.CuDan.delete({
      ID: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const sendThongBao = async (item) => {
    setLoading(true);
    let _res = await OtherService.sendThongBao({
      TieuDe: item.TieuDe,
      NoiDung: item.DienGiai,
      FormID: 1172,
      LinkID: item.MaTB,
      MatBang: item?.MatBang?.split(",")?.map(Number),
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Gửi thông báo thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_filter.MaTN);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách cư dân" + fileExtension);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) load(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    load(_ft);
  };
  
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
         <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
               <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              {trangthai?.map((item) => {
                return (
                  <Badge
                    key={item.MaTT}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.MaTT);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.MaTT === item.MaTT ? item.Color : null,
                        color: stateActive.MaTT === item.MaTT ? "white" : null,
                      }}
                    >
                      <p>{item.TenTT}</p>
                    </div>
                  </Badge>
                );
              })}
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                type="primary"
                onClick={() => refAdd.current.show(filterCondition?.MaTN)}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 3200,
          }}
          size="small"
        />
        <div style={{ textAlign: "right", paddingTop: "10px" }}>
          <Pagination onChange={onChangePage} total={data[0]?.TotalRows} />
        </div>
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{
          PhongBan: phongban,
          NhanVien: nhanvien,
          MucDo: mucdo,
          HinhThuc: hinhthuc,
          TrangThai: trangthai,
          ToaNha: toanha,
        }}
        filterCondition={filterCondition}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{
          NhanVien: nhanvien,
          TrangThai: trangthai,
        }}
      />
      <FormAddExcel ref={refAddExcel} />
      {contextHolder}
      <div style={{ display: "none" }}>
        <Editor style={{ height: 0 }} />
      </div>
    </Row>
  );
}
