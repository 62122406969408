// import React, { useMemo } from "react";
// import { Col, Tooltip } from "antd";
// import dayjs from "dayjs";

// const TableThueCanHo = React.memo(
//   ({ ThoiGianThue, dateArray, soCot, refDetail, data }) => {
//     const thoiGianRanges = useMemo(() => {
//       return ThoiGianThue?.map(({ NgayHL, NgayKT }) => ({
//         ngayHL: NgayHL ? dayjs(NgayHL) : null,
//         ngayKT: NgayKT ? dayjs(NgayKT) : null,
//       }));
//     }, [ThoiGianThue]);

//     // Combine rendering of both dateArray and thoiGianRanges
//     const renderCells = useMemo(() => {
//       return dateArray.map((item, index) => {
//         const itemDate = dayjs(item);
//         const isInRange = thoiGianRanges?.some(
//           ({ ngayHL, ngayKT }) =>
//             ngayHL &&
//             ngayKT &&
//             itemDate.isAfter(ngayHL.subtract(1, "day"), "day") &&
//             itemDate.isBefore(ngayKT.add(1, "day"), "day")
//         );

//         return (
//           <Col
//             key={index}
//             style={{
//               flex: `1 1 calc(100% / ${soCot})`,
//               // border: "1px solid #d9d9d9",

//               textAlign: "center",
//               // borderRight: "1px solid #d9d9d9",
//               // borderLeft: "1px solid #d9d9d9",
//             }}
//           >
//             <Tooltip
//               placement="top"
//               title={isInRange ? "Căn hộ đang được thuê" : "Thuê căn hộ"}
//             >
//               <p
//                 style={{
//                   fontWeight: 600,
//                   padding: "10px 0",
//                   minWidth: "70px",
//                   backgroundColor: isInRange ? "rgb(128, 197, 246)" : "white",
//                   // borderBottom: "1px solid #d9d9d9",
//                   height: "22px",
//                   borderBottom: "1px solid #d9d9d9",
//                   borderRight: "1px solid #d9d9d9",
//                 }}
//                 onClick={() =>
//                   isInRange
//                     ? refDetail.current.update(data)
//                     : refDetail.current.show(item, data?.MaMB)
//                 }
//               ></p>
//             </Tooltip>
//           </Col>
//         );
//       });
//     }, [dateArray, thoiGianRanges, refDetail, data?.MaMB, soCot]);

//     return <div style={{ display: "flex" }}>{renderCells}</div>;
//   }
// );

// export default TableThueCanHo;
import React, { useEffect, useState } from "react";
import { Col, Tooltip } from "antd";

const TableThueCanHo = React.memo(
  ({ ThoiGianThue, dateArray, soCot, refDetail, data }) => {
    const [renderData, setRenderData] = useState([]);

    useEffect(() => {
      const worker = new Worker(
        new URL("./dateRangeWorker.js", import.meta.url)
      );

      worker.postMessage({ ThoiGianThue, dateArray });

      worker.onmessage = (e) => {
        setRenderData(e.data);
      };

      // Cleanup worker khi component unmount
      return () => {
        worker.terminate();
      };
    }, [ThoiGianThue, dateArray]);

    return (
      <div style={{ display: "flex" }}>
        {renderData.map((item, index) => (
          <Col
            key={index}
            style={{
              flex: `1 1 calc(100% / ${soCot})`,
              textAlign: "center",
            }}
          >
            <Tooltip
              placement="top"
              title={item?.isInRange ? "Căn hộ đang được thuê" : "Thuê căn hộ"}
            >
              <p
                style={{
                  fontWeight: 600,
                  padding: "10px 0",
                  minWidth: "70px",
                  backgroundColor: item?.isInRange
                    ? "rgb(128, 197, 246)"
                    : "white",
                  height: "22px",
                  borderBottom: "1px solid #d9d9d9",
                  borderRight: "1px solid #d9d9d9",
                }}
                onClick={() =>
                  item?.isInRange
                    ? refDetail.current.update(data)
                    : refDetail.current.show(item?.date, data?.MaMB)
                }
              ></p>
            </Tooltip>
          </Col>
        ))}
      </div>
    );
  }
);

export default TableThueCanHo;
