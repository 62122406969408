import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [congTo, setCongTo] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tangLau, setTangLau] = useState([]);
  const [loaiMB, setLoaiMB] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [doiTuong, setDoiTuong] = useState([]);
  const defaultErrors = {
    MaSoMB: null,
    MaKN: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadTangLau = async (makn, matn) => {
    let _res = await ToaNhaService.Categories.getTangByKhoiNhas({
      KhoiNha: "," + makn + ",",
      MaTN: matn,
    });
    setTangLau(_res.data);
  };

  useImperativeHandle(ref, () => ({
    show: async () => {
      const _filter = _.cloneDeep(filterCondition);
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      if (_filter.MaTN && _filter.MaKN) {
        loadTangLau(_filter.MaKN, _filter.MaTN);
      }
      let _loaiMB = await ToaNhaService.Categories.getLMB(_filter);
      if (_loaiMB?.data) setLoaiMB(_loaiMB?.data);
      let _resDoiTuong = await ToaNhaService.getDoiTuong(_filter);
      if (_resDoiTuong?.data) setDoiTuong(_resDoiTuong?.data);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      console.log(data);

      const _filter = _.cloneDeep(filterCondition);
      loadTangLau(data?.MaKN, data?.MaTN);
      let _loaiMB = await ToaNhaService.Categories.getLMB(_filter);
      if (_loaiMB?.data) setLoaiMB(_loaiMB?.data);
      let _resDoiTuong = await ToaNhaService.getDoiTuong(_filter);
      if (_resDoiTuong?.data) setDoiTuong(_resDoiTuong?.data);
      setData(() => ({
        ...data,
        DienTichThuPhi: data?.DienTichChoThue,
        SoTienPerMet: data?.GiaChoThue,
      }));
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const filterByMatBang = async (_data) => {
    let _res = await DichVuService.Dien.getChiSoCu({
      MaMB: _data?.MaMB,
      MaCongTo: _data?.MaCongTo,
    });
    if (_res.data) {
      _data.ChiSoCu = _res.data?.ChiSoCu;
    }
    let _resVAT = await DichVuService.Dien.getVAT({
      MaKN: _data?.MaKN,
    });
    if (_resVAT.data) {
      _data.VAT = _resVAT.data;
    } else {
      _data.VAT = 0;
    }
  };

  // const filterTienDien = async (_data) => {
  //   let _res = await DichVuService.Dien.getTienDien({
  //     MaMB: _data?.MaMB,
  //     SoTieuThu: _data?.SoTieuThu,
  //     MaTN: _data?.MaTN,
  //     MaLMB: _data?.MaLMB,
  //     NgayNhap: _data?.NgayNhap,
  //   });

  //   if (_res.status === 2000) {
  //     setData((prevData) => {
  //       const updatedData = {
  //         ...prevData,
  //         TienDien: _res.data?.tienDien,
  //         ThueVAT: (_res.data?.tienDien * (prevData?.VAT ?? 0)) / 100,
  //         TienHaoHut: (_res.data?.tienDien * (prevData?.HaoHut ?? 0)) / 100,
  //       };
  //       sumTongTien(updatedData);
  //       return updatedData;
  //     });
  //   }
  // };
  const sumTongTien = (_data) => {
    _data.TongTien =
      (_data?.TienDien ?? 0) +
      (_data?.ThueVAT ?? 0) +
      (_data?.TienHaoHut ?? 0) -
      (_data?.NoTruoc ?? 0);
  };

  // useEffect(() => {
  //   let time = null;
  //   if (!time) {
  //     time = setTimeout(() => {
  //       if (data) filterTienDien(data);
  //     }, 500);
  //   }
  //   return () => clearTimeout(time);
  // }, [data?.ChiSoMoi, data?.NgayNhap, data?.MatBang]);

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKN":
        loadTangLau(v, filterCondition.MaTN);
        _data[p] = v;
        _data.MaTL = null;
        break;

      default:
        _data[p] = v;
        break;
    }

    // Call sumTongTien with the modified _data before updating the state
    sumTongTien(_data);

    // Update the state
    setData(_data);

    // Optionally validate data
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    const payload = {
      isChoThue: true,
      ..._data,
      MaTN: filterCondition?.MaTN,
    };
    let _res = await MatBangService.add(payload);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} căn hộ cho thuê!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const tabs = [
    {
      title: "Thông tin",
      key: "0",
    },
    {
      title: "Cài đặt nâng cao",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  // const [modal, setModal] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [staffForm, setStaffForm] = useState([]);

  // const toggle = () => {
  //   setModal(!modal);
  // };
  // const handleAdd = () => {
  //   setStaffForm([]);
  //   setIsEdit(false);
  //   toggle();
  // };
  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={24}>
            <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
              Thông tin chung
            </p>
          </Col>
          <Col span={12}>
            <InputCustom
              required={true}
              label="Mã số MB:"
              applyChange={applyChange}
              prop="MaSoMB"
              value={data?.MaSoMB}
              placeholder="Nhập mã số mặt bằng"
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              // required={true}
              label="Diện tích:"
              applyChange={applyChange}
              prop={"DienTichThuPhi"}
              value={data?.DienTichThuPhi}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              required={true}
              label="Khối nhà:"
              applyChange={applyChange}
              prop="MaKN"
              value={data?.MaKN}
              placeholder="Chọn khối nhà"
              options={options.KhoiNha}
              fieldNames={{ label: "TenKN", value: "MaKN" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              // required={true}
              label="Giá cho thuê:"
              applyChange={applyChange}
              prop={"SoTienPerMet"}
              value={data?.SoTienPerMet}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Tầng(lầu):"
              applyChange={applyChange}
              prop="MaTL"
              value={data?.MaTL}
              placeholder="Chọn tầng"
              options={tangLau}
              fieldNames={{ label: "TenTL", value: "MaTL" }}
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Trạng thái:"
              applyChange={applyChange}
              prop="MaTT"
              value={data?.MaTT}
              placeholder="Chọn trạng thái"
              options={congTo}
              fieldNames={{ label: "MaTT", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Loại MB:"
              applyChange={applyChange}
              prop="MaLMB"
              value={data?.MaLMB}
              placeholder="Chọn dự án"
              options={loaiMB}
              fieldNames={{ label: "TenLMB", value: "MaLMB" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Đối tượng sử dụng:"
              applyChange={applyChange}
              prop="MaDoiTuong"
              value={data?.MaDoiTuong}
              placeholder="Chọn đối tượng sử dụng"
              options={doiTuong}
              fieldNames={{ label: "Name", value: "ID" }}
            />
          </Col>
          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải:
              </p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={
        "Thêm: căn hộ cho thuê - " +
        options?.ToaNha?.find((item) => item?.MaTN === filterCondition.MaTN)
          ?.TenTN
      }
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
