import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import FormDetail from "./FormDetail";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { CongNoService } from "services/CongNoService";
import { SoQuyService } from "services/SoQuyService";
import FormAddExcel from "./FormAddExcel";
import {
  DeleteOutlined,
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  ImportOutlined,
  KeyOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import MauChiTiet from "./FormPrint/MauChiTiet";
import { useReactToPrint } from "react-to-print";
import Mau1A5 from "./FormPrint/Mau1A5";
import { BsMotherboard } from "react-icons/bs";
import { DateTime } from "luxon";
import { IoIosDocument } from "react-icons/io";
import { NhanVienService } from "services/NhanVienService";
import FormEdit from "./FormEdit";
import { Format_Datetime } from "layouts/ultis/common";
import { BiReset } from "react-icons/bi";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import MauDichVu from "./FormPrint/MauDichVu";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const componentRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);

  const [dataPrint, setDataPrint] = useState({});
  const [loading, setLoading] = useState(false);
  const [onOff, setOnOff] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [activeMauPT, setActiveMauPT] = useState("0");
  const [mauPT, setMauPT] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const [hinhThucTT, setHinhThucTT] = useState([]);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month").startOf("day");
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const defaultValue = {
    MaTN: null,
    InputSearch: "",
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
    MaNV: null,
    HinhThuc: ",,",
    PageIndex: 1,
    PageSize: 10000,
  };
  const [dataResetSP, setDataResetSP] = useState({
    NgayReset: dateInHoChiMinh.toISO(),
    IsChuyenKhoan: false,
  });
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refDetailView = useRef(null);
  const refAddExcel = useRef(null);
  const refEdit = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    let _resNV = await NhanVienService.getNhanVien({ InputSearch: "" });
    if (_resNV.data) setNhanVien(_resNV.data);

    const _resPT = await ToaNhaService.DuAn.getPhieuThu({});
    if (_resPT.data) {
      setMauPT(_resPT.data);
    }
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    // let _tn = await ToaNhaService.Categories.getKhoiNha({
    //   MaTN: matn,
    // });
    let _resHTTT = await SoQuyService.PhieuThu.getHinhThucTT({ MaTN: matn });
    if (_resHTTT.data) setHinhThucTT(_resHTTT.data);
    // setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      width: 150,
      // ellipsis: true,
      render: (item, record) => (
        <p
          style={{ fontWeight: "bold", color: "#1890ff", cursor: "pointer" }}
          onClick={() => refDetailView.current.show(record.MaPhieu)}
        >
          {record.SoPhieu}
        </p>
      ),
    },
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      // ellipsis: true,
    },
    {
      title: "Số tiền",
      dataIndex: "SoTienThanhToan",
      // ellipsis: true,
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Tổng tiền CT",
      dataIndex: "SoTienTongTT",
      // ellipsis: true,
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Hình thức thu tiền",
      dataIndex: "HinhThuc",
      width: 150,
      // ellipsis: true,
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
      width: 150,
      // ellipsis: true,
    },
    {
      title: "Người thu",
      dataIndex: "NguoiThu",
      // ellipsis: true,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "KyHieu",
      width: 120,
      // ellipsis: true,
    },

    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      width: 200,
      // ellipsis: true,
    },

    {
      title: "Số tài khoản",
      dataIndex: "SoTaiKhoan",
      // ellipsis: true,
    },
    {
      title: "Đợt TT",
      dataIndex: "DotThanhToan",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Đã duyệt",
      dataIndex: "KeToanDaDuyet",
      render: (text, record) => (
        <Checkbox checked={record?.KeToanDaDuyet}></Checkbox>
      ),
    },
    {
      title: "Ngày lập PT",
      dataIndex: "NgayNhap",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },
    {
      title: "Nhân viên sữa",
      dataIndex: "NhanVienSua",
    },
    {
      title: "Ngày sữa",
      dataIndex: "NgayCN",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },
    {
      title: "Số tháng",
      dataIndex: "SoThangThuPhiQuanLy",
    },
    {
      title: "Loại phiếu thu",
      dataIndex: "LoaiPhieuThu",
      // ellipsis: true,
    },
    {
      title: "Thu Thừa",
      dataIndex: "ThuThua",
      // ellipsis: true,
    },

    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      width: 200,
      // ellipsis: true,
    },
    {
      title: "",
      fixed: "right",
      width: 100,
      render: (item) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "3px",
          }}
        >
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refEdit.current.update(item)}
            />
          </Tooltip>
          <Tooltip placement="topRight" title="In phiếu thu">
            <Button
              type="primary"
              ghost
              icon={<PrinterOutlined />}
              onClick={async () => {
                await setDataPrint(item);
                handlePrint();
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách phiếu thu" + fileExtension);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOk = async () => {
    let _data = _.cloneDeep(dataResetSP);
    setLoading(true);
    let _res = await SoQuyService.PhieuThu.reset({
      ..._data,
      MaTN: filterCondition?.MaTN,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Reset số phiếu thành công!`);
      setIsModalVisible(false);
      setDataResetSP({
        NgayReset: dateInHoChiMinh.toISO(),
        IsChuyenKhoan: false,
      });
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleMenuClick = async (e) => {
    if (
      e.key !== "1" &&
      e.key !== "2" &&
      e.key !== "4" &&
      selectedRowKeys.length < 1
    ) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        let _res = await SoQuyService.PhieuThu.deletePhieuThu({
          MaTN: filterCondition?.MaTN,
          PhieuThu: selectedRowKeys,
        });
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data);
          let fil = _data.filter((v) => !selectedRowKeys.includes(v.MaPhieu));
          setData(fil);
          openNotification("success", "topRight", "Xoá thành công");
        } else {
          openNotification("error", "topRight", _res?.message);
        }
        break;
      case "4":
        setIsModalVisible(true);

        break;
      default:
        break;
    }
  };
  const handleMauPT = async (e) => {
    setActiveMauPT(e.key);
  };

  const menuMauPT = (
    <Menu onClick={handleMauPT}>
      {mauPT?.map((item, index) => {
        return (
          <Menu.Item
            style={{
              backgroundColor:
                activeMauPT === index.toString() ? "#e2e8f0" : "",
              color: activeMauPT === index.toString() ? "#1677FF" : "",
            }}
            key={index}
          >
            {item?.TenMauPT}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const menu2 = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
      <Menu.Item key="3">
        <DeleteOutlined style={{ paddingRight: "5px" }} />
        Xóa phiếu thu
      </Menu.Item>
      <Menu.Item key="4">
        <Flex align="center">
          <BiReset style={{ marginRight: "5px" }} />
          Reset số phiếu
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(dataResetSP);
    switch (p) {
      case "DotThanhToan":
        break;

      default:
        _data[p] = v;
        break;
    }
    setDataResetSP(_data);
  };
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
            NhanVien: nhanVien,
            HinhThucThanhToan: hinhThucTT,
          }}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              {/* {selectedRowKeys?.length > 0 && (
                <Button
                  loading={loading}
                  type="primary"
                  ghost
                  onClick={() => createAccount()}
                >
                  Tạo tài khoản MSB ({selectedRowKeys?.length})
                </Button>
              )} */}
              {/* <Button
                onClick={() => {
                  setOnOff(!onOff);
                  handlePrint();
                }}
              >
                Xem mẫu
              </Button> */}
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>

              <Dropdown overlay={menuMauPT} trigger={["click"]}>
                <Button
                  loading={loading}
                  icon={
                    <IoIosDocument
                      style={{ margin: "0px -5px", marginBottom: "-2px" }}
                    />
                  }
                >
                  Mẫu in
                </Button>
              </Dropdown>

              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 2500,
          }}
          // rowSelection={rowSelection}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: ["20", "50", "100", "500", "10000"],
            position: ["bottomRight"],
          }}
          virtual={true}
          size="small"
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={2}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {Format_Currency(
                        _.sum(_.map(pageData, "SoTienThanhToan"))
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {Format_Currency(_.sum(_.map(pageData, "SoTienTongTT")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <FormEdit
        ref={refEdit}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <FormDetail ref={refDetailView} onAfterSubmit={onAfterSubmit} />
      <FormAddExcel ref={refAddExcel} />

      {contextHolder}

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {(() => {
            switch (activeMauPT) {
              case "0":
                return (
                  <Mau1A5
                    songNgu={false}
                    double={false}
                    data={{ ...dataPrint, SoHD: "hn0808" }}
                    dataTable={[]}
                    dataCT={toanha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                  // <MauDichVu />
                );
              case "1":
                return (
                  <Mau1A5
                    songNgu={true}
                    double={false}
                    data={{ ...dataPrint, SoHD: "hn0808" }}
                    dataTable={[]}
                    dataCT={toanha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case "2":
                return (
                  <Mau1A5
                    songNgu={false}
                    double={true}
                    data={{ ...dataPrint, SoHD: "hn0808" }}
                    dataTable={[]}
                    dataCT={toanha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case "3":
                return (
                  <Mau1A5
                    songNgu={true}
                    double={true}
                    data={{ ...dataPrint, SoHD: "hn0808" }}
                    dataTable={[]}
                    dataCT={toanha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case "4":
              case "5":
              default:
                return (
                  <MauChiTiet
                    data={{ ...dataPrint, SoHD: "hn0808" }}
                    dataTable={[]}
                    dataCT={toanha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
            }
          })()}
        </div>
      </div>
      <Modal
        title="Reset số phiếu"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row gutter={[20, 12]}>
          <Col span={24}>
            <DatePickerCustom
              label="Tháng:"
              applyChange={applyChange}
              prop={"NgayReset"}
              value={dataResetSP?.NgayReset}
            />
          </Col>
          <Col span={24}>
            <InputNumberCustom
              label="Số lượng phiếu:"
              applyChange={applyChange}
              prop={"SoLuongPhieu"}
              value={dataResetSP?.SoLuongPhieu}
            />
          </Col>
          <Col span={8}>
            <Checkbox
              onChange={(e) => applyChange("IsChuyenKhoan", !e.target.value)}
              checked={dataResetSP?.IsChuyenKhoan}
              value={dataResetSP?.IsChuyenKhoan}
            >
              Chuyển khoản
            </Checkbox>
          </Col>
        </Row>
      </Modal>
      <MauDichVu />
    </Row>
  );
}
