import axiosApi from "./api_helper";

export const DichVuService = {
  Xe: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get", payload)
        .then((res) => res.data);
    },
    getByID: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-byid", payload)
        .then((res) => res.data);
    },

    getMBByID: async (payload) => {
      return await axiosApi()
        .post("api/mat-bang/get-byid", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/add", payload)
        .then((res) => res.data);
    },
    getPhiGiuiXe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-phiguixe", payload)
        .then((res) => res.data);
    },
    getNgayHetHan: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-ngay", payload)
        .then((res) => res.data);
    },
    getThongTinTheoMaMB: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-info-bymamb", payload)
        .then((res) => res.data);
    },
    getTheXe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-bymamb", payload)
        .then((res) => res.data);
    },
    getTheChuaSuDung: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-card-empty", payload)
        .then((res) => res.data);
    },
    getNhomTheBaiXe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-baixe", payload)
        .then((res) => res.data);
    },
    getLichSuTX: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/get-lichsuxuly", payload)
        .then((res) => res.data);
    },

    addCongNoByTN: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/create-congno-bytn", payload)
        .then((res) => res.data);
    },
    addCongNoByMB: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/create-congno-bymb", payload)
        .then((res) => res.data);
    },

    addCongNoByTX: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/create-congno-bytx", payload)
        .then((res) => res.data);
    },
    addKhoaThe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/lock-thexe", payload)
        .then((res) => res.data);
    },
    addPhieuHuy: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/tao-phieuhuy", payload)
        .then((res) => res.data);
    },
    addDonGia: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/update-dongia", payload)
        .then((res) => res.data);
    },
    addMaThe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/update-mathe", payload)
        .then((res) => res.data);
    },
    addNgayHH: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/update-ngayhh", payload)
        .then((res) => res.data);
    },
    addMatBang: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/update-matbang", payload)
        .then((res) => res.data);
    },
    importTX: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/import", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/xe/delete", payload)
        .then((res) => res.data);
    },
  },
  DichVuKhac: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/add", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/delete", payload)
        .then((res) => res.data);
    },

    addCongNoByTN: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/create-congno-bytn", payload)
        .then((res) => res.data);
    },
    addCongNoByMB: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/create-congno-bymb", payload)
        .then((res) => res.data);
    },

    addCongNoByDV: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/create-congno-bydv", payload)
        .then((res) => res.data);
    },
    khoaMoThe: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/lock-unlock", payload)
        .then((res) => res.data);
    },
    importExcel: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/khac/import", payload)
        .then((res) => res.data);
    },
  },
  Dien: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/add", payload)
        .then((res) => res.data);
    },
    getCongTo: async (payload) => {
      return await axiosApi()
        .post("api/category/dien/get-congto-bymakh", payload)
        .then((res) => res.data);
    },
    getChiSoCu: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/get-chisocu", payload)
        .then((res) => res.data);
    },
    getVAT: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/get-vat", payload)
        .then((res) => res.data);
    },
    getTienDien: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/get-tiendien", payload)
        .then((res) => res.data);
    },
    import: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/dien/import", payload)
        .then((res) => res.data);
    },
  },
  Nuoc: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/add", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/delete", payload)
        .then((res) => res.data);
    },
    getDongHo: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/get-dongho", payload)
        .then((res) => res.data);
    },
    getChiSoNuoc: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/get-chisocu", payload)
        .then((res) => res.data);
    },
    getTienNuoc: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/get-tiennuoc", payload)
        .then((res) => res.data);
    },
    importExcel: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/import", payload)
        .then((res) => res.data);
    },
    getDetailDM: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/nuoc/get-tien-theodinhmuc", payload)
        .then((res) => res.data);
    },
   
  },
  HopDongThiCong: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/get", payload)
        .then((res) => res.data);
    },
    getNhanVienTC: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/get-nhanvien", payload)
        .then((res) => res.data);
    },
    getSoHD: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/get-sohd", payload)
        .then((res) => res.data);
    },
    getNVTheoHD: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/get-nhanvien-byhd", payload)
        .then((res) => res.data);
    },
    deleteNV: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/delete-nhanvien", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/add", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/delete", payload)
        .then((res) => res.data);
    },
    NhatKyXuLy: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/hdthicong/get-nhatky", payload)
        .then((res) => res.data);
    },
  },
  PhiQuanLy: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/phiquanly/get", payload)
        .then((res) => res.data);
    },
  },
};
