import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { SaveOutlined } from "@ant-design/icons";
import { MatBangService } from "services/MatBangService";
import { SoQuyService } from "services/SoQuyService";
import SelectCustom from "components/Forms/SelectCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import { ToaNhaService } from "services/ToaNhaService";
const { TextArea } = Input;
const { Option } = Select;
const FormEdit = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nganHang, setNganHang] = useState([]);
  const defaultErrors = {
    // ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const filter = async () => {
    let _resNH = await ToaNhaService.DuAn.getTKNHByTN({
      MaTN: filterCondition.MaTN,
    });
    setNganHang(_resNH?.data ?? []);
  };

  useImperativeHandle(ref, () => ({
    update: async (data) => {
      console.log(data);

      setOpen(true);
      setIsUpdate(true);
      setData(data);
      filter();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "test":
        _data[p] = v;

        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }

    // _data.MaHinhThucThuTien = 1;
    // if (_data?.ChuyenKhoan === true) {
    //   _data.MaHinhThucThuTien = 2;
    // }
    // if (_data?.TTMayPOS === true) {
    //   _data.MaHinhThucThuTien = 3;
    // }
    // if (_data?.ThanhToanBTTC === true) {
    //   _data.MaHinhThucThuTien = 10;
    // }
    if (_data?.MaHinhThucThuTien !== 2) {
      _data.MaTK = null;
    }

    let _res = await SoQuyService.PhieuThu.edit({
      ..._data,
      MaTN: filterCondition?.MaTN,
    });

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderNguoiThanhToan = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <p
            style={{
              fontWeight: "600",
              backgroundColor: "#d9d9d9",
              padding: "3px 8px ",
            }}
          >
            Người thanh toán
          </p>
        </Col>
        <Col span={24}>
          <InputCustom
            label="Người nộp:"
            applyChange={applyChange}
            prop="NguoiNop"
            value={data?.NguoiNop}
            placeholder="Nhập tên người nhập"
          />
        </Col>
        <Col span={24}>
          <InputCustom
            label="Địa chỉ:"
            applyChange={applyChange}
            prop="DiaChi"
            value={data?.DiaChi}
            placeholder="Nhập tên người nhập"
          />
        </Col>
        <Col span={24}>
          <InputCustom
            disabled={true}
            label="Mặt bằng:"
            applyChange={applyChange}
            prop="MaSoMB"
            value={data?.MaSoMB}
            placeholder="Nhập tên người nhập"
          />
        </Col>
        <Col span={24}>
          <p
            style={{
              fontWeight: "600",
            }}
          >
            Diễn giải:
          </p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  const renderThongTinChung = () => {
    return (
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <p
            style={{
              fontWeight: "600",
              backgroundColor: "#d9d9d9",
              padding: "3px 8px ",
            }}
          >
            Thông tin phiếu thu
          </p>
        </Col>

        <Col span={24}>
          <InputCustom
            label="Số phiếu:"
            applyChange={applyChange}
            prop="SoPhieu"
            value={data?.SoPhieu}
          />
        </Col>
        <Col span={24}>
          <DatePickerCustom
            label="Ngày thu:"
            applyChange={applyChange}
            prop={"NgayThu"}
            value={data?.NgayThu}
            placeholder={"Chọn ngày thu"}
          />
        </Col>
        <Col span={24}>
          <InputNumberCustom
            disabled={true}
            label="Thực thu:"
            applyChange={applyChange}
            prop={"ThucThu"}
            value={data?.ThucThu}
            placeholder={"0"}
          />
        </Col>

        <Col span={24}>
          {" "}
          <SelectCustom
            label="Hình thức TT:"
            applyChange={applyChange}
            prop="MaHinhThucThuTien"
            value={data?.MaHinhThucThuTien}
            placeholder="Chọn hình thức thanh toán"
            options={[
              { TenHTTT: "Tiền mặt", key: 1 },
              { TenHTTT: "Chuyển khoản ngân hàng", key: 2 },
              { TenHTTT: "Bù trừ PQL", key: 8 },
              { TenHTTT: "Bù trừ tiền cọc", key: 10 },
              { TenHTTT: "Thanh toán máy POS", key: 3 },
            ]}
            fieldNames={{ label: "TenHTTT", value: "key" }}
          />
        </Col>
        {data?.MaHinhThucThuTien === 2 && (
          <Col span={24}>
            {" "}
            <SelectCustom
              label="Tài khoản:"
              applyChange={applyChange}
              prop="MaTK"
              value={data?.MaTK}
              placeholder="Chọn tài khoản"
              options={nganHang}
              fieldNames={{ label: "SoTaiKhoan", value: "ID" }}
            />
          </Col>
        )}

        {/* <Col span={24}>
          {" "}
          <SelectCustom
            disabled={!data?.ChuyenKhoan}
            label="Tài khoản:"
            applyChange={applyChange}
            prop="MaTK"
            value={data?.MaTK}
            placeholder="Chọn tài khoản"
            options={nganHang}
            fieldNames={{ label: "SoTaiKhoan", value: "ID" }}
          />
        </Col> */}

        {/* <Col span={12}>
          <Checkbox
            value={data?.ChuyenKhoan}
            onChange={(e) => applyChange("ChuyenKhoan", !e.target.value)}
            checked={data?.ChuyenKhoan}
          >
            {" "}
            Thanh toán bằng chuyển khoản
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.TTMayPOS}
            value={data?.TTMayPOS}
            onChange={(e) => applyChange("TTMayPOS", !e.target.value)}
          >
            {" "}
            Thanh toán máy POS
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            value={data?.ThanhToanBTTC}
            onChange={(e) => applyChange("ThanhToanBTTC", !e.target.value)}
            checked={data?.ThanhToanBTTC}
          >
            {" "}
            Thanh toán với tiền thừa
          </Checkbox>
        </Col> */}
      </Row>
    );
  };

  return (
    <Drawer
      title="Sửa phiếu thu"
      width={1020}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[12]}>
        <Col span={12}>{renderNguoiThanhToan()}</Col>
        <Col span={12}>{renderThongTinChung()}</Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormEdit);
