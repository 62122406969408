import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Checkbox,
} from "antd";
import { Editor } from "primereact/editor";
import _ from "lodash";
import { MatBangService } from "services/MatBangService";
import { OtherService } from "services/OtherService";
import { ToaNhaService } from "services/ToaNhaService";
import { SaveOutlined } from "@ant-design/icons";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const Option = Select.Option;
const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const defaultData = {
    NgayYC: new Date(),
    HanXuLy: new Date(),
    NgayHenKH: new Date(),
    NoiDung: "",
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [khoinha, setKhoinha] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [text, setText] = useState(null);
  const [images,setImages] = useState([]);
  const [errors, setErrors] = useState(defaultErrors);
  const [loaitb, setLoaitb] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [matbang, setMatbang] = useState([]);
  const loadcategories = async () => {
    let _res = await OtherService.getLoaiThongBao();
    setLoaitb(_res.data);
  };
  const loadKhoiNha = async (matn) => {
    let _res = await ToaNhaService.Categories.getKhoiNha({ MaTN: matn });
    setKhoinha(_res.data);
  };
  const loadTangLau = async (khoinha, matn) => {
    let _res = await ToaNhaService.Categories.getTangByKhoiNhas({
      KhoiNha: "," + khoinha + ",",
      MaTN: matn,
    });
    setTanglau(_res.data);
  };
  const loadMatBang = async (tanglau) => {
    let _res = await MatBangService.getByTangLaus({
      TangLau: "," + tanglau + ",",
    });
    setMatbang(_res.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (matn) => {
      setOpen(true);
      setData({ MaTN: matn });
      loadKhoiNha(matn);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      loadcategories();
      let _res = await OtherService.getDetail({ ID: data });
      loadKhoiNha(_res.data?.MaTN);
      loadTangLau(_res.data?.KhoiNha, _res.data?.MaTN);
      loadMatBang(_res.data?.TangLau);
      let _data = {
        ..._res.data,
        KhoiNha: _res.data.KhoiNha?.split(",")?.map(Number),
        TangLau: _res.data?.TangLau?.split(",")?.map(Number),
        MatBang: _res.data?.MatBang?.split(",")?.map(Number),
      };
      setText(_data.NoiDung);
      setData(_data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setText("");
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhoiNha":
        if (v.includes(0)) {
          if (_data[p]?.length > 0) {
            _data[p] = [];
          } else {
            _data[p] = _.map(khoinha, "MaKN");
          }
        } else {
          _data[p] = v;
        }
        loadTangLau(_data[p].join(","), _data.MaTN);
        break;
      case "TangLau":
        if (v.includes(0)) {
          if (_data[p]?.length > 0) {
            _data[p] = [];
          } else {
            _data[p] = _.map(tanglau, "MaTL");
          }
        } else {
          _data[p] = v;
        }
        loadMatBang(_data[p].join(","));
        break;
      case "MatBang":
        if (v.includes(0)) {
          if (_data[p]?.length > 0) {
            _data[p] = [];
          } else {
            _data[p] = _.map(matbang, "MaMB");
          }
        } else {
          _data[p] = v;
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (_data?.MatBang?.length === 0 && _data.isBQL !== true) {
      openNotification("error", "topRight", "Vui lòng chọn mặt bằng!");
      return;
    }
    _data.ListTangLau = _data.TangLau;
    _data.ListKhoiNha = _data.KhoiNha;
    _data.ListMatBang = _data.MatBang;
    _data.TangLau = _data.TangLau?.join(",");
    _data.KhoiNha = _data.KhoiNha?.join(",");
    _data.MatBang = _data.MatBang?.join(",");
    _data.NoiDung = text;
    setLoading(true);
    let _res = await OtherService.addThongBao(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const editorRef = useRef(null);
 const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.img = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };
  return (
    <Drawer
      title="Thông báo"
      width="100%"
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[20]}>
        <Col span={16}>
          {/* <button onClick={log}>Log editor content</button> */}
          {/* <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            // initialValue="<p>This is the initial content of the editor.</p>"
            value={data?.NoiDung}
            init={{
              height: window.innerHeight - 100,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={(v) => applyChange("NoiDung", v)}
          /> */}

          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ height: window.innerHeight - 100 }}
          />
        </Col>
        <Col span={8}>
          {/* <div
            dangerouslySetInnerHTML={{
              __html: data.ChinhSachThanhToan,
            }}
          ></div> */}
          <Row gutter={[20, 8]} style={{ marginTop: 20 }}>
            <Col span={24} className="gutter-row">
              <p style={{ color: "red" }}>Tiêu đề</p>
              <Input
                value={data?.TieuDe}
                placeholder="Nhập tiêu đề"
                onChange={(e) => applyChange("TieuDe", e.target.value)}
              />
            </Col>
            <Col span={24} className="gutter-row">
              <p style={{ color: "red" }}>Nội dung ngắn</p>
              <TextArea
                value={data?.DienGiai}
                placeholder="Nhập nội dung"
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Col>
            {/* <Col span={24}>
              <p style={{ color: "red" }}>Khối nhà</p>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                value={data?.KhoiNha}
                placeholder="Chọn khối nhà"
                onChange={(v) => applyChange("KhoiNha", v)}
                options={khoinha}
                fieldNames={{ label: "TenKN", value: "MaKN" }}
              />
            </Col> */}
            <Col span={24}>
              <p style={{ color: "red" }}>Khối nhà</p>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={data?.KhoiNha}
                onChange={(v) => applyChange("KhoiNha", v)}
                placeholder="Chọn khối nhà"
                maxTagCount="responsive"
              >
                <Option key={0} value={0}>
                  Tất cả
                </Option>
                {khoinha?.map((item) => (
                  <Option key={item.MaKN} value={item.MaKN}>
                    {item.TenKN}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* <Col span={24}>
              <p style={{ color: "red" }}>Tầng lầu</p>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                value={data?.TangLau}
                placeholder="Chọn tầng lầu"
                onChange={(v) => applyChange("TangLau", v)}
                options={tanglau}
                fieldNames={{ label: "TenTL", value: "MaTL" }}
              />
            </Col> */}
            <Col span={24}>
              <p style={{ color: "red" }}>Tầng lầu</p>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={data?.TangLau}
                onChange={(v) => applyChange("TangLau", v)}
                placeholder="Chọn tầng lầu"
                maxTagCount="responsive"
              >
                <Option key={0} value={0}>
                  {" "}
                  Tất cả{" "}
                </Option>
                {tanglau?.map((item) => (
                  <Option key={item.MaTL} value={item.MaTL}>
                    {item.TenTL}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* <Col span={24}>
              <p style={{ color: "red" }}>Mặt bằng</p>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                value={data?.MatBang}
                placeholder="Chọn mặt bằng"
                onChange={(v) => applyChange("MatBang", v)}
                options={matbang}
                fieldNames={{ label: "MaSoMB", value: "MaMB" }}
              />
            </Col> */}
            <Col span={24}>
              <p style={{ color: "red" }}>Mặt bằng</p>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={data?.MatBang}
                onChange={(v) => applyChange("MatBang", v)}
                maxTagCount="responsive"
              >
                <Option key={0} value={0}>
                  Tất cả
                  {/* {matbang?.length === data?.MatBang?.length ? "Tất cả" : "Bỏ chọn tất cả"} */}
                </Option>
                {matbang?.map((item) => (
                  <Option key={item.MaMB} value={item.MaMB}>
                    {item.MaSoMB}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* <Select
              mode="multiple"
              style={{ width: 100 }}
              value={data?.KhoiNha}
              onChange={(v) => applyChange("KhoiNha", v)}
            >
              <Option value="all">Select All</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
            <Col span={24}>
              <p style={{ color: "red" }}>Tầng lầu</p>
              <DebounceSelect
                mode="multiple"
                value={data?.KhoiNha}
                placeholder="Tìm theo mã khối nhà"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("KhoiNha", v)}
                style={{
                  width: "100%",
                }}
              />
            </Col> */}
            {/* <Col span={24}>
              <p style={{ color: "red" }}>Căn hộ</p>
              <DebounceSelect
                mode="multiple"
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Col> */}
            <Col span={24}>
              <p>Loại thông báo</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaLBT}
                placeholder="Chọn loại thông báo"
                onChange={(v) => applyChange("MaLBT", v)}
                options={loaitb}
                fieldNames={{ label: "TenLoai", value: "ID" }}
              />
            </Col>
            <Col span={8}>
              <Checkbox
                checked={data?.isBQL ?? false}
                onChange={(v) => applyChange("isBQL", v.target.checked)}
              >
                Ban quản lý
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                checked={data?.isBanner ?? false}
                onChange={(v) => applyChange("isBanner", v.target.checked)}
              >
                Banner
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                checked={data?.isHot ?? false}
                onChange={(v) => applyChange("isHot", v.target.checked)}
              >
                Hiển thị
              </Checkbox>
            </Col>
            <Col span={4}>
              <UploadComponent
                files={data?.img ? [data?.img] : []}
                multiple={false}
                affterUpload={affterUpload}
                onRemove={onRemove}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
