import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Datetime2 } from "layouts/ultis/common";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DichVuService } from "services/DichVuService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
    },
  }));

  const columns1 = [
    {
      title: "Mặt bằng",
      dataIndex: "MatBang",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
    },
    {
      title: "Tổng tiền/tháng",
      dataIndex: "TienTrenThang",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const columns2 = [
    {
      title: "Ký hiệu",
      dataIndex: "KyHieu",
    },
    {
      title: "Tên tài liệu",
      dataIndex: "TenTaiLieu",
    },
    {
      title: "Kích thước",
      dataIndex: "KichThuoc",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
    {
      title: "Nhóm tài liệu",
      dataIndex: "NhomTaiLieu",
    },
    {
      title: "Ngày ký VB",
      dataIndex: "NgayKyVB",
    },
    {
      title: "Nhân viên",
      dataIndex: "NhanVien",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCN",
      render: (item) => (item ? Format_Datetime(item) : ""),
    },
  ];
  const columns3 = [
    {
      title: "Dịch vụ",
      dataIndex: "DichVu",
    },
    {
      title: "Mặt bằng",
      dataIndex: "MatBang",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
    },
    {
      title: "Tổng tiền/tháng",
      dataIndex: "TienTrenThang",
    },
    {
      title: "Kỳ TT",
      dataIndex: "KyTT",
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
    },
    {
      title: "Ngày tính phí",
      dataIndex: "NgayTP",
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "NgayKetThuc",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const columns4 = [
    {
      title: "Dịch vụ",
      dataIndex: "DichVu",
    },
    {
      title: "Mặt bằng",
      dataIndex: "MatBang",
    },
    {
      title: "Lần TT",
      dataIndex: "LanTT",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
    },
    {
      title: "Tổng tiền",
      dataIndex: "TongTien",
    },
    {
      title: "Đã thu",
      dataIndex: "DaThu",
    },
    {
      title: "Còn lại",
      dataIndex: "ConLai",
    },
    {
      title: "Từ ngày",
      dataIndex: "TuNgay",
    },
    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
    },
    {
      title: "Ngày nhắc từ",
      dataIndex: "NgayNhacTu",
    },
    {
      title: "Nhắc đến ngày",
      dataIndex: "NhacDenNgay",
    },
  ];
  const columns5 = [
    {
      title: "Tên biểu mẫu",
      dataIndex: "TenBieuMau",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
  ];

  const columns6 = [
    {
      title: "Số phiếu tổng",
      dataIndex: "SoPhieuTong",
    },
    {
      title: "Ngày",
      dataIndex: "Ngay",
    },
    {
      title: "Sản phẩm",
      dataIndex: "SanPham",
    },
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
    },
    {
      title: "Đợt",
      dataIndex: "Dot",
    },
    {
      title: "Số tiền thu",
      dataIndex: "SoTienThu",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const columns7 = [
    {
      title: "Số phiếu tổng",
      dataIndex: "SoPhieuTong",
    },
    {
      title: "Ngày",
      dataIndex: "Ngay",
    },
    {
      title: "Sản phẩm",
      dataIndex: "Số phiếu",
    },
    {
      title: "Đợt",
      dataIndex: "Số tiền chi",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await DichVuService.Xe.getLichSuTX({
          MaTX: value,
        });
        if (_res.data) setData(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử hợp đồng cho thuê"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="1. Mặt bằng" key="1">
            <Table
              pagination={false}
              columns={columns1}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="2. Tài liệu" key="2">
            <Table
              pagination={false}
              columns={columns2}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="3. Dịch vụ" key="3">
            <Table
              pagination={false}
              columns={columns3}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1200,
              }}
            />
          </TabPane>
          <TabPane tab="4. Hóa đơn" key="4">
            <Table
              pagination={false}
              columns={columns4}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1100,
              }}
            />
          </TabPane>
          <TabPane tab="5. Biểu mẫu" key="5">
            <Table
              pagination={false}
              columns={columns5}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="6. Phiếu thu" key="6">
            <Table
              pagination={false}
              columns={columns6}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="7. Phiếu chi" key="7">
            <Table
              pagination={false}
              columns={columns7}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
