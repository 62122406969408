import { ConfigProvider, DatePicker, Flex, Input } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiCalendarDate } from "react-icons/ci";
import vi from "dayjs/locale/vi";
import viVN from "antd/es/locale/vi_VN";

const DatePickerCustom = ({
  label,
  applyChange,
  prop,
  value,
  disabled,
  required,
  open2,
}) => {
  const [open, setOpen] = useState(false);
  const [valueDefault, setValueDefault] = useState(
    value ? dayjs(value).format("DD-MM-YYYY") : null
  );
  const [dateValue, setDateValue] = useState(null);
  const [err, setErr] = useState("");

  useEffect(() => {
    setValueDefault(null);
  }, [open2]);
  useEffect(() => {
    if (value) {
      setValueDefault(dayjs(value).format("DD-MM-YYYY"));
      setDateValue(dayjs(value));
      // setErr(false);
    } else {
      setDateValue(null);
      // setValueDefault("__/__/____");
      setDateValue(null);
      // setErr(false);
    }
  }, [value]);
  const isValidDate = (day, month, year) => {
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10);
    if (monthInt < 1 || monthInt > 12 || dayInt < 1 || dayInt > 31) {
      // setErr(true);
      // setErr("Sai định dạng dd/mm/yyyy");
      return false;
    }
    const date = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD");
    return (
      date.isValid() && date.date() === dayInt && date.month() === monthInt - 1
    );
  };

  const handleInputChange = (e) => {
    if (e.target.value.length === 8 || e.target.value.length === 9) {
      const inputValue = e.target.value;
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      const sanitizedInput = inputValue.replace(/[^0-9-]/g, "");
      console.log(inputValue, "huan");
      console.log(start, "huan2");
      console.log(end, "huan123");

      let formattedValue = sanitizedInput;
      setValueDefault(formattedValue);
      const dateParts = formattedValue.split("-");
      const [day, month, year] = dateParts;

      if (day && month && year && year.length === 4) {
        if (isValidDate(day, month, year)) {
          const currentTime = dayjs();
          const fullDateTime = dayjs(
            `${day}-${month}-${year} ${currentTime.format("HH:mm")}`,
            "DD-MM-YYYY HH:mm"
          );
          setDateValue(fullDateTime);
          setErr("");
          typeof applyChange === "function" && applyChange(prop, fullDateTime);
        } else {
          setErr("Sai định dạng dd/mm/yyyy");
        }
      } else if (inputValue === "") {
        setDateValue(null);
        setErr("");
      }

      setTimeout(() => {
        e.target.selectionStart = start;
        e.target.selectionEnd = end;
      }, 0);
    } else {
      const inputValue = e.target.value;
      if (/^[0-9-]*$/.test(inputValue)) {
        let formattedValue = "";
        const sanitizedInput = inputValue.replace(/-/g, "");
        if (sanitizedInput.length > 0) {
          formattedValue += sanitizedInput.slice(0, 2); // Day
        }
        if (sanitizedInput.length > 2) {
          formattedValue += `-${sanitizedInput.slice(2, 4)}`; // Month
        }

        if (sanitizedInput.length > 4) {
          formattedValue += `-${sanitizedInput.slice(4, 8)}`; // Year
        }
        setValueDefault(formattedValue);
        console.log(formattedValue);
        const dateParts = formattedValue.split("-");
        console.log(dateParts, "huan");

        const [day, month, year] = dateParts;

        if (dateParts.length === 3 && year.length === 4) {
          // if (Number(year) < 1900 || Number(year) > 2200) {
          //   setErr("Năm phải > 1900 và < 2200");
          // }
          // else
          if (isValidDate(day, month, year)) {
            const currentTime = dayjs();
            const fullDateTime = dayjs(
              `${formattedValue} ${currentTime.format("HH:mm")}`,
              "DD-MM-YYYY HH:mm"
            );
            setDateValue(fullDateTime);
            setErr("");
            typeof applyChange === "function" &&
              applyChange(prop, fullDateTime);
          } else {
            // const currentTime = dayjs();
            // typeof applyChange === "function" &&
            //   applyChange(
            //     prop,
            //     dayjs(
            //       `${null} ${currentTime.format("HH:mm")}`,
            //       "DD-MM-YYYY HH:mm"
            //     )
            //   );
            setErr("Sai định dạng dd/mm/yyyy");
          }
        } else if (inputValue === "") {
          setDateValue(null);
          setErr("");
        }
      } else {
        e.preventDefault();
      }
    }
  };
  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("DD-MM-YYYY") : "";
    setValueDefault(formattedDate);
    setDateValue(date);
    typeof applyChange === "function" && applyChange(prop, date);
    setOpen(false);
  };

  const openDatePicker = () => {
    setOpen(true);
  };

  return (
    <div>
      <div className={required ? "border-required" : "container-datePick2"}>
        <Flex align="center">
          <label
            htmlFor={prop}
            style={{
              fontWeight: "600",
              width: "35%",
              cursor: "pointer",
            }}
          >
            {label}
            <span style={{ color: "red" }}> {required ? "*" : ""}</span>
          </label>

          <div style={{ position: "relative", width: "65%" }}>
            <Input
              id={prop}
              value={valueDefault}
              onChange={handleInputChange}
              onClick={openDatePicker}
              placeholder="DD-MM-YYYY"
              onBlur={() => setOpen(false)}
              style={{ paddingRight: "30px" }}
              disabled={disabled ? true : false}
              allowClear={false}
            />
            <CiCalendarDate
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={openDatePicker}
            />
          </div>
          <ConfigProvider locale={viVN}>
            <DatePicker
            
              allowClear={false}
              open={open}
              style={{ width: 0 }} // Ẩn DatePicker
              onChange={handleDateChange}
              format="DD-MM-YYYY"
              value={dateValue}
              locale={vi}
            />
          </ConfigProvider>
        </Flex>
        <p style={{ color: "red" }}>{err}</p>
      </div>{" "}
      {/* <p style={{ color: "red", textAlign: "center" }}>
        {err ? "vui lòng nhập đúng định dạng" : ""}
      </p> */}
    </div>
  );
};

export default DatePickerCustom;
