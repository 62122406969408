import {
  DownOutlined,
  DownSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Flex,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { GetRandomColor } from "layouts/ultis/common";
import { truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { IoIosAdd, IoMdArrowDropdown } from "react-icons/io";
import { ChatsService } from "services/ChatsService";
import _ from "lodash";
import { MdCheckBoxOutlineBlank, MdEdit } from "react-icons/md";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/vi"; // Sử dụng ngôn ngữ tiếng Việt
import { GoDotFill } from "react-icons/go";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa6";
import { CiSaveDown1 } from "react-icons/ci";
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const TabCongViec = ({
  showModal,
  activeTabCV,
  setActiveTabCV,
  filterCondition,
  activeTab,
  loading,
  setLoading,
  refInbox,
  onClickDetail,
  calculateMessageTime,
  renderModal,
  filterCV,
  formatTimeAgo,
  setDaTaGiaoViec,
  setIsModalOpen2,
  setTaiLieu,
  setEditCV,
  callChiTietCV,
}) => {
  const [selectedTab, setSelectedTab] = useState("Chưa xong"); // Mục mặc định
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataDetailt, setDataDetailt] = useState([]);
  const [chuaXong, setChuaXong] = useState(0);
  const [daXong, setDaXong] = useState(0);
  const [quaHan, setQuaHan] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const [value, setValue] = useState("all");
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [DataMessage, setDataMessage] = useState([]);
  const isFirstRender = useRef(true);
  const showModal2 = () => setVisible(true);
  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };
  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    if (tab === "Chưa xong") {
      const filterData = dataAll?.filter((item) => item?.is_done === false);
      setData(filterData);
    } else if (tab === "Đã xong") {
      const filterData = dataAll?.filter((item) => item?.is_done === true);
      setData(filterData);
    }
    // else if (tab === "Quá hạn") {
    //   if (Array.isArray(dataAll)) {
    //     const filterData = dataAll.filter((item) => {
    //       const dateSend = new Date(item?.date_expired);
    //       console.log(!isNaN(dateSend));

    //       return !isNaN(dateSend) && dateSend < new Date(); // Kiểm tra ngày hợp lệ và so sánh
    //     });
    //     setData(filterData);
    //   } else {
    //     setData([]);
    //   }
    // }
    else if (tab === "Quá hạn") {
      if (Array.isArray(dataAll)) {
        const filterData = dataAll.filter((item) => {
          if (!item?.date_expired) return false; // Loại bỏ nếu date_expired không tồn tại hoặc null
          const dateSend = new Date(item.date_expired);
          return !isNaN(dateSend) && dateSend < new Date(); // Kiểm tra ngày hợp lệ và so sánh
        });
        setData(filterData);
      } else {
        setData([]);
      }
    }
  };
  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);
  dayjs.extend(localizedFormat);
  dayjs.locale("vi"); // Cài đặt ngôn ngữ là tiếng Việt
  const messageEndRef = useRef(null);

  // Cuộn xuống cuối khi tin nhắn mới được thêm
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [DataMessage]);
  const formatDate = (dateExpired) => {
    const date = dayjs(dateExpired);
    if (dateExpired === null) {
      return "Không thời hạn";
    }

    if (date.isToday()) {
      return `Hôm nay, ${date.format("HH:mm")}`;
    } else if (date.isTomorrow()) {
      return `Ngày mai, ${date.format("HH:mm")}`;
    } else if (date.diff(dayjs(), "day") <= 6) {
      // Nếu trong tuần hiện tại
      return `${date.format("dddd")}, ${date.format("HH:mm")}`;
    } else {
      // Ngày xa hơn
      return date.format("DD/MM/YYYY, HH:mm");
    }
  };
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleChiTietCV(dataDetailt);
  }, [callChiTietCV]);

  const handleEditCV = () => {
    setIsModalOpen2(true);
    setEditCV(true);

    setDaTaGiaoViec({
      Title: dataDetailt?.info?.name,
      Content: dataDetailt?.info?.contents,
      Expired: dataDetailt?.info?.date_expired,
      ID: dataDetailt?.id,
      MaNV: dataDetailt?.staffs?.map((item) => item?.MaNV),
    });
    setTaiLieu(
      dataDetailt?.files?.map((item) => ({
        Name: item?.name,
        Link: item?.link,
        ID: item?.id,
      }))
    );
  };
  const handleMenuClick2 = (e) => {
    // if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
    //   openNotification(
    //     "error",
    //     "topRight",
    //     "Vui lòng chọn mặt bằng, xin cảm ơn."
    //   );
    //   return;
    // }
    switch (e.key) {
      case "1":
        handleSelectTab("Quá hạn");
        break;
      case "2":
        handleSelectTab("Chưa nhận");

        break;
      case "3":
        handleSelectTab("Đã nhận");

        break;
      case "4":
        break;
      case "5":
        break;

      default:
        break;
    }
  };
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1"> Quá hạn : 0</Menu.Item>
      <Menu.Item key="2">Chưa nhận: 0</Menu.Item>
      <Menu.Item key="3">Đã nhận: 0</Menu.Item>
    </Menu>
  );
  const renderTienTrinhViecLam = () => {
    return (
      <Flex
        style={{
          padding: "0px 10px",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <p
          style={{
            borderRadius: "5px",
            flex: "1",
            textAlign: "center",
            backgroundColor:
              selectedTab === "Chưa xong" ? "#E6F1FE" : "transparent", // Đổi màu nền
            fontWeight: selectedTab === "Chưa xong" ? "bold" : "normal", // Đổi độ đậm
            cursor: "pointer",
          }}
          onClick={() => handleSelectTab("Chưa xong")}
        >
          Chưa xong: {chuaXong}
        </p>
        <p
          style={{
            borderRadius: "5px",
            flex: "1",
            textAlign: "center",
            backgroundColor:
              selectedTab === "Đã xong" ? "#E6F1FE" : "transparent",
            fontWeight: selectedTab === "Đã xong" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => handleSelectTab("Đã xong")}
        >
          Đã xong: {daXong}
        </p>
        <p
          style={{
            borderRadius: "5px",
            flex: "1",
            textAlign: "center",
            backgroundColor:
              selectedTab === "Quá hạn" ? "#E6F1FE" : "transparent",
            fontWeight: selectedTab === "Quá hạn" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => handleSelectTab("Quá hạn")}
        >
          Quá hạn: {quaHan}
        </p>
        {/* <p
          style={{
            flex: "1",
            textAlign: "right",
            backgroundColor: selectedTab === "Khác" ? "#E6F1FE" : "transparent",
            fontWeight: selectedTab === "Khác" ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => handleSelectTab("Khác")}
        >
          Khác
        </p> */}
        {/* {selectedTab === "Quá hạn" ||
        selectedTab === "Chưa nhận" ||
        selectedTab === "Đã nhận" ? (
          <Dropdown overlay={menu2} trigger={["click"]}>
            <p
              style={{
                borderRadius: "5px",
                flex: "1",
                textAlign: "center",
                backgroundColor: "#E6F1FE",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {selectedTab}: 0
            </p>
          </Dropdown>
        ) : (
          <div
            style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}
          >
            <Dropdown overlay={menu2} trigger={["click"]}>
              <p
                // onClick={() => handleSelectTab("Khác")}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Khác
              
                <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
              </p>
            </Dropdown>
          </div>
        )} */}
      </Flex>
    );
  };
  const RenderThongBao = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            // backgroundColor: "#f0f2f5",
          }}
        >
          <DownOutlined
            style={{
              padding: "20px",
              backgroundColor: "#d5dbd6",
              textAlign: "center",
              color: "#fff",
              borderRadius: "5px",
            }}
          />
        </div>
        <p style={{ textAlign: "center", color: "#a8a7a5" }}>
          Danh sách công việc đang trống
        </p>
      </>
    );
  };
  const handleTabChange = (key) => {
    setActiveTabCV(key);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  useEffect(() => {
    if (activeTab === false) {
      return;
    }
    filter();
  }, [activeTabCV, activeTab, filterCV]);

  const filter = async () => {
    setLoading(true);
    if (activeTabCV === "3") {
      setData([]);
    }
    let _res = await ChatsService.get({
      MaTN: filterCondition?.MaTN,
      InputSearch: "",
      IsByMe: activeTabCV === "1" ? true : false,
    });

    setLoading(false);
    if (_res.data) {
      const allData = _res.data;
      setDataAll(allData);

      // Tính số lượng cho từng loại mục
      const chuaXong = allData.filter((item) => item?.is_done === false);
      const daXong = allData.filter((item) => item?.is_done === true);
      const quaHan = allData.filter((item) => {
        if (!item?.date_expired) return false;
        const dateSend = new Date(item.date_expired);
        return !isNaN(dateSend) && dateSend < new Date();
      });

      // Lưu dữ liệu và số lượng
      setChuaXong(chuaXong.length);
      setDaXong(daXong.length);
      setQuaHan(quaHan.length);

      // Hiển thị dữ liệu dựa trên tab hiện tại
      if (selectedTab === "Chưa xong") {
        setData(chuaXong);
      } else if (selectedTab === "Đã xong") {
        setData(daXong);
      } else if (selectedTab === "Quá hạn") {
        setData(quaHan);
      }
    }
  };

  const handleCheckTT = async (item, checkCallApi, trangThai) => {
    setLoading(true);
    let _res = await ChatsService.checkTrangThai({
      id: item?.id,
      is_done:
        trangThai === "done"
          ? true
          : trangThai === "no"
          ? false
          : !item?.is_done,
    });
    setLoading(false);
    if (_res.status === 2000) {
      if (checkCallApi) await handleChiTietCV(item);
      if (!checkCallApi) {
        openNotification("success", "topRight", `Sửa trạng thái thành công!`);
      }
      filter();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleNhanViec = async (item) => {
    setLoading(true);
    let _res = await ChatsService.nhanViec({
      id: item?.id,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `nhận việc thành công thành công!`
      );
      filter();
      onClickDetail(item);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleChiTietCV = async (item) => {
    setLoading(true);
    let _res = await ChatsService.getChiTietCV({
      id: item?.id,
    });
    setLoading(false);
    if (_res.status === 2000) {
      setDataDetailt({ ...item, ..._res?.data });
      setDataMessage(_res?.data?.history);
      showModal2();
    }
  };
  const handleXemMau = (link) => {
    if (link) {
      const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
      window.open(fullLink, "_blank"); // Opens the link in a new tab
    }
  };

  const handleDownload = (fileUrl, fileName) => {
    window.open(fileUrl, "_blank");
  };
  const handleAddMessage = async () => {
    setLoading(true);
    if (!message) {
      setLoading(false);
      return;
    }
    let _res = await ChatsService.addMessage({
      id: dataDetailt?.id,
      contents: message,
    });
    setLoading(false);

    if (_res.status === 2000) {
      setDataMessage((pr) => [
        ...pr,
        { contents: message, date_create: new Date() },
      ]);
      setMessage("");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddMessage();
    }
  };

  const RenderGropUser = () => {
    return (
      <>
        <div className="inboxs2">
          {loading && <Skeleton active />}
          {data.map((item) => {
            return (
              <div className="hover-container">
                <Flex
                  className={
                    item?.id === refInbox?.current?.id
                      ? "ripple-container-active"
                      : "ripple-container"
                  }
                  align="center"
                  gap={10}
                  key={item.id}
                  onClick={() => onClickDetail(item)}
                >
                  <div
                    className="avatar"
                    style={{
                      background: GetRandomColor(item.id),
                    }}
                  >
                    {item?.name_chat?.charAt(0)}
                  </div>
                  <div
                    className="CheckHoanThanh"
                    // style={{
                    //   background: GetRandomColor(item.id),
                    // }}
                  >
                    {/* <MdCheckBoxOutlineBlank
                    style={{ fontSize: "50px", zIndex: "99" }}
                  /> */}
                    <Tooltip title="Hoàn thành công việc">
                      <Checkbox
                        checked={item?.is_done}
                        onClick={() => handleCheckTT(item)}
                        style={{
                          transform: "scale(2)",
                          margin: "14px 15px",
                        }}
                      ></Checkbox>
                    </Tooltip>
                  </div>

                  <div style={{ width: "85%" }}>
                    <Flex justify="space-between" style={{ width: "100%" }}>
                      <p
                        style={{
                          fontWeight: item?.isread ? "400" : "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "180px",
                        }}
                      >
                        {item.name_chat}
                      </p>
                      <p className="CheckBlock">
                        {calculateMessageTime(item.date_expired)}
                      </p>
                      <p
                        className="CheckHoanThanh"
                        style={{ zIndex: "99" }}
                        onClick={() => handleChiTietCV(item)}
                      >
                        Chi tiết
                      </p>
                    </Flex>
                    <Flex justify="space-between">
                      <p
                        style={{
                          color:
                            item?.id === refInbox?.current?.id
                              ? "white"
                              : "rgb(142,142,146)",
                        }}
                      >
                        {item.last_sent_user_id ===
                          Number(
                            JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                          ) && <span>Bạn: </span>}
                        {truncate(item.contents, 30)}
                      </p>
                      {/* {!item?.isread && (
                      <Tag color="green" style={{ height: "23px" }}>
                        +1
                      </Tag>
                    )} */}
                    </Flex>
                    <Flex justify="space-between">
                      <p style={{ fontSize: "12px" }}>{item?.TrangThai}</p>
                      <p style={{ fontSize: "12px" }}>
                        Thời hạn: <span>{formatDate(item?.date_expired)}</span>
                      </p>
                    </Flex>
                  </div>
                </Flex>
              </div>
            );
          })}
        </div>
        {renderModal()}
      </>
    );
  };
  const RenderGropUserCL = () => {
    return (
      <>
        <div className="inboxs2">
          {loading && <Skeleton active />}
          {data.map((item) => {
            return (
              <div className="hover-container">
                <Flex
                  className={
                    item?.id === refInbox?.current?.id
                      ? "ripple-container-active"
                      : "ripple-container"
                  }
                  align="center"
                  gap={10}
                  key={item.id}
                  onClick={() => onClickDetail(item)}
                >
                  <div
                    className="avatar"
                    style={{
                      background: GetRandomColor(item.id),
                    }}
                  >
                    {item?.name_chat?.charAt(0)}
                  </div>
                  <div
                    onClick={() => handleCheckTT(item)}
                    className="CheckHoanThanh"
                    // style={{
                    //   background: GetRandomColor(item.id),
                    // }}
                  >
                    {/* <MdCheckBoxOutlineBlank
                    style={{ fontSize: "50px", zIndex: "99" }}
                  /> */}
                    <Tooltip title="Hoàn thành công việc">
                      <Checkbox
                        style={{
                          transform: "scale(2)",
                          margin: "14px 15px",
                        }}
                      ></Checkbox>
                    </Tooltip>
                  </div>

                  <div style={{ width: "85%" }}>
                    <Flex justify="space-between" style={{ width: "100%" }}>
                      <p
                        style={{
                          fontWeight: item?.isread ? "400" : "bold",
                        }}
                      >
                        {item.name_chat}
                      </p>
                      <p className="CheckBlock">
                        {calculateMessageTime(item.date_expired)}
                      </p>
                      <p
                        className="CheckHoanThanh"
                        style={{ zIndex: "99" }}
                        onClick={() => handleChiTietCV(item)}
                      >
                        Chi tiết
                      </p>
                    </Flex>
                    <Flex justify="space-between">
                      <p
                        style={{
                          color:
                            item?.id === refInbox?.current?.id
                              ? "white"
                              : "rgb(142,142,146)",
                        }}
                      >
                        {item.last_sent_user_id ===
                          Number(
                            JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                          ) && <span>Bạn: </span>}
                        {truncate(item.contents, 30)}
                      </p>
                      {/* {!item?.isread && (
                      <Tag color="green" style={{ height: "23px" }}>
                        +1
                      </Tag>
                    )} */}
                    </Flex>
                    <Flex justify="space-between">
                      {item?.accept_job ? (
                        <>
                          <p style={{ fontSize: "12px" }}>{item?.TrangThai}</p>
                        </>
                      ) : (
                        <>
                          <Button
                            size="small"
                            style={{
                              color: "#1677FE",
                              border: "1px solid #1677FE",
                            }}
                            onClick={() => handleNhanViec(item)}
                          >
                            Nhận việc
                          </Button>
                        </>
                      )}
                      <p style={{ fontSize: "12px" }}>
                        Thời hạn: <span>{formatDate(item?.date_expired)}</span>
                      </p>
                    </Flex>
                  </div>
                </Flex>
              </div>
            );
          })}
        </div>
        {renderModal()}
      </>
    );
  };

  return (
    <div>
      <p
        style={{
          position: "absolute",
          // top: "13px",
          right: "20px",
          bottom: "30px",
          zIndex: "10",
        }}
        onClick={showModal}
      >
        <Tooltip title="Giao việc">
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "#1677FE",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <PlusOutlined
              style={{ fontSize: "18px", color: "#fff", fontWeight: "bold" }}
            />
          </div>
        </Tooltip>
      </p>
      <Tabs
        style={{ position: "relative" }}
        activeKey={activeTabCV}
        onChange={handleTabChange}
        centered
      >
        <TabPane tab="Tôi giao" key="1">
          {renderTienTrinhViecLam()}
          {selectedTab === "Chưa xong" ? (
            data?.length > 0 ? (
              <>{RenderGropUser()}</>
            ) : (
              <>
                <div style={{ padding: "50px 20px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      backgroundColor: "#d5dbd6",
                      borderRadius: "5px",
                    }}
                  >
                    Danh sách này sẽ gồm các công việc bạn giao cho người khác
                    mà họ chưa hoàn thành.
                  </p>
                </div>
                {RenderThongBao()}
                <p
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                    color: "#1677FF",
                    cursor: "pointer",
                  }}
                  onClick={showModal}
                >
                  Giao việc
                </p>
              </>
            )
          ) : selectedTab === "Đã xong" ? (
            data?.length > 0 ? (
              <>{RenderGropUser()}</>
            ) : (
              <>
                <div style={{ padding: "50px 20px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      backgroundColor: "#d5dbd6",
                      borderRadius: "5px",
                    }}
                  >
                    Danh sách này sẽ gồm các công việc bạn giao cho người khác
                    mà họ đã hoàn thành.
                  </p>
                </div>
                {RenderThongBao()}
                <p
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                    color: "#1677FF",
                    cursor: "pointer",
                  }}
                  onClick={showModal}
                >
                  Giao việc
                </p>
              </>
            )
          ) : data?.length > 0 ? (
            <>{RenderGropUser()}</>
          ) : (
            <>
              {RenderThongBao()}
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  color: "#1677FF",
                  cursor: "pointer",
                }}
                onClick={showModal}
              >
                Giao việc
              </p>
            </>
          )}
        </TabPane>
        <TabPane tab="Cần làm" key="2">
          {renderTienTrinhViecLam()}
          {selectedTab === "Chưa xong" ? (
            data?.length > 0 ? (
              <>{RenderGropUserCL()}</>
            ) : (
              <>
                <div style={{ padding: "50px 20px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      backgroundColor: "#d5dbd6",
                      borderRadius: "5px",
                    }}
                  >
                    Danh sách này sẽ gồm các công việc giao cho bạn mà bạn chưa
                    hoàn thành.
                  </p>
                </div>
                {RenderThongBao()}
              </>
            )
          ) : selectedTab === "Đã xong" ? (
            data?.length > 0 ? (
              <>{RenderGropUserCL()}</>
            ) : (
              <>
                <div style={{ padding: " 50px 20px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      backgroundColor: "#d5dbd6",
                      borderRadius: "5px",
                    }}
                  >
                    Danh sách này sẽ gồm các công việc giao cho bạn mà bạn đã
                    hoàn thành.
                  </p>
                </div>
                {RenderThongBao()}
              </>
            )
          ) : data?.length > 0 ? (
            <>{RenderGropUserCL()}</>
          ) : (
            <>{RenderThongBao()}</>
          )}
        </TabPane>
        {/* <TabPane tab="Theo dõi" key="3">
          {renderTienTrinhViecLam()}
          <>
            <div style={{ padding: "50px 20px" }}>
              <p
                style={{
                  textAlign: "center",
                  padding: "15px",
                  backgroundColor: "#d5dbd6",
                  borderRadius: "5px",
                }}
              >
                Danh sách này sẽ gồm các công việc giao cho thành viên mà họ
                chưa hoàn thành, bạn trong danh sách theo dõi.
              </p>
            </div>
            {RenderThongBao()}
          </>
        </TabPane> */}
      </Tabs>
      <Modal
        title="Chi tiết công việc"
        visible={visible}
        onCancel={handleCancel}
        width={"700px"}
        // footer={[
        //   <Button key="cancel" onClick={handleCancel}>
        //     Đóng
        //   </Button>,
        //   <Button key="save" type="primary">
        //     Lưu
        //   </Button>,
        // ]}
        footer={false}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div style={{ borderBottom: "1px solid #eee" }}>
              <p
                style={{
                  // fontSize: "15px",
                  fontWeight: "500",
                  paddingBottom: "10px",
                }}
              >
                {dataDetailt?.info?.name}
              </p>
              <p style={{ paddingBottom: "15px" }}>
                {" "}
                {dataDetailt?.info?.contents}
              </p>
            </div>
            <div style={{ borderBottom: "1px solid #eee" }}>
              <p style={{ fontWeight: "500" }}>File</p>
              {dataDetailt?.files?.map((item) => {
                return (
                  <Flex
                    style={{ paddingBottom: "5px" }}
                    justify="space-between"
                    align="center"
                  >
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "200px",
                      }}
                    >
                      {item?.name}
                    </p>
                    <p
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100px",
                      }}
                      onClick={() => handleXemMau(item?.link)}
                    >
                      {item?.link}
                    </p>
                    <Tooltip title="Tải về">
                      <CiSaveDown1
                        onClick={() => handleDownload(item?.Link)}
                        style={{ fontSize: "20px", color: "blue" }}
                      />
                    </Tooltip>
                  </Flex>
                );
              })}
              <p style={{ paddingBottom: "6px" }}></p>
            </div>

            <div>
              <Flex justify="space-between">
                <p style={{ fontWeight: "500" }}>Hoạt động</p>
                {/* <Select
                  value={value}
                  onChange={handleChange}
                  style={{ width: 100 }}
                  placeholder="Chọn tùy chọn"
                >
                  <Option value="all">Tất cả</Option>
                  <Option value="answered">Trả lời</Option>
                </Select> */}
              </Flex>
            </div>
            {/* <div
              style={{
                minHeight: "300px",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
            >
              {DataMessage?.map((item) => {
                return (
                  <>
                    <p>{item?.contents}</p>
                    <p style={{ fontSize: "12px", color: "#999" }}>
                      {" "}
                      {formatDate(dataDetailt?.date_create)}
                    </p>
                  </>
                );
              })}
            </div>
            <Space.Compact
              style={{
                width: "99%",
              }}
            >
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Nhập @, tin nhắn cho công việc..."
              />
              <Button type="primary" onClick={() => handleAddMessage()}>
                Giửi
              </Button>
            </Space.Compact> */}
            <div>
              <div
                style={{
                  minHeight: "300px",
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                {DataMessage?.map((item, index) => (
                  <div key={index}>
                    <p>{item?.contents}</p>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#999",
                        marginTop: "-2px",
                        paddingBottom: "5px",
                      }}
                    >
                      {formatDate(dataDetailt?.date_create)}
                    </p>
                  </div>
                ))}
                {/* Thêm một div ở cuối để cuộn đến */}
                <div style={{ paddingBottom: "10px" }} ref={messageEndRef} />
              </div>

              <Space.Compact style={{ width: "99%" }}>
                <Input
                  onKeyDown={handleKeyDown}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Nhập @, tin nhắn cho công việc..."
                />
                <Button type="primary" onClick={handleAddMessage}>
                  Gửi
                </Button>
              </Space.Compact>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                boxShadow: "-5px 0 5px -5px rgba(0, 0, 0, 0.3)",
                borderLeft: "0.5px solid #eee",
                paddingLeft: "15px",
              }}
            >
              <p>Trạng thái</p>
              <Flex style={{ padding: "15px 0 10px" }} align="center">
                <GoDotFill style={{ color: "blue" }} />{" "}
                <p>{dataDetailt?.TrangThai}</p>
              </Flex>

              <p>Ngày hết hạn</p>
              <p style={{ padding: "15px 0 10px" }}>
                {formatDate(dataDetailt?.date_expired)}
              </p>
              <p>Giao cho</p>
              {dataDetailt?.staffs?.map((item) => {
                return (
                  <Flex style={{ padding: "6px 0" }} align="center">
                    <Avatar
                      size="small"
                      icon={<UserOutlined />}
                      style={{ marginRight: "5px" }}
                    />
                    <p>{item?.HoTenNV}</p>
                  </Flex>
                );
              })}

              <p style={{ paddingBottom: "10px" }}>Tác vụ</p>
              {dataDetailt?.info?.is_done ? (
                <>
                  {" "}
                  <Button
                    onClick={() => handleCheckTT(dataDetailt, true, "none")}
                    icon={<IoCheckmarkDoneCircleOutline />}
                    style={{ marginBottom: "7px", width: "160px" }}
                  >
                    Mở lại
                  </Button>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    onClick={() => handleCheckTT(dataDetailt, true, "done")}
                    icon={<IoCheckmarkDoneCircleOutline />}
                    style={{ marginBottom: "7px", width: "160px" }}
                  >
                    Hoàn thành
                  </Button>
                </>
              )}

              <br />
              <Button
                icon={<FaRegBell />}
                style={{ marginBottom: "7px", width: "160px" }}
              >
                Nhắc nhở
              </Button>
              <br />
              <Button
                onClick={() => handleEditCV()}
                icon={<MdEdit />}
                style={{ marginBottom: "7px", width: "160px" }}
              >
                Chỉnh sửa
              </Button>
              <br />
              {/* <Button
                icon={<IoIosAdd />}
                style={{ marginBottom: "7px", width: "160px" }}
              >
                Thêm
              </Button> */}
            </div>
          </Col>
        </Row>
      </Modal>
      {contextHolder}
    </div>
  );
};

export default TabCongViec;
