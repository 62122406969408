// import React, { useState, useImperativeHandle, forwardRef } from "react";
// import {
//   Button,
//   Input,
//   Row,
//   Col,
//   Space,
//   Drawer,
//   notification,
//   Checkbox,
//   Flex,
//   Table,
// } from "antd";
// import _ from "lodash";
// import dayjs from "dayjs";
// import "dayjs/locale/vi";
// import InputNumberCustom from "components/Forms/InputNumberCustom";
// import InputCustom from "components/Forms/InputCustom";
// import DebounceSelect from "components/DebounceSelect";
// import SelectCustom from "components/Forms/SelectCustom";
// import DatePickerCustom from "components/Forms/DatePickerCustom";
// import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
// import { DateTime } from "luxon";
// import { MatBangService } from "services/MatBangService";
// import { CategoryService } from "services/CategoryService";
// import { DichVuService } from "services/DichVuService";
// import { CongNoService } from "services/CongNoService";
// const { TextArea } = Input;

// const FormAdd = (props, ref) => {
//   const { onAfterSubmit, filterCondition } = props;
//   const [api, contextHolder] = notification.useNotification();
//   const currentDateTime = DateTime.utc();
//   const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
//   const defaulData = {
//     TuNgay: dateInHoChiMinh.toISO(),
//     DenNgay: dateInHoChiMinh.toISO(),
//     TaoHD: false,
//   };
//   const [data, setData] = useState(defaulData);
//   const [dataTable, setDataTable] = useState();
//   const [isupdate, setIsUpdate] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [loaiDV, setLoaiDV] = useState();
//   const [theXe, setTheXe] = useState();
//   const [disabled, setDisabled] = useState(false);
//   const defaultErrors = {
//     MatBang: null,
//     MaLDV: null,
//     SoThang: null,
//   };
//   const [errors, setErrors] = useState(defaultErrors);
//   const filterDV = async () => {
//     let _res = await CategoryService.Khac.get();
//     if (_res.data) {
//       setLoaiDV(_res.data.filter((item) => item.ID === 6 || item.ID === 12));
//     }
//   };
//   useImperativeHandle(ref, () => ({
//     show: async () => {
//       setOpen(true);
//       setIsUpdate(false);
//       filterDV();
//     },
//     update: async (data) => {
//       setOpen(true);
//       setIsUpdate(true);
//       setData(data);
//       filterDV();
//     },
//   }));
//   const onClose = () => {
//     setOpen(false);
//     setData(defaulData);
//     setDataTable();
//   };
//   const applyChange = async (p, v) => {
//     let _data = _.cloneDeep(data);
//     switch (p) {
//       case "MatBang":
//         const KhachHang2 = JSON.parse(v.title);
//         _data.KhachHang = KhachHang2.TenKH;
//         _data.MaSoMB = KhachHang2.MaSoMB;
//         _data.MaKH = KhachHang2.MaKH;
//         _data.MaMB = KhachHang2.MaMB;
//         _data.MaKN = KhachHang2.MaKN;
//         _data.DienTichThuPhi = KhachHang2.DienTichThuPhi;
//         _data.SoTienPerMet = KhachHang2.SoTienPerMet;
//         _data.MaTL = KhachHang2.MaTL;
//         _data.MaTN = KhachHang2.MaTN;
//         _data.PhiQuanLy = KhachHang2.PhiQuanLy;
//         _data[p] = v;
//         let _resTX = await DichVuService.Xe.getTheXe({
//           MaMB: _data?.MaMB,
//         });
//         if (_resTX.data) {
//           setTheXe(_resTX.data);
//         }
//         break;
//       case "TuNgay":
//         const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
//         const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
//         _data[p] = isoString;
//         break;
//       case "DenNgay":
//         const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
//         const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
//         _data[p] = isoString2;
//         break;
//       case "TaoHD":
//         _data[p] = !v;
//         break;
//       case "SoThang":
//         let denNgay = new Date(_data.TuNgay).setMonth(
//           new Date(_data.TuNgay).getMonth() + v
//         );
//         _data[p] = v;
//         _data.DenNgay = denNgay;
//         break;
//       case "MaLDV":
//         _data[p] = v;
//         _data.TenLDV = loaiDV.find((item) => item.ID === v)?.Name;
//         if (v === 12) {
//           setDisabled(true);
//         } else {
//           setDisabled(false);
//         }
//         break;
//       default:
//         _data[p] = v;
//         break;
//     }
//     setData(_data);
//   };
//   const openNotification = (type, placement, message) => {
//     api[type]({
//       message: `Thông báo`,
//       description: message,
//       placement,
//     });
//   };
//   const validate = () => {
//     let _errors = _.cloneDeep(errors);
//     Object.keys(_errors)?.map((item) => {
//       _errors[item] = data[item] ? null : "Không để trống";
//     });
//     setErrors(_errors);
//     let valid = _.uniq(Object.values(_errors).filter((v) => v));
//     return valid;
//   };
//   const onSubmit = async (close) => {
//     setLoading(true);
//     let _data = _.cloneDeep(data);
//     let valid = validate();
//     if (valid?.length > 0) {
//       setLoading(false);
//       return;
//     }
//     let _res = await CongNoService.HoaDonChiTiet.addHoaDon({
//       MaTN: _data?.MaTN,
//       List: dataTable,
//     });

//     setLoading(false);
//     if (_res.status === 2000) {
//       openNotification(
//         "success",
//         "topRight",
//         `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
//       );
//       setIsUpdate(true);
//       onAfterSubmit();
//       onClose();
//     } else {
//       openNotification("error", "topRight", _res?.message);
//     }
//   };
//   async function fetchMatBang(input) {
//     return MatBangService.getMatBang({
//       InputSearch: input,
//       MaTN: filterCondition?.MaTN,
//     }).then((res) => {
//       return res?.data?.map((item) => ({
//         label: `${item.MaSoMB}`,
//         value: item.MaMB,
//         title: JSON.stringify(item),
//       }));
//     });
//   }

//   const renderThongTinChung = () => {
//     return (
//       <>
//         <Row style={{ marginTop: "20px" }} gutter={[20, 12]}>
//           <Col span={12}>
//             <Flex style={{ borderBottom: "1px solid #ff9087" }}>
//               <p
//                 style={{
//                   fontWeight: "600",
//                   width: "30% ",
//                 }}
//               >
//                 Mặt bằng:<span style={{ color: "red" }}>*</span>
//               </p>
//               <DebounceSelect
//                 open={open}
//                 value={data?.MatBang}
//                 placeholder="Tìm theo mã mặt bằng"
//                 fetchOptions={fetchMatBang}
//                 onChange={(v) => applyChange("MatBang", v)}
//                 style={{
//                   width: "100%",
//                 }}
//               />
//             </Flex>
//             {errors && (
//               <p style={{ color: "red", textAlign: "center" }}>
//                 {errors && errors["MatBang"]}
//               </p>
//             )}
//           </Col>

//           <Col span={12}>
//             <InputCustom
//               label="Khách hàng:"
//               applyChange={applyChange}
//               prop="KhachHang"
//               value={data?.KhachHang}
//               placeholder=""
//               disabled={true}
//             />
//           </Col>
//           <Col span={12}>
//             <SelectCustom
//               required={true}
//               label="Loại dịch vụ:"
//               applyChange={applyChange}
//               prop="MaLDV"
//               value={data?.MaLDV}
//               placeholder="Chọn loại dịch vụ"
//               options={loaiDV}
//               fieldNames={{ label: "Name", value: "ID" }}
//             />
//             {errors && (
//               <p style={{ color: "red", textAlign: "center" }}>
//                 {errors && errors["MaLDV"]}
//               </p>
//             )}
//           </Col>
//           <Col span={12}>
//             <SelectCustom
//               required={true}
//               disabled={disabled}
//               label="Thẻ xe:"
//               applyChange={applyChange}
//               prop="MaTX"
//               value={data?.MaTX}
//               placeholder="Chọn thẻ xe"
//               options={theXe}
//               fieldNames={{ label: "BienSo", value: "ID" }}
//             />

//             {errors && !disabled && (
//               <p style={{ color: "red", textAlign: "center" }}>
//                 {errors && errors["MaTX"]}
//               </p>
//             )}
//           </Col>
//           <Col span={12}>
//             <DatePickerCustom
//               label="Từ ngày:"
//               applyChange={applyChange}
//               prop={"TuNgay"}
//               value={data?.TuNgay}
//               placeholder={""}
//             />
//           </Col>
//           <Col span={12}>
//             <DatePickerCustom
//               label="Đến ngày:"
//               applyChange={applyChange}
//               prop={"DenNgay"}
//               value={data?.DenNgay}
//               placeholder={""}
//             />
//           </Col>
//           <Col span={6}>
//             <InputNumberCustom
//               label="Số tháng:"
//               applyChange={applyChange}
//               prop={"SoThang"}
//               value={data?.SoThang}
//               placeholder="0"
//               required={true}
//             />{" "}
//             {errors && (
//               <p style={{ color: "red", textAlign: "center" }}>
//                 {errors && errors["SoThang"]}
//               </p>
//             )}
//           </Col>
//           <Col span={6}>
//             <InputNumberCustom
//               label="% chiết khấu:"
//               applyChange={applyChange}
//               prop={"ChietKhau"}
//               value={data?.ChietKhau}
//               placeholder="0"
//               max={100}
//             />
//           </Col>
//           <Col style={{ textAlign: "center" }} span={6}>
//             <Checkbox
//               style={{ marginTop: "5px" }}
//               value={data?.TaoHD}
//               checked={data.TaoHD}
//               onChange={(e) => applyChange("TaoHD", e.target.value)}
//               disabled={disabled}
//             >
//               Tạo hóa đơn tất cả xe
//             </Checkbox>
//           </Col>
//           <Col style={{ textAlign: "center" }} span={6}>
//             <Button onClick={() => taoHoaDon()}>
//               {" "}
//               <PlusOutlined
//                 style={{
//                   fontSize: "13px",
//                   marginLeft: " -4px",
//                   color: "green",
//                 }}
//               />
//               Tạo hóa đơn
//             </Button>
//           </Col>
//         </Row>
//       </>
//     );
//   };
//   const taoHoaDon = () => {
//     let _data = _.cloneDeep(data);

//     let valid = validate();
//     if (valid?.length > 0) {
//       setLoading(false);
//       return;
//     }
//     const startDate = dayjs(_data?.TuNgay).startOf("month");
//     const endDate = dayjs(_data?.DenNgay).startOf("month");

//     const monthsArray = [];

//     if (!_data?.TaoHD) {
//       const truChietKhau =
//         _data?.PhiQuanLy - (_data?.PhiQuanLy * (_data?.ChietKhau ?? 0)) / 100;

//       const TX = theXe.find((item) => item.ID === _data.MaTX);
//       const truChietKhauTX =
//         TX?.SoTien - (TX?.SoTien * (_data?.ChietKhau ?? 0)) / 100;

//       let currentDate = startDate;

//       while (
//         currentDate.isBefore(endDate) ||
//         currentDate.isSame(endDate, "month")
//       ) {
//         if (_data?.MaLDV === 6) {
//           monthsArray.push({
//             DienTichThuPhi: _data.DienTichThuPhi,
//             SoTienPerMet: _data.SoTienPerMet,
//             MaKN: _data?.MaKN,
//             MaTL: _data?.MaTL,
//             MaSoMB: _data?.MaSoMB,
//             DaThu: truChietKhauTX,
//             SoTien: TX?.SoTien,
//             IDTheXe: TX?.ID,
//             MaKH: _data?.MaKH,
//             MaMB: _data?.MaMB,
//             MaLDV: _data?.MaLDV,
//             TenKH: _data?.KhachHang,

//             DichVu: _data?.TenLDV,
//             NgayTT: currentDate.format("YYYY-MM-DD"),
//           });
//         } else {
//           monthsArray.push({
//             MaKN: _data?.MaKN,
//             MaTL: _data?.MaTL,
//             DienTichThuPhi: _data.DienTichThuPhi,
//             SoTienPerMet: _data.SoTienPerMet,
//             DaThu: truChietKhau,
//             SoTien: _data?.PhiQuanLy,
//             MaKH: _data?.MaKH,
//             MaMB: _data?.MaMB,
//             MaLDV: _data?.MaLDV,
//             TenKH: _data?.KhachHang,
//             MaSoMB: _data?.MaSoMB,
//             DichVu: _data?.TenLDV,
//             NgayTT: currentDate.format("YYYY-MM-DD"),
//           });
//         }

//         currentDate = currentDate.add(1, "month");
//       }
//     } else {
//       theXe.forEach((item) => {
//         let truChietKhauTX =
//           item?.SoTien - (item?.SoTien * (_data?.ChietKhau ?? 0)) / 100;

//         let currentDate = startDate;

//         while (
//           currentDate.isBefore(endDate) ||
//           currentDate.isSame(endDate, "month")
//         ) {
//           monthsArray.push({
//             MaKN: _data?.MaKN,
//             MaTL: _data?.MaTL,
//             DienTichThuPhi: _data.DienTichThuPhi,
//             SoTienPerMet: _data.SoTienPerMet,
//             DaThu: truChietKhauTX,
//             SoTien: item?.SoTien,
//             IDTheXe: item?.ID,
//             MaKH: _data?.MaKH,
//             MaMB: _data?.MaMB,
//             MaLDV: _data?.MaLDV,
//             TenKH: _data?.KhachHang,
//             MaSoMB: _data?.MaSoMB,
//             DichVu: _data?.TenLDV,
//             NgayTT: currentDate.format("YYYY-MM-DD"),
//           });
//           currentDate = currentDate.add(1, "month");
//         }
//       });
//     }

//     setDataTable(monthsArray);
//   };

//   const columns = [
//     {
//       title: "Tên Khách Hàng",
//       dataIndex: "TenKH",
//       key: "TenKH",
//     },
//     {
//       title: "Mặt Bằng",
//       dataIndex: "MaSoMB",
//       key: "MaSoMB",
//     },
//     {
//       title: "Dịch Vụ",
//       dataIndex: "DichVu",
//       key: "DichVu",
//     },
//     {
//       title: "Ngày Thanh Toán",
//       dataIndex: "NgayTT",
//       key: "NgayTT",
//     },
//     {
//       title: "Tiền Thanh Toán",
//       dataIndex: "SoTien",
//       key: "SoTien",
//     },
//     {
//       title: "Tiền Sau CK",
//       dataIndex: "DaThu",
//       key: "DaThu",
//     },
//   ];
//   return (
//     <Drawer
//       title="Hóa đơn thu trước"
//       width={1000}
//       onClose={onClose}
//       open={open}
//       styles={{
//         body: {
//           paddingBottom: 30,
//         },
//       }}
//       extra={
//         <Space>
//           <Button
//             loading={loading}
//             onClick={() => onSubmit(true)}
//             type="primary"
//           >
//             <SaveOutlined /> Lưu & Đóng
//           </Button>
//         </Space>
//       }
//     >
//       {contextHolder}

//       {renderThongTinChung()}
//       <Table
//         style={{ marginTop: "25px" }}
//         dataSource={dataTable}
//         columns={columns}
//         pagination={false}
//       />
//     </Drawer>
//   );
// };
// export default forwardRef(FormAdd);


import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
  Table,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { CategoryService } from "services/CategoryService";
import { DichVuService } from "services/DichVuService";
import { CongNoService } from "services/CongNoService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { filterCongNo, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    TuNgay: dateInHoChiMinh.toISO(),
    DenNgay: dateInHoChiMinh.toISO(),
    TaoHD: false,
  };
  const [data, setData] = useState(defaulData);
  const [dataTable, setDataTable] = useState();
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taoHoaDon2, setTaoHoaDon2] = useState(false);
  const [loaiDV, setLoaiDV] = useState();
  const [theXe, setTheXe] = useState();
  const [getMB, setGetMB] = useState();

  const [disabled, setDisabled] = useState(false);
  const defaultErrors = {
    MatBang: null,
    MaLDV: null,
    // SoThang: null,
    DenNgay: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const filterDV = async () => {
    let _res = await CategoryService.Khac.get();
    if (_res.data) {
      setLoaiDV(_res.data.filter((item) => item.ID === 6 || item.ID === 12));
    }
    
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      // console.log(item);

      setOpen(true);
      setIsUpdate(false);
      await filterDV();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
      filterDV();
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setDataTable();
    setTaoHoaDon2(false);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MatBang":
        const KhachHang2 = JSON.parse(v.title);
        _data.KhachHang = KhachHang2.TenKH;
        _data.MaSoMB = KhachHang2.MaSoMB;
        _data.MaKH = KhachHang2.MaKH;
        _data.MaMB = KhachHang2.MaMB;
        _data.MaKN = KhachHang2.MaKN;
        _data.DienTichThuPhi = KhachHang2.DienTichThuPhi;
        _data.SoTienPerMet = KhachHang2.SoTienPerMet;
        _data.MaTL = KhachHang2.MaTL;
        _data.MaTN = KhachHang2.MaTN;
        _data.PhiQuanLy = KhachHang2.PhiQuanLy;
        _data[p] = v;
        let _resTX = await DichVuService.Xe.getTheXe({
          MaMB: _data?.MaMB,
        });
        if (_resTX.data) {
          setTheXe(_resTX.data);
        }
        break;
      case "TuNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        _data.SoThang =
          (new Date(_data?.DenNgay).getFullYear() -
            new Date(isoString).getFullYear()) *
            12 +
          (new Date(_data?.DenNgay).getMonth() -
            new Date(isoString).getMonth()) +
          1;

        break;
      case "DenNgay":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        _data.SoThang =
          (new Date(isoString2).getFullYear() -
            new Date(_data?.TuNgay).getFullYear()) *
            12 +
          (new Date(isoString2).getMonth() -
            new Date(_data?.TuNgay).getMonth()) +
          1;
        break;
      case "TaoHD":
        _data[p] = !v;
        break;
      case "SoThang":
        // let denNgay = new Date(_data.TuNgay).setMonth(
        //   new Date(_data.TuNgay).getMonth() + v - 1
        // );
        let denNgay = new Date(_data.TuNgay);
        denNgay.setMonth(denNgay.getMonth() + v - 1);
        denNgay = new Date(denNgay.getFullYear(), denNgay.getMonth() + 1, 0);
        _data[p] = v;
        _data.DenNgay = denNgay;
        break;
      case "MaLDV":
        _data[p] = v;
        _data.TenLDV = loaiDV.find((item) => item.ID === v)?.Name;
        if (v === 12) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    if (taoHoaDon2 === false) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng tạo hóa đơn để lưu và xuất hóa đơn"
      );
      return;
    }
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await CongNoService.HoaDonChiTiet.addHoaDon({
      MaTN: _data?.MaTN,
      List: dataTable,
    });

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      filterCongNo(_data);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "20px" }} gutter={[20, 12]}>
          <Col span={12}>
            <Flex style={{ borderBottom: "1px solid #ff9087" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                }}
              >
                Mặt bằng:<span style={{ color: "red" }}>*</span>
              </p>
              <DebounceSelect
                open={open}
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MatBang"]}
              </p>
            )}
          </Col>

          <Col span={12}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder=""
              disabled={true}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              required={true}
              label="Loại dịch vụ:"
              applyChange={applyChange}
              prop="MaLDV"
              value={data?.MaLDV}
              placeholder="Chọn loại dịch vụ"
              options={loaiDV}
              fieldNames={{ label: "Name", value: "ID" }}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaLDV"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <SelectCustom
              required={true}
              disabled={disabled}
              label="Thẻ xe:"
              applyChange={applyChange}
              prop="MaTX"
              value={data?.MaTX}
              placeholder="Chọn thẻ xe"
              options={theXe}
              fieldNames={{ label: "BienSo", value: "ID" }}
            />

            {errors && !disabled && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaTX"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
              placeholder={""}
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              required={true}
              label="Đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
              placeholder={""}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["DenNgay"]}
              </p>
            )}
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Số tháng:"
              applyChange={applyChange}
              prop={"SoThang"}
              value={data?.SoThang}
              placeholder="0"
              // required={true}
            />{" "}
            {/* {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["SoThang"]}
              </p>
            )} */}
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="% chiết khấu:"
              applyChange={applyChange}
              prop={"ChietKhau"}
              value={data?.ChietKhau}
              placeholder="0"
              max={100}
            />
          </Col>
          <Col style={{ textAlign: "center" }} span={6}>
            <Checkbox
              style={{ marginTop: "5px" }}
              value={data?.TaoHD}
              checked={data.TaoHD}
              onChange={(e) => applyChange("TaoHD", e.target.value)}
              disabled={disabled}
            >
              Tạo hóa đơn tất cả xe
            </Checkbox>
          </Col>
          <Col style={{ textAlign: "center" }} span={6}>
            <Button onClick={() => taoHoaDon()}>
              {" "}
              <PlusOutlined
                style={{
                  fontSize: "13px",
                  marginLeft: " -4px",
                  color: "green",
                }}
              />
              Tạo hóa đơn
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  const taoHoaDon = () => {
    let _data = _.cloneDeep(data);

    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    setTaoHoaDon2(true);
    const startDate = dayjs(_data?.TuNgay).startOf("month");
    const endDate = dayjs(_data?.DenNgay).startOf("month");

    const monthsArray = [];

    if (!_data?.TaoHD) {
      const truChietKhau =
        _data?.PhiQuanLy - (_data?.PhiQuanLy * (_data?.ChietKhau ?? 0)) / 100;

      const TX = theXe?.find((item) => item.ID === _data.MaTX);
      const truChietKhauTX =
        TX?.SoTien - (TX?.SoTien * (_data?.ChietKhau ?? 0)) / 100;

      let currentDate = startDate;

      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "month")
      ) {
        if (_data?.MaLDV === 6) {
          monthsArray.push({
            DienTichThuPhi: _data.DienTichThuPhi,
            SoTienPerMet: _data.SoTienPerMet,
            MaKN: _data?.MaKN,
            MaTL: _data?.MaTL,
            MaSoMB: _data?.MaSoMB,
            DaThu: truChietKhauTX,
            SoTien: TX?.SoTien,
            IDTheXe: TX?.ID,
            MaKH: _data?.MaKH,
            MaMB: _data?.MaMB,
            MaLDV: _data?.MaLDV,
            TenKH: _data?.KhachHang,

            DichVu: _data?.TenLDV,
            NgayTT: currentDate.format("YYYY-MM-DD"),
          });
        } else {
          monthsArray.push({
            MaKN: _data?.MaKN,
            MaTL: _data?.MaTL,
            DienTichThuPhi: _data.DienTichThuPhi,
            SoTienPerMet: _data.SoTienPerMet,
            DaThu: truChietKhau,
            SoTien: _data?.PhiQuanLy,
            MaKH: _data?.MaKH,
            MaMB: _data?.MaMB,
            MaLDV: _data?.MaLDV,
            TenKH: _data?.KhachHang,
            MaSoMB: _data?.MaSoMB,
            DichVu: _data?.TenLDV,
            NgayTT: currentDate.format("YYYY-MM-DD"),
          });
        }

        currentDate = currentDate.add(1, "month");
      }
    } else {
      theXe.forEach((item) => {
        let truChietKhauTX =
          item?.SoTien - (item?.SoTien * (_data?.ChietKhau ?? 0)) / 100;

        let currentDate = startDate;

        while (
          currentDate.isBefore(endDate) ||
          currentDate.isSame(endDate, "month")
        ) {
          monthsArray.push({
            MaKN: _data?.MaKN,
            MaTL: _data?.MaTL,
            DienTichThuPhi: _data.DienTichThuPhi,
            SoTienPerMet: _data.SoTienPerMet,
            DaThu: truChietKhauTX,
            SoTien: item?.SoTien,
            IDTheXe: item?.ID,
            MaKH: _data?.MaKH,
            MaMB: _data?.MaMB,
            MaLDV: _data?.MaLDV,
            TenKH: _data?.KhachHang,
            MaSoMB: _data?.MaSoMB,
            DichVu: _data?.TenLDV,
            NgayTT: currentDate.format("YYYY-MM-DD"),
          });
          currentDate = currentDate.add(1, "month");
        }
      });
    }

    setDataTable(monthsArray);
  };

  const columns = [
    {
      title: "Tên Khách Hàng",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Mặt Bằng",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Dịch Vụ",
      dataIndex: "DichVu",
      key: "DichVu",
    },
    {
      title: "Ngày Thanh Toán",
      dataIndex: "NgayTT",
      key: "NgayTT",
    },
    {
      title: "Tiền Thanh Toán",
      dataIndex: "SoTien",
      key: "SoTien",
    },
    {
      title: "Tiền Sau CK",
      dataIndex: "DaThu",
      key: "DaThu",
    },
  ];
  return (
    <Drawer
      title="Hóa đơn thu trước"
      width={1100}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}

      {renderThongTinChung()}
      <Table
        style={{ marginTop: "25px" }}
        dataSource={dataTable}
        columns={columns}
        pagination={false}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
