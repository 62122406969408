import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Tabs,
  Tooltip,
  Select,
  InputNumber,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DichVuService } from "services/DichVuService";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import TextArea from "antd/es/input/TextArea";
import { NhanVienService } from "services/NhanVienService";
const { TabPane } = Tabs;
const { Option } = Select;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, toanha } = props;
  const [api, contextHolder] = notification.useNotification();

  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chuThe, setChuThe] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition?.MaTN }));
      let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
      setNhanVien(_nv.data);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MatBang":
        _data[p] = v;

        const valueMB = JSON.parse(v.title);

        const _res = await DichVuService.Xe.getThongTinTheoMaMB({
          MaMB: valueMB?.MaMB,
          MaKH: valueMB?.MaKH,
        });
        if (_res.data) {
          setChuThe(_res.data);
        }
        _data.MaMB = valueMB?.MaMB;
        _data.MaLMB = valueMB?.MaLMB;
        _data.DienThoai = _res.data?.chuTheChon[0]?.DienThoai;
        _data.QuanHeCHID = _res.data?.chuTheChon[0]?.QuanHeID;
        _data.ChuThe = _res.data?.chuTheChon[0]?.HoTenNK
          ? _res.data?.chuTheChon[0]?.HoTenNK
          : _res.data?.chuTheNhap;
        _data.MaKH = _res.data?.chuTheChon[0]?.ID;

        break;
      case "HaoHut":
        _data.TienHaoHut = (v * (_data?.TienDien ?? 0)) / 100;
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thông tin điện!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const RenderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={12}>
            <InputCustom
              label="Số thẻ:"
              applyChange={applyChange}
              prop={"SoThe"}
              value={data?.SoThe}
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Ngày ĐK:"
              applyChange={applyChange}
              prop={"NgayDK"}
              value={data?.NgayDK}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
              errors={errors}
            />
          </Col>

          <Col span={12}>
            <Flex align="center" style={{ borderBottom: "1px solid #ff9087" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "35% ",
                }}
              >
                Mặt bằng: <span style={{ color: "red" }}>*</span>
              </p>
              <DebounceSelect
                open={open}
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MatBang"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Chủ thẻ(C):"
              applyChange={applyChange}
              prop="MaKH"
              value={data?.MaKH}
              placeholder="Chọn thẻ"
              options={chuThe?.chuTheChon}
              fieldNames={{ label: "HoTenNK", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Chủ thẻ(N):"
              applyChange={applyChange}
              prop="ChuThe"
              value={data?.ChuThe}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Phí làm thẻ:"
              applyChange={applyChange}
              prop={"PhiLamThe"}
              value={data?.PhiLamThe}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Nhân viên:"
              applyChange={applyChange}
              prop="NhanVien"
              value={data?.NhanVien}
              placeholder="Chọn nhân viên"
              options={nhanVien}
              fieldNames={{ label: "HoTenNV", value: "MaNV" }}
            />
          </Col>
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Trạng thái:"
              applyChange={applyChange}
              prop="TrangThai"
              value={data?.TrangThai}
              placeholder="Chọn trạng thái"
              options={[]}
              fieldNames={{ label: "TenDA", value: "MaTN" }}
            />
          </Col>

          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải:
              </p>
              <TextArea
                value={data?.GhiChu}
                onChange={(e) => applyChange("GhiChu", e.target.value)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thêm: thẻ thang máy"
      width={980}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {RenderThongTinChung()}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
