import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { MatBangService } from "services/MatBangService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);
  const [cuDan, setCuDan] = useState([]);
  const [theXe, setTheXe] = useState([]);
  const [congNo, setCongNo] = useState([]);
  const [lichSuTP, setLichSuTP] = useState([]);
  const [lichSuYC, setLichSuYC] = useState([]);
  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.MaMB);
      fetchDataForTab("1", item?.MaMB);
    },
  }));

  const columns = [
    {
      title: "Ký hiệu",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
  
    },
    {
      title: "Lầu",
      dataIndex: "TenTL",
      key: "TenTL",
  
    },
    {
      title: "Loại mặt bằng",
      dataIndex: "TenLMB",
      key: "TenLMB",
  
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      key: "DienTich",
  
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
      key: "TenTT",
  
    },
    {
      title: "Chủ hộ",
      dataIndex: "TenKH",
      key: "TenKH",
  
    },
    {
      title: "Diện tích TP",
      dataIndex: "DienTichThuPhi",
      key: "DienTichThuPhi",
  
    },
    {
      title: "Đơn giá PQL",
      dataIndex: "SoTienPerMet",
      key: "SoTienPerMet",
  
    },
    {
      title: "Phí quản lý",
      dataIndex: "PhiQuanLy",
      key: "PhiQuanLy",
  
    },
    {
      title: "Ngày tính PQL",
      dataIndex: "BatDauTinhPhi",
      key: "BatDauTinhPhi",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày bàn giao MB",
      dataIndex: "NgayBanGiao",
      key: "NgayBanGiao",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Số người HDM",
      dataIndex: "SoNguoiHDM",
      key: "SoNguoiHDM",
  
    },
    {
      title: "Người nhập",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
  
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "DateCreate",
      key: "DateCreate",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Diễn giải",
      width: 200,
      dataIndex: "DienGiai",
      key: "DienGiai",
  
    },
  ];

  const columns2 = [
    {
      title: "Họ và tên",
      dataIndex: "HoTen",
      key: "HoTen",
  
    },
    {
      title: "Quan hệ",
      dataIndex: "QuanHe",
      key: "QuanHe",
  
    },
    {
      title: "Giới tính",
      dataIndex: "GioiTinh",
      key: "GioiTinh",
  
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      key: "NgaySinh",
  
    },
    {
      title: "Số CCCD",
      dataIndex: "CMND",
      key: "CMND",
  
    },
    {
      title: "Ngày cấp",
      dataIndex: "NgayCap",
      key: "NgayCap",
  
    },
    {
      title: "Nơi cấp",
      dataIndex: "NoiCap",
      key: "NoiCap",
  
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoai",
      key: "DienThoai",
  
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
  
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChiTT",
      key: "DiaChiTT",
  
    },
    {
      title: "Quốc tịch",
      dataIndex: "QuocTich",
      key: "QuocTich",
  
    },
    {
      title: "Đăng ký TT",
      dataIndex: "DaDangKyTT",
      key: "DaDangKyTT",
  
    },
  ];
  const columns3 = [
    {
      title: "Số thẻ xe",
      dataIndex: "SoTheXe",
      key: "SoTheXe",
  
    },
    {
      title: "Chủ thẻ",
      dataIndex: "ChuThe",
      key: "ChuThe",
  
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "NgayDangKyThe",
      key: "NgayDangKyThe",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Loại xe",
      dataIndex: "LoaiXe",
      key: "LoaiXe",
  
    },
    {
      title: "Biển số",
      dataIndex: "BienSo",
      key: "BienSo",
  
    },
    {
      title: "Phí làm thẻ",
      dataIndex: "PhiLamThe",
      key: "PhiLamThe",
  
    },
    {
      title: "Ghi chú",
      dataIndex: "DienGiaiTX",
      key: "DienGiaiTX",
  
    },
  ];
  const columns4 = [
    {
      title: "Dịch vụ",
      dataIndex: "Name",
      key: "Name",
  
    },
    {
      title: "Còn nợ",
      dataIndex: "TongCong",
      key: "TongCong",
  
    },
  ];
  const columns5 = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      key: "SoPhieu",
  
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
      key: "NguoiNop",
  
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      key: "DiaChi",
  
    },
    {
      title: "Số tiền",
      dataIndex: "SoTienThanhToan",
      key: "SoTienThanhToan",
  
      render: (item) => (item ? Format_Currency(item) : ""),
    },
    {
      title: "Đợt thanh toán",
      dataIndex: "DotThanhToan",
      key: "DotThanhToan",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Người thu",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
  
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
  
    },
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      key: "NgayThu",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },
  ];
  const columns6 = [
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
      key: "TenTT",
  
    },
    {
      title: "Mã YC",
      dataIndex: "MaYC",
      key: "MaYC",
  
    },
    {
      title: "Ngày YC",
      dataIndex: "NgayYC",
      key: "NgayYC",
  
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "Loại yêu cầu",
      dataIndex: "TenLYC",
      key: "TenLYC",
  
    },
    {
      title: "Tiêu đề",
      dataIndex: "TieuDe",
      key: "TieuDe",
  
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
      key: "NoiDung",
  
    },
    {
      title: "Độ ưu tiên",
      dataIndex: "DoUuTien",
      key: "DoUuTien",
  
    },
    {
      title: "Bộ phận",
      dataIndex: "TenPhongBan",
      key: "TenPhongBan",
  
    },
    {
      title: "Người gửi",
      dataIndex: "NguoiGui",
      key: "NguoiGui",
  
    },
    {
      title: "Số điện thoại",
      dataIndex: "SoDienThoaiNguoiGui",
      key: "SoDienThoaiNguoiGui",
  
    },
    // {
    //   title: "Ghi chú",
    //   dataIndex: "GhiChu",
    //   key: "GhiChu",
    //   ellipsis: true,
    // },
    // {
    //   title: "Hình ảnh",
    //   dataIndex: "HinhAnh",
    //   key: "HinhAnh",
    //   ellipsis: true,
    // },
    // {
    //   title: "Nhân viên xử lý",
    //   dataIndex: "NhanVienXuLy",
    //   key: "NhanVienXuLy",
    //   ellipsis: true,
    // },
    // {
    //   title: "Nhân viên",
    //   dataIndex: "NhanVien",
    //   key: "NhanVien",
    //   ellipsis: true,
    // },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await MatBangService.getLichSuCN({
          MaMB: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;
      case "2":
        let _res2 = await MatBangService.getCuDan({
          MaMB: value,
        });
        if (_res2.data) setCuDan(_res2.data);

        break;
      case "3":
        let _res3 = await MatBangService.getTheXe({
          MaMB: value,
        });
        if (_res3.data) setTheXe(_res3.data);

        break;
      case "4":
        let _res5 = await MatBangService.getCongNo({
          MaMB: value,
        });
        if (_res5.data) setCongNo(_res5.data);

        break;
      case "5":
        let _res6 = await MatBangService.getLichSuTP({
          MaMB: value,
        });
        if (_res6.data) setLichSuTP(_res6.data);

        break;
      case "6":
        let _res8 = await MatBangService.getLichSuYC({
          MaMB: value,
        });
        if (_res8.data) setLichSuYC(_res8.data);
        break;
      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử căn hộ"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1500}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Lịch sử cập nhật" key="1">
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1700,
              }}
            />
          </TabPane>
          <TabPane tab="Cư dân" key="2">
            <Table
              bordered
              pagination={false}
              columns={columns2}
              dataSource={cuDan}
              scroll={{
                y: window.innerHeight - 250,
                x: 1300,
              }}
            />
          </TabPane>
          <TabPane tab="Thẻ xe" key="3">
            <Table
              bordered
              pagination={false}
              columns={columns3}
              dataSource={theXe}
              scroll={{
                y: window.innerHeight - 250,
                x: 800,
              }}
            />
          </TabPane>
          <TabPane tab="Công nợ" key="4">
            <Table
              bordered
              pagination={false}
              columns={columns4}
              dataSource={congNo}
              scroll={{
                y: window.innerHeight - 250,
                x: 500,
              }}
            />
          </TabPane>
          <TabPane tab="Lịch sử thu phí" key="5">
            <Table
              bordered
              pagination={false}
              columns={columns5}
              dataSource={lichSuTP}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="Lịch sử yêu cầu" key="6">
            <Table
              bordered
              pagination={false}
              columns={columns6}
              dataSource={lichSuYC}
              scroll={{
                y: window.innerHeight - 250,
                x: 1200,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
