import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Drawer,
  Input,
  Row,
  Col,
  Button,
  Space,
  notification,
  Select,
  Checkbox,
  InputNumber,
  DatePicker,
} from "antd";
import _ from "lodash";
import { CongTyService } from "services/CongTyService";
import { PermissionService } from "services/PermissionService";
import { NhanVienService } from "services/NhanVienService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
const FormEdit = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [nhommodules, setNhommodules] = useState([]);
  const [server, setServer] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _res = await PermissionService.NhomModules.getNhomModule();
    setNhommodules(_res.data ?? []);
    let _resServer = await PermissionService.CongTy.getServer();
    setServer(_resServer.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      console.log(data);

      setOpen(true);
      setData(data);
      loadCategories();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (activeTab === "0") {
      let _res = await PermissionService.CongTy.addCT(_data);
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Thêm công ty thành công!`);
        onClose();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    } else {
      let _res = await PermissionService.NhomModules.addNhomModule(_data);
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Thêm công ty thành công!`);
        onClose();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    }
    setLoading(false);
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[15, 10]} style={{ marginTop: 20 }}>
        <Col span={8}>
          <p>Mã công ty</p>
          <Input
            placeholder="Nhập tên nhóm quyền"
            onChange={(v) => applyChange("MaCT", v.target.value)}
            value={data.MaCT}
          />
        </Col>
        <Col span={16}>
          <p>Tên công ty</p>
          <Input
            placeholder="Nhập tên nhóm quyền"
            onChange={(v) => applyChange("TenCT", v.target.value)}
            value={data.TenCT}
          />
        </Col>
        <Col span={12}>
          <p>Điện thoại</p>
          <Input
            placeholder="Nhập số điện thoại"
            onChange={(v) => applyChange("DienThoai", v.target.value)}
            value={data.DienThoai}
          />
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Input
            placeholder="Nhập email"
            onChange={(v) => applyChange("Email", v.target.value)}
            value={data.Email}
          />
        </Col>
        <Col span={24}>
          <p>Địa chỉ</p>
          <Input
            placeholder="Nhập địa chỉ"
            onChange={(v) => applyChange("DiaChi", v.target.value)}
            value={data.DiaChi}
          />
        </Col>
        <Col span={12}>
          <p>Tài khoản</p>
          <Input
            placeholder="Tài khoản"
            onChange={(v) => applyChange("TaiKhoan", v.target.value)}
            value={data.TaiKhoan}
          />
        </Col>
        <Col span={12}>
          <p>Mật khẩu</p>
          <Input
            placeholder="Nhập mật khẩu"
            onChange={(v) => applyChange("MatKhau", v.target.value)}
            value={data.MatKhau}
          />
        </Col>
        <Col span={12}>
          <p>Server</p>
          <Select
            defaultValue={1}
            onChange={(v) => applyChange("Server", v)}
            style={{
              width: "100%",
            }}
            options={server}
            value={data?.Server}
            fieldNames={{ label: "Name", value: "Code" }}
          />
        </Col>
        <Col span={12}>
          <p>Nhóm modules</p>
          <Select
            // defaultValue={1}
            onChange={(v) => applyChange("PackageID", v)}
            style={{
              width: "100%",
            }}
            options={nhommodules}
            value={data?.PackageID}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>

        <Col span={12}>
          <p>Ngày hết hạn</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayHetHan)}
            locale={vi}
            onChange={(v) => applyChange("NgayHetHan", v)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Drawer
      title="Thêm công ty"
      width={activeTab == "0" ? 900 : 500}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {renderTab1()}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormEdit);
