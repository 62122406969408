import { Button, Col, Row } from "antd";

export const Sidebar = () => {
  const onDragStart = (event, nodeType, label) => {
    console.log("NodeType:", nodeType);
    console.log("Label:", label);

    const nodeData = JSON.stringify({ nodeType, label });
    event.dataTransfer.setData("application/reactflow", nodeData);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <div style={{ minHeight: "100px" }}>
        <p style={{ fontWeight: "500", paddingBottom: "10px" }}>1. Thao tác</p>
        <Row gutter={[10, 6]}>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) => onDragStart(event, "input", "Thao tac 1")}
            draggable
          >
            <Button color="pink">Thao tác 1</Button>
          </Col>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) => onDragStart(event, "input", "Thao tac 2")}
            draggable
          >
            <Button color="pink">Thao tác 2</Button>
          </Col>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) => onDragStart(event, "input", "Thao tac 3")}
            draggable
          >
            <Button color="pink">Thao tác 3</Button>
          </Col>
        </Row>
      </div>
      <div style={{ minHeight: "100px" }}>
        <p style={{ fontWeight: "500", paddingBottom: "10px" }}>2. Điều kiện</p>
        <Row gutter={[10, 6]}>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) =>
              onDragStart(event, "default", "Điều kiện 1")
            }
            draggable
          >
            <Button color="red">Điều kiện 1</Button>
          </Col>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) =>
              onDragStart(event, "default", "Điều kiện 2")
            }
            draggable
          >
            <Button color="red">Điều kiện 2</Button>
          </Col>
        </Row>
      </div>
      <div style={{ minHeight: "100px" }}>
        <p style={{ fontWeight: "500", paddingBottom: "10px" }}>3. Hành động</p>
        <Row gutter={[10, 6]}>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) => onDragStart(event, "output", "Hành động 1")}
            draggable
          >
            <Button color="green"> Hành động 1</Button>
          </Col>
          <Col
            span={8}
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onDragStart={(event) => onDragStart(event, "output", "Hành động 2")}
            draggable
          >
            <Button color="green"> Hành động 2</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
