import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Flex,
  Menu,
  Row,
  Select,
  Spin,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  DownOutlined,
  ExportOutlined,
  EyeOutlined,
  ImportOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import FormQLHistory from "./FormQLHistory";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import FilterModal from "./FilterModal";
import { HopDongService } from "services/HopDongService";
import TableThueCanHo from "./TableThueCanHo";
import { useHistory } from "react-router-dom";
import "./index.scss";
import FormAddChoThue from "./FormAddChoThue";
import { BsMotherboard } from "react-icons/bs";
import { MdGridView, MdOutlineViewDay } from "react-icons/md";

export default function CanHoChoThue({ setIsCot,isCot }) {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [detailDate, setDetailDate] = useState(dayjs(dateInHoChiMinh.toISO()));
  const [soCot, setSoCot] = useState(7);
  const defaultValue = {
    TuNgay: dateInHoChiMinh.toISO(),
    // DenNgay: dateInHoChiMinh.plus({ days: soCot }).toISO(),
    MaTN: null,
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);

  const [matBang, setMatBang] = useState([]);
  const [listMB, setlistMB] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const defaultDataTitle = () => {
    const daysArray = [];
    // const newDate = date.add(soCot, "day");
    for (let i = 0; i < soCot; i++) {
      const date2 = detailDate.add(i, "day");
      const formattedDate = date2.format("DD/MM");
      const dayOfWeek = date2.format("dddd");
      const capitalizedDayOfWeek =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      daysArray.push(`${capitalizedDayOfWeek} ${formattedDate}`);
    }
    let dateArray2 = [];
    for (let i = 0; i < soCot; i++) {
      const newDate = detailDate.add(i, "day").format("YYYY-MM-DD");
      dateArray2.push(newDate);
    }
    setDateArray(dateArray2);

    return daysArray;
  };
  const [dataTitle, setDataTitle] = useState(defaultDataTitle);

  const [hovered, setHovered] = React.useState(null);
  const [activeButton, setActiveButton] = useState("7 ngày");
  const currentDate = DateTime.now();

  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);

    filter(_filter);
  };

  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await HopDongService.ChoThue.get(_filter);
    let _resMB = await HopDongService.ChoThue.getMatBang(_filter);
    setMatBang(_resMB?.data ?? []);
    if (_res.data) {
      setData(_res.data);
      setLoading(false);
    }
    const result = _resMB?.data?.map((item1) => {
      const relatedData = _res.data?.filter(
        (item2) => item2.MaMB === item1.MaMB
      );
      const ThoiGianThue = relatedData.map((item) => ({
        NgayHL: item.NgayHL,
        ThoiHan: item.ThoiHan,
        NgayKT: item.NgayKT,
      }));

      return {
        ID: relatedData[0]?.ID || null,
        MaMB: item1.MaMB,
        MaSoMB: item1.MaSoMB,
        MaKH: relatedData[0]?.MaKH,
        ThoiGianThue,
      };
    });

    setlistMB(result);
  };

  // const onChangeFilter = (_filter) => {
  //   let time = null;
  //   if (!time) {
  //     time = setTimeout(() => {
  //       filter(_filter);
  //       setFilterCondition(_filter);
  //     }, 500);
  //   }
  //   return () => clearTimeout(time);
  // };
  const timeRef = useRef(null);

  const onChangeFilter = (_filter) => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }

    timeRef.current = setTimeout(() => {
      filter(_filter);
      setFilterCondition(_filter);
    }, 500);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = [].map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const onChangeDate = (date, dateString) => {
    let _filter = _.cloneDeep(filterCondition);

    const daysArray = [];
    // const newDate = date.add(soCot, "day");
    for (let i = 0; i < soCot; i++) {
      const date2 = date.add(i, "day");
      const formattedDate = date2.format("DD/MM");
      const dayOfWeek = date2.format("dddd");
      const capitalizedDayOfWeek =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

      daysArray.push(`${capitalizedDayOfWeek} ${formattedDate}`);
    }
    let dateArray2 = [];
    for (let i = 0; i < soCot; i++) {
      const newDate = date.add(i, "day").format("YYYY-MM-DD");
      dateArray2.push(newDate);
    }
    setDateArray(dateArray2);
    setDetailDate(date);
    setDataTitle(daysArray);
    _filter.TuNgay = date;
    _filter.DenNgay = date.add(soCot, "day").toISOString();
    onChangeFilter(_filter);
  };

  const generateNextDays = (soNgay) => {
    console.log(soNgay);

    const daysArray = [];
    for (let i = 0; i < soNgay; i++) {
      const date = detailDate.add(i, "day");
      const formattedDate = date.format("DD/MM");
      const dayOfWeek = date.format("dddd");
      // daysArray.push(`${dayOfWeek} ${formattedDate}`);
      const capitalizedDayOfWeek =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

      daysArray.push(`${capitalizedDayOfWeek} ${formattedDate}`);
    }
    let dateArray2 = [];
    for (let i = 0; i < soNgay; i++) {
      const newDate = detailDate.add(i, "day").format("YYYY-MM-DD");
      dateArray2.push(newDate);
    }
    setDateArray(dateArray2);
    setDataTitle(daysArray);
  };
  const handleNextDate = () => {
    if (isDisabled) return;
    setIsDisabled(true);
    let _filter = _.cloneDeep(filterCondition);

    const daysArray = [];
    const newDate = detailDate.add(soCot, "day");
    for (let i = 0; i < soCot; i++) {
      const date = newDate.add(i, "day");
      const formattedDate = date.format("DD/MM");
      const dayOfWeek = date.format("dddd");
      const capitalizedDayOfWeek =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      daysArray.push(`${capitalizedDayOfWeek} ${formattedDate}`);
    }
    let dateArray2 = [];
    for (let i = 0; i < soCot; i++) {
      const newDate2 = newDate.add(i, "day").format("YYYY-MM-DD");
      dateArray2.push(newDate2);
    }
    setDateArray(dateArray2);
    setDetailDate(detailDate.add(soCot, "day"));
    setDataTitle(daysArray);
    _filter.TuNgay = newDate;
    _filter.DenNgay = newDate.add(soCot, "day").toISOString();
    onChangeFilter(_filter);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  const handlePreviewDate = () => {
    if (isDisabled) return;
    setIsDisabled(true);
    let _filter = _.cloneDeep(filterCondition);
    const daysArray = [];
    const newDate = detailDate.subtract(1, "day");
    for (let i = soCot - 1; i >= 0; i--) {
      const date = newDate.subtract(i, "day");
      const formattedDate = date.format("DD/MM");
      const dayOfWeek = date.format("dddd");
      const capitalizedDayOfWeek =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      daysArray.push(`${capitalizedDayOfWeek} ${formattedDate}`);
    }
    let dateArray2 = [];
    for (let i = soCot - 1; i >= 0; i--) {
      const newDate2 = newDate.subtract(i, "day").format("YYYY-MM-DD");
      dateArray2.push(newDate2);
    }
    setDateArray(dateArray2);
    setDetailDate(detailDate.subtract(soCot, "day"));
    setDataTitle(daysArray);
    _filter.TuNgay = newDate.subtract(soCot, "day").toISOString();
    _filter.DenNgay = newDate;
    onChangeFilter(_filter);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  const handleButtonClick = (button) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (button) {
      case "7 ngày":
        generateNextDays(7);
        setSoCot(7);
        _filter.TuNgay = detailDate;
        _filter.DenNgay = detailDate.add(7, "day").toISOString();

        onChangeFilter(_filter);
        break;
      case "15 ngày":
        generateNextDays(15);
        setSoCot(15); // Tạm tính 15 ngày
        _filter.TuNgay = detailDate;
        _filter.DenNgay = detailDate.add(15, "day").toISOString();
        onChangeFilter(_filter);
        break;
      case "30 ngày":
        generateNextDays(30);
        setSoCot(30); // Tạm tính 30 ngày
        _filter.TuNgay = detailDate;
        _filter.DenNgay = detailDate.add(30, "day").toISOString();
        onChangeFilter(_filter);
        break;
      case "2 tháng":
        generateNextDays(60);
        setSoCot(60); // Tạm tính 60 ngày
        _filter.TuNgay = detailDate;
        _filter.DenNgay = detailDate.add(60, "day").toISOString();
        onChangeFilter(_filter);
        break;
      case "danh sách":
        // history.push("/admin/mat-bang-cho-thue");
        // setIsCot(false);
        break;
      default:
        break;
    }

    setActiveButton(button);
  };
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        _filter[p] = v;
        break;

      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);

  // Function to sync the scroll of both columns
  const handleScroll = (e) => {
    if (e.target === leftColumnRef.current) {
      rightColumnRef.current.scrollTop = leftColumnRef.current.scrollTop;
    } else {
      leftColumnRef.current.scrollTop = rightColumnRef.current.scrollTop;
    }
  };

  const [renderedContent, setRenderedContent] = useState(null);
  const [isPending, startTransition] = useTransition();

  const handleRender = () => {
    startTransition(() => {
      const content = matBang.map((config, index) => (
        <TableThueCanHo
          key={listMB[index]?.id || index}
          dateArray={dateArray}
          soCot={soCot}
          refDetail={refDetail}
          ThoiGianThue={listMB[index]?.ThoiGianThue}
          data={listMB[index]}
        />
      ));
      setRenderedContent(content);
    });
  };

  // Gọi handleRender khi cần render lại nội dung
  React.useEffect(() => {
    handleRender();
  }, [matBang, listMB, dateArray, soCot, refDetail]);

  return (
    <div>
      {/* <Spin spinning={loading} indicator={<LoadingOutlined spin />} > */}
      <Row style={{ margin: "-10px -20px" }}>
        <Col span={24}>
          <Flex
            style={{ border: "1px solid #d9d9d9" }}
            align="center"
            justify="space-between"
          >
            <Flex align="center">
              <Select
                // mode="multiple"
                // defaultValue={localStorage.getItem("MaTN")}
                value={filterCondition.MaTN}
                placeholder="Chọn dự án"
                onChange={(v) => applyChangeFilter("MaTN", v)}
                style={{
                  width: "200px",
                }}
                options={toanha}
                fieldNames={{ label: "TenTN", value: "MaTN" }}
              />
              <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: activeButton === "7 ngày" ? "#1677ff" : "",
                  color: activeButton === "7 ngày" ? "#fff" : "",
                }}
                onClick={() => handleButtonClick("7 ngày")}
              >
                7 ngày
              </Button>
              <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: activeButton === "15 ngày" ? "#1677ff" : "",
                  color: activeButton === "15 ngày" ? "#fff" : "",
                }}
                onClick={() => handleButtonClick("15 ngày")}
              >
                15 ngày
              </Button>
              <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: activeButton === "30 ngày" ? "#1677ff" : "",
                  color: activeButton === "30 ngày" ? "#fff" : "",
                }}
                onClick={() => handleButtonClick("30 ngày")}
              >
                30 ngày
              </Button>

              {/* <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: activeButton === "2 tháng" ? "#1677ff" : "",
                  color: activeButton === "2 tháng" ? "#fff" : "",
                }}
                onClick={() => handleButtonClick("2 tháng")}
              >
                2 tháng
              </Button> */}
              {/* <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: activeButton === "1 năm" ? "#f5ca1c" : "",
                  color: activeButton === "1 năm" ? "#fff" : "",
                }}
                onClick={() => handleButtonClick("1 năm")}
              >
                1 năm
              </Button> */}
            </Flex>
            <Flex style={{ paddingRight: "10px" }} gap={10} align="center">
              <DatePicker
                format="DD-MM-YYYY"
                value={detailDate}
                style={{ padding: "5px 10px" }}
                onChange={onChangeDate}
              />
              <MdGridView
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: isCot ? "" : "blue",
                }}
                onClick={() => setIsCot(false)}
              />
              <MdOutlineViewDay
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: isCot ? "blue" : "",
                }}
                onClick={() => setIsCot(true)}
              />
            </Flex>
          </Flex>
        </Col>
        {/* <div style={{ display: "flex" }}> */}
        <Col span={2}>
          <div
            ref={leftColumnRef}
            onScroll={handleScroll}
            style={{
              maxHeight: "88vh",
              overflowY: "scroll",
              borderRight: "1px solid #d9d9d9",
            }}
          >
            <Flex
              align="center"
              justify="flex-end"
              style={{
                borderBottom: "1px solid #d9d9d9",
                top: "0px",
                position: "sticky",
                zIndex: "999",
                backgroundColor: "#E6F1Fe",
              }}
            >
              <p
                style={{
                  fontWeight: 600,
                  paddingRight: "5px",
                  textAlign: "center",
                }}
              >
                Mặt bằng
              </p>
              <Tooltip placement="top" title={"Previous page"}>
                <span
                  onClick={() => handlePreviewDate()}
                  style={{
                    padding: "9.5px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <LeftOutlined />
                </span>
              </Tooltip>
            </Flex>
            {matBang?.map((item) => (
              <p
                key={item?.MaMB}
                style={{
                  fontWeight: 600,
                  padding: "0 ",
                  backgroundColor: "white",
                  textAlign: "center",
                  borderBottom: "1px solid #d9d9d9",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.MaSoMB}
              </p>
            ))}
          </div>
        </Col>

        <Col span={21}>
          <div
            ref={rightColumnRef}
            onScroll={handleScroll}
            style={{
              maxHeight: "88vh",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingTop: "0",
                position: "sticky",
                top: "0",
                // backgroundColor: "#E6F1Fe",
                zIndex: "1000",
              }}
            >
              {dataTitle.map((item, index) => {
                const isSunday =
                  item.includes("Chủ nhật") || item.includes("Sunday");

                return (
                  <div
                    key={index}
                    style={{
                      flex: `1 1 calc(100% / ${soCot})`,
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#E6F1Fe",
                        fontWeight: 600,
                        padding: "5px 0",
                        minWidth: "70px",
                        minHeight: "50px",
                        color: isSunday ? "red" : "",
                        fontSize: activeButton === "30 ngày" ? "11px" : "13px",
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "1px solid #d9d9d9",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {item}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* {rowsConfig.map((config, index) => ( */}
            {/* {matBang.map((config, index) => (
              <TableThueCanHo
                key={index}
                dateArray={dateArray}
                soCot={soCot}
                refDetail={refDetail}
                ThoiGianThue={listMB[index]?.ThoiGianThue}
                data={listMB[index]}
              />
            ))} */}
            {matBang.map((config, index) => (
              <TableThueCanHo
                key={index}
                dateArray={dateArray}
                soCot={soCot}
                refDetail={refDetail}
                ThoiGianThue={listMB[index]?.ThoiGianThue}
                data={listMB[index]}
              />
            ))}

            {/* {renderedContent} */}
          </div>
        </Col>

        <Col span={1}>
          <Tooltip placement="top" title={"Next page"}>
            <p
              onClick={() => handleNextDate()}
              style={{ padding: "10px", fontSize: "20px", cursor: "pointer" }}
            >
              <RightOutlined />
            </p>
          </Tooltip>
        </Col>
      </Row>
      {/* </Spin> */}
      <FormAddChoThue
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        ToaNha={toanha}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
    </div>
  );
}
