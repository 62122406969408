import { Flex, InputNumber } from "antd";
import React from "react";
import "./index.scss";
const InputNumberCustom = ({
  label,
  applyChange,
  prop,
  value,
  placeholder,
  disabled,
  max,
  errors,
  addonAfter,
  required,
  widthLabel,
  suffix,
  min,
}) => {
  return (
    <div>
      <Flex
        className={required ? "border-required" : "input-custom"}
        align="center"
        justify="flex-start"
      >
        <label
          htmlFor={prop}
          //   className="text-overflow-ellipsis white-space-nowrap overflow-hidden"
          style={{
            fontWeight: "600",
            width: widthLabel ?? "35%",
            cursor: "pointer",
          }}
        >
          {label} <span style={{ color: "red" }}> {required ? "*" : ""}</span>
        </label>
        <InputNumber
          suffix={suffix ? suffix : ""}
          id={prop}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonAfter={addonAfter ? addonAfter : null}
          disabled={disabled}
          style={{ width: "65% ", border: "none" }}
          value={value}
          // placeholder={placeholder}
          onChange={(value) =>
            typeof applyChange === "function" && applyChange(prop, value)
          }
          max={max ? max : null}
          min={min ? min : null}
        />
      </Flex>
      {errors && (
        <p style={{ color: "red", textAlign: "center" }}>
          {errors && errors[prop]}
        </p>
      )}
    </div>
  );
};

export default InputNumberCustom;
