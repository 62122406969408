import React, { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { DateTime } from "luxon";
import viVN from "antd/es/locale/vi_VN";
import DatePickerCustomFilterYear from "components/Forms/DatePickCustomFilterYear";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    loadcategoriesByMaTN,
    defaultValue,
    setFilterCondition,
  } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = 0;
        _filter[p] = v;
        break;

      case "DenNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const dateInHoChiMinh = date.setZone("Asia/Ho_Chi_Minh");
        const Month = dateInHoChiMinh.month;
        const Year = dateInHoChiMinh.year;
        _filter.Month = Month;
        _filter.Year = Year;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };

  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      <FilterDate onChangeDate={onChangeDate} onOf={onOf} />
      {/* <ConfigProvider locale={viVN}>
        <div className="box-search">
          <p className="label" style={{ marginTop: 10 }}>
            Đến ngày
          </p>
          <DatePicker
            picker="month"
            format="MM-YYYY"
            style={{ width: "100%" }}
            defaultValue={dayjs(filterCondition.DenNgay)}
            placeholder="Đến ngày"
            onChange={(v) => applyChangeFilter("DenNgay", v)}
          />
        </div>
      </ConfigProvider> */}
      {/* <div className="box-search">
        <DatePickerCustomFilterYear
          label="Tháng năm"
          applyChange={applyChangeFilter}
          prop={"DenNgay"}
          value={dayjs()
            .month(filterCondition.Month - 1)
            .year(filterCondition.Year)}
          placeholder={"Chọn ngày nhập"}
        />
      </div> */}
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            // mode="multiple"
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
            // mode="multiple"
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
      </div>
    </div>
  );
};
export default FilterModal;
