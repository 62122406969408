import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Select, Drawer, Divider, Checkbox, Row, Col } from "antd";
import _ from "lodash";
import { PermissionService } from "services/PermissionService";
import { Accordion, AccordionTab } from "primereact/accordion";

const FormAddPerNhomModules = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [nhommodules, setNhommodules] = useState({});
  const [menulv1, setMenulv1] = useState([]);
  const [perID, setPerID] = useState(0);
  const loadModules = async () => {
    let _res2 = await PermissionService.getMenuLevel1({});
    // const menuCT = JSON.parse(localStorage.getItem("menu_congty"));
    // const congtylv1 = _.map(menuCT, "ModuleID");
    // const filteredRoutes = _res2?.data?.filter((item) => {
    //   if (congtylv1?.includes(item.ModuleID)) {
    //     return true;
    //   }
    //   return false;
    // });
    // setMenulv1(filteredRoutes);
    setMenulv1(_res2?.data);
  };

  const loadForm = async (_perid) => {
    let _res = await PermissionService.NhomModules.getForm({
      PackageID: _perid,
    });
    // const menuCT = JSON.parse(localStorage.getItem("menu_congty"));
    // const accessMenuCongTy = menuCT?.map((item) => item.FormID);
    // const filter = _res?.data?.filter((item) => {
    //   if (accessMenuCongTy?.includes(item.ID)) {
    //     return true;
    //   }
    //   return false;
    // });
    // setData(filter);
    setData(_res?.data);

  };

  const loadCategories = async (_perid) => {
    loadModules();
    loadForm(_perid);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setNhommodules(item);
      loadCategories(item);
      setPerID(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    onAfterSubmit();
  };
  const onChangePer = async (item) => {
    if (item.Per) {
      let payload = {
        PackageID: nhommodules,
        HavePer: null,
        FormID: item?.ID,
      };
      let _res = await PermissionService.NhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.ID === item.ID);
        _dt.Per = null;
        setData(_data);
      }
    } else {
      let payload = {
        PackageID: nhommodules,
        FormID: item.ID,
        HavePer: true,
      };

      let _res = await PermissionService.NhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.ID === item.ID);
        _dt.Per = item.ID;
        setData(_data);
      }
    }
  };
  return (
    <Drawer
      title="PHÂN QUYỀN CÔNG TY"
      width={620}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Accordion multiple>
        {menulv1?.map((lv1) => {
          return (
            <AccordionTab key={lv1.ModuleID} header={lv1.ModuleName}>
              {data
                ?.filter((v) => v.ModuleID === lv1.ModuleID)
                ?.map((item) => {
                  return (
                    <div key={item.ID}>
                      <Row gutter={[20]}>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Checkbox
                              onChange={() => onChangePer(item)}
                              checked={item.Per}
                            />
                            <p style={{ fontWeight: "500", fontSize: "16px" }}>
                              {item.Name}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Divider dashed style={{ margin: "8px 0px" }} />
                    </div>
                  );
                })}
            </AccordionTab>
          );
        })}
      </Accordion>
    </Drawer>
  );
};
export default forwardRef(FormAddPerNhomModules);
