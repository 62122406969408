import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiDollar, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  CaretRightOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DollarOutlined,
  DownOutlined,
  ExportOutlined,
  FileTextOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DatePickerCustom2 from "components/Forms/DatePickerCustom2";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { IoIosDocument } from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { HopDongService } from "services/HopDongService";
import FormTaoHoaDon from "./FormTaoHoaDon";
import { FaTags } from "react-icons/fa6";
import FormThanhLy from "./FormThanhLy";
import FormAddPhuLuc from "../PhuLuc/FormAddPhuLuc";
export default function HopDongThue() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [valueModal, setValueModal] = useState([]);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfYear = now.startOf("year");
  const endOfYear = now.endOf("year");
  const defaultValue = {
    TuNgay: startOfYear.toISO(),
    DenNgay: endOfYear.toISO(),
    MaTN: null,
    InputSearch: "",
  };
  const [state, setState] = useState([
    { key: "ALL", name: "Tất cả", color: "gray", total: 0 },
    { key: "NEW", name: "Chờ duyệt", color: "blue", total: 0 },
    { key: "ACCEPT", name: "Đã duyệt", color: "green", total: 0 },
    {
      key: "PENDING_APPROVAL",
      name: "Thanh lý chờ duyệt",
      color: "orange",
      total: 0,
    },
    { key: "CLEARED", name: "Đã thanh lý", color: "red", total: 0 },
  ]);
  const [stateActive, setStateActive] = useState({
    key: "ALL",
    name: "Tất cả",
    color: "gray",
  });
  const changeState = (state) => {
    if (state === "ALL") {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.State === state);
      setData(_data);
    }
  };
  useEffect(() => {
    const counts = dataAll.reduce(
      (acc, item) => {
        acc.ALL += 1;
        if (acc[item.State] !== undefined) {
          acc[item.State] += 1;
        }
        return acc;
      },
      { ALL: 0, NEW: 0, ACCEPT: 0, PENDING_APPROVAL: 0, CLEARED: 0 }
    );

    setState((prevState) =>
      prevState.map((item) => ({
        ...item,
        total: counts[item.key] || 0,
      }))
    );
  }, [dataAll]);

  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refTaoHoaDon = useRef(null);
  const refHistory = useRef(null);
  const refDetailPL = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    filter(_filter);
  };

  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await HopDongService.ChoThue.getHD(_filter);
    if (_res.data) {
      setData(_res.data);
      setDataAll(_res.data);
      setLoading(false);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const handleCancelModal = async () => {
    setIsModalOpen(false);
  };

  const handleDuyetHD = async (id) => {
    setLoading(true);
    let _res = await HopDongService.ChoThue.duyetHD({
      ID: id,
      State: "ACCEPT",
    });
    if (_res.data) {
      setLoading(false);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Duyệt hợp đồng thành công!`);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleHuyDuyetHD = async (id) => {
    setLoading(true);
    let _res = await HopDongService.ChoThue.duyetHD({
      ID: id,
      State: "REJECT",
    });
    if (_res.data) {
      setLoading(false);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Hủy duyệt hợp đồng thành công!`);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "Số HĐ",
      width:180,
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.SoHD}
        </p>
      ),
    },

    {
      title: "Trạng thái",
      width: 150,
      render: (item) => (
        <Tag
          color={
            item?.State === "ACCEPT"
              ? "green"
              : item?.State === "NEW"
              ? "blue"
              : item?.State === "CLEARED"
              ? "red"
              : item?.State === "REJECT"
              ? "magenta"
              : "orange"
          }
        >
          {item?.State === "ACCEPT"
            ? "Đã duyệt"
            : item?.State === "NEW"
            ? "Chờ duyệt"
            : item?.State === "PENDING_APPROVAL"
            ? "Thanh lý chờ duyệt"
            : item?.State === "REJECT"
            ? "Hủy duyệt"
            : "Đã thanh lý"}
        </Tag>
      ),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      width: 180,
    },
    {
      title: "Số CCCD",
      dataIndex: "CMND",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },

    {
      title: "Ngày nhập",
      dataIndex: "NgayTao",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày HS",
      dataIndex: "NgayHD",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Người cập nhật",
      dataIndex: "NguoiSua",
    },

    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCN",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Di động",
      dataIndex: "DienThoaiKH",
    },
    // {
    //   title: "Tiền cọc",
    //   dataIndex: "TienCoc",
    // },

    // {
    //   title: "Đã thu cọc",
    //   dataIndex: "DaThuCoc",
    // },
    // {
    //   title: "Còn lại cọc",
    //   dataIndex: "ConLaiCoc",
    // },
    {
      title: "Thời hạn",
      dataIndex: "ThoiHan",
    },

    {
      title: "Ngày ký",
      dataIndex: "NgayKy",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "NgayHL",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    // {
    //   title: "Tùy chọn nhắc",
    //   dataIndex: "TuyChonNhac",
    // },

    // {
    //   title: "Ngày nhắc",
    //   dataIndex: "NgayNhac",
    //   render: (item) => (item ? Format_Date(item) : ""),
    // },

    // {
    //   title: "Đến ngày",
    //   dataIndex: "DenNgay",
    //   render: (item) => (item ? Format_Date(item) : ""),
    // },

    {
      title: "Ngày KT",
      dataIndex: "NgayKT",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      width: 200,
    },

    {
      title: "",
      fixed: "right",
      width: "200px",
      render: (item) => {
        return (
          <div>
            {item?.State === "NEW" ? (
              <Popconfirm
                title="Duyệt hợp đồng"
                description="Bạn có chắc chắn muốn duyệt hợp đồng?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleDuyetHD(item.ID)}
              >
                <Tooltip placement="topRight" title="Duyệt">
                  <Button
                    style={{
                      marginLeft: 10,
                      color: "green",
                      border: "1px solid green",
                    }}
                    icon={<CheckCircleOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            ) : item?.State === "ACCEPT" ? (
              <>
                <Tooltip placement="topRight" title="Phụ lục">
                  <Button
                    onClick={() => refDetailPL.current.update(item)}
                    style={{
                      marginLeft: 10,
                      color: "grey",
                      border: "1px solid grey",
                    }}
                    icon={<FileTextOutlined />}
                  />
                </Tooltip>
                <Popconfirm
                  title="Hủy duyệt hợp đồng"
                  description="Bạn có chắc chắn muốn hủy duyệt hợp đồng?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => handleHuyDuyetHD(item.ID)}
                >
                  <Tooltip placement="topRight" title="Hủy duyệt">
                    <Button
                      style={{
                        marginLeft: 10,
                        color: "magenta",
                        border: "1px solid magenta",
                      }}
                      icon={<CheckCircleOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
                <Tooltip placement="topRight" title="Thanh lý">
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                      setValueModal(item);
                    }}
                    style={{
                      marginLeft: 10,
                      color: "red",
                      border: "1px solid red",
                    }}
                    icon={<CloseOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              ""
            )}

            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            {item?.State === "NEW" && (
              <Popconfirm
                title="Xoá hợp đồng thuê"
                description="Bạn có chắc chắn muốn xoá hợp đồng thuê?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(item.ID)}
              >
                <Tooltip placement="topRight" title="Xoá">
                  <Button
                    style={{ marginLeft: 10 }}
                    danger
                    ghost
                    icon={<CiTrash />}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await HopDongService.ChoThue.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      setDataAll(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    setDataSelect(
      data.filter(
        (item) =>
          newSelectedRowKeys.includes(item.key) && item?.State === "ACCEPT"
      )
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };

  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        refTaoHoaDon.current.show();
        break;
      case "4":
        break;
      case "5":
        break;

      default:
        break;
    }
  };
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
     
    </Menu>
  );
  const handleMenuClick = (e) => {
    if (e.key === "2") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn hóa đơn, xin cảm ơn."
        );
        return;
      }
    }
    if (dataSelect?.length <= 0 && e.key === "2") {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn hóa đơn đã duyệt, xin cảm ơn."
      );
      return;
    }
    refTaoHoaDon.current.show(e.key);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo tòa nhà
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo hóa đơn
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "150px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
              {state?.map((item) => {
                return (
                  <Badge
                    key={item.key}
                    count={item.total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.key);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item.color}`,
                        padding: "5px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.key === item.key ? item.color : null,
                        color: stateActive.key === item.key ? "white" : null,
                      }}
                    >
                      <p style={{ fontSize: "12px" }}>{item.name}</p>
                    </div>
                  </Badge>
                );
              })}
            </Flex>
          )}
          right={() => (
            <Flex gap={5}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  {/* <DollarOutlined /> */}
                  Tạo công nợ
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />

        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2100,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          // virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell
                    index={0}
                    colSpan={10}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>0</p>
                  </Table.Summary.Cell> */}
                  {/* <Table.Summary.Cell
                    index={0}
                    colSpan={10}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              showSizeChanger
              total={data[0]?.TotalRows}
              defaultPageSize={20}
            />
          )}
        </div>
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        ToaNha={toanha}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormThanhLy
        filterCondition={filterCondition}
        handleCancel={handleCancelModal}
        isModalOpen={isModalOpen}
        // caseModal={caseModal}
        setIsModalOpen={setIsModalOpen}
        selectedRowKeys={selectedRowKeys}
        onAfterSubmit={onAfterSubmit}
        valueModal={valueModal}
      />
      <FormTaoHoaDon
        ref={refTaoHoaDon}
        dataSelect={dataSelect}
        selectedRowKeys={selectedRowKeys}
        filterCondition={filterCondition}
        setSelectedRowKeys={setSelectedRowKeys}
        onAfterSubmit={onAfterSubmit}
      />
      <FormAddPhuLuc
        ref={refDetailPL}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        ToaNha={toanha}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
    </Row>
  );
}
