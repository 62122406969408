import React, { useState } from "react";
import { Select } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { DateTime } from "luxon";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    loadcategoriesByMaTN,
    defaultValue,
    setFilterCondition,
  } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "LoaiDV":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month");
  const defaultDate = {
    Name: "Tháng này",
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
  };

  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      <FilterDate onChangeDate={onChangeDate} defaultDateProp={defaultDate} onOf={onOf} />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("LoaiDV", v)}
          style={{
            width: "100%",
          }}
          options={options.LoaiDV}
          fieldNames={{ label: "TenLDV", value: "MaLDV" }}
        />
      </div>
      {/* 
      <div className="box-search">
        <p className="label">Loại dịch vụ</p>
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("LoaiDV", v)}
          style={{
            width: "100%",
          }}
          options={options.LoaiDV}
          fieldNames={{ label: "TenLDV", value: "MaLDV" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
