import React, { useState } from "react";
import { Select } from "antd";
import _ from "lodash";
import ActiveRoute from "components/ActiveRoute";
import { YeuCauService } from "services/YeuCauService";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    defaultValue,
    setFilterCondition,
  } = props;
  const [loaiyeucau, setLoaiyeucau] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
      case "DoUuTien":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "MaTN":
        _filter[p] = v;
        _filter.MaBP = null;
        _filter.MaLYC = null;
        localStorage.setItem("MaTN", v);
        break;
      case "MaBP":
        loadLoaiYeuCau(v);
        _filter.MaLYC = null;
        _filter[p] = v;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const loadLoaiYeuCau = async (mapb) => {
    let _res = await YeuCauService.Categories.getLoaiYeuCau({ MaPB: mapb });
    setLoaiyeucau(_res.data);
  };

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const [onOf, setOnOf] = useState(false);
  return (
    <div className="">
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
        }}
      />
      {/* <div className="box-search">
        <p className="label">Thời gian</p>
        <Popover content={content} placement="right" trigger="click">
          <Flex gap={10} align="center">
            <Checkbox
              style={{ borderRadius: "50%" }}
              //  onChange={onChange}
            />
            <Flex
              style={{
                borderBottom: "1px solid #d9d9d9",
                width: "90%",
                padding: "3px 0px",
              }}
              align="center"
              justify="space-between"
            >
              <p>Hôm nay</p>
              <FaSort color="#d9d9d9" />
            </Flex>
          </Flex>
        </Popover>
        <Flex gap={10} align="center">
          <Checkbox
            style={{ borderRadius: "50%" }}
            //  onChange={onChange}
          />
          <DatePicker
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            defaultValue={dayjs(filterCondition.DenNgay)}
            placeholder="Lựa chọn khác"
            // locale={locale}
            onChange={(v) => applyChangeFilter("DenNgay", v)}
          />
        </Flex>
      </div> */}
      <FilterDate onChangeDate={onChangeDate} onOf={onOf} />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            // mode="multiple"
            value={filterCondition.MaBP}
            placeholder="Chọn phòng ban"
            onChange={(v) => applyChangeFilter("MaBP", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.PhongBan}
            fieldNames={{ label: "TenPB", value: "MaPB" }}
          />
        )}
        {filterCondition?.MaBP && (
          <Select
            // mode="multiple"
            value={filterCondition.MaLYC}
            placeholder="Chọn loại yêu cầu"
            onChange={(v) => applyChangeFilter("MaLYC", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={loaiyeucau}
            fieldNames={{ label: "TenLYC", value: "MaLYC" }}
          />
        )}
        <Select
          mode="multiple"
          value={filterCondition.DoUuTien.split(",")
            .filter(Boolean)
            .map(Number)}
          placeholder="Chọn mức độ ưu tiên"
          onChange={(v) => applyChangeFilter("DoUuTien", v)}
          style={{
            width: "100%",
          }}
          options={options?.MucDo}
          fieldNames={{ label: "TenDoUuTien", value: "MaDoUuTien" }}
        />
        <Select
          mode="multiple"
          value={filterCondition.HinhThuc.split(",")
            .filter(Boolean)
            .map(Number)}
          placeholder="Chọn hình thức"
          onChange={(v) => applyChangeFilter("HinhThuc", v)}
          style={{
            width: "100%",
          }}
          options={options?.HinhThuc}
          fieldNames={{ label: "TenHT", value: "ID" }}
        />
        <Select
          mode="multiple"
          value={filterCondition.NhanVien.split(",")
            .filter(Boolean)
            .map(Number)}
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={options?.NhanVien}
          fieldNames={{ label: "HoTenNV", value: "MaNV" }}
        />
      </div>
      {/* 
      <div className="box-search">
        <p className="label">Mức độ ưu tiên</p>
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn mức độ ưu tiên"
          onChange={(v) => applyChangeFilter("DoUuTien", v)}
          style={{
            width: "100%",
          }}
          options={options?.MucDo}
          fieldNames={{ label: "TenDoUuTien", value: "MaDoUuTien" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Hình thức tiếp nhận</p>
        <Select
          mode="multiple"
          // value={filterCondition.HinhThuc}
          placeholder="Chọn hình thức"
          onChange={(v) => applyChangeFilter("HinhThuc", v)}
          style={{
            width: "100%",
          }}
          options={options?.HinhThuc}
          fieldNames={{ label: "TenHT", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Nhân viên xử lý</p>
        <Select
          mode="multiple"
          // value={filterCondition.MaTN}
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={options?.NhanVien}
          fieldNames={{ label: "HoTenNV", value: "MaNV" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
