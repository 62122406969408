import React, { useRef, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  Flex,
  ConfigProvider,
  notification,
  Checkbox,
} from "antd";
import vi from "dayjs/locale/vi";
import SelectCustom from "components/Forms/SelectCustom";
import _ from "lodash";
import dayjs from "dayjs";
import viVN from "antd/es/locale/vi_VN";
import { CongNoService } from "services/CongNoService";
import FormDetailMail from "./FormDetailMail";
import { DateTime } from "luxon";

const FormModal = (prop) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const {
    isModalVisible,
    setIsModalVisible,
    bieuMau,
    onAfterSubmit,
    maTN,
    selectedRowKeys,
    dataAll,
  } = prop;
  const defaultData = {
    Year: dayjs(),
    Month: dayjs(),
    IsGopFile: false,
  };
  const refDetailMail = useRef();
  const [data, setData] = useState(defaultData);

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "Year":
        _data[p] = v;
        break;
      case "Month":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const handleOk = () => {
    onSubmit();
  };

  console.log(data);

  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (!_data?.MaBM) {
      openNotification(
        "warning",
        "topRight",
        `Vui lòng chọn biểu mẫu để tiếp tục!`
      );
      return;
    }

    _data.MaTN = maTN;
    _data.Year = dayjs(_data?.Year).month() + 1;
    _data.Month = _data?.Month.$M + 1;

    const matBang = dataAll.filter((item) =>
      selectedRowKeys.includes(item?.MaMB)
    );

    _data.MatBang = matBang.map((item) => ({
      MaCN: 0,
      MaKH: item.MaKH,
      MaMB: item.MaMB,
      SDT: item?.DienThoai,
      TenKH: item?.TenKH,
      Email: item?.Email,
      MaSoMB: item.MaSoMB,
    }));

    if (_data?.ThaoTac === "GuiMail") {
      refDetailMail.current.show(_data);
      return;
    }

    setLoading(true);
    let _res = await CongNoService.TongHop.thaoTacThongBao(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Đang xử lý thông báo!`);
      setIsModalVisible(false);
      setData(defaultData);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setData(defaultData);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  return (
    <>
      <Modal
        width={600}
        title="Thông báo"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Đồng ý"
        cancelText="Hủy"
      >
        <Row style={{ marginTop: "15px" }} gutter={[20, 20]}>
          <Col span={12}>
            <Flex align="center" className={"input-custom"}>
              <label
                htmlFor={"Year"}
                style={{
                  fontWeight: "600",
                  width: "35%",
                  cursor: "pointer",
                }}
              >
                Năm:
              </label>
              <DatePicker
                id="Year"
                style={{
                  minWidth: "65%",
                  border: "none",
                }}
                allowClear={false}
                placeholder="Chọn năm"
                format="YYYY"
                value={data?.Year}
                locale={vi}
                picker="year"
                onChange={(v) => applyChange("Year", v)}
              />
            </Flex>
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Biểu mẫu:"
              applyChange={applyChange}
              prop="MaBM"
              value={data?.MaBM}
              placeholder="Chọn thẻ"
              options={bieuMau}
              fieldNames={{ label: "TenBM", value: "MaBM" }}
            />
          </Col>
          <Col span={12}>
            <Flex align="center" className={"input-custom"}>
              <label
                htmlFor={"Month"}
                style={{
                  fontWeight: "600",
                  width: "35%",
                  cursor: "pointer",
                }}
              >
                Tháng:
              </label>
              <ConfigProvider locale={viVN}>
                <DatePicker
                  id="Month"
                  style={{
                    minWidth: "65%",
                    border: "none",
                  }}
                  allowClear={false}
                  placeholder="Chọn tháng"
                  format="MM"
                  value={data?.Month}
                  locale={vi}
                  picker="month"
                  onChange={(v) => applyChange("Month", v)}
                />
              </ConfigProvider>
            </Flex>
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Thao tác:"
              applyChange={applyChange}
              prop="ThaoTac"
              value={data?.ThaoTac}
              placeholder="Chọn thao tác"
              options={[
                {
                  TenThaoTac: "Xem thông báo",
                  ID: "XemThongBao",
                },
                {
                  TenThaoTac: "In thông báo",
                  ID: "InThongBao",
                },
                {
                  TenThaoTac: "Xuất PDF",
                  ID: "XuatPDF",
                },
                {
                  TenThaoTac: "Giửi gmail",
                  ID: "GuiMail",
                },
                {
                  TenThaoTac: "Tạo thông báo app",
                  ID: "ThongBaoApp",
                },
              ]}
              fieldNames={{ label: "TenThaoTac", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Lần TB:"
              applyChange={applyChange}
              prop="SoLanTB"
              value={data?.SoLanTB}
              placeholder="Chọn lần thông báo"
              options={[
                {
                  TenLanTB: "Lần 1",
                  ID: 1,
                },
                {
                  TenLanTB: "Lần 2",
                  ID: 2,
                },
                {
                  TenLanTB: "Lần 3",
                  ID: 3,
                },
                {
                  TenLanTB: "Lần 4",
                  ID: 4,
                },
                {
                  TenLanTB: "Lần 5",
                  ID: 5,
                },
              ]}
              fieldNames={{ label: "TenLanTB", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <Checkbox
              onChange={(e) => applyChange("IsGopFile", !e.target.value)}
              checked={data?.IsGopFile}
              value={data?.IsGopFile}
            >
              Gộp file
            </Checkbox>
          </Col>
        </Row>
      </Modal>
      {contextHolder}
      <FormDetailMail
        ref={refDetailMail}
        onAfterSubmit={onAfterSubmit}
        setIsModalVisible={setIsModalVisible}
        options={{
          ToaNha: [],
        }}
      />
    </>
  );
};

export default FormModal;
