import axiosApi from "./api_helper";
export const SoQuyService = {
  PhieuThu: {
    reset: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/reset-sophieu", payload)
        .then((res) => res.data);
    },
    edit: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/edit", payload)
        .then((res) => res.data);
    },
    getHinhThucTT: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/category/get-hinhthuctt", payload)
        .then((res) => res.data);
    },
    getChiTietPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-detail-bymapt", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/phieuthutrang/add", payload)
        .then((res) => res.data);
    },
    getLoaiPhieuThu: async () => {
      return await axiosApi()
        .post("api/phieuthu/get-loaipt")
        .then((res) => res.data);
    },
    getPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-list", payload)
        .then((res) => res.data);
    },
    getByID: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-byid", payload)
        .then((res) => res.data);
    },
    addPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/add", payload)
        .then((res) => res.data);
    },
    updatePhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/update", payload)
        .then((res) => res.data);
    },
    deletePhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/delete", payload)
        .then((res) => res.data);
    },

    getSoPT: async (payload) => {
      return await axiosApi()
        .post("api/phieuthutrang/get-sophieu", payload)
        .then((res) => res.data);
    },
    getLoaiDV: async (payload) => {
      return await axiosApi()
        .post("api/category/dichvu/get-tenloaidv", payload)
        .then((res) => res.data);
    },
    getNganHang: async (payload) => {
      return await axiosApi()
        .post("api/toa-nha/get-tknh", payload)
        .then((res) => res.data);
    },
  },
  PhieuThuChiTiet: {
    getPhieuThuChiTiet: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-list-detail", payload)
        .then((res) => res.data);
    },
  },
  PhieuChi: {
    getPhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieu-chi/get-list", payload)
        .then((res) => res.data);
    },

    addPhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/add", payload)
        .then((res) => res.data);
    },
    updatePhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/update", payload)
        .then((res) => res.data);
    },
    deletePhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/delete", payload)
        .then((res) => res.data);
    },
    getDetail: async (payload) => {
      return await axiosApi()
        .post("api/phieu-chi/get-detail-bymapc", payload)
        .then((res) => res.data);
    },
    getPCByID: async (payload) => {
      return await axiosApi()
        .post("api/phieu-chi/get-detail-bymapc", payload)
        .then((res) => res.data);
    },
    
  },
  PhieuHuyTX: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/dichvu/phieuhuy/get-list", payload)
        .then((res) => res.data);
    },
  },
  PhieuThuXoa: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/phieuthuxoa/get-list", payload)
        .then((res) => res.data);
    },
  },
};
