import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Flex,
  Menu,
  notification,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./index.scss";
import Chart from "react-apexcharts";
import { DateTime } from "luxon";
import { ToaNhaService } from "services/ToaNhaService";
import _ from "lodash";
import { HeThongService } from "services/HeThongService";
import { ThongKeService } from "services/ThongKeService";
import FilterModal from "./FilterModal";
import { DashboardService } from "services/dashboardService";
import { Format_Currency } from "layouts/ultis/common";
import TabPane from "antd/es/tabs/TabPane";
import {
  DashOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd/lib";
import Aos from "aos";
import "aos/dist/aos.css";
import ModalAddDashboard from "../ModalAddDashboard";
const MauChoThueDashboard = (props, ref) => {
  const {
    onAfterSubmit,
    options,
    dashboard,
    setMaDashboard,
    dashboardNV,
    setDashboardNV,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toaNha, setToaNha] = useState([]);
  const [data, setData] = useState([]);
  const [dienTich, setDienTich] = useState([]);
  const [tong12Thang, setTong12Thang] = useState({});
  const currentDate = DateTime.now();
  const defaultValue = {
    Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log("Người dùng nhấn OK");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    console.log("Người dùng nhấn Cancel");
    setIsModalVisible(false);
  };

  //    useEffect(() => {
  //      loadCategories(filterCondition);
  //    }, []);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToaNha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    //  let _res = await HeThongService.BieuMau.getLBM();
    //  if (_res.data) setLoaiBM(_res.data);

    setFilterCondition(_filter);
    filter(_filter);
  };
  const filter = async (_filter) => {
    setLoading(true);

    let doanhThu = [];
    let chiPhi = [];
    let _res = await DashboardService.getDienTichMB(_filter);
    if (_res?.data) setDienTich(_res?.data);
    // Lấy dữ liệu Doanh Thu
    let _resDT = await ThongKeService.BaoCao.getDoanhThu(_filter);
    if (_resDT?.data) {
      doanhThu = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        const total = _resDT.data
          .filter(
            (item) => item.Thang === month && item.SoTienThanhToan != null
          )
          .reduce((sum, item) => sum + item.SoTienThanhToan, 0);
        return { Thang: month, TongTienThu: total };
      });
    }

    // Lấy dữ liệu Chi Phí
    let _resCP = await ThongKeService.BaoCao.getChiPhi(_filter);
    if (_resCP?.data) {
      chiPhi = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        const total = _resCP.data
          .filter(
            (item) => item.Thang === month && item.SoTienThanhToan != null
          )
          .reduce((sum, item) => sum + item.SoTienThanhToan, 0);
        return { Thang: month, TongTienChi: total };
      });
    }

    // Gộp dữ liệu thành 1 mảng
    const combinedResult = Array.from({ length: 12 }, (_, i) => {
      const month = i + 1;
      const doanhThuThang =
        doanhThu.find((item) => item.Thang === month)?.TongTienThu || 0;
      const chiPhiThang =
        chiPhi.find((item) => item.Thang === month)?.TongTienChi || 0;
      return {
        Thang: month,
        TongTienThu: doanhThuThang,
        TongTienChi: chiPhiThang,
        LoiNhuan: doanhThuThang - chiPhiThang,
      };
    });

    const totals = combinedResult.reduce(
      (acc, item) => {
        acc.totalDoanhThu += item.TongTienThu;
        acc.totalChiPhi += item.TongTienChi;
        acc.totalLoiNhuan += item.LoiNhuan;
        return acc;
      },
      { totalDoanhThu: 0, totalChiPhi: 0, totalLoiNhuan: 0 }
    );
    setTong12Thang(totals);
    setData(combinedResult);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onClose = () => {
    setOpen(false);
  };

  //   useImperativeHandle(ref, () => ({
  //     show: async (item) => {
  //       loadCategories();
  //       setOpen(true);
  //     },
  //   }));
  useEffect(() => {
    loadCategories();
  }, []);
  function formatToTy(value) {
    if (isNaN(value)) return "Không hợp lệ";
    return (value / 1e9).toFixed(2) + " tỷ";
  }

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: [0, 3],
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
      title: {
        text: "VND",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
  };

  const chartSeriesTKTLX = [
    {
      name: "Doanh thu",
      type: "bar", // Đặt loại là cột
      data: data?.map((item) => item?.TongTienThu),
    },
    {
      name: "Chi phí",
      type: "bar", // Đặt loại là cột
      data: data?.map((item) => item?.TongTienChi),
    },
    {
      name: "Lợi nhuận",
      type: "line", // Đặt loại là đường
      data: data?.map((item) => item?.LoiNhuan),
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    // AOS.refresh();
  }, []);

  const handleTabChange = (key) => {
    setMaDashboard(key);
  };
  const handleAddDashboard = async (item) => {
    setLoading(true);
    let _res = await DashboardService.TranChu.add({
      MaDashboard: item?.ID,
      Name: item?.Name,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Thêm dashboard thành công!`);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleIsView = async (item) => {
    const isLastTrue =
      dashboardNV.filter((dashboard) => dashboard.IsView).length === 1;

    if (isLastTrue && item.IsView) {
      // Hiển thị thông báo cảnh báo
      openNotification(
        "warning",
        "topRight",
        "Tối thiểu phải có 1 dashboard được chọn!"
      );
      return; // Ngăn không cho tiếp tục xử lý
    }
    setLoading(true);
    let _res = await DashboardService.TranChu.isView({
      ID: item?.ID,
      IsView: !item?.IsView,
    });
    setLoading(false);
    if (_res.status === 2000) {
      // openNotification("success", "topRight", `Thêm dashboard thành công!`);
      onAfterSubmit();
    } else {
      // openNotification("error", "topRight", _res?.message);
    }
  };
  const PopoverContent = () => {
    return (
      <div style={{ width: "150px" }}>
        <p style={{ fontWeight: "bold", paddingBottom: "10px" }}>Tùy chỉnh</p>
        {dashboardNV?.map((item, index) => {
          return (
            <>
              <Flex
                style={{ paddingBottom: "10px" }}
                align="center"
                justify="space-between"
                key={item?.ID}
              >
                <Checkbox
                  onChange={() => handleIsView(item)}
                  checked={item?.IsView}
                  value={true}
                >
                  {item?.Name}
                </Checkbox>
                <Popconfirm
                  title="Xoá dashboard"
                  description="Bạn có chắc chắn muốn xoá dashboard?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => handleDelete(item.ID)}
                >
                  <Tooltip placement="topRight" title="Xoá">
                    <DeleteOutlined style={{ color: "red" }} />
                  </Tooltip>
                </Popconfirm>
                {/* <Dropdown
                  overlay={menu(item.ID)}
                  trigger={["hover"]}
                  placement="bottomLeft"
                  overlayStyle={{ zIndex: 9999999999999 }}
                  getPopupContainer={() => document.body}
                >
                  <MoreOutlined style={{ cursor: "pointer" }}></MoreOutlined>
                </Dropdown> */}
              </Flex>
            </>
          );
        })}
      </div>
    );
  };
  const PopoverContentAdd = () => {
    const filteredDashboard = dashboard?.filter(
      (item) => !dashboardNV?.some((nvItem) => nvItem.MaDashboard === item.ID)
    );

    return (
      <div style={{ width: "150px" }}>
        <p style={{ fontWeight: "bold", paddingBottom: "10px" }}>Thêm mới</p>
        {filteredDashboard?.length > 0 ? (
          <>
            {filteredDashboard?.map((item, index) => {
              return (
                <>
                  <Flex
                    style={{ paddingBottom: "10px" }}
                    align="center"
                    justify="space-between"
                    key={item?.ID}
                  >
                    <Checkbox
                      value={true}
                      onChange={() => handleAddDashboard(item)}
                    >
                      {" "}
                      {item?.Name}
                    </Checkbox>
                    <p>
                      <MoreOutlined />
                    </p>
                  </Flex>
                </>
              );
            })}
          </>
        ) : (
          <>
            <p>Đã thêm tất cả mẫu</p>
          </>
        )}
      </div>
    );
  };
  const menu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDelete(id)}>
        Xóa
      </Menu.Item>
    </Menu>
  );
  const handleDelete = async (id) => {
    setLoading(true);
    let _res = await DashboardService.TranChu.delete({
      ID: id,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Xóa dashboard thành công!");
      setDashboardNV((prev) => prev.filter((item) => item.ID !== id));
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <div style={{ padding: "10px 20px" }}>
      <div>
        <Row>
          <Col span={16}>
            <Flex justify="" align="center">
              <Tabs defaultActiveKey="2" onChange={handleTabChange}>
                {dashboardNV
                  ?.filter((item) => item.IsView === true)
                  .map((item) => (
                    <TabPane tab={item?.Name} key={item?.MaDashboard}></TabPane>
                  ))}
              </Tabs>
              <Tooltip title={"Thêm dashboard"}>
                {/* <Popover content={PopoverContentAdd()} trigger="click"> */}
                <p
                  onClick={() => showModal()}
                  style={{
                    paddingBottom: "13px",
                    margin: "0 15px",
                    cursor: "pointer",
                  }}
                >
                  <PlusOutlined />
                </p>{" "}
                {/* </Popover> */}
              </Tooltip>
              <Tooltip title="Tùy chỉnh">
                <Popover content={PopoverContent()} trigger="click">
                  <p
                    style={{
                      paddingBottom: "13px",
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <DashOutlined />
                  </p>
                </Popover>
              </Tooltip>
            </Flex>
          </Col>
          <Col span={8}>
            {" "}
            <FilterModal
              defaultValue={defaultValue}
              setFilterCondition={setFilterCondition}
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
              options={{
                ToaNha: toaNha,
              }}
            />
          </Col>
        </Row>
        <p
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "18px",
            padding: "20px 0",
          }}
        >
          Thống kê chung các tòa nhà
        </p>
        <Row style={{ textAlign: "center" }} gutter={[20, 20]}>
          <Col data-aos="zoom-in" span={8}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "blue", fontSize: "23px" }}>
                {filterCondition?.MaTN ? 1 : toaNha?.length}
              </p>
              <p
                style={{
                  borderBottom: "2px solid rgb(158, 191, 231)",
                  width: "90%",
                  paddingBottom: "15px",
                }}
              >
                Tòa nhà
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  color: "green",
                  fontSize: "23px",
                }}
              >
                {Format_Currency(
                  Math.round(_.sumBy(dienTich, "DienTichThuPhi"))
                )}{" "}
                m2
              </p>
              <p>Diện tích vận hành</p>
            </div>
          </Col>
          <Col data-aos="zoom-in" span={4}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  paddingTop: "10px",
                  color: "green",
                  fontSize: "23px",
                }}
              >
                {Format_Currency(
                  Math.round(
                    _.sumBy(
                      _.filter(dienTich, { MaDoiTuong: 2 }),
                      "DienTichThuPhi"
                    )
                  )
                )}
                m2
              </p>
              <p>Diện tích cho thuê</p>
            </div>
          </Col>
          <Col data-aos="zoom-in" span={8}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  paddingTop: "10px",
                  color: "green",
                  fontSize: "23px",
                }}
              >
                {Format_Currency(
                  Math.round(
                    _.sumBy(
                      _.filter(dienTich, {
                        MaDoiTuong: 1,
                        isDangThue: true,
                      }),
                      "DienTichThuPhi"
                    )
                  ) +
                    Math.round(
                      _.sumBy(
                        _.filter(dienTich, {
                          MaDoiTuong: 2,
                          isDangThue: true,
                        }),
                        "DienTichThuPhi"
                      )
                    )
                )}
                m2
              </p>
              <p
                style={{
                  borderBottom: "2px solid rgb(158, 191, 231)",
                  width: "90%",
                  paddingBottom: "15px",
                }}
              >
                {" "}
                Tổng khai thác
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      paddingTop: "10px",
                      color: "green",
                      fontSize: "23px",
                    }}
                  >
                    {Format_Currency(
                      Math.round(
                        _.sumBy(
                          _.filter(dienTich, {
                            MaDoiTuong: 1,
                            isDangThue: true,
                          }),
                          "DienTichThuPhi"
                        )
                      )
                    )}
                  </p>
                  <p>Nội bộ</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      paddingTop: "10px",
                      color: "green",
                      fontSize: "23px",
                    }}
                  >
                    {Format_Currency(
                      Math.round(
                        _.sumBy(
                          _.filter(dienTich, {
                            MaDoiTuong: 2,
                            isDangThue: true,
                          }),
                          "DienTichThuPhi"
                        )
                      )
                    )}
                  </p>
                  <p>Khai thác cho thuê</p>
                </div>
              </div>
            </div>
          </Col>
          <Col data-aos="zoom-in" span={4}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  paddingTop: "10px",
                  color: "green",
                  fontSize: "23px",
                }}
              >
                {(
                  ((_.sumBy(
                    _.filter(dienTich, {
                      MaDoiTuong: 1,
                      isDangThue: true,
                    }),
                    "DienTichThuPhi"
                  ) +
                    Math.round(
                      _.sumBy(
                        _.filter(dienTich, {
                          MaDoiTuong: 2,
                          isDangThue: true,
                        }),
                        "DienTichThuPhi"
                      )
                    )) /
                    Math.round(_.sumBy(dienTich, "DienTichThuPhi"))) *
                  100
                ).toFixed(2)}{" "}
                %
              </p>
              <p>Tỷ lệ lấp đầy</p>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "15px" }} gutter={[20]}>
          <Col data-aos="zoom-in" span={12}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  paddingTop: "10px",
                  color: "pink",
                  fontSize: "23px",
                }}
              >
                {/* {Format_Currency(tong12Thang?.totalDoanhThu / 1000000000)} */}
                {formatToTy(tong12Thang?.totalDoanhThu)}
              </p>
              <p
                style={{
                  textAlign: "center",
                  borderBottom: "2px solid rgb(158, 191, 231)",
                  width: "90%",
                  paddingBottom: "15px",
                }}
              >
                {" "}
                Tổng doanh thu
              </p>
            </div>
          </Col>
          <Col data-aos="zoom-in" span={12}>
            <div
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(200, 218, 240)",
                backgroundColor: "#E6F1FE",
                padding: "10px",
                fontWeight: "500",
                minHeight: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  paddingTop: "10px",
                  color: "blue",
                  fontSize: "23px",
                }}
              >
                {formatToTy(tong12Thang?.totalChiPhi)}
              </p>
              <p
                style={{
                  textAlign: "center",
                  borderBottom: "2px solid rgb(158, 191, 231)",
                  width: "90%",
                  paddingBottom: "15px",
                }}
              >
                Chi phí
              </p>
            </div>
          </Col>
        </Row>

        <p
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "18px",
            padding: "25px 0",
          }}
        >
          Thông tin tổng hợp tòa nhà
        </p>
        {/* <Row
            style={{ paddingTop: "15px", textAlign: "center" }}
            gutter={[20]}
          >
            <Col span={8}>
              <p style={{ fontSize: "15px" }}>
                Tổng doanh thu:{" "}
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  15.000.000 tỷ VNĐ
                </span>
              </p>
              <p style={{ color: "blue" }}>Xem báo cáo dự kiến doanh thu</p>
            </Col>
            <Col span={8}>
              <p style={{ fontSize: "15px" }}>
                Tổng chi phí:{" "}
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  1.000.000 tỷ VNĐ
                </span>
              </p>
              <p style={{ color: "blue" }}>Xem báo cáo dự kiến chi phí</p>
            </Col>
            <Col span={8}>
              <p style={{ fontSize: "15px" }}>
                Tỷ lệ lấp đầy tòa nhà:{" "}
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  10 %{" "}
                </span>
              </p>
              <p style={{ color: "blue" }}>Xem báo cáo tỷ lệ lấp đầy tòa nhà</p>
            </Col>
          </Row> */}
        <Chart
          data-aos="zoom-in"
          options={chartOptions}
          series={chartSeriesTKTLX}
          type="bar"
          height={350} // Chiều cao biểu đồ
        />
      </div>
      <ModalAddDashboard
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        dashboard={dashboard}
        dashboardNV={dashboardNV}
        handleAddDashboard={handleAddDashboard}
      />
      {contextHolder}
    </div>
  );
};

export default MauChoThueDashboard;
