import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Space, Drawer, notification, Row, Col } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";

const FormDetailNode = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [isupdate, setIsUpdate] = useState(false);
  const [errors, setErrors] = useState();
  const loadCategories = async (data) => {};

  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
      setData(data);
    },
    update: async (data) => {
      setOpen(true);
      loadCategories(data);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKhoThe":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      if (item === "TenCongTy" || item === "DiDong") {
        if (data?.MaKH?.length === 0 || data?.MaKH === undefined) {
          _errors[item] = data[item] ? null : "Không để trống";
        }
        //
      } else {
        _errors[item] = data[item] ? null : "Không để trống";
      }
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      return;
    }

    let _res;
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thẻ thành công!`
      );
      if (close) onClose();
      setIsUpdate(true);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  return (
    <Drawer
      title="CHI TIẾT NODE"
      width={"1080px"}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <h1>{data?.data?.label}</h1>

      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormDetailNode);
