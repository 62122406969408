import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Modal,
  Skeleton,
  Dropdown,
  Tag,
  Button,
  Tabs,
  Input,
  Select,
  Form,
  DatePicker,
  Avatar,
  ConfigProvider,
  Tooltip,
  Menu,
} from "antd";
import _ from "lodash";
import "./index.scss";
import { CiSaveDown1, CiSearch, CiTrash } from "react-icons/ci";
import { ToaNhaService } from "services/ToaNhaService";
import { useHistory } from "react-router-dom";
import { GetRandomColor } from "layouts/ultis/common";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import { GoDotFill } from "react-icons/go";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import InputCustom from "components/Forms/InputCustom";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import vi from "dayjs/locale/vi";
import viVN from "antd/es/locale/vi_VN";
import { FaCheckCircle } from "react-icons/fa";
import Column from "antd/es/table/Column";
import { DataTable } from "primereact/datatable";
import { UploadService } from "services/UploadService";
import { BsMotherboard } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { URL_UPLOAD } from "services/UploadService";
import { ChatsService } from "services/ChatsService";
import TabCongViec from "./TabCongViec";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

function calculateMessageTime(messageTime) {
  // Chuyển đổi thời gian tin nhắn sang đối tượng Date
  const messageDate = new Date(messageTime);
  // Lấy thời gian hiện tại
  const currentDate = new Date();
  // Tính khoảng thời gian giữa hai thời điểm (tính bằng mili giây)
  const timeDifference = currentDate - messageDate;
  // Chuyển đổi khoảng thời gian sang phút, giờ và ngày
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Nếu số ngày trôi qua lớn hơn 7, hiển thị dưới dạng dd/mm/yy
  if (days > 7) {
    const dd = messageDate.getDate();
    const mm = messageDate.getMonth() + 1; // Tháng bắt đầu từ 0
    const yy = messageDate.getFullYear().toString().slice(-2); // Lấy 2 chữ số cuối của năm
    return `${dd}/${mm}/${yy}`;
  } else {
    // Trả về kết quả dưới dạng chuỗi mô tả thời gian
    if (days > 0) {
      return `${days} ngày`;
    } else if (hours > 0) {
      return `${hours} giờ`;
    } else if (minutes > 0) {
      return `${minutes} phút`;
    } else {
      return "Vừa gửi";
    }
  }
}
function truncate(str, n) {
  return str?.length > n ? str.slice(0, n - 1) + "..." : str;
}
const BoxLeft = ({
  refInbox,
  onClickDetail,
  applyChange,
  loading,
  setLoading,
  data,
  filterCondition,
  nhanVien,
}) => {
  const history = useHistory();
  const [toanha, setToanha] = useState([]);
  const [dataGiaoViec, setDaTaGiaoViec] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(false);
  const [filterCV, setFilterCV] = useState(false);
  const [editCV, setEditCV] = useState(false);
  const [callChiTietCV, setCallChiTietCV] = useState(false);
  const [activeTabCV, setActiveTabCV] = useState("1");
  const [api, contextHolder] = notification.useNotification();
  const [taiLieu, setTaiLieu] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  
  const applyChange2 = async (p, v) => {
    let _data = _.cloneDeep(dataGiaoViec);
    switch (p) {
      case "MaNV":
        _data[p] = v;
        const newNV = nhanVien?.filter((item) =>
          _data?.MaNV.includes(item?.MaNV)
        );
        _data.NhanVien = newNV?.map((item) => ({
          MaNV: item?.MaNV,
          Phone: item?.DienThoai,
        }));
        break;
      case "Expired":
        if (v) {
          const date = dayjs(v);
          if (date.isValid()) {
            _data[p] = date.format("YYYY-MM-DDTHH:mm:ss");
          } else {
            console.error("Giá trị ngày không hợp lệ:", v);
          }
        } else {
          console.warn("Không có giá trị ngày để xử lý.");
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setDaTaGiaoViec(_data);
  };
  const handleTabChange = (key) => {
    if (key === "2") {
      setActiveTab(true);
    } else {
      setActiveTab(false);
    }
    setActiveTabCV("1");
  };
  const items = [
    {
      label: <p>Đổi toà nhà</p>,
      key: "0",
      style: { width: 200 },
      onClick: () => changeTN(),
    },

    {
      type: "divider",
    },
    {
      label: "Đăng xuất",
      key: "3",
      onClick: () => logout(),
    },
  ];
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("MaCTDK");
    localStorage.removeItem("NhanVien");
    history.push("/auth/sign-in?chat=" + filterCondition.MaTN);
  };
  const changeTN = async () => {
    setLoading(true);
    setOpen(true);
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    setLoading(false);
  };
  const openChats = (v) => {
    let url = window.location.origin + "/#chats/" + v;
    window.open(url, "_self");
    window.location.reload();
  };
  const renderModal = () => {
    const hide = () => {
      setOpen(false);
    };

    return (
      <Modal
        title="Đổi toà nhà"
        open={open}
        width={400}
        onCancel={hide}
        footer=""
      >
        <Row gutter={[0, 10]}>
          {toanha?.map((item) => {
            return (
              <Col
                key={item.MaTN}
                span={24}
                style={{
                  background:
                    item.MaTN === filterCondition.MaTN
                      ? "rgb(51, 144, 236)"
                      : "white",
                  padding: 6,
                  borderRadius: 6,
                  cursor: "pointer",
                }}
                onClick={() =>
                  item.MaTN !== filterCondition.MaTN && openChats(item.MaTN)
                }
              >
                {item.TenTN}
              </Col>
            );
          })}
        </Row>
      </Modal>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderTaiLieu = () => {
    const addTaiLieu = () => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu.push({
        ID: null,
        Name: "",
        Link: "",
      });
      setTaiLieu(_taiLieu);
    };

    const onchangeTaiLieu = (field, value, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      let item = _taiLieu.slice(index, index + 1);

      if (item?.length > 0) {
        _taiLieu.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setTaiLieu(_taiLieu);
    };

    const deleteTaiLieu = (item, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu.splice(index, 1);
      setTaiLieu(_taiLieu);
      if (item.ID) {
        ChatsService.deleteFile({ ID: item.ID });
      }
    };
    const submitTaiLieu = async (item, index) => {
      const formData = new FormData();
      formData.append("Image", item?.File);
      const _res = await UploadService.upload(formData);
      // console.log(_res?.data);
      if (_res?.data) {
        // let _resSM = await HopDongService.ChoThue.addTLTheoHD({
        //   MaHD: payLoad,
        //   Name: item?.Name,
        //   Link: URL_UPLOAD + _res?.data[0],
        // });
        // if (_resSM.status === 2000) {
        //   let _resDT = await HopDongService.ChoThue.getTLTheoHD({
        //     ID: payLoad,
        //   });
        //   if (_resDT.data) setTaiLieu(_resDT.data);
        //   openNotification("success", "topRight", `Thêm tài liệu thành công!`);
        // } else {
        //   openNotification("error", "topRight", _res?.message);
        // }
      }
      // MaHD
    };
    const handleUploadFile = (file, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu[index].Name = file?.name;
      _taiLieu[index].File = file;
      setTaiLieu(_taiLieu);
    };
    const handleXemMau = (link) => {
      if (link) {
        const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
        window.open(fullLink, "_blank"); // Opens the link in a new tab
      }
    };

    const handleDownload = (fileUrl, fileName) => {
      window.open(fileUrl, "_blank");
    };
    return (
      <div style={{ maxHeight: "180px", overflowY: "scroll" }}>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={taiLieu}
          // tableStyle={{ maxHeight: "20rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Tên công việc</p>}
            body={(item, row) => (
              <Input
                value={item.Name}
                onChange={(e) =>
                  onchangeTaiLieu("Name", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>File</p>}
            body={(item, row) => {
              return item?.ID ? (
                <p
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "300px",
                  }}
                  onClick={() => handleXemMau(item?.Link)}
                >
                  {item?.Link}
                </p>
              ) : (
                <input
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      handleUploadFile(file, row.rowIndex);
                    }
                  }}
                  style={{
                    border: "1px solid #d9d9d9",
                    padding: "5px",
                    borderRadius: "4px",
                    width: "300px",
                  }}
                />
              );
            }}
          />

          <Column
            fixed="right"
            header={
              <Button
                // loading={loading}
                onClick={addTaiLieu}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Flex gap={10}>
                {/* {item?.ID ? (
                  <Tooltip title="Tải về">
                    <Button
                      style={{
                        border: "1px solid blue",
                        color: "blue",
                      }}
                      onClick={() => handleDownload(item?.Link)}
                      icon={<CiSaveDown1 />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Lưu">
                    <Button
                      style={{
                        border: "1px solid green",
                        color: "green",
                      }}
                      onClick={() => submitTaiLieu(item, row.rowIndex)}
                      icon={<FaCheckCircle />}
                    />
                  </Tooltip>
                )} */}

                <Tooltip title="Xóa">
                  <Button
                    onClick={() => deleteTaiLieu(item, row.rowIndex)}
                    danger
                    ghost
                    icon={<CiTrash />}
                  />
                </Tooltip>
              </Flex>
            )}
            style={{
              textAlign: "right",
              width: "80px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </div>
    );
  };
  const handleOk = () => {
    // Xử lý khi bấm Đồng ý
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // Đóng modal
    setLoading(false);
    setIsModalOpen(false);
    setDaTaGiaoViec({});
    setTaiLieu([]);
    
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const formatTimeAgo = (dateString) => {
    const dateTime = DateTime.fromISO(dateString);

    const time = dateTime.toFormat("HH:mm");
    const now = dayjs();
    const targetDate = dayjs(dateString);
    if (now.diff(targetDate, "hour") >= 24) {
      return time;
    } else if (now.diff(targetDate, "hour") >= 1) {
      return `${now.diff(targetDate, "hour")} giờ trước`;
    } else if (now.diff(targetDate, "minute") >= 1) {
      return `${now.diff(targetDate, "minute")} phút trước`;
    } else {
      return "Vừa xong";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const _taiLieu = _.cloneDeep(taiLieu);

    let Files = [];

    for (const item of _taiLieu) {
      if (item?.ID) {
        Files.push(item);
      }
      if (item?.File) {
        const formData = new FormData();
        formData.append("Image", item.File);
        try {
          const _res = await UploadService.upload(formData);
          // console.log("Upload thành công:", _res?.data);
          Files.push({
            Link: URL_UPLOAD + _res?.data[0],
            Name: item?.Name,
          });
        } catch (error) {
          console.error("Lỗi khi upload:", error);
        }
      } else {
        console.warn("Không tìm thấy file:", item);
      }
    }
    const _payload = {
      ...dataGiaoViec,
      MaTN: filterCondition?.MaTN,
      Files,
    };
    // let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res;
    if (editCV) {
      _res = await ChatsService.edit(_payload);
    } else {
      _res = await ChatsService.add(_payload);
    }
    setLoading(false);
    if (_res.status === 2000) {
      setEditCV(false);
      setCallChiTietCV(!callChiTietCV);
      console.log("huan");
      
      openNotification("success", "topRight", `Giao việc  thành công!`);
      setDaTaGiaoViec({});
      setIsModalOpen(false);
      setFilterCV(!filterCV);
      setTaiLieu([]);
      // onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  // console.log(taiLieu);

  return (
    <>
      <Flex
        style={{
          padding: "5px 10px",
          boxShadow: "0 2px 2px rgb(114,114,114,0.169)",
        }}
        align="center"
        gap={10}
      >
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Flex
            align="center"
            justify="center"
            style={{
              background: "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
              width: 35,
              height: 35,
              borderRadius: 50,
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: 9,
            }}
          >
            <p>User</p>
          </Flex>
        </Dropdown>

        <div className="search-input">
          <CiSearch size={20} />
          <input
            className="input"
            placeholder="Tìm kiếm"
            onChange={(v) => applyChange("InputSearch", v.target.value)}
          />
        </div>
      </Flex>
      <Tabs onChange={handleTabChange} defaultActiveKey="1" centered>
        <TabPane tab="TIN NHẮN" key="1">
          <>
            <div className="inboxs">
              {loading && <Skeleton active />}
              {_.orderBy(data, ["date_send"], ["desc"]).map((item) => {
                return (
                  <Flex
                    className={
                      item?.id === refInbox?.current?.id
                        ? "ripple-container-active"
                        : "ripple-container"
                    }
                    align="center"
                    gap={10}
                    key={item.id}
                    onClick={() => onClickDetail(item)}
                  >
                    <div
                      className="avatar"
                      style={{
                        background: GetRandomColor(item.id),
                      }}
                    >
                      {item?.name_chat?.charAt(0)}
                    </div>
                    <div style={{ width: "85%" }}>
                      <Flex justify="space-between" style={{ width: "100%" }}>
                        <p
                          style={{ fontWeight: item?.isread ? "400" : "bold" }}
                        >
                          {item.name_chat}
                        </p>
                        <p>{calculateMessageTime(item.date_send)}</p>
                      </Flex>
                      <Flex justify="space-between">
                        <p
                          style={{
                            color:
                              item?.id === refInbox?.current?.id
                                ? "white"
                                : "rgb(142,142,146)",
                          }}
                        >
                          {item.last_sent_user_id ===
                            Number(
                              JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                            ) && <span>Bạn: </span>}
                          {truncate(item.last_message, 30)}
                        </p>
                        {!item?.isread && (
                          <Tag color="green" style={{ height: "23px" }}>
                            +1
                          </Tag>
                        )}
                      </Flex>
                    </div>
                  </Flex>
                );
              })}
            </div>
            {renderModal()}
          </>
        </TabPane>
        <TabPane
          style={{ marginTop: "-20px", size: "10px" }}
          tab="CÔNG VIỆC"
          key="2"
        >
          {
            <TabCongViec
              showModal={showModal}
              activeTabCV={activeTabCV}
              setActiveTabCV={setActiveTabCV}
              filterCondition={filterCondition}
              activeTab={activeTab}
              loading={loading}
              setLoading={setLoading}
              refInbox={refInbox}
              onClickDetail={onClickDetail}
              calculateMessageTime={calculateMessageTime}
              renderModal={renderModal}
              filterCV={filterCV}
              formatTimeAgo={formatTimeAgo}
              setDaTaGiaoViec={setDaTaGiaoViec}
              setIsModalOpen2={setIsModalOpen}
              setTaiLieu={setTaiLieu}
              setEditCV={setEditCV}
              callChiTietCV={callChiTietCV}
            />
          }
        </TabPane>
        <TabPane tab="PHÊ DUYỆT" key="3">
          <p>Nội dung của mục Phê duyệt.</p>
        </TabPane>
      </Tabs>

      <Modal
        title="Giao công việc mới"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={"700px"} 
        zIndex={1000}
      >
        <Row gutter={[12, 20]}>
          <Col style={{ paddingTop: "10px" }} span={24}>
            <InputCustom
              label="Tiêu đề:"
              applyChange={applyChange2}
              prop="Title"
              value={dataGiaoViec?.Title}
              placeholder="Nhập tiêu đề"
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="Nội dung:"
              applyChange={applyChange2}
              prop="Content"
              value={dataGiaoViec?.Content}
              placeholder="Nhập nội dung công việc"
            />
          </Col>
          <Col span={24}>
            <SelectCustom
              disabled={editCV}
              mode="multiple"
              label="Giao cho:"
              applyChange={applyChange2}
              prop="MaNV"
              value={dataGiaoViec?.MaNV}
              placeholder="Bản thân"
              options={nhanVien}
              fieldNames={{ label: "HoTenNV", value: "MaNV" }}
            />
          </Col>
          <Col span={24}>
            <Flex align="center">
              <label
                htmlFor={"ThoiHan"}
                style={{
                  fontWeight: "600",
                  width: "57%",
                  cursor: "pointer",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                {" "}
                Thời hạn
              </label>
              <ConfigProvider locale={viVN}>
                <DatePicker
                  id="ThoiHan"
                  showTime
                  onChange={(date, dateString) => {
                    if (date && dayjs(date).isValid()) {
                      applyChange2(
                        "Expired",
                        dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                      );
                    } else {
                      console.warn("Ngày không hợp lệ:", date);
                      applyChange2("Expired", null);
                    }
                  }}
                  value={
                    dataGiaoViec?.Expired ? dayjs(dataGiaoViec.Expired) : null
                  }
                  placeholder="Không thời hạn"
                  style={{ width: "100%" }}
                />
              </ConfigProvider>
            </Flex>
          </Col>
        </Row>

        <Col span={24}>
          <p style={{ fontWeight: "500", padding: "10px 0px" }}>
            Chi tiết công việc
          </p>
        </Col>
        <Col style={{ paddingBottom: "15px" }} span={24}>
          {renderTaiLieu()}
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button onClick={handleCancel} style={{ marginRight: 8 }}>
            Hủy
          </Button>
          <Button
            style={{ backgroundColor: "#1677FF", color: "#fff" }}
            onClick={() => handleSubmit()}
          >
            {editCV ? "Cập nhật" : "Giao việc"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(BoxLeft);
