import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Tabs,
  Tooltip,
  Select,
  InputNumber,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DichVuService } from "services/DichVuService";
import { MatBangService } from "services/MatBangService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HaoHut":
        _data.TienHaoHut = (v * (_data?.TienDien ?? 0)) / 100;
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thông tin điện!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const RenderNhanVienThiCong = () => {
    const columnsDV = [
      { title: "Mã thẻ", dataIndex: "MaThe", key: "MaThe" },
      { title: "Họ tên", dataIndex: "HoTen", key: "HoTen" },
      { title: "CCCD/Hộ chiếu", dataIndex: "CCCD", key: "CCCD" },
      { title: "Ghi chú", dataIndex: "GhiChu", key: "GhiChu" },
    ];

    const [formData, setFormData] = useState([
      { id: 1, MaThe: "", HoTen: "", CCCD: "", GhiChu: "" },
    ]);

    const handleChange = (id, key, value) => {
      setFormData((prev) =>
        prev.map((item) => (item.id === id ? { ...item, [key]: value } : item))
      );
    };

    const handleSetLengthDV = () => {
      setFormData((prev) => [
        ...prev,
        { id: Math.random() * 100, MaThe: "", HoTen: "", CCCD: "", GhiChu: "" },
      ]);
    };

    const handleRemoveDV = (id) => {
      setFormData((prev) => prev.filter((item) => item.id !== id));
    };
    return (
      <>
        <div>
          <Row
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontWeight: "600",
              backgroundColor: "#E6F1FE",
              padding: "10px 0",
            }}
          >
            {columnsDV.map((item) => (
              <Col span={5} key={item.title}>
                <p>{item.title}</p>
              </Col>
            ))}
            <Col span={2}></Col>
            <Col span={2}>
              <Tooltip title="Thêm cột">
                <Button onClick={() => handleSetLengthDV()}>
                  <PlusOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          {formData.map((item, index) => (
            <Row
              key={item.id}
              gutter={16}
              style={{
                border: "1px solid #d9d9d9",
                borderTop: "none",
                textAlign: "center",
                margin: "0px",
              }}
            >
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.MaThe}
                  onChange={(e) =>
                    handleChange(item.id, "MaThe", e.target.value)
                  }
                />
              </Col>
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.HoTen}
                  onChange={(e) =>
                    handleChange(item.id, "HoTen", e.target.value)
                  }
                />
              </Col>
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.CCCD}
                  onChange={(e) =>
                    handleChange(item.id, "CCCD", e.target.value)
                  }
                />
              </Col>
              <Col
                span={7}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.GhiChu}
                  onChange={(e) =>
                    handleChange(item.id, "GhiChu", e.target.value)
                  }
                />
              </Col>

              <Col
                span={2}
                style={{
                  padding: "8px",
                }}
              >
                <Tooltip title="Xóa">
                  <Button
                    onClick={() => handleRemoveDV(item.id)}
                    danger
                    style={{ marginLeft: "5px" }}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Tooltip>
              </Col>
            </Row>
          ))}
        </div>
      </>
    );
  };
  const handleTabChange = (key) => {
    console.log("Tab đang được chọn:", key);
  };

  return (
    <Drawer
      title="Thêm: nhân viên thi công"
      width={1180}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {RenderNhanVienThiCong()}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
