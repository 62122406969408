import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { SoQuyService } from "services/SoQuyService";
import { CongTyService } from "services/CongTyService";
import { ToaNhaService } from "services/ToaNhaService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { BiSolidUserPlus } from "react-icons/bi";
import FormAddNV from "./FormAddNV";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    PageIndex: 1,
    PageSize: 20,
    InputSearch: "",
  });
  const refDetail = useRef(null);
  const refAddNV = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ToaNhaService.getToaNha(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const openChats = (v) => {
    let url = window.location.origin + "/#chats/" + v;

    window.open(url);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dự án" + fileExtension);
  };
  const columns = [
    {
      title: "Tên dự án",
      dataIndex: "TenTN",
      // ellipsis: true,
    },
    {
      title: "Tên viết tắt",
      dataIndex: "TenVT",
      // ellipsis: true,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayTao",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
      // ellipsis: true,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCN",
      // ellipsis: true,
      render: (item) => Format_Date(item),
    },
    {
      title: "Người cập nhật",
      dataIndex: "NguoiSua",
      // ellipsis: true,
    },
    {
      title: "",
      fixed: "right",
      width: "185px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Thêm nhân viên">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<BiSolidUserPlus />}
                onClick={() => refAddNV.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Nhắn tin">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<CiChat2 />}
                onClick={() => openChats(item.MaTN)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá toà nhà"
              description="Bạn có chắc chắn muốn xoá toà nhà?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaTN)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={15}>
      <Col span={24}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button type="primary" onClick={() => refDetail.current.show()}>
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddNV ref={refAddNV} />
      {contextHolder}
    </Row>
  );
}
