import React, { useEffect, useState, useCallback } from "react";
import { DatePicker, Select, InputNumber, Popover, Button } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { FaGreaterThanEqual, FaLessThanEqual, FaEquals } from "react-icons/fa";
import { DateTime } from "luxon";
import DatePickerCustomFilterYear from "components/Forms/DatePickCustomFilterYear";
import { StarOutlined } from "@ant-design/icons";
import ActiveRoute from "components/ActiveRoute";

dayjs.locale("vi");

const FilterModal = ({
  filterCondition,
  onChangeFilter,
  options,
  loadcategoriesByMaTN,
  defaultValue,
  setFilterCondition
}) => {
  const [filterTT, setFilterTT] = useState("*");
  const [visible, setVisible] = useState(false);
  const [valueLTT, setValueLTT] = useState(
    filterCondition?.SoTieuThu?.Value || 0
  );

  const filterOptions = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };

  const applyChangeFilter = useCallback(
    _.debounce((p, v) => {
      let _filter = _.cloneDeep(filterCondition);
      switch (p) {
        case "MaTN":
          loadcategoriesByMaTN(v);
          _filter.MaKN = 0;
          _filter[p] = v;
          break;
        case "LocTieuThu":
          if (v.Type === "*") {
            if (
              _filter.SoTieuThu.Type === "Empty" &&
              _filter.SoTieuThu.Value !== v.Value
            ) {
              return;
            } else {
              _filter.SoTieuThu = {
                Type: "Empty",
                Value: 0,
              };
            }
          } else {
            _filter.SoTieuThu = {
              Type: filterOptions[v.Type]?.label,
              Value: v.Value,
            };
          }
          break;
        case "MaKN":
          _filter.MaKN = v ?? 0;
          break;
        case "DenNgay":
          const date = DateTime.fromJSDate(v.$d, { zone: "utc" }).setZone(
            "Asia/Ho_Chi_Minh"
          );
          _filter.Month = date.month;
          _filter.Year = date.year;
          break;
        default:
          _filter[p] = v;
          break;
      }
      onChangeFilter(_filter);
    }, 1000),
    [filterCondition, loadcategoriesByMaTN, onChangeFilter]
  );

  const handleClick = (type) => {
    setFilterTT(type);
    setVisible(false);
    // applyChangeFilter("LocTieuThu", {
    //   Type: filterOptions[type].label,
    //   Value: valueLTT,
    // });
  };

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) {
          applyChangeFilter("LocTieuThu", { Type: filterTT, Value: valueLTT });
        }
      }, 500);
    }
    return () => clearTimeout(time);
  }, [valueLTT, filterTT]);

  const content = (
    <div>
      {Object.entries(filterOptions).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );
  const [onOf, setOnOf] = useState(false);
  return (
    <div>
        <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          // setTuDen(false);
          setFilterTT("*");
          setVisible(false);
          setValueLTT(0)
        }}
      />
      <div className="box-search">
        <DatePickerCustomFilterYear
          label="Tháng năm"
          applyChange={applyChangeFilter}
          prop={"DenNgay"}
          value={dayjs()
            .month(filterCondition.Month - 1)
            .year(filterCondition.Year)}
          placeholder={"Chọn ngày nhập"}
        />
      </div>
      <div className="box-search">
      
        <Select
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{ width: "100%" }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{ width: "100%" }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
      </div>
      <div className="box-search">
        <p className="label">Lọc tiêu thụ</p>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Popover
            content={content}
            title=""
            trigger="click"
            visible={visible}
            onClick={() => setVisible(!visible)}
          >
            <Button>{filterOptions[filterTT]?.icon}</Button>
          </Popover>
          <InputNumber
            style={{ width: "90%" }}
            value={valueLTT}
            onChange={(value) => setValueLTT(value || 0)}
            placeholder="Nhập số tiêu thụ"
            min={0}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
