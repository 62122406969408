import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";
import { CiChat2, CiHome } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { BsCash } from "react-icons/bs";
import { BiListMinus } from "react-icons/bi";

import BaoCao from "views/admin/BaoCao/index";
import Chats from "views/admin/Chats/index";

// he thong
// import DanhSachLSCaLamViec from "views/admin/HeThong/LichSuCaLamViec/index";
import LichSuOTP from "views/admin/HeThong/LichSuOTP/index";
import DanhSachVirtualAccount from "views/admin/HeThong/VirtualAccount/index";
import Dashboard from "views/admin/HeThong/Dashboard";
import BieuMau from "views/admin/HeThong/BieuMau";
import HistoryNotification from "views/admin/HeThong/LichSuNotification";
import PhanQuyen from "views/admin/PhanQuyen";
import DanhSachNhanVien from "views/admin/HeThong/NhanVien/index";

//công nợ
import CongNoTongHop from "views/admin/CongNo/TongHop/index";
import CongNoTheoKhachHang from "views/admin/CongNo/KhachHang/index";
import HoaDonChiTiet from "views/admin/CongNo/HoaDonChiTiet/index";
import HoaDonChiTietXoa from "views/admin/CongNo/HoaDonChiTietXoa/index";
import DanhSachHoaDonPhaiTra from "views/admin/CongNo/DanhSachHoaDonPhaiTra";
import ThanhToanApp from "views/admin/CongNo/ThanhToanApp/index";
//dịch vụ
import DichVuNuoc from "views/admin/DichVu/Nuoc/index";
import DichVuDien from "views/admin/DichVu/Dien/index";
import DichVuXe from "views/admin/DichVu/Xe/index";
import DichVuKhac from "views/admin/DichVu/Khac/index";
import DichVuGas from "views/admin/DichVu/Gas/index";
import PhiQuanLy from "views/admin/DichVu/PhiQuanLy/index";
import TheThangMay from "views/admin/DichVu/TheThangMay/index";
import HopDongThiCong from "views/admin/DichVu/HopDongThiCong/index";
import NhanVienThiCong from "views/admin/DichVu/DanhSachNhanVienTC/index";

// so quy
import DanhSachPhieuThu from "views/admin/SoQuy/PhieuThu/index";
import DanhSachPhieuChi from "views/admin/SoQuy/PhieuChi/index";
// import DanhSach from "views/admin/SoQuy/DanhSach/index";
import PhieuThuChiTiet from "views/admin/SoQuy/PhieuThuChiTiet/index";
import PhieuHuyTX from "views/admin/SoQuy/PhieuHuyTX/index";
import PhieuThuXoa from "views/admin/SoQuy/PhieuThuXoa/index";
//danh muc
import DanhMucNhanVien from "views/admin/DanhMuc/NhanVien/index";
import DanhMucToaNha from "views/admin/DanhMuc/ToaNha/index";
import DanhMucYeuCau from "views/admin/DanhMuc/YeuCau/index";
import DanhMucDichVu from "views/admin/DanhMuc/DichVu/index";
import DanhMucKhachHang from "views/admin/DanhMuc/KhachHang/index";

//du an
import DanhSachDuAn from "views/admin/DuAn/index";
import DanhSachMatBang from "views/admin/DuAn/MatBang/index";
import DanhSachChoThue from "views/admin/DuAn/MatBangChoThue";

//khach hang
import YeuCau from "views/admin/KhachHang/YeuCau/index";
import CuDan from "views/admin/KhachHang/CuDan/index";
import KhachHang from "views/admin/KhachHang/KhachHang/index";
import ThongBao from "views/admin/KhachHang/ThongBao/index";
import TienIch from "views/admin/KhachHang/TienIch/index";

//Ki thuat
import TaiSanKiemTra from "views/admin/KiThuat/TaiSanKiemTra";
import NhatKyRuiRo from "views/admin/KiThuat/NhatKyRuiRo";
import NhatKyRuiRoChiTiet from "views/admin/KiThuat/NhatKyRuiRoChiTiet";
import QuanLyCaLamViec from "views/admin/KiThuat/QuanLyCaLamViec";

//Hop dong
import HopDongThue from "views/admin/HopDong/HopDongThue";
import ThanhLy from "views/admin/HopDong/ThanhLy";
import DanhSachDichVu from "views/admin/HopDong/DanhSachDichVu";
import CanHoChoThue from "views/admin/HopDong/CanHoChoThue";

//Thong ke
import BieuDo from "views/admin/ThongKe/BieuDo";
import BaoCaoThongKe from "views/admin/ThongKe/BaoCao";
//bao cao
// import BaoCaoLichSuQuetThe from "views/admin/BaoCao/LichSuQuetVe/index";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import Register from "views/auth/register";
import { GoDotFill } from "react-icons/go";
import { CgPlayListAdd } from "react-icons/cg";
import {
  RiBuilding2Line,
  RiMoneyDollarCircleLine,
  RiServiceLine,
} from "react-icons/ri";
import { FaUsersViewfinder } from "react-icons/fa6";
import InfoUser from "views/admin/InfoUser";
import CauHinh from "views/admin/HeThong/CauHinh";
import { FcStatistics } from "react-icons/fc";
import { StockOutlined } from "@ant-design/icons";
import PhanQuyenCT from "views/admin/PhanQuyen/TheoCongTy";
import Automation from "views/admin/HeThong/Automantion";
import { FaTools } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import DanhSachChuyenNhuong from "views/admin/HopDong/ChuyenNhuong";
import PhuLuc from "views/admin/HopDong/PhuLuc";

const routes = [
  {
    key: "trang-chu",
    // label: "Trang chủ",
    layout: "/admin",
    icon: <Icon as={CiHome} width="20px" height="20px" color="inherit" />,
    path: "/trang-chu",
    component: BaoCao,
    id: 0,
  },
  // {
  //   key: "chats",
  //   label: "Nhắn tin",
  //   layout: "/admin",
  //   icon: <Icon as={CiChat2} width="20px" height="20px" color="inherit" />,
  //   path: "/chats",
  //   component: Chats,
  //   id: 0,
  // },
  {
    label: "Dự án",
    layout: "/admin",
    icon: <RiBuilding2Line size={18} />,
    children: [
      {
        key: "du-an",
        label: "Dự án",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/du-an",
        component: DanhSachDuAn,
        id: 1,
      },
      {
        key: "mat-bang",
        label: "Mặt bằng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/mat-bang",
        component: DanhSachMatBang,
        id: 2,
      },
      {
        key: "mat-bang-cho-thue",
        label: "Mặt bằng cho thuê",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/mat-bang-cho-thue",
        component: DanhSachChoThue,
        id: 37,
      },
      // {
      //   key: "nhan-vien",
      //   label: "Nhân viên",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/nhan-vien",
      //   component: DanhSachNhanVien,
      //   id: 3,
      // },
    ],
    id: 2,
  },
  {
    label: "Công nợ",
    layout: "/admin",
    icon: <RiMoneyDollarCircleLine size={18} />,
    children: [
      {
        key: "cong-no-tong-hop",
        label: "Công nợ tổng hợp",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cong-no-tong-hop",
        component: CongNoTongHop,
        id: 4,
      },
      {
        key: "cong-no-theo-khach-hang",
        label: "Công nợ theo Khách hàng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cong-no-theo-khach-hang",
        component: CongNoTheoKhachHang,
        id: 38,
      },
      {
        key: "hoa-don-chi-tiet",
        label: "Hóa đơn chi tiết",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hoa-don-chi-tiet",
        component: HoaDonChiTiet,
        id: 5,
      },
      {
        key: "hoa-don-chi-tiet-xoa",
        label: "Hóa đơn chi tiết xóa",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hoa-don-chi-tiet-xoa",
        component: HoaDonChiTietXoa,
        id: 6,
      },

      {
        key: "hoa-don-phai-tra",
        label: "Hóa đơn phải trả",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hoa-don-phai-tra",
        component: DanhSachHoaDonPhaiTra,
        id: 39,
      },
      {
        key: "thanh-toan-app",
        label: "Thanh toán app",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/thanh-toan-app",
        component: ThanhToanApp,
        id: 7,
      },
    ],
    id: 1774,
  },

  {
    label: "Dịch vụ",
    layout: "/admin",
    icon: <RiServiceLine size={18} />,
    children: [
      {
        key: "dich-vu-nuoc",
        label: "Nước",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-nuoc",
        component: DichVuNuoc,
        id: 8,
      },
      {
        key: "dich-vu-dien",
        label: "Điện",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-dien",
        component: DichVuDien,
        id: 9,
      },
      {
        key: "dich-vu-xe",
        label: "Xe",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-xe",
        component: DichVuXe,
        id: 10,
      },
      {
        key: "dich-vu-khac",
        label: "Dịch vụ khác",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-khac",
        component: DichVuKhac,
        id: 11,
      },
      // {
      //   key: "dich-vu-gas",
      //   label: "Dịch vụ gas",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/dich-vu-gas",
      //   component: DichVuGas,
      //   id: 11,
      // },
      // {
      //   key: "phi-quan-ly",
      //   label: "Phí quản lý",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/phi-quan-ly",
      //   component: PhiQuanLy,
      //   id: 11,
      // },
      // {
      //   key: "the-thang-may",
      //   label: "Thẻ thang máy",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/the-thang-may",
      //   component: TheThangMay,
      //   id: 11,
      // },
      {
        key: "hop-dong-thi-cong",
        label: "Hợp đồng thi công",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hop-dong-thi-cong",
        component: HopDongThiCong,
        id: 40,
      },
      {
        key: "nhan-vien-thi-cong",
        label: "Nhân viên thi công",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/nhan-vien-thi-cong",
        component: NhanVienThiCong,
        id: 41,
      },
    ],
    id: 44,
  },
  {
    label: "Khách hàng",
    layout: "/admin",
    icon: <FaUsersViewfinder style={{ marginRight: "3px" }} />,

    children: [
      {
        key: "yeu-cau",
        label: "Yêu cầu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/yeu-cau",
        component: YeuCau,
        id: 13,
      },
      {
        key: "thong-bao",
        label: "Thông báo",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/thong-bao",
        component: ThongBao,
        id: 14,
      },
      {
        key: "khach-hang",
        label: "Khách hàng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/khach-hang",
        component: KhachHang,
        id: 12,
      },
      {
        key: "cu-dan",
        label: "Cư dân",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cu-dan",
        component: CuDan,
        id: 15,
      },
      {
        key: "tien-ich",
        label: "Tiện Ích",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/tien-ich",
        component: TienIch,
        id: 16,
      },
    ],
    id: 109,
  },
  {
    label: "Sổ quỹ",
    layout: "/admin",
    icon: (
      <Icon
        as={BsCash}
        width="20px"
        height="20px"
        color="inherit"
        style={{ marginRight: "3px" }}
      />
    ),
    children: [
      {
        key: "danh-sach-phieu-thu",
        label: "Phiếu thu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-sach-phieu-thu",
        component: DanhSachPhieuThu,
        id: 17,
      },
      {
        key: "phieu-thu-chi-tiet",
        label: "Phiếu thu chi tiết",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phieu-thu-chi-tiet",
        component: PhieuThuChiTiet,
        id: 18,
      },
      {
        key: "danh-sach-phieu-chi",
        label: "Phiếu chi",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-sach-phieu-chi",
        component: DanhSachPhieuChi,
        id: 20,
      },
      {
        key: "phieu-huy-the-xe",
        label: "Phiếu hủy thẻ xe",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phieu-huy-the-xe",
        component: PhieuHuyTX,
        id: 42,
      },
      {
        key: "phieu-thu-xoa",
        label: "Phiếu thu xóa",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phieu-thu-xoa",
        component: PhieuThuXoa,
        id: 19,
      },
      // {
      //   key: "danh-sach",
      //   label: "Danh sách",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/danh-sach",
      //   component: DanhSach,
      //   id: 121,
      // },
    ],
    id: 669,
  },
  {
    label: "Kĩ thuật",
    layout: "/admin",
    icon: <FaTools style={{ paddingRight: "2px" }} size={13} />,
    children: [
      {
        key: "tai-san-kiem-tra",
        label: "Tài sản kiểm tra",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/tai-san-kiem-tra",
        component: TaiSanKiemTra,
        id: 48,
      },
      {
        key: "nhat-ky-rui-ro",
        label: "Nhật ký rủi ro",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/nhat-ky-rui-ro",
        component: NhatKyRuiRo,
        id: 49,
      },
      {
        key: "nhat-ky-rui-ro-chi-tiet",
        label: "Nhật ký rủi ro chi tiết",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/nhat-ky-rui-ro-chi-tiet",
        component: NhatKyRuiRoChiTiet,
        id: 50,
      },
      {
        key: "quan-ly-ca-viec-lam",
        label: "Quản lý ca làm việc",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/quan-ly-ca-viec-lam",
        component: QuanLyCaLamViec,
        id: 51,
      },
    ],
    id: 7,
  },
  {
    label: "Hợp đồng",
    layout: "/admin",
    icon: <IoIosDocument style={{ paddingRight: "2px" }} size={18} />,
    children: [
      {
        key: "hop-dong-thue",
        label: "Hợp đồng thuê",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hop-dong-thue",
        component: HopDongThue,
        id: 43,
      },
      {
        key: "thanh-ly",
        label: "Thanh lý",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/thanh-ly",
        component: ThanhLy,
        id: 44,
      },
      {
        key: "chuyen-nhuong",
        label: "Chuyển nhượng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/chuyen-nhuong",
        component: DanhSachChuyenNhuong,
        id: 45,
      },
      {
        key: "danh-sach-dich-vu",
        label: "Danh sách dịch vụ",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-sach-dich-vu",
        component: DanhSachDichVu,
        id: 46,
      },

      {
        key: "phu-luc",
        label: "Phụ Lục",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phu-luc",
        component: PhuLuc,
        id: 47,
      },
    ],
    id: 1777,
  },
  {
    label: "Thống kê",
    layout: "/admin",
    icon: (
      <Icon as={StockOutlined} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "bao-cao",
        label: "Báo cáo",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bao-cao",
        component: BaoCaoThongKe,
        id: 21,
      },
      {
        key: "bieu-do",
        label: "Biểu đồ",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bieu-do",
        component: BieuDo,
        id: 22,
      },
    ],
    id: 211,
  },
  {
    label: "Hệ thống",
    layout: "/admin",
    icon: (
      <Icon as={IoSettingsOutline} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "lichsu-otp",
        label: "Lịch sử otp",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/lichsu-otp",
        component: LichSuOTP,
        id: 23,
      },
      {
        key: "phan-quyen-cong-ty",
        label: "Phân quyền công ty",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phan-quyen-cong-ty",
        component: PhanQuyenCT,
        id: 34,
      },
      {
        key: "phan-quyen",
        label: "Phân quyền",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phan-quyen",
        component: PhanQuyen,
        id: 24,
      },
      {
        key: "nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/nhan-vien",
        component: DanhSachNhanVien,
        id: 3,
      },
      {
        key: "virtual-account",
        label: "Tài khoản MSB",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/virtual-account",
        component: DanhSachVirtualAccount,
        id: 25,
      },

      {
        key: "muc-dashboard",
        label: "Mục Dashboard",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/muc-dashboard",
        component: Dashboard,
        id: 26,
      },
      {
        key: "bieu-mau",
        label: "Biểu mẫu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bieu-mau",
        component: BieuMau,
        id: 27,
      },
      {
        key: "lich-su-notification",
        label: "Lịch sử notification",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/lich-su-notification",
        component: HistoryNotification,
        id: 28,
      },
      {
        key: "cau-hinh",
        label: "Cấu hình",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cau-hinh",
        component: CauHinh,
        id: 29,
      },
      {
        key: "automation",
        label: "Automation",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/automation",
        component: Automation,
        id: 29,
      },
    ],
    id: 194,
  },
  {
    label: "Danh mục",
    layout: "/admin",
    icon: (
      <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "danh-muc-du-an",
        label: "Dự án",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-du-an",
        component: DanhMucToaNha,
        id: 30,
      },
      // {
      //   key: "danhmuc-nhan-vien",
      //   label: "Nhân viên",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/danhmuc-nhan-vien",
      //   component: DanhMucNhanVien,
      //   id: 395,
      // },
      // {
      //   key: "danhmuc-yeu-cau",
      //   label: "Yêu cầu",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/danhmuc-yeu-cau",
      //   component: DanhMucYeuCau,
      //   id: 395,
      // },
      {
        key: "danh-muc-dich-vu",
        label: "Dịch vụ",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-dich-vu",
        component: DanhMucDichVu,
        id: 32,
      },
      {
        key: "danh-muc-khach-hang",
        label: "Khách hàng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-khach-hang",
        component: DanhMucKhachHang,
        id: 33,
      },
    ],
    id: 1775,
  },
  {
    // label: "Thông tin người dùng",
    layout: "/admin",
    // icon: (
    //   <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    // ),
    children: [
      {
        key: "info-user",
        label: "Info user",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/info-user",
        component: InfoUser,
        // id: 395,
      },
    ],
    // id: 94,
  },
  // {
  //   label: "Báo cáo",
  //   layout: "/admin",
  //   icon: <Icon as={FaChartPie} width="20px" height="20px" color="inherit" />,
  //   children: [
  //     {
  //       key: "lich-su-quet-the",
  //       label: "Lịch sử quẹt vé",
  //       layout: "/admin",
  //       icon: (
  //         <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
  //       ),
  //       path: "/lich-su-quet-the",
  //       component: BaoCaoLichSuQuetThe,
  //       id: 406,
  //     },
  //   ],
  //   id: 61,
  // },
  {
    label: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    label: "Đăng ký",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Register,
  },
];

export default routes;
