import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  InputNumber,
  Popover,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import viVN from "antd/es/locale/vi_VN";
import { DateTime } from "luxon";
import DatePickerCustomFilterYear from "components/Forms/DatePickCustomFilterYear";
import { FaEquals, FaGreaterThanEqual, FaLessThanEqual } from "react-icons/fa6";
import { StarOutlined, SwapOutlined } from "@ant-design/icons";
const FilterModal = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    options,
    loadcategoriesByMaTN,
    defaultValue,
    setFilterCondition,
  } = props;

  const [filterTT, setFilterTT] = useState("*");
  const [visible, setVisible] = useState(false);
  const [tuDen, setTuDen] = useState(false);
  const [valueLTT, setValueLTT] = useState();
  const [valueTu, setValueTu] = useState();
  const [valueDen, setValueDen] = useState();

  const [filterTT2, setFilterTT2] = useState("*");
  const [visible2, setVisible2] = useState(false);
  const [tuDen2, setTuDen2] = useState(false);
  const [valueLTT2, setValueLTT2] = useState();
  const [valueTu2, setValueTu2] = useState();
  const [valueDen2, setValueDen2] = useState();

  const [filterTT3, setFilterTT3] = useState("*");
  const [visible3, setVisible3] = useState(false);
  const [tuDen3, setTuDen3] = useState(false);
  const [valueLTT3, setValueLTT3] = useState();
  const [valueTu3, setValueTu3] = useState();
  const [valueDen3, setValueDen3] = useState();

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = null;
        _filter[p] = v;
        break;
      case "PhaiThu":
        if (v.Type === "Từ->đến") {
          _filter.PhaiThu = {
            Type: "TuDen",
            Value: v.ValueTu,
            ValueTo: v.ValueDen,
          };
        } else if (v.Type === "*") {
          if (
            _filter.PhaiThu.Value !== v.Value &&
            _filter.PhaiThu.Type === "Empty"
          ) {
            return;
          } else {
            _filter.PhaiThu = {
              Type: "Empty",
              Value: 0,
            };
          }
        } else {
          _filter.PhaiThu = {
            Type: filterOptions[v.Type].label,
            Value: v.Value,
          };
        }
        break;
      case "DaThu":
        if (v.Type === "Từ->đến") {
          _filter.DaThu = {
            Type: "TuDen",
            Value: v.ValueTu,
            ValueTo: v.ValueDen,
          };
        } else if (v.Type === "*") {
          if (
            _filter.DaThu.Value !== v.Value &&
            _filter.DaThu.Type === "Empty"
          ) {
            return;
          } else {
            _filter.DaThu = {
              Type: "Empty",
              Value: 0,
            };
          }
        } else {
          _filter.DaThu = {
            Type: filterOptions[v.Type].label,
            Value: v.Value,
          };
        }
        break;
      case "ConNo":
        if (v.Type === "Từ->đến") {
          _filter.ConNo = {
            Type: "TuDen",
            Value: v.ValueTu,
            ValueTo: v.ValueDen,
          };
        } else if (v.Type === "*") {
          if (
            _filter.ConNo.Value !== v.Value &&
            _filter.ConNo.Type === "Empty"
          ) {
            return;
          } else {
            _filter.ConNo = {
              Type: "Empty",
              Value: 0,
            };
          }
        } else {
          _filter.ConNo = {
            Type: filterOptions[v.Type].label,
            Value: v.Value,
          };
        }
        break;
      case "LoaiXe":
      case "DichVu":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "KhoiNha":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        _filter.MaKN = v;
        break;
      case "DenNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const dateInHoChiMinh = date.setZone("Asia/Ho_Chi_Minh");
        const Month = dateInHoChiMinh.month;
        const Year = dateInHoChiMinh.year;
        _filter.Month = Month;
        _filter.Year = Year;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {}, []);

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month");
  const defaultDate = {
    Name: "Tháng này",
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
  };

  const filterOptions = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "Từ->đến": { label: "TuDen", icon: <SwapOutlined /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };
  const filterOptions2 = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "Từ->đến": { label: "TuDen", icon: <SwapOutlined /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };
  const filterOptions3 = {
    ">=": { label: "LonHonBang", icon: <FaGreaterThanEqual /> },
    "<=": { label: "BeHonBang", icon: <FaLessThanEqual /> },
    "=": { label: "Bang", icon: <FaEquals /> },
    "Từ->đến": { label: "TuDen", icon: <SwapOutlined /> },
    "*": { label: "Empty", icon: <p style={{ fontWeight: "600" }}>All</p> },
  };

  const handleClick = (type) => {
    if (type === "Từ->đến") {
      setTuDen(true);
    } else {
      setTuDen(false);
    }
    setFilterTT(type);
    setVisible(false);
  };
  const handleClick2 = (type) => {
    if (type === "Từ->đến") {
      setTuDen2(true);
    } else {
      setTuDen2(false);
    }
    setFilterTT2(type);
    setVisible2(false);
  };

  const handleClick3 = (type) => {
    if (type === "Từ->đến") {
      setTuDen3(true);
    } else {
      setTuDen3(false);
    }
    setFilterTT3(type);
    setVisible3(false);
  };

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) {
          if (filterTT === "Từ->đến") {
            applyChangeFilter("PhaiThu", {
              Type: filterTT,
              ValueTu: valueTu,
              ValueDen: valueDen,
            });
          } else {
            applyChangeFilter("PhaiThu", { Type: filterTT, Value: valueLTT });
          }
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [valueLTT, filterTT, valueTu, valueDen]);
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) {
          if (filterTT2 === "Từ->đến") {
            applyChangeFilter("DaThu", {
              Type: filterTT2,
              ValueTu: valueTu2,
              ValueDen: valueDen2,
            });
          } else {
            applyChangeFilter("DaThu", { Type: filterTT2, Value: valueLTT2 });
          }
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [valueLTT2, filterTT2, valueTu2, valueDen2]);

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) {
          if (filterTT3 === "Từ->đến") {
            applyChangeFilter("ConNo", {
              Type: filterTT3,
              ValueTu: valueTu3,
              ValueDen: valueDen3,
            });
          } else {
            applyChangeFilter("ConNo", { Type: filterTT3, Value: valueLTT3 });
          }
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [valueLTT3, filterTT3, valueTu3, valueDen3]);
  const content = (
    <div>
      {Object.entries(filterOptions).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );
  const content2 = (
    <div>
      {Object.entries(filterOptions2).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick2(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );
  const content3 = (
    <div>
      {Object.entries(filterOptions3).map(([type, { icon }]) => (
        <p
          key={type}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "8px",
            fontSize: "16px",
          }}
          onClick={() => handleClick3(type)}
        >
          {icon}
        </p>
      ))}
    </div>
  );
  
  const [onOf, setOnOf] = useState(false);
  return (
    <div>
      <ActiveRoute
        removeFilter={() => {
          setOnOf(!onOf);
          onChangeFilter({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setFilterCondition({
            ...defaultValue,
            MaTN: filterCondition.MaTN,
          });
          setTuDen3(false);
          setFilterTT3("*");
          setVisible3(false);

          setTuDen2(false);
          setFilterTT2("*");
          setVisible2(false);
          setTuDen(false);
          setFilterTT("*");
          setVisible(false);
        }}
      />

      <FilterDate
        onChangeDate={onChangeDate}
        defaultDateProp={defaultDate}
        onOf={onOf}
      />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            mode="multiple"
            value={filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("KhoiNha", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
          mode="multiple"
          value={filterCondition.DichVu.split(",").filter(Boolean).map(Number)}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("DichVu", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: 1, Name: "Điện" },
            { ID: 2, Name: "Nước" },
            { ID: 6, Name: "Giữ xe" },
            { ID: 12, Name: "Phí quản lý" },
            { ID: 3, Name: "Dịch vụ khác" },
            { ID: 5, Name: "Hợp đồng thuê" },
            { ID: 33, Name: "Tiền nội thất" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.LoaiXe.split(",").filter(Boolean).map(Number)}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div>

      {/* <div className="box-search">
        <p className="label">Loại dịch vụ</p>
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("DichVu", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: 1, Name: "Điện" },
            { ID: 2, Name: "Nước" },
            { ID: 6, Name: "Giữ xe" },
            { ID: 12, Name: "Phí quản lý" },
            { ID: 3, Name: "Dịch vụ khác" },
            { ID: 5, Name: "Hợp đồng thuê" },
            { ID: 33, Name: "Tiền nội thất" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Loại xe</p>
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          // value={filterCondition.MaTT}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div> */}
      <div className="box-search">
        <p className="label">Lọc theo điều kiện</p>
        {tuDen ? (
          <>
            <div
              style={{
                // display: "flex",
                gap: 5,
                alignItems: "center",
                padding: "0 5px",
                marginBottom: "8px",
              }}
            >
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <Popover
                  content={content}
                  title=""
                  trigger="click"
                  visible={visible}
                  onClick={() => setVisible(!visible)}
                >
                  <Button style={{ marginRight: "15px" }}>Từ</Button>
                </Popover>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueTu}
                  onChange={(value) => setValueTu(value)}
                  placeholder="Từ số tiền phải thu"
                  min={0}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => setVisible(!visible)}
                >
                  Đến
                </Button>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueDen}
                  onChange={(value) => setValueDen(value)}
                  placeholder="Đến số tiền phải thu"
                  min={0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Popover
                content={content}
                title=""
                trigger="click"
                visible={visible}
                onClick={() => setVisible(!visible)}
              >
                <Button>{filterOptions[filterTT]?.icon}</Button>
              </Popover>
              <InputNumber
                style={{ width: "90%" }}
                value={valueLTT}
                onChange={(value) => setValueLTT(value)}
                placeholder="Lọc số tiền phải thu"
                min={0}
              />
            </div>
          </>
        )}
        {tuDen2 ? (
          <>
            <div
              style={{
                // display: "flex",
                gap: 5,
                alignItems: "center",
                padding: "0 5px",
                marginBottom: "8px",
              }}
            >
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <Popover
                  content={content2}
                  title=""
                  trigger="click"
                  visible={visible2}
                  onClick={() => setVisible2(!visible2)}
                >
                  <Button style={{ marginRight: "15px" }}>Từ</Button>
                </Popover>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueTu2}
                  onChange={(value) => setValueTu2(value)}
                  placeholder="Từ số tiền đã thu"
                  min={0}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => setVisible2(!visible2)}
                >
                  Đến
                </Button>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueDen2}
                  onChange={(value) => setValueDen2(value)}
                  placeholder="Đến số tiền đã thu"
                  min={0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Popover
                content={content2}
                title=""
                trigger="click"
                visible={visible2}
                onClick={() => setVisible2(!visible2)}
              >
                <Button>{filterOptions[filterTT2]?.icon}</Button>
              </Popover>
              <InputNumber
                style={{ width: "90%" }}
                value={valueLTT2}
                onChange={(value) => setValueLTT2(value)}
                placeholder="Lọc số tiền đã thu"
                min={0}
              />
            </div>
          </>
        )}
        {tuDen3 ? (
          <>
            <div
              style={{
                // display: "flex",
                gap: 5,
                alignItems: "center",
                padding: "0 5px",
                marginBottom: "8px",
              }}
            >
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <Popover
                  content={content3}
                  title=""
                  trigger="click"
                  visible={visible3}
                  onClick={() => setVisible3(!visible3)}
                >
                  <Button style={{ marginRight: "15px" }}>Từ</Button>
                </Popover>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueTu3}
                  onChange={(value) => setValueTu3(value)}
                  placeholder="Từ số tiền còn nợ"
                  min={0}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => setVisible3(!visible3)}
                >
                  Đến
                </Button>
                <InputNumber
                  style={{ width: "100%" }}
                  value={valueDen3}
                  onChange={(value) => setValueDen3(value)}
                  placeholder="Đến số tiền còn nợ"
                  min={0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Popover
                content={content3}
                title=""
                trigger="click"
                visible={visible3}
                onClick={() => setVisible3(!visible3)}
              >
                <Button>{filterOptions[filterTT3]?.icon}</Button>
              </Popover>
              <InputNumber
                style={{ width: "90%" }}
                value={valueLTT3}
                onChange={(value) => setValueLTT3(value)}
                placeholder="Lọc số tiền còn nợ"
                min={0}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default FilterModal;
