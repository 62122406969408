import { Flex, Select } from "antd";
import React from "react";
import "./index.scss";

const SelectCustom = ({
  label,
  applyChange,
  value,
  prop,
  mode,
  placeholder,
  options,
  fieldNames,
  maxTagCount,
  errors,
  disabled,
  required,
  widthLabel,
  widthSelect,
}) => {
  return (
    <div>
      <Flex
        justify="flex-start"
        align="center"
        className={required ? "border-required" : "select-custom"}
      >
        <label
          htmlFor={prop}
          style={{
            fontWeight: "600",
            width: widthLabel ? widthLabel : "35%",
            cursor: "pointer",
          }}
        >
          {label} <span style={{ color: "red" }}> {required ? "*" : ""}</span>
        </label>
        <Select
          id={prop}
          disabled={disabled}
          maxTagCount={maxTagCount ? maxTagCount : null}
          showSearch
          mode={mode ?? "single"}
          placeholder={placeholder}
          value={value}
          onChange={(v) => applyChange(prop, v)}
          style={{
            width: widthSelect ? widthSelect : "65%",
          }}
          options={options ?? []}
          filterOption={(input, option) =>
            (option[fieldNames?.label] ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          fieldNames={
            fieldNames ? fieldNames : { label: "label", value: "value" }
          }
        />
      </Flex>
      {errors && (
        <p style={{ color: "red", textAlign: "center" }}>
          {errors && errors[prop]}
        </p>
      )}
    </div>
  );
};

export default SelectCustom;
