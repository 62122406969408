import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit, MdGridView, MdOutlineViewDay } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  CaretRightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  ExportOutlined,
  EyeOutlined,
  HomeOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { HopDongService } from "services/HopDongService";
import FormTaoHoaDon from "./FormTaoHoaDon";
import FormThanhLy from "./FormThanhLy";
import { useHistory } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import { Format_Currency } from "layouts/ultis/common";
import FormAddChoThue from "views/admin/HopDong/CanHoChoThue/FormAddChoThue";
import CanHoChoThue from "views/admin/HopDong/CanHoChoThue";
import { FaCog, FaHome, FaUserAlt } from "react-icons/fa";
export default function DanhSachChoThue() {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCot, setIsCot] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [valueModal, setValueModal] = useState([]);
  const [khoiNha, setKhoiNha] = useState([]);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfYear = now.startOf("year");
  const endOfYear = now.endOf("year");
  const defaultValue = {
    MaTN: 0,
    InputSearch: "",
    PageIndex: 1,
    PageSize: 10,
    // State: null,
  };
  const [state, setState] = useState([
    { key: "ALL", name: "Tất cả", color: "gray", total: 0 },
    { key: "NEW", name: "Còn trống", color: "blue", total: 0 },
    { key: "ACCEPT", name: "Đã cho thuê", color: "green", total: 0 },
    {
      key: "PENDING_APPROVAL",
      name: "Thanh lý chờ duyệt",
      color: "orange",
      total: 0,
    },
    { key: "CLEARED", name: "Đã thanh lý", color: "red", total: 0 },
  ]);
  const [stateActive, setStateActive] = useState({
    key: "ALL",
    name: "Tất cả",
    color: "gray",
  });
  const changeState = (state) => {
    if (state === "ALL") {
      setData(dataAll);
    } else if (state === "NEW") {
      let _data = dataAll.filter(
        (v) => v.TrangThai === state || v.TrangThai === null
      );
      setData(_data);
    } else {
      let _data = dataAll.filter((v) => v.TrangThai === state);
      setData(_data);
    }
  };
  useEffect(() => {
    const counts = dataAll.reduce(
      (acc, item) => {
        acc.ALL += 1;
        if (acc[item.TrangThai] !== undefined) {
          acc[item.TrangThai] += 1;
        } else {
          acc["NEW"] += 1;
        }
        return acc;
      },
      { ALL: 0, NEW: 0, ACCEPT: 0, PENDING_APPROVAL: 0, CLEARED: 0 }
    );

    setState((prevState) =>
      prevState.map((item) => ({
        ...item,
        total: counts[item.key] || 0,
      }))
    );
  }, [dataAll]);

  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refTaoHoaDon = useRef(null);
  const refChoThue = useRef(null);
  const refHistory = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    setFilterCondition(_filter);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoiNha(_tn.data);
  };

  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ToaNhaService.getCanHoChothue(_filter);
    if (_res.data) {
      setData(_res.data);
      setDataAll(_res.data);
      setLoading(false);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const handleCancelModal = async () => {
    setIsModalOpen(false);
  };

  const handleDuyetPL = async (item) => {
    setLoading(true);
    let _res = await HopDongService.PhuLuc.duyet({
      ID: item?.ID,
      MaHD: item?.MaHD,
    });
    if (_res.data) {
      setLoading(false);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Duyệt phụ lục thành công!`);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const handleHuyDuyetHD = async (id) => {
    setLoading(true);
    let _res = await HopDongService.ChoThue.duyetHD({
      ID: id,
      State: "REJECT",
    });
    if (_res.data) {
      setLoading(false);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Hủy duyệt hợp đồng thành công!`);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "Mã mặt bằng",
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.MaSoMB}
        </p>
      ),
    },
    {
      title: "Khối nhà",
      dataIndex: "TenKN",
      // render: (item) => khoiNha?.find((v) => v.MaKN === item)?.TenKN,
    },
    {
      title: "Tầng",
      dataIndex: "TenTL",
    },

    {
      title: "Diện tích CT",
      dataIndex: "DienTichChoThue",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Giá Cho thuê",
      dataIndex: "GiaChoThue",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Trạng thái",
      width: 150,
      render: (item) => (
        <Tag
          color={
            item?.TrangThai === "ACCEPT"
              ? "green"
              : item?.TrangThai === "NEW"
              ? "blue"
              : item?.TrangThai === "CLEARED"
              ? "red"
              : item?.TrangThai === "REJECT"
              ? "magenta"
              : item?.TrangThai === "PENDING_APPROVAL"
              ? "orange"
              : "blue"
          }
        >
          {item?.TrangThai === "ACCEPT"
            ? "Đã cho thuê"
            : item?.TrangThai === "NEW"
            ? "Còn trống"
            : item?.TrangThai === "PENDING_APPROVAL"
            ? "Thanh lý chờ duyệt"
            : item?.TrangThai === "REJECT"
            ? "Hủy duyệt"
            : item?.TrangThai === "CLEARED"
            ? "Đã thanh lý"
            : "Còn trống"}
        </Tag>
      ),
    },

    {
      title: "Loại MB",
      dataIndex: "LoaiMB",
    },

    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      width: 180,
    },
    {
      title: "Mã số thuế",
      dataIndex: "MaSoThue",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      width: 180,
    },
    {
      title: "Di động",
      dataIndex: "DienThoaiKH",
    },
    {
      title: "Email",
      dataIndex: "EmailKH",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "",
      fixed: "right",
      width: "140px",
      render: (item) => {
        return (
          <Flex gap={10}>
            <Tooltip
              placement="topRight"
              title={
                item?.TrangThai === "NEW" || item?.TrangThai === null
                  ? "Thuê căn hộ"
                  : "Thông tin căn hộ đã thuê"
              }
            >
              <Button
                type="primary"
                ghost
                icon={<HomeOutlined />}
                onClick={() =>
                  item?.TrangThai === "NEW" || item?.TrangThai === null
                    ? refChoThue.current.show(new Date(), item?.MaMB, item)
                    : refChoThue.current.update(item)
                }
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá mặt bằng"
              description="Bạn có chắc chắn muốn xoá mặt bằng?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaMB)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await MatBangService.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaMB !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    setDataSelect(
      data.filter(
        (item) =>
          newSelectedRowKeys.includes(item.key) && item?.State === "ACCEPT"
      )
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };

  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        refTaoHoaDon.current.show();
        break;
      case "4":
        break;
      case "5":
        break;

      default:
        break;
    }
  };
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Thêm import mặt bằng
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn hóa đơn, xin cảm ơn."
        );
        return;
      }
    }
    if (dataSelect?.length <= 0 && e.key === "2") {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn hóa đơn đã duyệt, xin cảm ơn."
      );
      return;
    }
    refTaoHoaDon.current.show(e.key);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo tòa nhà
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo hóa đơn
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isCot ? (
        <>
          <CanHoChoThue setIsCot={setIsCot} isCot={isCot} />
        </>
      ) : (
        <Row gutter={15}>
          <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
            <FilterModal
              defaultValue={defaultValue}
              setFilterCondition={setFilterCondition}
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
              loadcategoriesByMaTN={loadcategoriesByMaTN}
              options={{
                ToaNha: toanha,
                KhoiNha: khoinha,
              }}
            />
          </Col>
          <Col span={collapsed ? 24 : 20}>
            <Toolbar
              left={() => (
                <Flex align="center" gap={10}>
                  <AiOutlineMenuFold
                    size={20}
                    className="icon-filter"
                    color="primary"
                    onClick={() => onHandleHide()}
                  />
                  <Input
                    style={{
                      width: "150px",
                      padding: "4px 11px",
                      borderRadius: 10,
                    }}
                    placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                    onChange={(e) => onSearch(e.target.value)}
                  />
                  {state?.map((item) => {
                    return (
                      <Badge
                        key={item.key}
                        count={item.total}
                        onClick={() => {
                          setStateActive(item);
                          changeState(item.key);
                        }}
                      >
                        <div
                          style={{
                            border: `1px solid ${item.color}`,
                            padding: "5px 15px",
                            cursor: "pointer",
                            borderRadius: 10,
                            background:
                              stateActive.key === item.key ? item.color : null,
                            color:
                              stateActive.key === item.key ? "white" : null,
                          }}
                        >
                          <p style={{ fontSize: "12px" }}>{item.name}</p>
                        </div>
                      </Badge>
                    );
                  })}
                </Flex>
              )}
              right={() => (
                <Flex gap={5} align="center">
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => refDetail.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>

                  <Dropdown overlay={menu2} trigger={["click"]}>
                    <Button style={{ display: "flex", alignItems: "center" }}>
                      <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác
                      khác
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  {/* <Dropdown overlay={menuXem} trigger={["click"]}>
                    <Button style={{ display: "flex", alignItems: "center" }}>
                      <EyeOutlined style={{ marginRight: "5px" }} /> Xem
                      <DownOutlined />
                    </Button>
                  </Dropdown> */}
                  <MdGridView
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: isCot ? "" : "blue",
                    }}
                    onClick={() => setIsCot(false)}
                  />
                  <MdOutlineViewDay
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: isCot ? "blue" : "",
                    }}
                    onClick={() => setIsCot(true)}
                  />
                </Flex>
              )}
            />

            <Table
              loading={loading}
              dataSource={data}
              columns={columns}
              scroll={{
                y: window.innerHeight - 250,
                x: 2100,
              }}
              rowSelection={{ ...rowSelection, columnWidth: 60 }}
              bordered
              pagination={false}
              // virtual={true}
              size="small"
              summary={(pageData) => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2}>
                        <p style={{ fontWeight: "bold" }}>
                          Tổng: {pageData?.length}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "500" }}>
                          {Format_Currency(_.sumBy(data, "DienTichChoThue"))}
                        </p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>0</p>
                  </Table.Summary.Cell> */}
                      {/* <Table.Summary.Cell
                    index={0}
                    colSpan={10}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "500" }}>100.000</p>
                  </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
          <FormAdd
            ref={refDetail}
            onAfterSubmit={onAfterSubmit}
            filterCondition={filterCondition}
            options={{
              ToaNha: toanha,
              KhoiNha: khoiNha,
            }}
          />
          {contextHolder}
          <FormAddExcel
            ref={refAddExcel}
            onAfterSubmit={onAfterSubmit}
            maTN={filterCondition.MaTN}
          />
          <FormThanhLy
            filterCondition={filterCondition}
            handleCancel={handleCancelModal}
            isModalOpen={isModalOpen}
            // caseModal={caseModal}
            setIsModalOpen={setIsModalOpen}
            selectedRowKeys={selectedRowKeys}
            onAfterSubmit={onAfterSubmit}
            valueModal={valueModal}
          />
          <FormTaoHoaDon
            ref={refTaoHoaDon}
            dataSelect={dataSelect}
            selectedRowKeys={selectedRowKeys}
            filterCondition={filterCondition}
            setSelectedRowKeys={setSelectedRowKeys}
            onAfterSubmit={onAfterSubmit}
          />
          <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
          <FormAddChoThue
            ref={refChoThue}
            onAfterSubmit={onAfterSubmit}
            filterCondition={filterCondition}
            ToaNha={toanha}
          />
        </Row>
      )}
    </>
  );
}
