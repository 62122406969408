import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Tabs,
  Tooltip,
  Select,
  InputNumber,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DichVuService } from "services/DichVuService";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import TextArea from "antd/es/input/TextArea";
import { DateTime } from "luxon";
import { CategoryService } from "services/CategoryService";
const { TabPane } = Tabs;
const { Option } = Select;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const defaulData = {
    TuNgay: null,
    DenNgay: null,
  };
  const [data, setData] = useState(defaulData);
  const [tinhTrang, setTinhTrang] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [formData, setFormData] = useState([
    { id: 1, MaThe: "", HoTen: "", CMND: "", GhiChu: "" },
  ]);

  const filterSoHD = async () => {
    let _res = await DichVuService.HopDongThiCong.getSoHD({
      MaTN: filterCondition?.MaTN,
    });
    if (_res?.data) setData((pr) => ({ ...pr, SoHD: _res.data }));
    let _resTT = await CategoryService.DichVu.HopDongThiCong.TinhTrang.get();
    if (_resTT.data) setTinhTrang(_resTT.data);
  };

  const filterNV = async (data) => {
    let _res = await DichVuService.HopDongThiCong.getNVTheoHD({
      MaHDTC: data?.ID,
    });
    if (_res?.data) setFormData(_res?.data);
    setData({
      ...data,
      MatBang: {
        value: data?.MaMB,
        label: data?.MaSoMB,
      },
    });
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      filterSoHD();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);

      filterNV(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setFormData([]);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TuNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        if (isoString && _data.DenNgay) {
          const tuNgay = DateTime.fromISO(_data.TuNgay, {
            zone: "Asia/Ho_Chi_Minh",
          });
          const denNgay = DateTime.fromISO(_data.DenNgay, {
            zone: "Asia/Ho_Chi_Minh",
          });

          const diffInDays = denNgay.diff(tuNgay, "days").days;
          _data.SoNgayDK = Math.round(diffInDays);
        }
        break;
      case "DenNgay":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;

        const ngayHienTai = DateTime.now().setZone("Asia/Ho_Chi_Minh");
        const denNgayObj = DateTime.fromISO(isoString2, {
          zone: "Asia/Ho_Chi_Minh",
        });
        const soNgayHetHan = denNgayObj.diff(ngayHienTai, "days").days;
        _data.NgayConLai = Math.round(soNgayHetHan);

        if (_data.TuNgay && isoString2) {
          const tuNgay = DateTime.fromISO(_data.TuNgay, {
            zone: "Asia/Ho_Chi_Minh",
          });
          const denNgay = DateTime.fromISO(_data.DenNgay, {
            zone: "Asia/Ho_Chi_Minh",
          });

          const diffInDays = denNgay.diff(tuNgay, "days").days;
          _data.SoNgayDK = Math.round(diffInDays);
        }
        break;
      case "MatBang":
        console.log(v);

        const KH = JSON.parse(v?.title);
        _data.KhachHang = KH?.TenKH;
        _data.MaMB = KH?.MaMB;
        _data.MaKH = KH?.MaKH;
        _data.MaSoMB = KH?.MaSoMB;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    const _payload = {
      ..._data,
      MaTN: filterCondition?.MaTN,
      ListNhanVien: formData,
    };
    let _res = await DichVuService.HopDongThiCong.add(_payload);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thông tin điện!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const RenderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={5}>
            <InputCustom
              label="Số HĐ:"
              applyChange={applyChange}
              prop={"SoHD"}
              value={data?.SoHD}
            />
          </Col>

          <Col span={7}>
            <Flex align="center" style={{ borderBottom: "1px solid #ff9087" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "35% ",
                }}
              >
                Mặt bằng: <span style={{ color: "red" }}>*</span>
              </p>
              <DebounceSelect
                open={open}
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MatBang"]}
              </p>
            )}
          </Col>
          <Col span={4}>
            <InputNumberCustom
              label="Đơn giá:"
              applyChange={applyChange}
              prop={"DonGia"}
              value={data?.DonGia}
              placeholder="0"
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder="Nhập di tên khách hàng"
              disabled={true}
            />
          </Col>

          <Col span={5}>
            <DatePickerCustom
              open2={open}
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              open2={open}
              label="Đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
            />
          </Col>
          <Col span={5}>
            <DatePickerCustom
              open2={open}
              label="Ngay TT:"
              applyChange={applyChange}
              prop={"NgayTT"}
              value={data?.NgayTT}
            />
          </Col>
          <Col span={8}>
            <InputNumberCustom
              disabled={true}
              label="Số ngày ĐK:"
              applyChange={applyChange}
              prop={"SoNgayDK"}
              value={data?.SoNgayDK}
              placeholder="0"
            />
          </Col>

          <Col span={5}>
            <InputCustom
              label="Chức vụ:"
              applyChange={applyChange}
              prop="ChucVu"
              value={data?.ChucVu}
            />
          </Col>
          <Col span={11}>
            <InputCustom
              label="Người được UQ:"
              applyChange={applyChange}
              prop="NguoiDaiDien"
              value={data?.NguoiDaiDien}
            />
          </Col>
          <Col span={8}>
            {" "}
            <SelectCustom
              label="Tình trạng:"
              applyChange={applyChange}
              prop="MaTT"
              value={data?.MaTT}
              placeholder="Chọn tình trạng"
              options={tinhTrang}
              fieldNames={{ label: "TenTT", value: "MaTT" }}
            />
          </Col>

          <Col span={8}>
            <InputCustom
              label="CCCD:"
              applyChange={applyChange}
              prop="CMND"
              value={data?.CMND}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="SĐT:"
              applyChange={applyChange}
              prop="SDT"
              value={data?.SDT}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Nhà thầu TC:"
              applyChange={applyChange}
              prop="NhaThauThiCong"
              value={data?.NhaThauThiCong}
            />
          </Col>

          <Col span={8}>
            <InputCustom
              label="Hạng mục TC:"
              applyChange={applyChange}
              prop="HangMucThiCong"
              value={data?.HangMucThiCong}
            />
          </Col>
          <Col span={8}>
            <InputNumberCustom
              label="Tiền cọc:"
              applyChange={applyChange}
              prop={"ThanhTien"}
              value={data?.ThanhTien}
              placeholder="0"
            />
          </Col>

          <Col span={8}>
            <InputNumberCustom
              label="Phí thi công:"
              applyChange={applyChange}
              prop={"PhiThiCong"}
              value={data?.PhiThiCong}
              placeholder="0"
            />
          </Col>
          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Gi chú:
              </p>
              <TextArea
                value={data?.GhiChu}
                onChange={(e) => applyChange("GhiChu", e.target.value)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };

  const RenderNhanVienThiCong = () => {
    const columnsDV = [
      { title: "Mã thẻ", dataIndex: "MaThe", key: "MaThe" },
      { title: "Họ tên", dataIndex: "HoTen", key: "HoTen" },
      { title: "CCCD/Hộ chiếu", dataIndex: "CCCD", key: "CCCD" },
      { title: "Ghi chú", dataIndex: "GhiChu", key: "GhiChu" },
    ];

    const handleChange = (index, prop, value, item) => {
      let _formData = _.cloneDeep(formData);
      _formData.splice(index, 1, { ...item, [prop]: value });
      setFormData(_formData);
    };

    const handleSetLengthDV = () => {
      setFormData((prev) => [
        ...prev,
        { MaThe: "", HoTen: "", CMND: "", GhiChu: "" },
      ]);
    };

    const handleRemoveDV = async (value, index) => {
      let _formData = _.cloneDeep(formData);
      _formData.splice(index, 1);
      setFormData(_formData);
      if (value) {
        await DichVuService.HopDongThiCong.deleteNV({
          ID: Number(value),
        });
      }
    };
    return (
      <>
        <div>
          <Row
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontWeight: "600",
              backgroundColor: "#E6F1FE",
              padding: "10px 0",
            }}
          >
            {columnsDV.map((item) => (
              <Col span={5} key={item.title}>
                <p>{item.title}</p>
              </Col>
            ))}
            <Col span={2}></Col>
            <Col span={2}>
              <Tooltip title="Thêm cột">
                <Button onClick={() => handleSetLengthDV()}>
                  <PlusOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          {formData.map((item, index) => (
            <Row
              key={index}
              gutter={16}
              style={{
                border: "1px solid #d9d9d9",
                borderTop: "none",
                textAlign: "center",
                margin: "0px",
              }}
            >
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.MaThe}
                  onChange={(e) =>
                    handleChange(index, "MaThe", e.target.value, item)
                  }
                />
              </Col>
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.HoTen}
                  onChange={(e) =>
                    handleChange(index, "HoTen", e.target.value, item)
                  }
                />
              </Col>
              <Col
                span={5}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.CMND}
                  onChange={(e) =>
                    handleChange(index, "CMND", e.target.value, item)
                  }
                />
              </Col>
              <Col
                span={7}
                style={{
                  padding: "8px",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                <Input
                  value={item.GhiChu}
                  onChange={(e) =>
                    handleChange(index, "GhiChu", e.target.value, item)
                  }
                />
              </Col>

              <Col
                span={2}
                style={{
                  padding: "8px",
                }}
              >
                <Tooltip title="Xóa">
                  <Button
                    onClick={() => handleRemoveDV(item.ID, index)}
                    danger
                    style={{ marginLeft: "5px" }}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Tooltip>
              </Col>
            </Row>
          ))}
        </div>
      </>
    );
  };
  const handleTabChange = (key) => {
    console.log("Tab đang được chọn:", key);
  };

  return (
    <Drawer
      title="Thêm: hợp đồng thi công"
      width={1180}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Thông tin chung" key="1">
          {RenderThongTinChung()}
        </TabPane>
        <TabPane tab="Thêm nhân viên thi công" key="2">
          {RenderNhanVienThiCong()}
        </TabPane>
      </Tabs>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
