import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Datetime2 } from "layouts/ultis/common";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DichVuService } from "services/DichVuService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
    },
  }));

  const columns = [
    {
      title: "Mã tài sản",
      dataIndex: "MaTaiSan",
      key: "MaTaiSan",
    },
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
      key: "MaVach",
    },
    {
      title: "Tên tài sản",
      dataIndex: "TenTaiSan",
    },
    {
      title: "Loại tài sản",
      dataIndex: "LoaiTaiSan",
    },
    {
      title: "Ghi chú",
      dataIndex: "Ghi chú",
    },
  ];
  const columns2 = [
    {
      title: "Mã số",
      dataIndex: "MaSo",
    },
    {
      title: "Họ tên",
      dataIndex: "HoTen",
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
    },
  ];
  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await DichVuService.Xe.getLichSuTX({
          MaTX: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử ca làm việc"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Chi tiết tài sản kiểm tra" key="1">
            <Table
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="Chi tiết nhân viên" key="2">
            <Table
              pagination={false}
              columns={columns2}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
