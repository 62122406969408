import axiosApi from "./api_helper";
import axios from "axios";
const API_URL = "https://api-beesky.onrender.com";
// const API_URL = 'http://localhost:3000';
const axiosApiChat = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const ChatsService = {
  edit: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/edit", payload)
      .then((res) => res.data);
  },
  deleteFile: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/delete-file", payload)
      .then((res) => res.data);
  },
  addMessage: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/add-hoatdong", payload)
      .then((res) => res.data);
  },
  getChiTietCV: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/get-byid", payload)
      .then((res) => res.data);
  },
  nhanViec: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/nhan-viec", payload)
      .then((res) => res.data);
  },
  checkTrangThai: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/update-state", payload)
      .then((res) => res.data);
  },
  get: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/get-inbox", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/chats/cong-viec/add", payload)
      .then((res) => res.data);
  },
  getInbox: async (payload) => {
    return await axiosApi()
      .post("api/chats/inbox", payload)
      .then((res) => res.data);
  },
  // getInbox: async (payload) => {
  //   return await axiosApiChat
  //     .get("/chats/inbox", {
  //       params: payload,
  //     })
  //     .then((res) => res.data);
  // },
  getMessages: async (payload) => {
    return await axiosApiChat
      .get("/chats/messages/" + payload)
      .then((res) => res.data);
  },
  getMemberGroup: async (payload) => {
    return await axiosApiChat
      .get("/chats/participants/member", {
        params: {
          inbox_id: payload,
        },
      })
      .then((res) => res.data);
  },
  sendMessage: async (payload) => {
    return await axiosApiChat
      .post("/chats/messages", payload)
      .then((res) => res.data);
  },
  readMessage: async (payload) => {
    return await axiosApiChat
      .put("/chats/messages/read", payload)
      .then((res) => res.data);
  },
  User: {
    getById: async (payload) => {
      return await axiosApiChat
        .get("/users/get-by-id", {
          params: { user_id: payload },
        })
        .then((res) => res.data);
    },
  },
  MatBang: {
    getById: async (payload) => {
      return await axiosApiChat
        .get("/mat-bang/get-by-id", {
          params: { ma_mb: payload },
        })
        .then((res) => res.data);
    },
  },
};
