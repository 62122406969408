import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _, { cloneDeep } from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputCustom from "components/Forms/InputCustom";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { KhachHangService } from "services/KhachHangService";
import { CategoryService } from "services/CategoryService";
import { NhanVienService } from "services/NhanVienService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, options, loadcategoriesByMaTN } =
    props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    BatDauTinhPhi: null,
    KetThucTinhPhi: null,
    NgayTTPQL: null,
    VATPQL: 0,
    SoTienPerMet: 0,
    DienTichThuPhi: 0,
    isChoThue: false,
    TinhPhiTheoDienTich: false,
    DaGiaoChiaKhoa: false,
    IsCanHoCaNhan: false,
    SoNguoiHDM: 1,
    MaHTTP: 1,
    KyThanhToan: 1,

    // NgayKyHDDichVu: dateInHoChiMinh.toISO(),
    // NgayBanGiaoDuKien: dateInHoChiMinh.toISO(),
    // NgayBanGiaoCanHo: dateInHoChiMinh.toISO(),
    // NgayBanGiaoHD: dateInHoChiMinh.toISO(),
    // NgayBanGiao: dateInHoChiMinh.toISO(),
  };

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [tangLau, setTangLau] = useState([]);
  const [loaiMB, setLoaiMB] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [hinhThucTP, setHinhThucTP] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const [loaiTien, setLoaiTien] = useState([]);
  const [khachHang, setKhachHang] = useState([]);

  // const handleDisableInput = () => {
  //   setDisabledInput(!disabledInput);
  // };
  const defaultErrors = {
    MaSoMB: null,
    MaTN: null,
    MaKN: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      const _filter = _.cloneDeep(filterCondition);
      setOpen(true);
      setIsUpdate(false);

      if (_filter.MaTN && _filter.MaKN) {
        loadTangLau(_filter.MaKN, _filter.MaTN);
      }
      let _loaiMB = await ToaNhaService.Categories.getLMB(_filter);
      if (_loaiMB?.data) setLoaiMB(_loaiMB?.data);
      let _trangThai = await CategoryService.DuAn.MatBang.TrangThai.get(
        _filter
      );
      if (_trangThai?.data) setTrangThai(_trangThai?.data);

      let _hhtp = await MatBangService.getHinhThucTP(_filter);
      if (_hhtp?.data) setHinhThucTP(_hhtp?.data);
      let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
      setNhanVien(_nv.data);
      let _loaiTien = await MatBangService.getLoaiTien();
      setLoaiTien(_loaiTien.data);
      setData((pr) => ({
        ...pr,
        MaTN: _filter.MaTN,
        MaKN: _filter.MaKN,
        MaTG: 1,
      }));
      const _resKH = await ToaNhaService.DuAn.getKhachHang({
        MaTN: _filter.MaTN,
      });
      setKhachHang(_resKH.data);
    },
    update: async (data) => {
      const _filter = cloneDeep(filterCondition);
      setOpen(true);
      setIsUpdate(true);
      loadTangLau(data?.MaKN, data?.MaTN);
      let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
      setNhanVien(_nv.data);
      let _loaiTien = await MatBangService.getLoaiTien();
      setLoaiTien(_loaiTien.data);
      let _loaiMB = await ToaNhaService.Categories.getLMB(_filter);
      if (_loaiMB?.data) setLoaiMB(_loaiMB?.data);
      let _trangThai = await CategoryService.DuAn.MatBang.TrangThai.get(
        _filter
      );
      if (_trangThai?.data) setTrangThai(_trangThai?.data);
      let _hhtp = await MatBangService.getHinhThucTP(_filter);
      if (_hhtp?.data) setHinhThucTP(_hhtp?.data);
      setData((pr) => ({
        ...data,
        KhachHang: data.TenKH,
      }));
      const _resKH = await ToaNhaService.DuAn.getKhachHang({
        MaTN: _filter.MaTN,
      });
      setKhachHang(_resKH.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
  };
  const SUM = (_data) => {
    _data.ThueVATPQL = Math.ceil(
      (_data.SoTienPerMet * _data.DienTichThuPhi * _data.VATPQL) / 100
    );
    _data.PhiQuanLy = Math.ceil(
      _data.SoTienPerMet * _data.DienTichThuPhi + _data.ThueVATPQL
    );
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhachHang":
        _data.MaKH = v;
        _data.MaKHF1 = v;
        _data[p] = v;
        break;
      case "isChoThue":
        _data[p] = !v;
        break;
      case "TinhPhiTheoDienTich":
        _data[p] = !v;
        break;
      case "DaGiaoChiaKhoa":
        _data[p] = !v;
        break;
      case "IsCanHoCaNhan":
        _data[p] = !v;
        break;

      // case "MaSoMB":
      //   const dataMB = JSON.parse(v.title);
      //   // _data.MaMB = dataMB?.MaMB;
      //   _data.MaSoMB = dataMB?.MaSoMB;
      //   break;
      case "MaTN":
        _data[p] = v;
        _data.MaKN = null;
        _data.MaTL = null;
        _data.MaTT = null;
        setTangLau([]);
        filterCondition.MaTN = v;
        loadcategoriesByMaTN(v);
        let _trangThai = await CategoryService.DuAn.MatBang.TrangThai.get({
          MaTN: v,
        });
        if (_trangThai?.data) setTrangThai(_trangThai?.data);
        break;
      case "MaKN":
        loadTangLau(v, _data?.MaTN);
        _data[p] = v;
        _data.MaTL = null;
        break;
      case "DienTichThuPhi":
        _data.DienTichThuPhi = v;
        SUM(_data);

        break;
      case "SoTienPerMet":
        _data.SoTienPerMet = v;
        SUM(_data);

        break;
      case "VATPQL":
        _data.VATPQL = v;
        SUM(_data);

        break;
      case "BatDauTinhPhi":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "KetThucTinhPhi":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "NgayTTPQL":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayKyHDDichVu":
        const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString5;
        break;
      case "NgayBanGiaoDuKien":
        const date6 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString6 = date6.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString6;
        break;
      case "NgayBanGiaoCanHo":
        const date7 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString7 = date7.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString7;
        break;

      case "NgayBanGiaoHD":
        const date8 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString8 = date8.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString8;
        break;

      case "NgayBanGiao":
        const date9 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString9 = date9.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString9;
        break;

      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await MatBangService.add(_data);
    // if (!_data?.MaTN) {
    //   setData((pre) => {
    //     return { ...pre, MaTN: _res.data };
    //   });
    // }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} mặt bằng thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
      setErrors((prevErrors) => ({
        ...prevErrors,
        MaSoMB: _res?.message,
      }));
    }
  };

  const tabs = [
    {
      title: "Thông tin chung",
      key: "0",
    },
    {
      title: "Thông tin khác",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const handleCheckboxChange = () => {
    // setDisabledInput(!disabledInput);
    setData({
      ...data,
      // DienTichThuPhi: null,
      // SoTienPerMet: null,
    });
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  async function fetchKhachHang(input) {
    return KhachHangService.filterKhachHang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.KyHieu}-${item.TenKH}`,
        value: item.MaKH,
        title: JSON.stringify(item),
      }));
    });
  }
  const loadTangLau = async (makn, matn) => {
    let _res = await ToaNhaService.Categories.getTangByKhoiNhas({
      KhoiNha: "," + makn + ",",
      MaTN: matn,
    });
    setTangLau(_res.data);
  };

  const renderThongTinChung = () => {
    return (
      <Row style={{ marginTop: "-25px" }} gutter={[20, 12]}>
        <Col span={18}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Thông tin chung
              </p>
            </Col>
            <Col span={8}>
              <InputCustom
                required={true}
                label="Mã số MB:"
                applyChange={applyChange}
                prop="MaSoMB"
                value={data?.MaSoMB}
                placeholder="Nhập mã số mặt bằng"
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["MaSoMB"]}
                </p>
              )}
            </Col>

            {/* <Col span={8}>
              <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                <p
                  style={{
                    fontWeight: "600",
                    width: "30% ",
                  }}
                >
                  Loại Mặt bằng:
                </p>
                <DebounceSelect
                  value={data?.MaSoMB}
                  placeholder="Tìm theo mã mặt bằng"
                  fetchOptions={fetchMatBang}
                  onChange={(v) => applyChange("MaSoMB", v)}
                  style={{
                    width: "100%",
                  }}
                />
              </Flex>
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["MaSoMB"]}
                </p>
              )}
            </Col> */}
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Loại MB:"
                applyChange={applyChange}
                prop="MaLMB"
                value={data?.MaLMB}
                placeholder="Chọn dự án"
                options={loaiMB}
                fieldNames={{ label: "TenLMB", value: "MaLMB" }}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["MaLMB"]}
                </p>
              )}
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="Số N định mức:"
                applyChange={applyChange}
                prop={"SoNguoiHDM"}
                value={data?.SoNguoiHDM}
                placeholder="1"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Dự án:"
                applyChange={applyChange}
                prop="MaTN"
                value={data?.MaTN}
                placeholder="Chọn dự án"
                options={options.ToaNha}
                fieldNames={{ label: "TenTN", value: "MaTN" }}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["MaTN"]}
                </p>
              )}
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Trạng thái:"
                applyChange={applyChange}
                prop="MaTT"
                value={data?.MaTT}
                placeholder="Chọn trang thái"
                options={trangThai}
                fieldNames={{ label: "TenTT", value: "MaTT" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                disabled
                label="Nhân viên:"
                applyChange={applyChange}
                prop="MaNV"
                value={data?.MaNV}
                placeholder="Chọn nhân viên"
                options={nhanVien}
                fieldNames={{ label: "HoTenNV", value: "MaNV" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                required={true}
                label="Khối nhà:"
                applyChange={applyChange}
                prop="MaKN"
                value={data?.MaKN}
                placeholder="Chọn khối nhà"
                options={options.KhoiNha}
                fieldNames={{ label: "TenKN", value: "MaKN" }}
              />
              {errors && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors && errors["MaKN"]}
                </p>
              )}
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="TG miễn phí:"
                applyChange={applyChange}
                prop={"ThoiGianMienPhi"}
                value={data?.ThoiGianMienPhi}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT thực tế:"
                applyChange={applyChange}
                prop={"DienTich"}
                value={data?.DienTich}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Tầng(lầu):"
                applyChange={applyChange}
                prop="MaTL"
                value={data?.MaTL}
                placeholder="Chọn tầng"
                options={tangLau}
                fieldNames={{ label: "TenTL", value: "MaTL" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="HT thu phí:"
                applyChange={applyChange}
                prop="MaHTTP"
                value={data?.MaHTTP}
                placeholder="Chọn hình thức thu phí"
                options={hinhThucTP}
                fieldNames={{ label: "TenHT", value: "ID" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT thông thủy:"
                applyChange={applyChange}
                prop={"DTThongThuy"}
                value={data?.DTThongThuy}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              <InputCustom
                label="Số căn hộ:"
                applyChange={applyChange}
                prop="SoNha"
                value={data?.SoNha}
                placeholder="Nhập số căn hộ"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Loại tiền:"
                applyChange={applyChange}
                prop="MaTG"
                value={data?.MaTG}
                placeholder="Chọn loại tiền"
                options={loaiTien}
                fieldNames={{ label: "TenVT", value: "MaTG" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT tim tường:"
                applyChange={applyChange}
                prop={"DTTimTuong"}
                value={data?.DTTimTuong}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              {/* <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                <p
                  style={{
                    fontWeight: "600",
                    width: "12% ",
                  }}
                >
                  Khách hàng:
                </p>
                <DebounceSelect
                  open={open}
                  value={data?.KhachHang}
                  placeholder="Tìm khách hàng"
                  fetchOptions={fetchKhachHang}
                  onChange={(v) => applyChange("KhachHang", v)}
                  style={{
                    width: "100%",
                  }}
                /> */}{" "}
              <SelectCustom
                label="Khách hàng:"
                applyChange={applyChange}
                prop="KhachHang"
                value={data?.KhachHang}
                placeholder="Chọn hình thức thu phí"
                options={khachHang}
                fieldNames={{ label: "TenKH", value: "MaKH" }}
              />
            </Col>
            {/* <Col span={8}>
              <InputCustom
                label="Địa chỉ:"
                applyChange={applyChange}
                prop="DiaChi"
                value={data?.DiaChi}
                placeholder="Nhập ký hiệu"
              />
            </Col> */}
            <Col span={24}>
              <Flex>
                <p
                  style={{
                    fontWeight: "600",
                    width: "20%",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  Diễn giải:
                </p>
                <TextArea
                  value={data?.DienGiai}
                  onChange={(e) => applyChange("DienGiai", e.target.value)}
                />
              </Flex>
            </Col>
            <Col span={24}>
              <Checkbox
                onChange={(e) => applyChange("isChoThue", e.target.value)}
                checked={data?.isChoThue}
                value={data?.isChoThue}
              >
                Cho thuê
              </Checkbox>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Phí quản lý
              </p>
            </Col>
            <Col span={24}>
              <Checkbox
                // checked={disabledInput}
                onClick={() => handleCheckboxChange()}
                onChange={(e) =>
                  applyChange("TinhPhiTheoDienTich", e.target.value)
                }
                checked={data?.TinhPhiTheoDienTich}
                value={data?.TinhPhiTheoDienTich}
              >
                Theo diện tích
              </Checkbox>
            </Col>
            <Col span={24}>
              <InputNumberCustom
                disabled={!data?.TinhPhiTheoDienTich}
                label="Diện tích thu phí:"
                applyChange={applyChange}
                prop={"DienTichThuPhi"}
                value={data?.DienTichThuPhi}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                disabled={!data?.TinhPhiTheoDienTich}
                label="Số tiền:"
                applyChange={applyChange}
                prop={"SoTienPerMet"}
                value={data?.SoTienPerMet}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                label="% VAT:"
                applyChange={applyChange}
                prop={"VATPQL"}
                value={data?.VATPQL}
                placeholder="0"
                max={100}
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                // disabled={true}
                label="Phí quản lý:"
                applyChange={applyChange}
                prop={"PhiQuanLy"}
                value={data?.PhiQuanLy}
                placeholder="0"
              />
            </Col>

            <Col span={24}>
              <InputNumberCustom
                disabled={true}
                label="Tiền VAT:"
                applyChange={applyChange}
                prop={"ThueVATPQL"}
                value={data?.ThueVATPQL}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày bắt đầu tính:"
                applyChange={applyChange}
                prop={"BatDauTinhPhi"}
                value={data?.BatDauTinhPhi}
                placeholder={"Chọn ngày bắt đầu tính"}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày kết thúc tính:"
                applyChange={applyChange}
                prop={"KetThucTinhPhi"}
                value={data?.KetThucTinhPhi}
                placeholder={"Chọn ngày kết thúc tính"}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày thanh toán:"
                applyChange={applyChange}
                prop={"NgayTTPQL"}
                value={data?.NgayTTPQL}
                placeholder={"Chọn ngày thanh toán"}
              />
            </Col>

            <Col span={24}>
              <InputNumberCustom
                label="Thanh toán:"
                applyChange={applyChange}
                prop={"KyThanhToan"}
                value={data?.KyThanhToan}
                placeholder="0"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const renderThongTinKhac = () => {
    return (
      <Row style={{ marginTop: "-25px" }} gutter={[20, 12]}>
        <Col span={24}>
          {" "}
          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Cài đặt điện nước
          </p>
        </Col>
        <Col span={6}>
          <InputCustom
            label="Seri đồng hồ nước:"
            applyChange={applyChange}
            prop="SeriDongHoNuoc"
            value={data?.SeriDongHoNuoc}
            placeholder="Nhập seri đông hồ nước"
          />
        </Col>
        <Col span={6}>
          <InputCustom
            label="Seri đồng hồ điện:"
            applyChange={applyChange}
            prop="SeriDongHoDien"
            value={data?.SeriDongHoDien}
            placeholder="Nhập đồng hồ điện"
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Chỉ số nước bàn giao:"
            applyChange={applyChange}
            prop={"CSNuocBanGiao"}
            value={data?.CSNuocBanGiao}
            placeholder="0"
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Chỉ số điện bàn giao:"
            applyChange={applyChange}
            prop={"CSDienBanGiao"}
            value={data?.CSDienBanGiao}
            placeholder="0"
          />
        </Col>
        <Col span={24}>
          {" "}
          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Thiết lập khác
          </p>
        </Col>
        <Col span={8}>
          <Checkbox
          // onChange={(e) => applyChange("TinhPhiTheoDienTich", e.target.value)}
          // checked={data?.TinhPhiTheoDienTich}
          // value={data?.TinhPhiTheoDienTich}
          >
            Xác nhận diện tích
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
            onChange={(e) => applyChange("DaGiaoChiaKhoa", e.target.value)}
            checked={data?.DaGiaoChiaKhoa}
            value={data?.DaGiaoChiaKhoa}
          >
            Chìa khóa dự phòng
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
            onChange={(e) => applyChange("IsCanHoCaNhan", e.target.value)}
            checked={data?.IsCanHoCaNhan}
            value={data?.IsCanHoCaNhan}
          >
            Đây là căn hộ cá nhân
          </Checkbox>
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày ký hợp đồng dịch vụ:"
            applyChange={applyChange}
            prop={"NgayKyHDDichVu"}
            value={data?.NgayKyHDDichVu}
            placeholder={"Chọn ngày ký hợp đồng dịch vụ"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao dự kiến:"
            applyChange={applyChange}
            prop={"NgayBanGiaoDuKien"}
            value={data?.NgayBanGiaoDuKien}
            placeholder={"Chọn ngày bàn giao dự kiến"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao căn hộ:"
            applyChange={applyChange}
            prop={"NgayBanGiaoCanHo"}
            value={data?.NgayBanGiaoCanHo}
            placeholder={"Chọn ngày bàn dao căn hộ"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao thực tế:"
            applyChange={applyChange}
            prop={"NgayBanGiaoHD"}
            value={data?.NgayBanGiaoHD}
            placeholder={"Chọn ngày bàn giao thực tế"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao thông báo:"
            applyChange={applyChange}
            prop={"NgayBanGiao"}
            value={data?.NgayBanGiao}
            placeholder={"Chọn ngày bàn giao thông báo"}
          />
        </Col>
        <Col span={12}>
          <Flex>
            <p
              style={{
                fontWeight: "600",
                width: "20%",
                borderBottom: "1px solid #d9d9d9",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
          </Flex>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Toà nhà"
      width={1300}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? renderThongTinChung() : renderThongTinKhac()}
            </TabPane>
          );
        })}
      </Tabs>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
