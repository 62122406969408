import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
} from "react-icons/md";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import "../scss/index.scss";
import { OtherService } from "services/OtherService";
import { Editor } from "primereact/editor";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineMenuFold } from "react-icons/ai";

export default function Index() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const defaultValue = {
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaTN: null,
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const [mucdo, setMucdo] = useState([]);
  const [hinhthuc, setHinhthuc] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [trangthai, setTrangthai] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [toanha, setToanha] = useState([]);

  useEffect(() => {
    loadCategories();
    // load(filterCondition);
  }, []);

  const load = async (_filter) => {
    setLoading(true);
    let _res = await OtherService.getThongBao(_filter);
    // let _tt = await YeuCauService.Categories.getTrangThai({
    //   MaTN: _filter.MaTN,
    // });
    // let status = [
    //   { MaTT: 0, TenTT: "Tất cả", Color: "#cccccc" },
    //   ..._tt.data?.filter((v) => v.isViewApp),
    // ];
    // status.map((item) => {
    //   if (item.MaTT === 0) {
    //     item.Total = _res.data?.length;
    //   } else {
    //     item.Total = _res?.data?.filter((v) => v.MaTT === item.MaTT)?.length;
    //   }
    // });
    // setTrangthai(status);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _ht = await YeuCauService.Categories.getHinhThuc();
    setHinhthuc(_ht.data);
    let _md = await YeuCauService.Categories.getMucDo();
    setMucdo(_md.data);
    let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
    setNhanvien(_nv.data);
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    load(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _pb = await NhanVienService.Categories.getPhongBan({
      MaTN: matn,
    });
    setPhongban(_pb.data);
  };
  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "TieuDe",
      // ellipsis: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      // ellipsis: true,
    },
    // {
    //   title: "Trạng thái",
    //   dataIndex: "MaTT",
    // ellipsis: true,
    //   render: (item) => (
    //     <Tag color={trangthai?.find((v) => v.MaTT === item)?.Color}>
    //       {trangthai?.find((v) => v.MaTT === item)?.TenTT}
    //     </Tag>
    //   ),
    // },
    {
      title: "Người nhập",
      dataIndex: "HoTenNV",
      // ellipsis: true,
    },
    // {
    //   title: "Hiển thị",
    //   dataIndex: "NguoiGui",
    // ellipsis: true,
    // },
    {
      title: "Hiển thị",
      dataIndex: "isHot",
      // ellipsis: true,
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: "Banner",
      dataIndex: "isBanner",
      // ellipsis: true,
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: "Tin ban quản lý",
      dataIndex: "isBQL",
      // ellipsis: true,
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayNhap",
      // ellipsis: true,
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={10} justify="center">
            <Tooltip placement="topRight" title="Gửi thông báo">
              <Button
                type="link"
                icon={<MdNotificationsActive size={18} />}
                onClick={() => sendThongBao(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="link"
                icon={<MdEdit size={18} color="" />}
                onClick={() => refAdd.current.update(item.MaTB)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá yêu cầu"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaTB)}
            >
              <Button type="text" danger icon={<CiTrash size={18} />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await OtherService.deleteThongBao({
      ID: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTB !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const sendThongBao = async (item) => {
    setLoading(true);
    let _res = await OtherService.sendThongBao({
      TieuDe: item.TieuDe,
      NoiDung: item.DienGiai,
      FormID: 1172,
      LinkID: item.MaTB,
      MatBang: item?.MatBang?.split(",")?.map(Number),
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Gửi thông báo thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_filter.MaTN);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách khách hàng thông báo" + fileExtension);
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            PhongBan: phongban,
            NhanVien: nhanvien,
            MucDo: mucdo,
            HinhThuc: hinhthuc,
            ToaNha: toanha,
          }}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              {trangthai?.map((item) => {
                return (
                  <Badge
                    key={item.MaTT}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.MaTT);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.MaTT === item.MaTT ? item.Color : null,
                        color: stateActive.MaTT === item.MaTT ? "white" : null,
                      }}
                    >
                      <p>{item.TenTT}</p>
                    </div>
                  </Badge>
                );
              })}
            </div>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                type="primary"
                onClick={() => refAdd.current.show(filterCondition?.MaTN)}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            // x: 2000,
          }}
          size="small"
        />
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{
          PhongBan: phongban,
          NhanVien: nhanvien,
          MucDo: mucdo,
          HinhThuc: hinhthuc,
          TrangThai: trangthai,
          ToaNha: toanha,
        }}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{
          NhanVien: nhanvien,
          TrangThai: trangthai,
        }}
      />
      {contextHolder}
      <div style={{ display: "none" }}>
        <Editor style={{ height: 0 }} />
      </div>
    </Row>
  );
}
