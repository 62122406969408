import {
  Button,
  Drawer,
  Flex,
  Input,
  notification,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import Column from "antd/es/table/Column";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { Format_Datetime2 } from "layouts/ultis/common";
import { DataTable } from "primereact/datatable";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CiSaveDown1, CiTrash } from "react-icons/ci";
import { DichVuService } from "services/DichVuService";
import { HopDongService } from "services/HopDongService";
import _ from "lodash";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import FormDetail from "views/admin/SoQuy/PhieuChi/FormDetail";
import FormDetailPT from "views/admin/SoQuy/PhieuThu/FormDetail";
import { FaCheckCircle, FaRegSave } from "react-icons/fa";
import { UploadService } from "services/UploadService";
import { MdOutlinePreview } from "react-icons/md";
const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  const [payLoad, setPayload] = useState();
  const [taiLieu, setTaiLieu] = useState([]);
  const [visible, setVisible] = useState(false);

  const refPC = useRef(null);
  const refPT = useRef(null);
  const { TabPane } = Tabs;
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Link = URL_UPLOAD + file[0];
      setData(_data);
    }
  };

  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };
  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
      setActiveKey("1");
    },
  }));

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns1 = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tổng tiền/tháng",
      dataIndex: "TongTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  // const columns2 = [
  //   {
  //     title: "Ký hiệu",
  //     dataIndex: "KyHieu",
  //   },
  //   {
  //     title: "Tên tài liệu",
  //     dataIndex: "Name",
  //   },
  //   {
  //     title: "Kích thước",
  //     dataIndex: "KichThuoc",
  //   },
  //   {
  //     title: "Diễn giải",
  //     dataIndex: "DienGiai",
  //   },
  //   {
  //     title: "Nhóm tài liệu",
  //     dataIndex: "NhomTaiLieu",
  //   },
  //   {
  //     title: "Ngày ký VB",
  //     dataIndex: "NgayKyVB",
  //   },
  //   {
  //     title: "Nhân viên",
  //     dataIndex: "NhanVien",
  //   },
  //   {
  //     title: "Ngày cập nhật",
  //     dataIndex: "NgayCN",
  //     render: (item) => (item ? Format_Datetime(item) : ""),
  //   },
  // ];
  const columns3 = [
    {
      title: "Dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "% VAT",
      dataIndex: "TyLeVAT",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Tổng tiền + VAT",
      dataIndex: "TongTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Kỳ TT",
      dataIndex: "KyThanhToan",
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Ngày tính phí",
      dataIndex: "NgayTinhPhi",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "NgayKT",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      width: 150,
    },
  ];
  const columns4 = [
    {
      title: "Dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },

    {
      title: "Tổng tiền",
      dataIndex: "SoTien",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Đã thu",
      dataIndex: "DaThu",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Còn lại",
      dataIndex: "ConNo",
      render: (item) => (item ? Format_Currency(item) : 0),
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
      render: (item) => (item ? Format_Date(item) : null),
    },
  ];
  const columns5 = [
    {
      title: "Tên biểu mẫu",
      dataIndex: "TenBieuMau",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
  ];
  const columns6 = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      // ellipsis: true,
      render: (item, record) => (
        <p
          style={{ fontWeight: "bold", color: "#1890ff", cursor: "pointer" }}
          onClick={() => refPT.current.show(record?.MaPhieu)}
        >
          {record.SoPhieu}
        </p>
      ),
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
    },
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Số tiền thu",
      dataIndex: "SoTienThanhToan",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const columns7 = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      // ellipsis: true,
      render: (item, record) => (
        <p
          style={{ fontWeight: "bold", color: "#1890ff", cursor: "pointer" }}
          onClick={() => refPC.current.show(record?.MaPhieu)}
        >
          {record.SoPhieu}
        </p>
      ),
    },
    {
      title: "Ngày chi",
      dataIndex: "NgayChi",
      render: (item) => (item ? Format_Date(item) : null),
    },

    {
      title: "Đã chi",
      dataIndex: "SoTienThanhToan",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const columns8 = [
    {
      title: "Số phụ lục",
      dataIndex: "SoPL",
    },
    {
      title: "Tên loại PL",
      dataIndex: "TenLPL",
    },
    {
      title: "Ngày phụ lục",
      dataIndex: "NgayPL",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Ngày Tạo",
      dataIndex: "NgayTao",
      render: (item) => (item ? Format_Date(item) : null),
    },

    {
      title: "Trạng thái",
      render: (item) => (
        <Tag color={item?.State === "NEW" ? "blue" : "green"}>
          {item?.State === "NEW" ? "Chờ duyệt" : "Đã duyêt"}
        </Tag>
      ),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
    },
  ];
  const renderTaiLieu = () => {
    const addTaiLieu = () => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu.push({
        ID: null,
        Name: "",
        Link: "",
      });
      setTaiLieu(_taiLieu);
    };

    const onchangeTaiLieu = (field, value, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      let item = _taiLieu.slice(index, index + 1);

      if (item?.length > 0) {
        _taiLieu.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setTaiLieu(_taiLieu);
    };

    const deleteTaiLieu = (item, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu.splice(index, 1);
      setTaiLieu(_taiLieu);
      if (item.ID) {
        HopDongService.ChoThue.deleteTLTheoHD({ ID: item.ID });
      }
    };
    const submitTaiLieu = async (item, index) => {
      const formData = new FormData();
      formData.append("Image", item?.File);
      const _res = await UploadService.upload(formData);
      console.log(_res?.data);
      if (_res?.data) {
        let _resSM = await HopDongService.ChoThue.addTLTheoHD({
          MaHD: payLoad,
          Name: item?.Name,
          Link: URL_UPLOAD + _res?.data[0],
        });
        if (_resSM.status === 2000) {
          let _resDT = await HopDongService.ChoThue.getTLTheoHD({
            ID: payLoad,
          });
          if (_resDT.data) setTaiLieu(_resDT.data);

          openNotification("success", "topRight", `Thêm tài liệu thành công!`);
        } else {
          openNotification("error", "topRight", _res?.message);
        }
      }
      // MaHD
    };
    const handleUploadFile = (file, index) => {
      let _taiLieu = _.cloneDeep(taiLieu);
      _taiLieu[index].Name = file?.name;
      _taiLieu[index].File = file;
      setTaiLieu(_taiLieu);
    };
    const handleXemMau = (link) => {
      if (link) {
        const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
        window.open(fullLink, "_blank"); // Opens the link in a new tab
      }
    };

    const handleDownload = (fileUrl, fileName) => {
      window.open(fileUrl, "_blank");
    };
    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={taiLieu}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Tên tài liệu</p>}
            body={(item, row) => (
              <Input
                value={item.Name}
                onChange={(e) =>
                  onchangeTaiLieu("Name", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Upload</p>}
            body={(item, row) =>
              item?.ID ? (
                // <Tooltip placement="topRight" title="Xem mẫu">
                //   <Button
                //     style={{ marginLeft: 10 }}
                //     type="primary"
                //     ghost
                //     icon={<MdOutlinePreview />}
                //     onClick={() => handleXemMau(item?.Link)}
                //   />
                // </Tooltip>
                <p
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "300px",
                  }}
                  onClick={() => handleXemMau(item?.Link)}
                >
                  {item?.Link}
                </p>
              ) : (
                <input
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      handleUploadFile(file, row.rowIndex);
                    }
                  }}
                  style={{
                    border: "1px solid #d9d9d9",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                />
              )
            }
          />
          <Column
            fixed="right"
            header={
              <Button
                // loading={loading}
                onClick={addTaiLieu}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Flex gap={10}>
                {item?.ID ? (
                  <Tooltip title="Tải về">
                    <Button
                      style={{
                        border: "1px solid blue",
                        color: "blue",
                      }}
                      onClick={() => handleDownload(item?.Link)}
                      icon={<CiSaveDown1 />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Lưu">
                    <Button
                      style={{
                        border: "1px solid green",
                        color: "green",
                      }}
                      onClick={() => submitTaiLieu(item, row.rowIndex)}
                      icon={<FaCheckCircle />}
                    />
                  </Tooltip>
                )}

                <Tooltip title="Xóa">
                  <Button
                    onClick={() => deleteTaiLieu(item, row.rowIndex)}
                    danger
                    ghost
                    icon={<CiTrash />}
                  />
                </Tooltip>
              </Flex>
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };

  const fetchDataForTab = async (key, value) => {
    setActiveKey(key);
    let _res;
    switch (key) {
      case "1":
        _res = await HopDongService.ChoThue.getMBTheoHD({
          ID: value,
        });
        if (_res.data) setData(_res.data);

        break;
      case "2":
        _res = await HopDongService.ChoThue.getTLTheoHD({
          ID: value,
        });
        if (_res.data) setTaiLieu(_res.data);
        break;
      case "3":
        _res = await HopDongService.ChoThue.getDVTheoHD({
          ID: value,
        });
        if (_res.data) setData(_res.data);

        break;
      case "4":
        _res = await HopDongService.ChoThue.getHDTheoHD({
          ID: value,
        });
        if (_res.data) {
          setData(_res.data);
        }

        break;
      case "5":
        _res = await HopDongService.ChoThue.getPTTheoHD({
          ID: value,
        });
        if (_res.data) setData(_res.data);
        break;
      case "6":
        _res = await HopDongService.ChoThue.getPCTheoHD({
          ID: value,
        });
        if (_res.data) setData(_res.data);
        break;
      case "7":
        _res = await HopDongService.ChoThue.getPLTheoHD({
          ID: value,
        });
        if (_res.data) setData(_res.data);
        break;
      default:
        setData([]);
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử hợp đồng cho thuê"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="1. Mặt bằng" key="1">
            <Table
              pagination={false}
              columns={columns1}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="2. Tài liệu" key="2">
            {renderTaiLieu()}
          </TabPane>
          <TabPane tab="3. Dịch vụ" key="3">
            <Table
              pagination={false}
              columns={columns3}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1800,
              }}
            />
          </TabPane>
          <TabPane tab="4. Hóa đơn" key="4">
            <Table
              pagination={false}
              columns={columns4}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1100,
              }}
              summary={(pageData) => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2}>
                        <p style={{ fontWeight: "bold" }}>
                          Tổng: {pageData?.length}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}>
                          {Format_Currency(
                            data.reduce((sum, item) => sum + item.SoTien, 0)
                          )}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}>
                          {Format_Currency(
                            data.reduce((sum, item) => sum + item.DaThu, 0)
                          )}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}>
                          {Format_Currency(
                            data.reduce((sum, item) => sum + item.ConNo, 0)
                          )}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}></p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </TabPane>

          <TabPane tab="5. Phiếu thu" key="5">
            <Table
              pagination={false}
              columns={columns6}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
              summary={(pageData) => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}>
                          Tổng: {pageData?.length}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        <p style={{ fontWeight: "bold" }}>
                          {Format_Currency(
                            data.reduce(
                              (sum, item) => sum + item.SoTienThanhToan,
                              0
                            )
                          )}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </TabPane>
          <TabPane tab="6. Phiếu chi" key="6">
            <Table
              pagination={false}
              columns={columns7}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="7. Phụ lục" key="7">
            <Table
              pagination={false}
              columns={columns8}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
        <FormDetail ref={refPC} onAfterSubmit={onAfterSubmit} />
        <FormDetailPT ref={refPT} onAfterSubmit={onAfterSubmit} />
      </Drawer>
      {contextHolder}
    </>
  );
};

export default forwardRef(FormQLHistory);
