import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Radio,
  Flex,
  Table,
  Tooltip,
  Popconfirm,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import { SaveOutlined } from "@ant-design/icons";
import { CiTrash } from "react-icons/ci";
import { MdOutlineRemoveRoad } from "react-icons/md";
import Column from "antd/es/table/Column";
import { DataTable } from "primereact/datatable";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nganHangSelect, setNganHangSlect] = useState([]);
  const [hTTTSelect, setHTTTSelect] = useState([]);
  const [nganHang, setNganHang] = useState([]);
  const [hotlineBQL, setHotlineBQL] = useState([]);
  const [hTTTApp, setHTTTApp] = useState([]);

  const [tKNH, setTKNH] = useState([]);
  const [hotline, setHotline] = useState([]);
  const [hinhTTT, setHinhTTT] = useState([]);
  const [pT, SetPT] = useState([]);

  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [valueRadio, setValueRadio] = useState(1);
  const onChangeRadio = (e) => {
    applyChange("KieuThanhToan", e.target.value);
    setValueRadio(e.target.value);
  };

  const filterByMaTN = async (maTN) => {
    const _resNH = await ToaNhaService.DuAn.getTKNHByTN({
      MaTN: maTN,
    });
    if (_resNH.data) {
      setTKNH(_resNH.data);
    }
    const _resHL = await ToaNhaService.DuAn.getHotlineByTN({
      MaTN: maTN,
    });
    if (_resHL.data) {
      setHotline(_resHL.data);
    }
    const _resHT = await ToaNhaService.DuAn.getHTTTByTN({
      MaTN: maTN,
    });
    if (_resHT.data) {
      setHinhTTT(_resHT.data);
    }
  };
  const filterOptionSelect = async () => {
    const _resNH = await ToaNhaService.DuAn.getNganHang({});
    if (_resNH.data) {
      setNganHangSlect(_resNH.data);
    }
    const _resHTTT = await ToaNhaService.DuAn.getHTTT({});
    if (_resHTTT.data) {
      setHTTTSelect(_resHTTT.data);
    }
    const _resPT = await ToaNhaService.DuAn.getPhieuThu({});
    if (_resPT.data) {
      SetPT(_resPT.data);
    }
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      filterOptionSelect();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      data.MauPhieuThu = _.map(data.MaMauPhieuThu?.split(","), (v) =>
        Number(v)
      );
      setData(data);
      filterByMaTN(data?.MaTN);
      filterOptionSelect();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MauPhieuThu":
        _data.MaMauPhieuThu = v.toString(); //JSON.stringify(v);
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    const payload = {
      ..._data,
      TaiKhoanNganHang: tKNH,
      Hotlines: hotline,
      HinhThuc: hinhTTT,
    };
    let _res = await ToaNhaService.addToaNha(payload);
    if (!_data?.MaTN) {
      setData((pre) => {
        return { ...pre, MaTN: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} dự án thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const tabs = [
    {
      title: "Thông tin",
      key: "0",
    },
    {
      title: "Cài đặt nâng cao",
      key: "1",
    },
    {
      title: "Cài đặt khác",
      key: "2",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };
  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          1. Thông tin chung
        </p>
        <Row gutter={[24, 12]}>
          <Col span={8}>
            <InputCustom
              label="Tên viết tắt:"
              applyChange={applyChange}
              prop="TenVT"
              value={data?.TenVT}
              placeholder="Nhập tên viết tắt"
            />
          </Col>
          {/* <Col span={18}>
            <SelectCustom
              label="Tên toà nhà:"
              applyChange={applyChange}
              prop="TenTN"
              value={data?.TenTN}
              placeholder="Chọn tên tòa nhà"
              options={[]}
              // fieldNames={{ label: "TenLMB", value: "MaLMB" }}
              errors={errors}
            />
          </Col> */}
          <Col span={8}>
            <InputCustom
              label="Tên tòa nhà:"
              applyChange={applyChange}
              prop="TenTN"
              value={data?.TenTN}
              placeholder="Nhập tên tòa nhà "
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Mã số thuế:"
              applyChange={applyChange}
              prop="MaSoThue"
              value={data?.MaSoThue}
              placeholder="Nhập mã số thuế "
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Điện thoại:"
              applyChange={applyChange}
              prop="DienThoai"
              value={data?.DienThoai}
              placeholder="Nhập số điện thoại "
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Email:"
              applyChange={applyChange}
              prop="Email"
              value={data?.Email}
              placeholder="Nhập email "
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Địa chỉ:"
              applyChange={applyChange}
              prop="DiaChi"
              value={data?.DiaChi}
              placeholder="Nhập địa chỉ "
            />
          </Col>
        </Row>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          1. Thông tin trên báo cáo
        </p>
        <Row gutter={[24, 20]}>
          <Col span={8}>
            <InputCustom
              label="Công ty Ql:"
              applyChange={applyChange}
              prop="CongTyQuanLy"
              value={data?.CongTyQuanLy}
              placeholder="Nhập địa chỉ công ty quản lý"
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Địa chỉ CTQL:"
              applyChange={applyChange}
              prop="DiaChiCongTy"
              value={data?.DiaChiCongTy}
              placeholder="Nhập địa chỉ công ty "
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Tiêu đề app:"
              applyChange={applyChange}
              prop="TieuDeApp"
              value={data?.TieuDeApp}
              placeholder="Nhập tiêu đề app"
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Người quản lý:"
              applyChange={applyChange}
              prop="NguoiDaiDien"
              value={data?.NguoiDaiDien}
              placeholder="Nhập tên người quản lý"
            />
          </Col>
          <Col span={8}>
            {/* <SelectCustom
              label="Ngân hàng:"
              applyChange={applyChange}
              prop="NganHang"
              value={data?.NganHang}
              placeholder="Chọn ngân hàng"
              options={nganHangSelect}
                fieldNames={{ label: "TenNganHang", value: "ID" }}
              errors={errors}
            /> */}
            <InputCustom
              label="Ngân hàng:"
              applyChange={applyChange}
              prop="NganHang"
              value={data?.NganHang}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Tên người nhận:"
              applyChange={applyChange}
              prop="ChuTaiKhoan"
              value={data?.ChuTaiKhoan}
              placeholder="Nhập tên người nhận"
            />
          </Col>

          <Col span={12}>
            <InputCustom
              label="Số tài khoản:"
              applyChange={applyChange}
              prop={"SoTaiKhoan"}
              value={data?.SoTaiKhoan}
              placeholder="Nhập số tài khoản"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Kế toán trưởng:"
              applyChange={applyChange}
              prop="KeToanTruong"
              value={data?.KeToanTruong}
              placeholder="Nhập tên kế toán trưởng"
            />
          </Col>

          <Col span={6}>
            <Checkbox
              checked={data?.isBaiXeVinh}
              onChange={(v) => applyChange("isBaiXeVinh", v.target.checked)}
            >
              Bãi xe khác
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isBaiXeFutech}
              onChange={(v) => applyChange("isBaiXeFutech", v.target.checked)}
            >
              Bãi xe Futech
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isApp}
              onChange={(v) => applyChange("isApp", v.target.checked)}
            >
              Đăng ký app
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isDinhMuc}
              onChange={(v) => applyChange("isDinhMuc", v.target.checked)}
            >
              ĐM nước
            </Checkbox>
          </Col>
          <Col span={24}>
            <UploadComponent
              files={data?.Logo ? [data?.Logo] : []}
              multiple={false}
              affterUpload={affterUpload}
              onRemove={onRemove}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderNangCao = () => {
    return (
      <>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <p
              style={{
                fontWeight: "500",

                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Cài đặt dịch vụ thẻ xe
            </p>
          </Col>
          <Col span={12}>
            <p
              style={{
                fontWeight: "500",

                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Mẫu thông báo
            </p>
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Ngày hết hạn:"
              applyChange={applyChange}
              prop={"Ngay"}
              value={data?.Ngay}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Ngày hết hạn:"
              applyChange={applyChange}
              prop={"NgayHetHanThongBao"}
              value={data?.NgayHetHanThongBao}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Kiểu ngày hết hạn:"
              applyChange={applyChange}
              prop="KieuNgay"
              value={data?.KieuNgay}
              placeholder="Chọn thẻ"
              options={[
                {
                  TenNgay: "Trong tháng",
                  ID: 1,
                },
                {
                  TenNgay: "Tháng tiếp theo",
                  ID: 2,
                },
                {
                  TenNgay: "Tháng tiếp tiếp theo",
                  ID: 3,
                },
              ]}
              fieldNames={{ label: "TenNgay", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Chọn mẫu TB:"
              applyChange={applyChange}
              prop="MaMauThongBao"
              value={data?.MaMauThongBao}
              placeholder="Chọn thẻ"
              options={[]}
              fieldNames={{ label: "HoTenNK", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            {/* <Flex align="center"> */}
            <p
              style={{
                fontWeight: "500",
                marginBottom: "10px",
                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Kiểu thanh toán:
            </p>

            <Radio.Group onChange={onChangeRadio} value={valueRadio}>
              <Radio value={0}>Theo ngày</Radio>
              <Radio value={1}>Nửa tháng</Radio>
              <Radio value={2}>Tròn tháng</Radio>
            </Radio.Group>
            {/* </Flex> */}
          </Col>
          <Col style={{ marginBottom: "-15px" }} span={12}>
            <p
              style={{
                fontWeight: "500",
                marginBottom: "10px",
                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Mẫu nhắc nợ
            </p>
            <InputNumberCustom
              label="Hết ngày:"
              applyChange={applyChange}
              prop={"NhacNoHetNgay"}
              value={data?.NhacNoHetNgay}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="VAT xe:"
              applyChange={applyChange}
              prop={"VATXe"}
              value={data?.VATXe}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Trước ngày:"
              applyChange={applyChange}
              prop={"NhacNoTruocNgay"}
              value={data?.NhacNoTruocNgay}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="VAT nước:"
              applyChange={applyChange}
              prop={"VATNuoc"}
              value={data?.VATNuoc}
            />
          </Col>

          <Col span={12}>
            <p
              style={{
                fontWeight: "500",
                marginBottom: "10px",
                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Mẫu phiếu thu
            </p>
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="VAT điện:"
              applyChange={applyChange}
              prop={"VatDien"}
              value={data?.VatDien}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              mode={"multiple"}
              label="Chọn mẫu PT:"
              applyChange={applyChange}
              prop="MauPhieuThu"
              value={data?.MauPhieuThu}
              placeholder="Chọn thẻ"
              options={pT}
              fieldNames={{ label: "TenMauPT", value: "ID" }}
            />
          </Col>

          <Col span={12}>
            <InputNumberCustom
              label="VAT hao hụt ĐN:"
              applyChange={applyChange}
              prop={"VAT_DNHaoHut"}
              value={data?.VAT_DNHaoHut}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Số tháng lùi điện nước:"
              applyChange={applyChange}
              prop={"SoThangLuiDN"}
              value={data?.SoThangLuiDN}
            />
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "500",

                // backgroundColor: "#d1cbcb",
                color: "#1677FF",
              }}
            >
              Thông tin liên lạc phiếu thu
            </p>
          </Col>
          <Col span={12}>
            <InputCustom
              label="SĐT 1:"
              applyChange={applyChange}
              prop="SDTPhieuThu1"
              value={data?.SDTPhieuThu1}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="SĐT 2:"
              applyChange={applyChange}
              prop="SDTPhieuThu2"
              value={data?.SDTPhieuThu2}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="SĐT 3:"
              applyChange={applyChange}
              prop="SDTPhieuThu3"
              value={data?.SDTPhieuThu3}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="SĐT 4:"
              applyChange={applyChange}
              prop="SDTPhieuThu4"
              value={data?.SDTPhieuThu4}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Nợ cũ:"
              applyChange={applyChange}
              prop="SDTPhieuThu5"
              value={data?.SDTPhieuThu5}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Ngày khóa PT:"
              applyChange={applyChange}
              prop={"NgayKhoaPT"}
              value={data?.NgayKhoaPT}
              placeholder={""}
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="TT tại văn phòng:"
              applyChange={applyChange}
              prop="gbTTTaiQuay"
              value={data?.gbTTTaiQuay}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="TT tại quầy:"
              applyChange={applyChange}
              prop="gbTTTaiVanPhong"
              value={data?.gbTTTaiVanPhong}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderHinhTTT = () => {
    const addHinhTTT = () => {
      let _hinhTTT = _.cloneDeep(hinhTTT);
      _hinhTTT.push({
        ID: null,
        MaHinhThuc: null,
        isViewApp: false,
      });
      setHinhTTT(_hinhTTT);
    };

    const onChangeHinhTTT = (field, value, index) => {
      let _hinhTTT = _.cloneDeep(hinhTTT);
      let item = _hinhTTT.slice(index, index + 1);
      if (item?.length > 0) {
        _hinhTTT.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setHinhTTT(_hinhTTT);
    };

    const deleteHinhTTT = (item, index) => {
      let _hinhTTT = _.cloneDeep(hinhTTT);
      _hinhTTT.splice(index, 1);
      setHinhTTT(_hinhTTT);
      if (item.ID) {
        ToaNhaService.DuAn.deleteHinhTTT({ ID: item.ID });
      }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={hinhTTT}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
             style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Tên Hình Thức</p>}
            body={(item, row) => (
              <Select
                style={{ width: "500px" }}
                value={item.MaHinhThuc}
                onChange={(value) =>
                  onChangeHinhTTT("MaHinhThuc", value, row.rowIndex)
                }
                options={hTTTSelect}
                fieldNames={{ label: "TenHinhThuc", value: "ID" }}
              />
            )}
          />
          <Column
             style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Hiển thị</p>}
            body={(item, row) => (
              <Checkbox
                checked={item.isViewApp}
                onChange={() =>
                  onChangeHinhTTT("isViewApp", !item.isViewApp, row.rowIndex)
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addHinhTTT}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteHinhTTT(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{ textAlign: "right", width: "120px",border:'1px solid #d9d9d9' }}
          />
        </DataTable>
      </>
    );
  };

  const renderHotline = () => {
    const addHotline = () => {
      let _hotline = _.cloneDeep(hotline);
      _hotline.push({
        ID: null,
        tenHotline: "",
        dienThoai: "",
        ghiChu: "",
        isViewApp: true,
      });
      setHotline(_hotline);
    };

    const onChangeHotline = (field, value, index) => {
      let _hotline = _.cloneDeep(hotline);
      let item = _hotline.slice(index, index + 1);
      if (item?.length > 0) {
        _hotline.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setHotline(_hotline);
    };

    const deleteHotline = (item, index) => {
      let _hotline = _.cloneDeep(hotline);
      _hotline.splice(index, 1);
      setHotline(_hotline);
      if (item.ID) {
        ToaNhaService.DuAn.deleteHotline({ ID: item.ID });
      }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={hotline}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
             style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Tên Hotline</p>}
            body={(item, row) => (
              <Input
                value={item.tenHotline}
                onChange={(e) =>
                  onChangeHotline("tenHotline", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
             style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Di động</p>}
            body={(item, row) => (
              <Input
                value={item.dienThoai}
                onChange={(e) =>
                  onChangeHotline("dienThoai", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Ghi chú</p>}
            body={(item, row) => (
              <Input
                value={item.ghiChu}
                onChange={(e) =>
                  onChangeHotline("ghiChu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}

            header={<p style={{ fontSize: "13px" }}>Hiển thị app cư dân</p>}
            body={(item, row) => (
              <Checkbox
                checked={item.isViewApp}
                onChange={() =>
                  onChangeHotline("isViewApp", !item.isViewApp, row.rowIndex)
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addHotline}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteHotline(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}        
            style={{ textAlign: "right", width: "120px",border:'1px solid #d9d9d9' }}
          />
        </DataTable>
      </>
    );
  };

  const renderTKNH = () => {
    const addTKNH = () => {
      let _tKNH = _.cloneDeep(tKNH);
      _tKNH.push({
        ID: null, // null nếu thêm mới
        ChiNhanh: "",
        SoTaiKhoan: "",
        ChuTaiKhoan: "",
        NganHang: "",
        MaNH: null,
        SoTT: null,
        isHienThiApp: true,
        isTrucTuyen: false,
      });
      setTKNH(_tKNH);
    };

    const onChangeTKNH = (p, v, index) => {
      let _tKNH = _.cloneDeep(tKNH);
      if (_tKNH[index]) {
        _tKNH[index] = {
          ..._tKNH[index],
          [p]: v,
          NganHang: nganHangSelect.find((item) => item.ID === v)?.TenNganHang,
        };
      }
      setTKNH(_tKNH);
    };

    const deleteTKNH = (v, index) => {
      let _tKNH = _.cloneDeep(tKNH);
      _tKNH.splice(index, 1);
      setTKNH(_tKNH);
      if (v.ID) {
        ToaNhaService.DuAn.deleteTKNH({ ID: v.ID });
      }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={tKNH}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Hiển thị app</p>}
            body={(item, row) => (
              <Checkbox
                checked={item.isHienThiApp}
                onChange={() =>
                  onChangeTKNH("isHienThiApp", !item.isHienThiApp, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Is trực tuyến</p>}
            body={(item, row) => (
              <Checkbox
                checked={item.isTrucTuyen}
                onChange={() =>
                  onChangeTKNH("isTrucTuyen", !item.isTrucTuyen, row.rowIndex)
                }
              />
            )}
          />
          <Column
          
            header={<p style={{ fontSize: "13px" }}>Sắp xếp</p>}
            style={{ width: "100px",border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                value={item.SoTT}
                placeholder=""
                onChange={(value) => onChangeTKNH("SoTT", value, row.rowIndex)}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Ngân hàng</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.MaNH}
                onChange={(value) => onChangeTKNH("MaNH", value, row.rowIndex)}
                options={nganHangSelect}
                fieldNames={{ label: "TenNganHang", value: "ID" }}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Chủ tài khoản</p>}
            body={(item, row) => (
              <Input
                value={item.ChuTaiKhoan}
                onChange={(e) =>
                  onChangeTKNH("ChuTaiKhoan", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số tài khoản</p>}
            body={(item, row) => (
              // <Input
              //   value={item.SoTaiKhoan}
              //   onChange={(value) =>
              //     onChangeTKNH("SoTaiKhoan", value, row.rowIndex)
              //   }
              //   // formatter={(value) =>
              //   //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              //   // }
              // />
              <Input
                value={item.SoTaiKhoan}
                placeholder=""
                onChange={(e) =>
                  onChangeTKNH("SoTaiKhoan", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Chi nhánh</p>}
            body={(item, row) => (
              <Input
                value={item.ChiNhanh}
                onChange={(e) =>
                  onChangeTKNH("ChiNhanh", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={
              <Button
                loading={loading}
                onClick={() => addTKNH()}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteTKNH(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{ textAlign: "right", width: "120px" ,border: "1px solid #d9d9d9" }}
          />
        </DataTable>
      </>
    );
  };

  const renderKhac = () => {
    return (
      <>
        <Row gutter={[20, 15]}>
          <Col span={24}>
            <p
              style={{
                fontWeight: "500",
                paddingLeft: "5px",
              }}
            >
              Duyệt hóa đơn
            </p>
          </Col>
          <Col span={6}>
            {" "}
            <Checkbox
              value={data?.isDuyetDien}
              checked={data?.isDuyetDien}
              onChange={(e) => applyChange("isDuyetDien", !e.target.value)}
            >
              Dịch vụ điện
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              value={data?.isDuyetGas}
              checked={data?.isDuyetGas}
              onChange={(e) => applyChange("isDuyetGas", !e.target.value)}
            >
              Dịch vụ GAS
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isDuyetNuoc}
              value={data?.isDuyetNuoc}
              onChange={(e) => applyChange("isDuyetNuoc", !e.target.value)}
            >
              Dịch vụ nước
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isDuyetHDThiCong}
              value={data?.isDuyetHDThiCong}
              onChange={(e) => applyChange("isDuyetHDThiCong", !e.target.value)}
            >
              Hợp đồng thi công
            </Checkbox>
          </Col>
          <Col span={6}>
            {" "}
            <Checkbox
              checked={data?.isDuyetPQL}
              value={data?.isDuyetPQL}
              onChange={(e) => applyChange("isDuyetPQL", !e.target.value)}
            >
              Phí quản lý
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isDuyetDVK}
              value={data?.isDuyetDVK}
              onChange={(e) => applyChange("isDuyetDVK", !e.target.value)}
            >
              Dịch vụ khác
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              checked={data?.isOto}
              value={data?.isOto}
              onChange={(e) => applyChange("isOto", !e.target.value)}
            >
              Hiển thị phí Ô tô
            </Checkbox>
          </Col>
          <Col span={6}>
            {" "}
            <Checkbox
              checked={data?.isDuyetXe}
              value={data?.isDuyetXe}
              onChange={(e) => applyChange("isDuyetXe", !e.target.value)}
            >
              Giữ xe
            </Checkbox>
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "500",

                paddingLeft: "5px",
                marginBottom: "15px",
              }}
            >
              Tài khoản ngân hàng
            </p>
          </Col>
          <Col span={24}>{renderTKNH()}</Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "500",

                paddingLeft: "5px",
                marginBottom: "15px",
              }}
            >
              Hotline BQL
            </p>
          </Col>
          <Col span={24}>{renderHotline()}</Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "500",

                paddingLeft: "5px",
                marginBottom: "15px",
              }}
            >
              Hình thức TT App mobile
            </p>
          </Col>
          <Col span={24}>{renderHinhTTT()}</Col>
          <Col span={24}>
            {/* <>
              <Button
                onClick={handleAddRow}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                Thêm dòng
              </Button>
              <Table
                columns={columns6}
                dataSource={dataSource}
                pagination={false}
                rowKey="key"
              />
            </> */}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Toà nhà"
      width={1080}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0"
                ? renderThongTinChung()
                : activeTab === "1"
                ? renderNangCao()
                : renderKhac()}
            </TabPane>
          );
        })}
      </Tabs>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
