import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Collapse,
  Tooltip,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { SoQuyService } from "services/SoQuyService";
import SelectCustom from "components/Forms/SelectCustom";
const { TextArea } = Input;
const { Option } = Select;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    NgayThu: dateInHoChiMinh.toISO(),
    DotThanhToan: dateInHoChiMinh.toISO(),
    ThanhToanBCK: false,
    TTMayPOS: false,
    ThanhToanVTT: false,
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiDichVu, setLoaiDichVu] = useState([]);
  const [soPhieu, setSoPhieu] = useState([]);
  const [nganHang, setNganHang] = useState([]);
  const [formData, setFormData] = useState([
    { id: 1, MaLDV: "", PhaiThu: 0, dienGiai: "" },
  ]);

  const defaultErrors = {
    // ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const filterLDV = async () => {
    let _ldv = await SoQuyService.PhieuThu.getLoaiDV({});
    setLoaiDichVu(_ldv.data);

    let _NganHang = await SoQuyService.PhieuThu.getNganHang({
      MaTN: filterCondition?.MaTN,
    });
    setNganHang(_NganHang.data);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      filterLDV();
      let _res = await SoQuyService.PhieuThu.getSoPT({
        MaTN: filterCondition?.MaTN,
      });
      setData((pr) => ({ ...pr, SoPhieu: _res?.data?.soPhieu }));
      setSoPhieu(_res.data);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      filterLDV();
      setData(data);
      let _res = await SoQuyService.PhieuThu.getSoPT({
        MaTN: filterCondition?.MaTN,
      });
      setSoPhieu(_res.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MatBang":
        const KH = JSON.parse(v.title);
        _data.KhachHang = KH.TenKH;
        _data.NguoiNop = KH.TenKH;
        _data.MaTN = filterCondition?.MaTN;
        _data.MaKH = KH?.MaKH;
        _data.MaMB = KH?.MaMB;
        _data.MaLMB = KH?.MaLMB;

        _data[p] = v;
        break;
      case "NgayThu":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "DotThanhToan":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "ThanhToanVTT":
        _data[p] = !v;
        _data.SoPhieu = soPhieu?.soPhieuTT;
        if (v === true) {
          _data.SoPhieu = soPhieu?.soPhieu;
        }
        if (v === false) {
          _data.ThanhToanBCK = false;
          _data.TTMayPOS = false;
        }
        break;
      case "ThanhToanBCK":
        _data[p] = !v;
        _data.SoPhieu = soPhieu?.soPhieuCK;
        if (v === true) {
          _data.SoPhieu = soPhieu?.soPhieu;
        }
        if (v === false) {
          _data.ThanhToanVTT = false;
          _data.TTMayPOS = false;
        }
        break;
      case "TTMayPOS":
        _data[p] = !v;
        _data.SoPhieu = soPhieu?.soPhieuCK;
        if (v === true) {
          _data.SoPhieu = soPhieu?.soPhieu;
        }
        if (v === false) {
          _data.ThanhToanVTT = false;
          _data.ThanhToanBCK = false;
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    _data.MaHinhThucThuTien = 1;
    if (_data?.ThanhToanBCK === true) {
      _data.MaHinhThucThuTien = 2;
    }
    if (_data?.TTMayPOS === true) {
      _data.MaHinhThucThuTien = 3;
    }
    if (_data?.ThanhToanVTT === true) {
      _data.MaHinhThucThuTien = 6;
    }
    const totalPhaiThu = formData.reduce((sum, item) => sum + item.PhaiThu, 0);
    _data.PhaiThu = totalPhaiThu;

    let _res = await SoQuyService.PhieuThu.add({
      ..._data,
      DichVu: formData,
    });
    if (!_data?.MaTN) {
      setData((pre) => {
        return { ...pre, MaTN: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Thông tin chung
        </p>

        <Row gutter={[20, 12]}>
          <Col span={8}>
            <InputCustom
              label="Số PT:"
              applyChange={applyChange}
              prop="SoPhieu"
              value={data?.SoPhieu}
              placeholder="Nhập số phiếu thu"
            />
          </Col>
          <Col span={16}>
            <InputCustom
              label="Người nộp:"
              applyChange={applyChange}
              prop="NguoiNop"
              value={data?.NguoiNop}
              placeholder="Nhập tên người nhập"
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Ngày thu:"
              applyChange={applyChange}
              prop={"NgayThu"}
              value={data?.NgayThu}
              placeholder={"Chọn ngày thu"}
            />
          </Col>
          <Col span={16}>
            <InputCustom
              label="Địa chỉ:"
              applyChange={applyChange}
              prop="DiaChi"
              value={data?.DiaChi}
              placeholder="Nhập địa chỉ"
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Đợt thu:"
              applyChange={applyChange}
              prop={"DotThanhToan"}
              value={data?.DotThanhToan}
              placeholder={"Chọn đợt thu"}
            />
          </Col>

          <Col span={8}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "40% ",
                }}
              >
                Mặt bằng:
              </p>
              <DebounceSelect
                open={open}
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MatBang"]}
              </p>
            )}
          </Col>
          <Col span={8}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder="Chọn khách hàng"
              disabled={true}
            />
          </Col>
          {data?.ThanhToanBCK && (
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Ngân hàng:"
                applyChange={applyChange}
                prop="MaTK"
                value={data?.MaTK}
                placeholder="Chọn ngân hàng"
                options={nganHang}
                fieldNames={{ label: "SoTaiKhoan", value: "ID" }}
              />
            </Col>
          )}
          <Col span={24}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Lý do:
            </p>
            <TextArea
              value={data?.LyDo}
              onChange={(e) => applyChange("LyDo", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Checkbox
              value={data?.ThanhToanBCK}
              onChange={(e) => applyChange("ThanhToanBCK", e.target.value)}
              checked={data?.ThanhToanBCK}
            >
              {" "}
              Thanh toán bằng chuyển khoản
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              checked={data?.TTMayPOS}
              value={data?.TTMayPOS}
              onChange={(e) => applyChange("TTMayPOS", e.target.value)}
            >
              {" "}
              Thanh toán máy POS
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              value={data?.ThanhToanVTT}
              onChange={(e) => applyChange("ThanhToanVTT", e.target.value)}
              checked={data?.ThanhToanVTT}
            >
              {" "}
              Thanh toán với tiền thừa
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  };
  const columnsDV = [
    { title: "Dịch vụ", dataIndex: "dichVu", key: "dichVu" },
    { title: "Số tiền", dataIndex: "PhaiThu", key: "PhaiThu" },
    { title: "Diễn giải", dataIndex: "Diễn giải", key: "Diễn giải" },
  ];

  const handleChange = (id, key, value) => {
    setFormData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, [key]: value } : item))
    );
  };

  const handleSetLengthDV = () => {
    setFormData((prev) => [
      ...prev,
      { id: Math.random() * 100, MaLDV: "", PhaiThu: 0, dienGiai: "" },
    ]);
  };

  const handleRemoveDV = (id) => {
    setFormData((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <Drawer
      title="Phiếu thu trắng"
      width={1020}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
      <div>
        <Row
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            backgroundColor: "#E6F1FE",
            padding: "10px 0",
          }}
        >
          {columnsDV.map((item) => (
            <Col span={6} key={item.title}>
              <p>{item.title}</p>
            </Col>
          ))}
          <Col span={4}></Col>
          <Col span={2}>
            <Tooltip title="Thêm cột">
              <Button onClick={() => handleSetLengthDV()}>
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        {formData.map((item, index) => (
          <Row
            key={item.id}
            gutter={16}
            style={{
              border: "1px solid #d9d9d9",
              borderTop: "none",
              textAlign: "center",
              margin: "0px",
            }}
          >
            <Col
              span={6}
              style={{
                padding: "8px",
                borderRight: "1px solid #d9d9d9",
              }}
            >
              <Select
                placeholder="Chọn dịch vụ"
                style={{ width: "100%" }}
                onChange={(value) => handleChange(item.id, "MaLDV", value)}
                value={item.dichVu}
                options={loaiDichVu}
                fieldNames={{ label: "Name", value: "ID" }}
              ></Select>
            </Col>
            <Col
              span={6}
              style={{
                padding: "8px",
                borderRight: "1px solid #d9d9d9",
              }}
            >
              <InputNumber
                value={item.PhaiThu}
                style={{ width: "100%" }}
                placeholder="Nhập số tiền"
                onChange={(value) => handleChange(item.id, "PhaiThu", value)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col
              span={10}
              style={{
                padding: "8px",
              }}
            >
              <Input
                value={item.dienGiai}
                placeholder="Nhập diễn giải"
                onChange={(e) =>
                  handleChange(item.id, "dienGiai", e.target.value)
                }
              />
            </Col>
            <Col
              span={2}
              style={{
                padding: "8px",
              }}
            >
              <Tooltip title="Xóa">
                <Button
                  onClick={() => handleRemoveDV(item.id)}
                  danger
                  style={{ marginLeft: "5px" }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Tooltip>
            </Col>
          </Row>
        ))}
      </div>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
