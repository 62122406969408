import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Table,
  DatePicker,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { CiTrash } from "react-icons/ci";
import Column from "antd/es/table/Column";
import { DataTable } from "primereact/datatable";
import { CongNoService } from "services/CongNoService";
import { Format_Currency } from "layouts/ultis/common";
import { HopDongService } from "services/HopDongService";
import { KhachHangService } from "services/KhachHangService";
import { ToaNhaService } from "services/ToaNhaService";
const { TextArea } = Input;
const { Option } = Select;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [chiTietThuChi, setChiTietThuChi] = useState([]);
  const [chiTietThuChiTable, setChiTietThuChiTable] = useState([]);
  const [khachHangValue, setKhachHangValue] = useState([]);
  const [khachHang, setKhachHang] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [nganHang, setNganHang] = useState([]);

  const filterDV = async (data) => {
    let _res = await CongNoService.HoaDonPhaiTra.getDV({ MaMB: data?.MaMB });
    setChiTietThuChiTable(_res?.data ?? []);
    let _resSoPhieu = await HopDongService.ChoThue.getSoPhieu({
      MaTN: filterCondition?.MaTN,
      Type: "PC",
    });
    let _resNH = await ToaNhaService.DuAn.getTKNHByTN({
      MaTN: filterCondition.MaTN,
    });
    let _resKH = await KhachHangService.KhachHang.get({
      InputSearch: "",
      CheckMail: false,
      MaTN: filterCondition?.MaTN,
    });
    setNganHang(_resNH?.data ?? []);
    setKhachHang(_resKH?.data ?? []);
    let _data = _.cloneDeep(data);
    _data.MaKH = data?.MaKH;
    const InfoUser = _resKH?.data.find((item) => item?.MaKH === data?.MaKH);
    _data.CMND = InfoUser?.CMND;
    _data.NgayCap = InfoUser?.NgayCap;
    _data.NoiCap = InfoUser?.NoiCap;
    _data.DienThoaiKH = InfoUser?.DienThoaiKH;
    _data.NgaySinh = InfoUser?.NgaySinh;
    _data.EmailKH = InfoUser?.EmailKH;
    _data.DCTT = InfoUser?.DCTT;
    _data.DiaChi = InfoUser?.DCLL;
    _data.NguoiNhan = InfoUser?.TenKH;
    setSelectedValue(InfoUser?.TenKH);

    setData((pr) => ({
      ...pr,
      ...data,
      ..._data,
      MaHopDong: _res?.data[0]?.LinkID,
      SoTien: _res?.data.reduce((sum, item) => sum + item.SoTien, 0),
      MaTN: filterCondition.MaTN,
      SoPhieu: _resSoPhieu?.data,
    }));
  };

  console.log(data);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      filterDV(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setLoading(false);
    setKhachHangValue([]);
    setSelectedValue(null);
    setErrors(defaultErrors);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhachHang":
        _data.MaKH = v;
        const InfoUser = khachHang.find((item) => item?.MaKH === v);
        _data.CMND = InfoUser?.CMND;
        _data.NgayCap = InfoUser?.NgayCap;
        _data.NoiCap = InfoUser?.NoiCap;
        _data.DienThoaiKH = InfoUser?.DienThoaiKH;
        _data.NgaySinh = InfoUser?.NgaySinh;
        _data.EmailKH = InfoUser?.EmailKH;
        _data.DCTT = InfoUser?.DCTT;
        _data.DiaChi = InfoUser?.DCLL;
        _data.NguoiNhan = InfoUser?.TenKH;

        _data[p] = v;
        break;
      case "MaHinhThucTT":
        if (v === 2) {
          let _resSoPhieu = await HopDongService.ChoThue.getSoPhieu({
            MaTN: filterCondition?.MaTN,
            Type: "PCCK",
          });
          _data.SoPhieu = _resSoPhieu?.data;
        } else {
          let _resSoPhieu = await HopDongService.ChoThue.getSoPhieu({
            MaTN: filterCondition?.MaTN,
            Type: "PC",
          });
          _data.SoPhieu = _resSoPhieu?.data;
        }

        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
    validate(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    if (_data?.MaHinhThucTT !== 2) {
      _data.MaTK = null;
    }
    const payload = {
      ..._data,

      DichVu: chiTietThuChiTable,
    };
    let _res = await CongNoService.HoaDonPhaiTra.addPhieuChi(payload);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tài sản thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {};
  const handleTabChange = (key) => {
    console.log("Tab active:", key);
  };
  const renderChiTietThuChi = () => {
    const addChiTietThuChi = () => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      _chiTietThuChi.push({
        ID: null,
        SanPham: "",
        SoPhieu: "",
        SoTienChi: "",
        LoaiChi: "",
        NgayChi: "",
        DienGiai: "",
        GhiChu: "",
        PTGiamTinhLai: "",
        SoTienChiGiamLai: "",
        SoGiaoDich: "",
        SoDatMua: "",
        SoHDMB: "",
        SoHDVay: "",
        NguoiNop: "",
      });
      setChiTietThuChi(_chiTietThuChi);
    };

    const onChangeChiTietThuChi = (field, value, index) => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      let item = _chiTietThuChi.slice(index, index + 1);
      if (item?.length > 0) {
        _chiTietThuChi.splice(index, 1, {
          ...item[0],
          [field]: value,
        });
      }
      setChiTietThuChi(_chiTietThuChi);
    };

    const deleteChiTietThuChi = (item, index) => {
      let _chiTietThuChi = _.cloneDeep(chiTietThuChi);
      _chiTietThuChi.splice(index, 1);
      setChiTietThuChi(_chiTietThuChi);
      // if (item.ID) {
      //   ToaNhaService.DuAn.deleteChiTietThuChi({ ID: item.ID });
      // }
    };

    return (
      <>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={chiTietThuChi}
          tableStyle={{ minWidth: "150rem" }}
        >
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Sản phẩm</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.SanPham}
                onChange={(value) =>
                  onChangeChiTietThuChi("SanPham", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số phiếu</p>}
            body={(item, row) => (
              <Input
                value={item.SoPhieu}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoPhieu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Số tiền chi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                value={item.SoTienChi}
                placeholder=""
                onChange={(value) =>
                  onChangeChiTietThuChi("SoTienChi", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Loại chi</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.LoaiChi}
                onChange={(value) =>
                  onChangeChiTietThuChi("LoaiChi", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Ngày chi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <DatePicker
                value={item?.NgayChi ? dayjs(item?.NgayChi) : null}
                onChange={(date) =>
                  onChangeChiTietThuChi(
                    "NgayChi",
                    date ? date.format("YYYY-MM-DD") : null,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Diễn giải</p>}
            body={(item, row) => (
              <Input
                value={item.DienGiai}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "DienGiai",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />

          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Ghi chú</p>}
            body={(item, row) => (
              <Input
                value={item.GhiChu}
                onChange={(e) =>
                  onChangeChiTietThuChi("GhiChu", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>PT giảm tính lãi</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.PTGiamTinhLai}
                onChange={(value) =>
                  onChangeChiTietThuChi("PTGiamTinhLai", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            header={<p style={{ fontSize: "13px" }}>Số tiền chi giảm lãi</p>}
            style={{ width: "100px", border: "1px solid #d9d9d9" }}
            body={(item, row) => (
              <InputNumber
                value={item.SoTienChiGiamLai}
                placeholder=""
                onChange={(value) =>
                  onChangeChiTietThuChi("SoTienChiGiamLai", value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số giao dịch</p>}
            body={(item, row) => (
              <Select
                style={{ width: "150px" }}
                value={item.SoGiaoDich}
                onChange={(value) =>
                  onChangeChiTietThuChi("SoGiaoDich", value, row.rowIndex)
                }
                options={[]}
                fieldNames={{ label: "SanPham", value: "ID" }}
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số đặt mua</p>}
            body={(item, row) => (
              <Input
                value={item.SoDatMua}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "SoDatMua",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số HĐMB</p>}
            body={(item, row) => (
              <Input
                value={item.SoHDMB}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoHDMB", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Số HĐ vay</p>}
            body={(item, row) => (
              <Input
                value={item.SoHDVay}
                onChange={(e) =>
                  onChangeChiTietThuChi("SoHDVay", e.target.value, row.rowIndex)
                }
              />
            )}
          />
          <Column
            style={{ border: "1px solid #d9d9d9" }}
            header={<p style={{ fontSize: "13px" }}>Người nộp</p>}
            body={(item, row) => (
              <Input
                value={item.NguoiNop}
                onChange={(e) =>
                  onChangeChiTietThuChi(
                    "NguoiNop",
                    e.target.value,
                    row.rowIndex
                  )
                }
              />
            )}
          />
          <Column
            fixed="right"
            header={
              <Button
                loading={loading}
                onClick={addChiTietThuChi}
                type="primary"
                ghost
                style={{ marginBottom: 10 }}
              >
                Thêm
              </Button>
            }
            body={(item, row) => (
              <Button
                style={{ marginRight: "50px" }}
                onClick={() => deleteChiTietThuChi(item, row.rowIndex)}
                danger
                ghost
                icon={<CiTrash />}
              />
            )}
            style={{
              textAlign: "right",
              width: "120px",
              border: "1px solid #d9d9d9",
            }}
          />
        </DataTable>
      </>
    );
  };
  const columns = [
    {
      title: "Tên loại dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Số tiền",
      dataIndex: "SoTien",
      render: (item) => (item ? Format_Currency(item) : null),
    },
  ];
  const handleSearch = (value) => {
    if (!value) {
    } else {
      const filteredOptions = khachHang.filter(
        (option) =>
          option.TenKH?.toLowerCase().includes(value.toLowerCase()) ||
          option?.CMND?.includes(value) ||
          option?.DienThoaiKH?.includes(value)
      );
      setKhachHangValue(filteredOptions);
    }
  };
  const handleSelectChange = (value) => {
    setSelectedValue(value);
    applyChange("KhachHang", value);
  };
  return (
    <Drawer
      title="Thêm mới phiếu chi"
      width={1300}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            disabled={chiTietThuChiTable?.length <= 0}
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Row style={{ paddingTop: "10px" }} gutter={[20, 12]}>
        <Col span={6}>
          {" "}
          <InputCustom
            widthLabel={"45%"}
            disabled={true}
            label="Số phiếu chi:"
            applyChange={applyChange}
            prop="SoPhieu"
            value={data?.SoPhieu}
          />
        </Col>
        <Col span={6}>
          <Flex align="center">
            <lable
              htmlFor={"TimKiemKhachHang"}
              style={{
                fontWeight: "600",
                width: "50% ",
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "6px",
                marginBottom: "-4px",
              }}
            >
              Khách hàng:
            </lable>
            <Select
              id={"TimKiemKhachHang"}
              showSearch
              placeholder="Tìm kiếm khách hàng"
              onSearch={handleSearch}
              onChange={handleSelectChange}
              value={selectedValue}
              style={{ width: "70%" }}
              allowClear
              filterOption={false}
            >
              {khachHangValue.map((option) => (
                <Option key={option.MaKH} value={option.MaKH}>
                  {option.TenKH} - {option.CMND}
                </Option>
              ))}
            </Select>
          </Flex>
          {errors && (
            <p style={{ color: "red", textAlign: "center" }}>
              {errors && errors["KhachHang"]}
            </p>
          )}
        </Col>{" "}
        <Col span={6}>
          {" "}
          <InputCustom
            widthLabel="45%"
            label="Người nhận:"
            applyChange={applyChange}
            prop="NguoiNhan"
            value={data?.NguoiNhan}
          />
        </Col>
        {/* <Col span={8}></Col> */}
        <Col span={6}>
          {" "}
          <DatePickerCustom
            label="Ngày chi:"
            applyChange={applyChange}
            prop={"NgayChi"}
            value={data?.NgayChi}
          />
        </Col>
        <Col span={6}>
          {" "}
          <InputNumberCustom
            label="Tổng chi:"
            applyChange={applyChange}
            prop={"SoTien"}
            value={data?.SoTien}
            placeholder={"0"}
          />
        </Col>
        <Col span={6}>
          {" "}
          <InputCustom
            label="Di động:"
            applyChange={applyChange}
            prop="DienThoaiKH"
            value={data?.DienThoaiKH}
          />
        </Col>
        {/* <Col span={8}>
          {" "}
          <SelectCustom
            label="Tài khoản có:"
            applyChange={applyChange}
            prop="TaiKhoanCo"
            value={data?.TaiKhoanCo}
            placeholder="Chọn tài khoản có"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col> */}
        <Col span={6}>
          {" "}
          <InputCustom
            label="Số CCCD:"
            applyChange={applyChange}
            prop="CMND"
            value={data?.CMND}
          />
        </Col>
        <Col span={6}>
          {" "}
          <DatePickerCustom
            label="Ngày cấp:"
            applyChange={applyChange}
            prop={"NgayCap"}
            value={data?.NgayCap}
          />
        </Col>
        {/* <Col span={8}>
          {" "}
          <SelectCustom
            label="Tài khoản nợ:"
            applyChange={applyChange}
            prop="TaiKhoanNo"
            value={data?.TaiKhoanNo}
            placeholder="Chọn tài khoản nợ"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col> */}
        <Col span={6}>
          {" "}
          <InputCustom
            label="Nơi cấp:"
            applyChange={applyChange}
            prop="NoiCap"
            value={data?.NoiCap}
          />
        </Col>
        <Col span={6}>
          {" "}
          <InputCustom
            label="Email:"
            applyChange={applyChange}
            prop="EmailKH"
            value={data?.EmailKH}
          />
        </Col>
        <Col span={12}>
          {" "}
          <InputCustom
            label="Địa chỉ:"
            applyChange={applyChange}
            prop="DiaChi"
            value={data?.DiaChi}
          />
        </Col>
        <Col span={24}>
          <p style={{ fontWeight: "600", width: "13%" }}>Ghi chú:</p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
        {/* <Col span={24}>
          {" "}
          <InputCustom
            label="Kèm theo:"
            applyChange={applyChange}
            prop="KemTheo"
            value={data?.KemTheo}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Dự án:"
            applyChange={applyChange}
            prop="DuAn"
            value={data?.DuAn}
            placeholder="Chọn dự án"
            options={[]}
            fieldNames={{ label: "TenLX", value: "MaLX" }}
          />
        </Col> */}
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Hình thức TT:"
            applyChange={applyChange}
            prop="MaHinhThucTT"
            value={data?.MaHinhThucTT}
            placeholder="Chọn hình thức thanh toán"
            options={[
              { TenHTTT: "Tiền mặt", key: 1 },
              { TenHTTT: "Chuyển khoản ngân hàng", key: 2 },
              { TenHTTT: "Bù trừ PQL", key: 8 },
              { TenHTTT: "Bù trừ tiền cọc", key: 10 },
              { TenHTTT: "Thanh toán máy POS", key: 3 },
            ]}
            fieldNames={{ label: "TenHTTT", value: "key" }}
          />
        </Col>
        {data?.MaHinhThucTT === 2 && (
          <Col span={8}>
            {" "}
            <SelectCustom
              label="Tài khoản:"
              applyChange={applyChange}
              prop="MaTK"
              value={data?.MaTK}
              placeholder="Chọn tài khoản"
              options={nganHang}
              fieldNames={{ label: "SoTaiKhoan", value: "ID" }}
            />
          </Col>
        )}
      </Row>
      {/* <Tabs defaultActiveKey="1" onChange={handleTabChange} type="line">
        <TabPane tab="Chi tiết thu chi" key="1">
          {renderChiTietThuChi()}
        </TabPane>
      </Tabs> */}
      <div>
        <p style={{ padding: "20px 0", fontWeight: "500" }}>Chi tiết thu chi</p>
        <Table dataSource={chiTietThuChiTable} columns={columns} />
      </div>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
