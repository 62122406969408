import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Table,
  Pagination,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { CuDanService } from "services/CuDanService";
import { YeuCauService } from "services/YeuCauService";
import { SaveOutlined } from "@ant-design/icons";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputCustom from "components/Forms/InputCustom";
import { Editor } from "primereact/editor";
import { CongNoService } from "services/CongNoService";
import { HeThongService } from "services/HeThongService";
import SelectCustom from "components/Forms/SelectCustom";

const { TextArea } = Input;
const FormDetailMail = (props, ref) => {
  const { onAfterSubmit, options, setIsModalVisible } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [taiKhoanMail, setTaiKhoanMail] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [mauMail, setMauMail] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [text, setText] = useState(null);
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      console.log(data);
      setData(data);
      setDataTable(data?.MatBang);
      setOpen(true);
      let _res = await HeThongService.CauHinh.taiKhoanMail.get();
      if (_res?.data) setTaiKhoanMail(_res.data);

      let _resMail = await HeThongService.CauHinh.mauMail.get();
      setMauMail(_resMail.data ?? []);
    },
  }));
  console.log(text);

  const onClose = (value) => {
    setOpen(false);
    setIsModalVisible(value === true ? false : true);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaMB":
        _data[p] = v;
        break;
      case "TenKH":
        _data[p] = v;
        break;
      case "MaSoMB":
        _data[p] = v;
        break;
      case "MauMail":
        console.log(v);
        setText(mauMail?.find((item) => item?.TempID === v)?.Contents);
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors)?.filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    _data.NoiDung = text;
    _data.MatBang = _data?.MatBang?.filter((item) => item?.Email);
    setLoading(true);
    let _res = await CongNoService.TongHop.thaoTacThongBao(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("warning", "topRight", `Đang xử lý thông báo!`);
      onClose(true);
      setData({});
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const columns = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
  ];

  return (
    <Drawer
      title="Thông báo giửi mail"
      width={1520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Select
            allowClear
            placeholder="Chọn mẫu mail"
            style={{
              width: 140,
              border: "1px solid #d9d9d9",
              borderBottom: "2px solid #d9d9d",
              borderRadius: "5px",
              padding: "0 2px 0 10px",
            }}
            onChange={(value) => applyChange("MauMail", value)}
            value={data?.MauMail}
            options={mauMail}
            fieldNames={{ label: "TempName", value: "TempID" }}
          />
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 8]} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Table
                // style={{ maxHeight: "800px", overflow: "scroll" }}
                columns={columns}
                // dataSource={
                //   Array.isArray(data?.MatBang)
                //     ? data?.MatBang.filter((item) => item?.Email)
                //     : []
                // }
                dataSource={data?.MatBang?.filter((item) => item?.Email)}
                bordered
                pagination={false}
                scroll={{
                  y: window.innerHeight - 250,
                  // x: 1000,
                }}
                summary={(pageData) => {
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={3}>
                          <p style={{ fontWeight: "bold" }}>
                            Tổng{" "}
                            {data?.MatBang
                              ? data?.MatBang?.filter((item) => item?.Email)
                                  ?.length
                              : null}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
              <div style={{ textAlign: "right", paddingTop: "15px" }}>
                {data?.length > 0 && (
                  <Pagination
                    // onChange={onChangePage}
                    total={
                      data?.MatBang
                        ? data?.MatBang?.filter((item) => item?.Email)?.length
                        : null
                    }
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    defaultPageSize={"100"}
                  />
                )}
              </div>
            </Col>{" "}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <DatePickerCustom
                label="Thời gian:"
                applyChange={applyChange}
                prop={"ThoiGianGui"}
                value={data?.ThoiGianGui}
                placeholder={""}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="Tiêu đề:"
                applyChange={applyChange}
                prop="TieuDe"
                value={data?.TieuDe}
              />
            </Col>{" "}
            <Col span={24}>
              <SelectCustom
                label="Tài khoản mail:"
                applyChange={applyChange}
                prop="MaTK"
                value={data?.MaTK}
                placeholder="Chọn tài khoản mail"
                options={taiKhoanMail}
                fieldNames={{ label: "DiaChi", value: "ID" }}
              />
            </Col>{" "}
            {/* <Col span={24}>
              <SelectCustom
                label="Mẫu mail:"
                applyChange={applyChange}
                prop="MauMail"
                value={data?.MauMail}
                placeholder="Chọn tài khoản mail"
                options={mauMail}
                fieldNames={{ label: "TempName", value: "TempID" }}
              />
            </Col>{" "} */}
            <Col span={24}>
              <p style={{ fontWeight: "600", paddingBottom: "5px" }}>
                Nội dung
              </p>
              <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{ minHeight: "420px" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormDetailMail);
