import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, ToaNha } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HaoHut":
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    setData(_data);
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} tài sản thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Logo = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };

  const handleTabChange = (key) => {
    console.log("Tab active:", key);
  };

  const renderThongTinChung = () => {
    return (
      <Row gutter={[20, 12]}>
        <Col span={24}>
          <p
            style={{
              fontWeight: "500",
              backgroundColor: "#d9d9d9",
              paddingLeft: "5px",
            }}
          >
            Tài sản
          </p>
        </Col>

        <Col span={8}>
          {" "}
          <SelectCustom
            label="Tòa nhà:"
            applyChange={applyChange}
            prop="MaTN"
            value={data?.MaTN}
            placeholder="Chọn tòa nhà"
            options={ToaNha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Tên tài sản:"
            applyChange={applyChange}
            prop="TenTS"
            value={data?.TenTS}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Mã vạch:"
            applyChange={applyChange}
            prop="MaVach"
            value={data?.MaVach}
            required={true}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Ký hiệu:"
            applyChange={applyChange}
            prop="KyHieu"
            value={data?.KyHieu}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Tên nhóm:"
            applyChange={applyChange}
            prop="TenNhom"
            value={data?.TenNhom}
            placeholder="Chọn tên nhóm"
            options={[]}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
            required={true}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Vị trí:"
            applyChange={applyChange}
            prop="ViTri"
            value={data?.ViTri}
            placeholder="Chọn vị trí"
            options={[]}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="LTS cấp trên:"
            applyChange={applyChange}
            prop="LTSCapTren"
            value={data?.LTSCapTren}
            placeholder="Chọn loại tài sản"
            options={[]}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
        </Col>

        <Col span={24}>
          <p
            style={{
              fontWeight: "500",
              backgroundColor: "#d9d9d9",
              paddingLeft: "5px",
            }}
          >
            Thông tin chi tiết
          </p>
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Loại:"
            applyChange={applyChange}
            prop="Loai"
            value={data?.Loai}
            placeholder="Chọn loại"
            options={[]}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Đơn vị tính:"
            applyChange={applyChange}
            prop="DonViTinh"
            value={data?.DonViTinh}
            placeholder="Chọn đơn vị tính"
            options={[]}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Quốc gia sx:"
            applyChange={applyChange}
            prop="QuocGiaSX"
            value={data?.QuocGiaSX}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Đơn giá:"
            applyChange={applyChange}
            prop={"DonGia"}
            value={data?.DonGia}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="TG bảo hành:"
            applyChange={applyChange}
            prop={"TGBaoHanh"}
            value={data?.TGBaoHanh}
          />
        </Col>
        <Col span={8}>
          {" "}
          <InputCustom
            label="Đặc tính:"
            applyChange={applyChange}
            prop="DacTinh"
            value={data?.DacTinh}
          />
        </Col>
        <Col span={8}>
          <UploadComponent
            files={data?.Logo ? [data?.Logo] : []}
            multiple={false}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </Col>
        <Col span={8}>
          <Checkbox
            checked={data?.IsBaoTri}
            onChange={(e) => applyChange("IsBaoTri", !e.target.value)}
          >
            Tài sản bảo trì
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
            checked={data?.IsCheckList}
            onChange={(e) => applyChange("IsCheckList", !e.target.value)}
          >
            Tài sản check list
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderCongViecBaoTri = () => {
    return (
      <>
        <h1>Chức năng đang được cập nhật</h1>
      </>
    );
  };
  const renderCongViecLienQuan = () => {
    return (
      <>
        <h1>Chức năng đang được cập nhật</h1>
      </>
    );
  };

  return (
    <Drawer
      title="Thêm: chi tiết tài sản"
      width={1000}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Tabs defaultActiveKey="1" onChange={handleTabChange} type="line">
        <TabPane tab="Thông tin chung" key="1">
          {renderThongTinChung()}
        </TabPane>
        <TabPane tab="Công việc bảo trì" key="2">
          {renderCongViecBaoTri()}
        </TabPane>
        <TabPane tab="Công việc liên quan" key="3">
          {renderCongViecLienQuan()}
        </TabPane>
      </Tabs>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
