import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import FormQLHistory from "./FormQLHistory";
export default function NhatKyRuiRo() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const currentDate = DateTime.now();
  const defaultValue = {
    Month: currentDate.month,
    Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
    PageIndex: 1,
    PageSize: 20,
    InputSearch: "",
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await DichVuService.Dien.get(_filter);
    if (_res.data) {
      setData(_res.data);

      setLoading(false);
    }
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const dataSource = [
    {
      key: 1,
      MaSo: "MS001",
      HoTen: "Nguyễn Văn A",
      CaLamViec: "Sáng",
      TongPhaiKiemTra: 100,
      TongDaKiemTra: 95,
      TyLe: "95%",
    },
    {
      key: 2,
      MaSo: "MS002",
      HoTen: "Trần Thị B",
      CaLamViec: "Chiều",
      TongPhaiKiemTra: 80,
      TongDaKiemTra: 60,
      TyLe: "75%",
    },
    {
      key: 3,
      MaSo: "MS003",
      HoTen: "Lê Văn C",
      CaLamViec: "Tối",
      TongPhaiKiemTra: 120,
      TongDaKiemTra: 100,
      TyLe: "83.33%",
    },
  ];
  
  const columns = [
    {
      title: "Mã số",
      // dataIndex: "MaSo",
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            render: (text) => <EllipsisWithTooltip text={text} />,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.MaSo}
        </p>
      ),
    },
    {
      title: "Họ tên",
      dataIndex: "HoTen",
    },
    {
      title: "Ca làm việc",
      dataIndex: "CaLamViec",
    },
    {
      title: "Tổng phải kiểm tra",
      dataIndex: "TongPhaiKiemTra",
    },
    {
      title: "Tổng đã kiểm tra",
      dataIndex: "TongDaKiemTra",
    },
    {
      title: "Tỷ lệ",
      dataIndex: "TyLe",
    },

    // {
    //   title: "Ngày nhập",
    //   dataIndex: "NgayTao",
    //   render: (item) => (item ? Format_Date(item) : null),
    // },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "150px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Tooltip placement="topRight" title="Nhắn tin">
    //           <Button
    //             style={{ marginLeft: 10 }}
    //             type="primary"
    //             ghost
    //             icon={<CiChat2 />}
    //             onClick={() => openChats(item.MaTN)}
    //           />
    //         </Tooltip>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             style={{ marginLeft: 10 }}
    //             type="primary"
    //             ghost
    //             icon={<MdEdit />}
    //             onClick={() => refDetail.current.update(item)}
    //           />
    //         </Tooltip>

    //         <Popconfirm
    //           title="Xoá toà nhà"
    //           description="Bạn có chắc chắn muốn xoá toà nhà?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.MaTN)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               style={{ marginLeft: 10 }}
    //               danger
    //               ghost
    //               icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Dien.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />{" "}
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          {data.length > 0 && (
            <Pagination
              onChange={onChangePage}
              showSizeChanger
              total={data[0]?.TotalRows}
              defaultPageSize={20}
            />
          )}
        </div>
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
    </Row>
  );
}
