import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import { NhanVienService } from "services/NhanVienService";
import { DashboardService } from "services/dashboardService";
import { SaveOutlined } from "@ant-design/icons";
import _, { cloneDeep } from "lodash";
const FormAddDashboard = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([]);
  const [dataPQ, setDataPQ] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [maDashboard, setMaDashboard] = useState(0);
  const columns = [
    {
      title: "Tên dashboard",
      dataIndex: "Name",
      key: "Name",
    },
  ];

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadData = async (id) => {
    let _resDB = await NhanVienService.getDashboard();

    let _res = await DashboardService.getPhanQuyen({
      PerID: id,
    });
    let selects = [];
    _res?.data?.map((item) => {
      selects.push(item.MaDashboard);
    });
    setSelectedRowKeys(selects);
    setData(_resDB.data);
    setMaDashboard(id);
  };
  useImperativeHandle(ref, () => ({
    show: async (_perid) => {
      setOpen(true);
      loadData(_perid);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setLoading(false);
    setData([]);
    onAfterSubmit();
  };

  const onSelectChange = async (newSelectedRowKeys) => {
    // setLoading(true);
    if (selectedRowKeys.length > newSelectedRowKeys.length) {
      let id = _.difference(selectedRowKeys, newSelectedRowKeys);
      if (id?.length > 0) {
        await DashboardService.deletePhanQuyen({
          Dashboard: id,
          PerID: maDashboard,
        });
      }
    } else {
      let id = _.difference(newSelectedRowKeys, selectedRowKeys);
      if (id?.length > 0) {
        await DashboardService.addPhanQuyen({
          Dashboard: id,
          PerID: maDashboard,
        });
      }
    }
    setSelectedRowKeys(newSelectedRowKeys);
    setLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Drawer
      title="PHÂN QUYỀN DASHBOARD"
      width={720}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Row style={{ paddingTop: "15px" }} gutter={[20, 20]}>
        {/* {data?.map((item) => {
          return (
            <>
              <Col span={8}>
                <Checkbox>{item?.Name}</Checkbox>
              </Col>
            </>
          );
        })} */}
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Col>
      </Row>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAddDashboard);
