import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { CongNoService } from "services/CongNoService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IoIosDoneAll } from "react-icons/io";
import { ExportOutlined } from "@ant-design/icons";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const defaultValue = {
    MaTN: null,
    // State: "ALL",
    InputSearch: "",
    TuNgay: new Date(),
    DenNgay: new Date(),
    PageIndex: 1,
    PageSize: 10,
  };
  const [filterCondition, setFilterCondition] = useState(defaultValue);
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await CongNoService.GetThanhToanApp(_filter);
    if (_res.data) {
      let totalTongTien = 0;

      _res.data.forEach((item) => {
        totalTongTien += item.TongTien;
      });
      setData(_res.data);
      setTotalPrice(totalTongTien);
    }
    setLoading(false);
  };
  const onChangePage = (page, size) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const GetColor ={
    NEW : "blue",
    PENDING:"orange",
    CANCEL:"magenta",
    ERROR:"red",
    DONE:"green"
  }

  const columns = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Số tiền",
      dataIndex: "TongTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã chuyển khoản",
      dataIndex: "DaChuyenKhoan",

      render: (item) => Format_Currency(item),
    },
    {
      title: "Trạng thái",
      // dataIndex: "TenTT",
      render: (item) => <Tag color={GetColor[item.State]}>{item.TenTT}</Tag>,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",

      width: 150,
    },
    {
      title: "TransactionID",
      dataIndex: "TransactionID",
    },
    {
      title: "ngày tạo",
      dataIndex: "NgayTao",

      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Transaction date",
      dataIndex: "TransactionDate",
    },
    {
      title: "",
      fixed: "right",
      width: "60px",
      render: (item) => {
        return (
          <Flex gap={5} justify="center">
            {item?.State !== "DONE" && (
              <Popconfirm
                title="Duyệt thanh toán"
                description="Bạn có chắc chắn muốn duyệt hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(item)}
              >
                <Button type="primary" icon={<IoIosDoneAll />} />
              </Popconfirm>
            )}
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await CongNoService.DuyetThanhToan({
      masterAccountNumber: value.TK,
      amount: value.TongTien,
      transactionDate: new Date(),
      transactionId: "Duyet-Thu-Cong",
      remark: value.DienGiai,
    });
    if (_res.status === 200) {
      filter(filterCondition);
      openNotification("success", "topRight", "Thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách thanh toán app" + fileExtension);
  };
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          defaultValue={defaultValue}
          setFilterCondition={setFilterCondition}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              {selectedRowKeys?.length > 0 && (
                <Button
                  loading={loading}
                  type="primary"
                  ghost
                  onClick={() => createAccount()}
                >
                  Tạo tài khoản MSB ({selectedRowKeys?.length})
                </Button>
              )}

              {/* <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                Thêm
              </Button> */}
              <Button onClick={() => exportToCSV()}>
                {" "}
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />{" "}
                Export
              </Button>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2600,
          }}
          bordered
          // rowSelection={rowSelection}
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={2}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(totalPrice)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "10px" }}>
          <Pagination onChange={onChangePage} total={data[0]?.TotalRows} />
        </div>
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
