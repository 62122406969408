import {
  Button,
  Drawer,
  Flex,
  Input,
  notification,
  Select,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import Column from "antd/es/table/Column";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { Format_Datetime2 } from "layouts/ultis/common";
import { DataTable } from "primereact/datatable";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CiSaveDown1, CiTrash } from "react-icons/ci";
import { DichVuService } from "services/DichVuService";
import { HopDongService } from "services/HopDongService";
import _ from "lodash";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import FormDetail from "views/admin/SoQuy/PhieuChi/FormDetail";
import FormDetailPT from "views/admin/SoQuy/PhieuThu/FormDetail";
import { FaCheckCircle, FaRegSave } from "react-icons/fa";
import { UploadService } from "services/UploadService";
import { MdOutlinePreview } from "react-icons/md";
const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");

  const [payLoad, setPayload] = useState();
  const [taiLieu, setTaiLieu] = useState([]);
  const [visible, setVisible] = useState(false);

  const refPC = useRef(null);
  const refPT = useRef(null);
  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item);
      fetchDataForTab("1", item);
      setActiveKey("1");
    },
  }));

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns1 = [
    {
      title: "Tên loại DV",
      dataIndex: "TenLDV",
    },
    {
      title: "Số tiền",
      dataIndex: "SoTien",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Từ ngày",
      dataIndex: "TuNgay",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayTao",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Người nhập",
      dataIndex: "NhanVien",
    },
    {
      title: "Nội dung",
      dataIndex: "DienGiai",
    },
  ];
  const columns2 = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
    },
    {
      title: "Dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "PT VAT",
      dataIndex: "TyLeVAT",
    },
    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Tổng tiền",
      dataIndex: "TongTien",
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Kỳ TT",
      dataIndex: "KyThanhToan",
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "NgayKT",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      width: 200,
    },
  ];

  const fetchDataForTab = async (key, item) => {
    setActiveKey(key);
    let _res;
    switch (key) {
      case "1":
        _res = await HopDongService.DanhSachDichVu.getLSNgayTT({
          ID: item?.ID,
          MaLDV: item?.MaDV,
        });
        if (_res.data) setData(_res.data);

        break;
      case "2":
        _res = await HopDongService.DanhSachDichVu.getLSPhuLuc({
          ID: item?.ID,
        });
        if (_res.data) setData(_res.data);
        break;

      default:
        setData([]);
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Thông tin chi tiết dịch vụ"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs activeKey={activeKey} onChange={onTabChange}>
          <TabPane tab="1. Lịch sử ngày TT" key="1">
            <Table
              pagination={false}
              columns={columns1}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
          <TabPane tab="2. Lịch sử phụ lục" key="2">
            <Table
              pagination={false}
              columns={columns2}
              dataSource={data}
              scroll={{
                y: window.innerHeight - 250,
                x: 1600,
              }}
            />
          </TabPane>
        </Tabs>
        <FormDetail ref={refPC} onAfterSubmit={onAfterSubmit} />
        <FormDetailPT ref={refPT} onAfterSubmit={onAfterSubmit} />
      </Drawer>
      {contextHolder}
    </>
  );
};

export default forwardRef(FormQLHistory);
