import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { notification, Modal, Col, Row, Flex, Input } from "antd";
import _, { cloneDeep } from "lodash";
import "dayjs/locale/vi";
import { KhachHangService } from "services/KhachHangService";
import DebounceSelect from "components/DebounceSelect";
import { DatabaseCoreService } from "services/DatabaseCoreService";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import TextArea from "antd/es/input/TextArea";
import { HopDongService } from "services/HopDongService";
import dayjs from "dayjs";

const FormModal = (props, ref) => {
  const {
    filterCondition,
    handleCancel,
    isModalOpen,
    setIsModalOpen,
    onAfterSubmit,
    valueModal,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhachHang":
        const KH = JSON.parse(v?.title);
        _data.DiDongMoi = KH?.DienThoaiKH;
        _data.MaKH = KH?.MaKH;
        _data.TenKH = v?.label;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  useEffect(() => {
    const fetchSoPhieu = async () => {
      if (isModalOpen) {
        try {
          const _resSoPhieu = await HopDongService.ChoThue.getSoPhieu({
            MaTN: filterCondition?.MaTN,
            Type: "TL",
          });
          setData((prev) => ({
            ...prev,
            SoTL: _resSoPhieu?.data,
            NgayTL: prev?.NgayTL || dayjs().format("YYYY-MM-DD"),
            NgayKyVBKK: prev?.NgayKyVBKK || dayjs().format("YYYY-MM-DD"),
            NgayKyVBHT: prev?.NgayKyVBHT || dayjs().format("YYYY-MM-DD"),
          }));
        } catch (error) {
          console.error("Failed to fetch SoPhieu:", error);
        }
      }
    };

    fetchSoPhieu();
  }, [isModalOpen]);

  const handleSubmit = async () => {
    const _data = _.cloneDeep(data);
    let _res = await HopDongService.ChoThue.thanhLy({
      ..._data,
      MaTN: filterCondition.MaTN,
      MaKH: valueModal?.MaKH,
      MaHDT: valueModal?.ID,
    });

    if (_res?.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      setIsModalOpen(false);
      setData([]);
      onAfterSubmit();
    } else {
      openNotification(
        "error",
        "topRight",
        _res?.message || "Cập nhật thất bại."
      );
    }
  };

  return (
    <Modal
      width="800px"
      title="Thanh lý"
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Row gutter={[20, 12]} style={{ margin: "30px 0" }}>
        <Col span={12}>
          <InputCustom
            disabled={true}
            label="Số TL:"
            applyChange={applyChange}
            prop="SoTL"
            value={data?.SoTL}
            placeholder="Số hợp thanh lý"
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            open2={true}
            label="Ngày TL:"
            applyChange={applyChange}
            prop={"NgayTL"}
            value={data?.NgayTL}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            open2={true}
            label="Ngày ký VB kiểm kê:"
            applyChange={applyChange}
            prop={"NgayKyVBKK"}
            value={data?.NgayKyVBKK}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            open2={true}
            label="Ngày ký VB hoàn trả:"
            applyChange={applyChange}
            prop={"NgayKyVBHT"}
            value={data?.NgayKyVBHT}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            label="Tiền vi phạm HĐ:"
            applyChange={applyChange}
            prop={"TienViPhamHD"}
            value={data?.TienViPhamHD}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            widthLabel={"55%"}
            label="Tiền bồi thường thiệt hại:"
            applyChange={applyChange}
            prop={"TienBoiThuongThietHai"}
            value={data?.TienBoiThuongThietHai}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            widthLabel={"55%"}
            label="Phí dịch vụ và phí khác:"
            applyChange={applyChange}
            prop={"PhiDichVu"}
            value={data?.PhiDichVu}
            placeholder={"0"}
          />
        </Col>
        <Col span={12}>
          <InputNumberCustom
            widthLabel={"55%"}
            label="Tiền thuê phát sinh thêm:"
            applyChange={applyChange}
            prop={"TienThuePhatSinh"}
            value={data?.TienThuePhatSinh}
            placeholder={"0"}
          />
        </Col>
        <Col span={24}>
          <InputNumberCustom
            label="Tiền thuê đã thanh toán trước:"
            applyChange={applyChange}
            prop={"TienThueDaTT"}
            value={data?.TienThueDaTT}
            placeholder={"0"}
          />
        </Col>
        <Col span={24}>
          <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
      </Row>
      {contextHolder}
    </Modal>
  );
};
export default forwardRef(FormModal);
